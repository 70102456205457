import React from "react";
import { useQuery } from "react-apollo";

import { Header, Checkbox } from "semantic-ui-react";

import AutomaticCancel from "./BillingAutomation/AutomaticCancel";
import CommunicationsSetup from "./BillingAutomation/CommunicationsSetup";

import GQL_PREFERENCES from "src/graphql/queries/Preferences.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";
import { saveUiPref, getUiPref } from "src/utils/uiPreferences";

function BillingAutomation() {
  const { loading, error, data } = useQuery(GQL_PREFERENCES);
  const [state, setState] = React.useState({
    interest: getUiPref("interest", false),
  });

  function handleInterest(e) {
    const interest = !e.currentTarget.querySelector("input").checked;
    setState({ ...state, interest });
    saveUiPref("interest", interest);
  }

  if (loading) return <GqlLoadingIcon />;
  if (error) return <GqlError error={error} />;

  const {
    preferences: { communicationsSetup, automaticCancel },
  } = data;

  return (
    <div>
      <Header content="Aceitar pagamento até quantos dias após o vencimento?" />
      <div className="pl-4 pr-4">
        <AutomaticCancel days={automaticCancel} />
      </div>

      <Header content="Cobrar juros?" />
      <Checkbox
        toggle
        fitted
        onChange={handleInterest}
        checked={state.interest}
      />

      <Header content="Qual régua de cobrança é mais adequada?" />
      <CommunicationsSetup setup={communicationsSetup} />
    </div>
  );
}

export default BillingAutomation;
