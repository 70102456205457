import { gql } from 'apollo-boost'

import CustomerExtraFieldsProps from '../fragments/customer_extra_fields'

const GQL_UPSERT_CUSTOMER_EXTRA_FIELDS = gql`
  mutation UpsertCustomerExtraFields(
    $id: ID!,
    $extraFields: [CustomerExtraFieldInput!]!
  ) {
    updateExtraFields(
      id: $id,
      extraFields: $extraFields
    ) {
      ...CustomerExtraFieldsProps
    }
  }
  ${CustomerExtraFieldsProps}
`

export default GQL_UPSERT_CUSTOMER_EXTRA_FIELDS