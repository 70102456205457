import { gql } from 'apollo-boost'
import CustomerProps from '../fragments/customer'

const GQL_CUSTOMERS = gql`
  query Customers {
    customers {
      ...CustomerProps
    }
  }
  ${CustomerProps}
`

export default GQL_CUSTOMERS
