import React from "react";
import { Mutation } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";

import { routes } from "../../routes";
import toDecimal, { floatToDecimal } from "src/utils/decimal";
import { billingListable } from "../../../BillingFields/scopes";
import { recurrenceMajorFieldsEditable } from "../../../RecurrenceFields/scopes";
import { mdayText } from "../../../RecurrenceFields/misc";

import InlineEdit from "@atlaskit/inline-edit";
import { Message, Popup, Checkbox, Grid, Header } from "semantic-ui-react";
import { MdInfo, MdEdit, MdExpandLess, MdExpandMore } from "react-icons/md";
import { FaPauseCircle, FaPlayCircle } from "react-icons/fa";

import Input from "src/uikit/Forms/Input";
import DaySelector from "src/uikit/Forms/DaySelector";
import InlineReceivable from "../Receivables/InlineReceivable";

import style from "./EditRecurrence.module.css";

import GQL_UPDATE_RECURRENCE from "src/graphql/mutations/UpdateRecurrence.gql";
import GQL_RECURRENCE_BILLINGS from "src/graphql/queries/RecurrenceBillings.gql";
import GQL_PAUSE_RECURRENCE from "src/graphql/mutations/PauseRecurrence.gql";
import GQL_RESUME_RECURRENCE from "src/graphql/mutations/ResumeRecurrence.gql";
import GqlError from "src/uikit/GqlError";
import DestroyRecurrence from "./DestroyArchiveUnarchiveRecurrence";

class EditRecurrence extends React.Component {
  constructor(props) {
    super(props);

    const recurrence = this.props.recurrence || {};

    this.state = {
      redirectTo: null,

      id: recurrence.id,
      status: recurrence.status,
      amount: recurrence.amount,
      amountFace: floatToDecimal(recurrence.amount),
      mday: recurrence.mday,
      description: recurrence.description,
      interest: recurrence.interestPolicy !== "NO_INTEREST",
      interestPolicy: recurrence.interestPolicy,
      discountAmount: recurrence.discountAmount,
      discountAmountFace: floatToDecimal(recurrence.discountAmount),
      discountDays: recurrence.discountDays,
      totalCycles: recurrence.totalCycles,
    };

    this.handleAmount = this.handleAmount.bind(this);
    this.handleMday = this.handleMday.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleInterest = this.handleInterest.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.submittable = this.submittable.bind(this);
    this.getVars = this.getVars.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.goto = this.goto.bind(this);
  }

  handleAmount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amount: floatValue,
      amountFace: decimalValue,
    });
  }

  handleMday(mday) {
    this.setState({ mday });
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleInterest(value, callback) {
    const val = !this.state.interest;

    this.setState(
      {
        interest: val,
        interestPolicy: val ? "MARKET" : "NO_INTEREST",
      },
      callback
    );
  }

  handleDiscount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      discountAmount: floatValue,
      discountAmountFace: decimalValue,
    });
  }

  submittable() {
    if (
      this.state.discountAmount > 0 &&
      this.state.amount > 0 &&
      this.state.discountAmount >= this.state.amount
    )
      return false;

    if (this.props.customer.status !== "ACTIVE") return false;

    return this.state.mday && this.state.amount > 0;
  }

  getVars() {
    return {
      id: this.props.recurrence.id,
      amount: this.state.amount,
      description: this.state.description,
      interestPolicy: this.state.interestPolicy,
      discountAmount: this.state.discountAmount,
      discountDays: this.state.discountDays,
    };
  }

  handleMutationUpdate(cache, { data: { updateRecurrence } }) {
    cache.writeQuery({
      query: GQL_RECURRENCE_BILLINGS,
      variables: { id: updateRecurrence.id },
      data: {
        recurrence: updateRecurrence,
      },
    });
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  renderError(errors) {
    if (!errors) return null;

    return (
      <Message negative>
        Erro, cheque se os dados são válidos e os campos estão preenchidos.
      </Message>
    );
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  renderEmptyBlock() {
    return (
      <Grid.Row columns={1}>
        <Grid.Column className="cursor-default">
          <i>Nenhuma até o momento.</i>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderBillings(list, recurrence) {
    const billings = _.filter(list, billingListable);

    const scheduledBillings = _.sortBy(
      _.filter(billings, (b) => b.status === "PENDING"),
      (b) => moment(b.dueDate).format("YYYYMMDD")
    );

    const issuedBillings = _.reverse(
      _.sortBy(
        _.filter(billings, (b) => b.status !== "PENDING"),
        (b) => moment(b.dueDate).format("YYYYMMDD")
      )
    );

    const scheduledBillingsDisplay = _.slice(
      scheduledBillings,
      0,
      this.state.expanded ? _.size(scheduledBillings) : this.scheduledBlockSize
    );

    return (
      <div>
        <Header content="Programadas" className="pt-3 pb-3 cursor-default" />
        <Grid centered>
          {_.map(scheduledBillingsDisplay, (b) => (
            <InlineReceivable billing={b} key={`Billing:${b.id}`} />
          ))}
          {_.isEmpty(scheduledBillings) && this.renderEmptyBlock()}
          {_.size(scheduledBillings) > this.scheduledBlockSize && (
            <div>
              {this.state.expanded ? (
                <Popup
                  inverted
                  content="Mostrar apenas os mais próximos"
                  trigger={
                    <MdExpandLess
                      onClick={this.expandToggle}
                      className={style.Expand}
                    />
                  }
                />
              ) : (
                <Popup
                  inverted
                  content="Mostrar todas"
                  trigger={
                    <MdExpandMore
                      onClick={this.expandToggle}
                      className={style.Expand}
                    />
                  }
                />
              )}
            </div>
          )}
          {recurrence.status === "GENERATING" && recurrence.totalCycles <= 0 && (
            <Grid.Row>
              <Grid.Column>
                <p>Mostrando cobranças para os próximos 12 meses.</p>
                <p>
                  As cobranças com vencimento posterior serão geradas mês a mês.
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
        <Header
          content="Emitidas & histórico"
          className="pt-3 pb-3 cursor-default"
        />
        <Grid centered>
          {_.map(issuedBillings, (b) => (
            <InlineReceivable billing={b} key={`Billing:${b.id}`} />
          ))}
          {_.isEmpty(issuedBillings) && this.renderEmptyBlock()}
        </Grid>
      </div>
    );
  }

  renderPause() {
    return (
      <Mutation
        mutation={GQL_PAUSE_RECURRENCE}
        variables={{ id: this.props.recurrence.id }}
      >
        {(pauseRecurrence, { loading, error }) => {
          if (error) return <GqlError error={error} />;

          return (
            <div className="text-center">
              <Popup
                trigger={
                  <FaPauseCircle
                    size={48}
                    className={
                      loading ? style.ActionButtonDisabled : style.ActionButton
                    }
                    onClick={loading ? () => {} : pauseRecurrence}
                  />
                }
                inverted
                header="Pausar"
                content="Suspender todas as cobranças agendadas"
              />
            </div>
          );
        }}
      </Mutation>
    );
  }

  renderResume() {
    return (
      <Mutation
        mutation={GQL_RESUME_RECURRENCE}
        variables={{ id: this.props.recurrence.id }}
      >
        {(resumeRecurrence, { loading, error }) => {
          if (error) return <GqlError error={error} />;

          return (
            <div className="text-center">
              <Popup
                trigger={
                  <FaPlayCircle
                    size={48}
                    className={
                      loading ? style.ActionButtonDisabled : style.ActionButton
                    }
                    onClick={loading ? () => {} : resumeRecurrence}
                  />
                }
                inverted
                header="Reativar"
                content="Reagendar todas as cobranças"
              />
            </div>
          );
        }}
      </Mutation>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    if (!this.state.id)
      return (
        <Redirect
          to={
            this.props.customerId
              ? routes.CUSTOMER.getLink(this.props.customerId)
              : "/clientes"
          }
        />
      );

    const editPlaceholder = <MdEdit className={style.EditPlaceholder} />;
    const r = this.props.recurrence;
    const recurrence = this.state;
    const billings = this.props.recurrence.billings;

    return (
      <Mutation
        mutation={GQL_UPDATE_RECURRENCE}
        variables={this.getVars()}
        onError={this.handleMutationError}
      >
        {(updateRecurrence, { loading, error }) => {
          return (
            <div className={style.ModalBody}>
              <div className="mt-1 mb-3">
                <Link
                  to={routes.RECEIVABLES.getLink(
                    this.props.recurrence.customerId
                  )}
                  className={classNames(style.ListItemLink, "fadeInUp")}
                >
                  &laquo; Voltar para cobranças
                </Link>
              </div>

              {this.renderError(error)}

              <div className="mt-3">
                <div
                  className={classNames(
                    style.FieldLabel,
                    "text-center",
                    "cursor-default"
                  )}
                >
                  Valor
                </div>
                <div>
                  <Grid centered verticalAlign="middle">
                    <Grid.Row centered textAlign="center" columns={1}>
                      {recurrenceMajorFieldsEditable(r) ? (
                        <InlineEdit
                          defaultValue={this.state.amountFace}
                          readView={() =>
                            loading && r.amount !== recurrence.amount ? (
                              "Carregando..."
                            ) : (
                              <div
                                className={classNames(
                                  style.FieldView,
                                  style.Amount
                                )}
                              >
                                {recurrence.amountFace || editPlaceholder}
                              </div>
                            )
                          }
                          editView={(_fieldProps) => (
                            <Input
                              type="decimal"
                              placeholder="Valor"
                              onChange={this.handleAmount}
                              value={this.state.amountFace}
                              textAlign="center"
                              fontSize="large"
                            />
                          )}
                          onCancel={(_e) => {}}
                          onConfirm={(value, _) => updateRecurrence()}
                        />
                      ) : (
                        <Grid.Column
                          width={12}
                          textAlign={"center"}
                          className="pr-1"
                        >
                          <div
                            className={classNames(
                              style.Amount,
                              "cursor-default"
                            )}
                          >
                            {this.state.amountFace}
                          </div>
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </div>
              </div>

              <div className="mt-3">
                <div
                  className={classNames(
                    style.FieldLabel,
                    "text-center",
                    "cursor-default"
                  )}
                >
                  Vencimento
                </div>
                <div className="text-center">
                  {recurrenceMajorFieldsEditable(r) && false ? (
                    <InlineEdit
                      defaultValue={
                        recurrence.mday
                          ? moment(recurrence.mday).format("DD[/]MM[/]YYYY")
                          : null
                      }
                      readView={() =>
                        loading && r.mday !== recurrence.mday ? (
                          "Carregando..."
                        ) : (
                          <div
                            className={classNames(
                              style.FieldView,
                              style.DueDate
                            )}
                          >
                            {mdayText(r) || editPlaceholder}
                          </div>
                        )
                      }
                      editView={(_fieldProps) => (
                        <DaySelector
                          onClick={this.handleMday}
                          value={this.state.mday}
                          title="Vencimento"
                        />
                      )}
                      onCancel={(_e) => {}}
                      onConfirm={(value, _) => updateRecurrence()}
                    />
                  ) : (
                    <div
                      className={classNames(style.DueDate, "cursor-default")}
                    >
                      {mdayText(r)}
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-3">
                <div
                  className={classNames(
                    style.FieldLabel,
                    "text-center",
                    "cursor-default"
                  )}
                >
                  {r.status === "SUSPENDED" && this.renderResume()}
                  {["GENERATING", "GENERATED"].includes(r.status) &&
                    this.renderPause()}
                  {r.status === "SUSPENDED" && (
                    <DestroyRecurrence recurrence={r} size={48} />
                  )}
                </div>
              </div>

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Descrição
                </div>
                <InlineEdit
                  defaultValue={recurrence.description}
                  readView={() =>
                    loading && r.description !== recurrence.description ? (
                      "Carregando..."
                    ) : (
                      <div className={style.FieldView}>
                        {recurrence.description || editPlaceholder}
                      </div>
                    )
                  }
                  editView={(_fieldProps) => (
                    <Input
                      value={this.state.description}
                      onChange={this.handleDescription}
                      placeholder="Descrição"
                      showClearBtn
                    />
                  )}
                  onCancel={(_e) => {}}
                  onConfirm={(value, _) => updateRecurrence()}
                />
              </div>

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Juros&nbsp;
                  <Popup
                    trigger={<MdInfo size={12} />}
                    header="Juros de mercado"
                    content="2% de multa no atraso acrescido de 1% de mora ao mês"
                    inverted
                  />
                </div>
                {recurrenceMajorFieldsEditable(r) ? (
                  <Checkbox
                    toggle
                    fitted
                    onChange={(value, _) =>
                      this.handleInterest(value, updateRecurrence)
                    }
                    checked={this.state.interest}
                  />
                ) : (
                  <div className="cursor-default">
                    {this.state.interest ? "Sim" : "Não"}
                  </div>
                )}
              </div>

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Desconto até o vencimento
                </div>
                {recurrenceMajorFieldsEditable(r) ? (
                  <InlineEdit
                    defaultValue={recurrence.discountAmountFace}
                    readView={() =>
                      loading &&
                      r.discountAmount !== recurrence.discountAmount ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {recurrence.discountAmountFace || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(_fieldProps) => (
                      <Input
                        onChange={this.handleDiscount}
                        value={this.state.discountAmountFace}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _) => updateRecurrence()}
                  />
                ) : (
                  <div className="cursor-default">
                    {recurrence.discountAmountFace || "0,00"}
                  </div>
                )}
              </div>

              {this.renderBillings(billings, r)}
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default EditRecurrence;
