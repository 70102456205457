import { useMutation } from "@apollo/react-hooks";
import { MenuItem } from "@plipag/ceci-ui";
import GQL_DESTROY_CUSTOMER from "src/graphql/mutations/DestroyCustomer.gql";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  id: number;
  onClick: () => void;
};

export function DestroyButton({ id, onClick }: Props) {
  const [dispatch, { loading, error }] = useMutation(GQL_DESTROY_CUSTOMER, {
    variables: { id },
    refetchQueries: ["CustomersConnection"],
  });

  async function handleClick() {
    if (
      !window.confirm(
        "Esta é uma operação permanente. Uma vez excluído, não será possível reaver suas informações cadastrais, anotações e outros registros históricos. \nTem certeza de que deseja continuar?"
      )
    ) {
      return;
    }
    await dispatch();
    toast("Cliente excluído com sucesso", { hideProgressBar: true });
    onClick();
  }

  if (error) {
    console.log("error", error);
  }

  return (
    <MenuItem onClick={handleClick} disabled={loading}>
      Excluir cliente
    </MenuItem>
  );
}
