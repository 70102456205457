import { gql } from 'apollo-boost'

import CustomerProps from '../fragments/customer'
import RecurrenceProps from '../fragments/recurrence'
import BillingProps from '../fragments/billing'

const GQL_ARCHIVE_CUSTOMER = gql`
  mutation ArchiveCustomer($id: ID!) {
    archiveCustomer(id: $id) {
      ...CustomerProps
      recurrences { ...RecurrenceProps }
      billings { ...BillingProps }
    }
  }
  ${CustomerProps}
  ${RecurrenceProps}
  ${BillingProps}
`

export default GQL_ARCHIVE_CUSTOMER