export function nameShortner(name, style = '') {
  const str = (name || "").trim().split(' ')
  const n = str.length

  if (n <= 1)
    return name

  const
    first = str[0],
    last = str[n - 1]

  switch (style) {
    case 'Full':
      return name
    case 'F Last':
      return `${first[0]}. ${last ? last : null}`
    case 'First Last':
      return `${first} ${last ? last : null}`
    case 'First L':
      return `${first} ${last ? last[0] : null}.`
    case 'First':
      return first
    case 'Last':
      return last
    default:
      throw Error('Invalid name shortner style')
  }
}
