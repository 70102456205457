import React from "react";
import { Mutation } from "react-apollo";

import {
  Button,
  Grid,
  Header,
  Placeholder,
  Popup,
  Icon,
} from "semantic-ui-react";
import { MdEmail } from "react-icons/md";

import { formatPhone, PHONE_FORMATS } from "src/utils/phone";
import { validEmail } from "src/utils/email";

import Input from "src/uikit/Forms/Input";

import style from "./Review.module.css";

import GQL_BILLING_SEND_EXAMPLE from "src/graphql/mutations/BillingSendExample.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

const EMPTY_LOGO =
  "https://assets.plipag.com.br/conta/images/logos/billing-no-logo.png";

class Review extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      email: this.props.merchant.email || "",
    };

    this.handleEmail = this.handleEmail.bind(this);
    this.sendBilling = this.sendBilling.bind(this);
  }

  handleEmail(e) {
    this.setState({ email: e.target.value });
  }

  sendBilling() {
    this.setState({
      submitted: true,
    });
  }

  renderFormReplacement() {
    return (
      <div>
        <Header>
          <Header.Content>Fatura configurada!</Header.Content>
          <Header.Subheader className="mt-2">
            A fatura foi personalizada e os clientes receberão uma fatura como
            esta.
          </Header.Subheader>
        </Header>
      </div>
    );
  }

  renderForm() {
    return (
      <div>
        <Header>
          <Header.Content>Fatura de teste</Header.Content>
          <Header.Subheader className="mt-2">
            Envie para seu email uma fatura de exemplo, similar às que serão
            entregues aos clientes.
          </Header.Subheader>
        </Header>
        <Input
          icon={<MdEmail size={18} />}
          onChange={this.handleEmail}
          value={this.state.email}
          invalid={!validEmail(this.state.email)}
          placeholder="Email"
        />
        <Mutation
          mutation={GQL_BILLING_SEND_EXAMPLE}
          variables={{ email: this.state.email }}
        >
          {(sendExampleBilling, { loading, error }) => {
            if (loading) return <GqlLoadingIcon />;
            if (error) return <GqlError error={error} />;

            const click = () => {
              this.sendBilling();
              sendExampleBilling();
            };

            return (
              <Button
                primary
                className="mt-3"
                onClick={click}
                disabled={!validEmail(this.state.email)}
              >
                Enviar
              </Button>
            );
          }}
        </Mutation>
      </div>
    );
  }

  renderFormSubmitted() {
    return (
      <div>
        <Header>
          <Header.Content>Fatura enviada</Header.Content>
          <Header.Subheader>
            Cheque o email {this.state.email}, uma fatura de exemplo foi enviada
          </Header.Subheader>
        </Header>
      </div>
    );
  }

  renderBillingPreview() {
    return (
      <div className={style.BillingExample}>
        <Grid centered>
          <Grid.Row columns={3}>
            <Grid.Column width={4}>
              {this.props.merchant.logo ? (
                <img
                  src={this.props.merchant.logo}
                  alt=""
                  className={style.Logo}
                />
              ) : (
                <Popup
                  trigger={
                    <img src={EMPTY_LOGO} alt="" className={style.Logo} />
                  }
                  inverted
                  position="left center"
                  header="Logo"
                  content="Volte no passo anterior e coloque sua logo aqui"
                />
              )}
            </Grid.Column>
            <Grid.Column width={8}>
              <div className={style.MerchantName}>
                {this.props.merchant.name}
              </div>
              <Popup
                trigger={
                  <div className={style.MerchantInfo}>
                    {this.props.merchant.document}
                    <br />
                    {this.props.merchant.street}
                    <br />
                    {this.props.merchant.district}
                    <br />
                    {this.props.merchant.city} / {this.props.merchant.province}
                    &nbsp;- {this.props.merchant.zipcode}
                    <br />
                    {this.props.merchant.email}
                    <br />
                    {formatPhone(this.props.merchant.phone, PHONE_FORMATS.E164)}
                  </div>
                }
                header="Identificação"
                inverted
                position="bottom center"
                content="A identificação completa do cobrador é obrigatória,
                  conforme resoluções normativas do Banco Central."
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Popup
                trigger={
                  <div className={style.CustomerName}>
                    Fulano da Silva
                    <br />
                    123.456.789-09
                  </div>
                }
                header="Nome do cliente pagador"
                content="Identificação clara"
                inverted
                position="left center"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <hr className={style.Bar} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={3}>
            <Grid.Column width={10}>
              <Header color="blue">Sua fatura está pronta!</Header>
              <Placeholder>
                <Placeholder.Paragraph>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Paragraph>
              </Placeholder>
            </Grid.Column>
            <Grid.Column width={3} />
            <Grid.Column width={3}>
              <Popup
                trigger={
                  <div className={style.Meajuda}>
                    <MdEmail size={24} />
                    <br />
                    <b>meajuda</b>@<br />
                    plipag.com.br
                  </div>
                }
                header="Dificuldade no pagamento?"
                content="Quem cuida é o PLI."
                inverted
                position="left center"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Popup
                trigger={
                  <div>
                    <Header>Referente a</Header>
                    <Placeholder>
                      <Placeholder.Paragraph>
                        <Placeholder.Line />
                        <Placeholder.Line />
                      </Placeholder.Paragraph>
                    </Placeholder>
                  </div>
                }
                inverted
                header="Descrição da fatura"
                position="top center"
                content="Ao lançar uma cobrança, a descrição dela aparece aqui"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <div className={style.Boleto}>
                <Placeholder>
                  <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Header>
                  <Placeholder.Paragraph>
                    <Placeholder.Line />
                    <Placeholder.Line />
                    <Placeholder.Line />
                  </Placeholder.Paragraph>
                </Placeholder>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={16}>
              <Popup
                trigger={
                  <div>
                    <Icon
                      name="barcode"
                      size="huge"
                      className={style.PaymentMethod}
                    />
                    <Icon
                      name="qrcode"
                      size="huge"
                      className={style.PaymentMethod}
                    />
                  </div>
                }
                inverted
                content="Meios de pagamento"
                position="left center"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render() {
    return (
      <Grid centered stackable doubling className="mt-5">
        <Grid.Row columns={2}>
          <Grid.Column width={4}>
            {this.state.submitted
              ? this.renderFormSubmitted()
              : this.renderFormReplacement()}
          </Grid.Column>
          <Grid.Column width={12}>{this.renderBillingPreview()}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default Review;
