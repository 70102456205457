import { gql } from 'apollo-boost'

const GQL_BILLING_CREATE_FAKE = gql`
  mutation BillingCreateFake($amount: Float!, $name: String!, $email: Email!) {
    createFakeBilling(amount: $amount, name: $name, email: $email) {
      id
      customerId
      url
      urlPreview
    }
  }
`

export default GQL_BILLING_CREATE_FAKE
