import React, { useRef, useEffect } from "react";
import * as typeformEmbed from "@typeform/embed";
import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";
import { useQuery } from "react-apollo";

const TypeformElem = ({ url }) => {
  const typeformRef = useRef(null);
  const { error, loading, data } = useQuery(GQL_MERCHANT);

  useEffect(() => {
    if (!data) return;

    typeformEmbed.makeWidget(
      typeformRef.current,
      `${url}#email=${data.merchant.email}`,
      {
        hideFooter: true,
        hideHeaders: true,
        opacity: 0,
      }
    );
  }, [data, url, typeformRef]);

  if (error || loading) return null;

  return <div ref={typeformRef} style={{ height: "100vh", width: "100%" }} />;
};

export default TypeformElem;
