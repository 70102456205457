const uiPrefsKey = 'uiPreferences'

export function uiPrefs() {
  return JSON.parse(localStorage.getItem(uiPrefsKey) || '{}')
}

export function saveUiPref(key, val) {
  localStorage.setItem(
    uiPrefsKey,
    JSON.stringify(
      Object.assign(
        uiPrefs(),
        { [key.trim()]: val }
      )
    )
  )
}

export function getUiPref(key, defaultValue) {
  const obj = uiPrefs()

  return typeof obj[key] === 'undefined' ? defaultValue : obj[key]
}
