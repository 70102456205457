import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { Button } from "@plipag/ceci-ui";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { CustomersArchive } from "src/graphql/mutations/CustomersArchive.gql";
import { CustomersContext } from "../context";

export function ArchiveCustomers() {
  const { selecteds, items, setSelecteds } = React.useContext(CustomersContext);
  const [dispatch, { loading }] = useMutation(CustomersArchive, {
    variables: {
      customersIds: items
        .filter(({ id }) => selecteds.includes(id))
        .map(({ id }) => id),
    },
    refetchQueries: ["CustomersConnection"],
  });

  async function handleClick() {
    await dispatch();
    toast("Clientes arquivados com sucesso", { hideProgressBar: true });
    setSelecteds([]);
  }

  return (
    <Button
      size="large"
      variant="outlined"
      onClick={handleClick}
      disabled={loading}
    >
      <MdDelete size={24} style={{ marginBottom: 2, marginRight: 10 }} />
      Arquivar clientes
    </Button>
  );
}
