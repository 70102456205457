import React from 'react'
import classNames from 'classnames'
import style from './PasswordStrength.module.css'

class PasswordStrength extends React.Component {
  render() {
    return (
      <progress value={this.props.strength} max={4} className={
        classNames(
          style.MeterProgress,
          style['Strength-' + this.props.strength]
        )
      } />
    )
  }
}

export default PasswordStrength
