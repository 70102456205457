const a = {
  // Animals.
  DOG: "circular/long_shadow/1805783-animals/png/047-dog.png",
  CAT: "circular/long_shadow/1805783-animals/png/022-cat.png",
  MONKEY: "circular/long_shadow/1805783-animals/png/049-monkey.png",
  PARROT: "circular/long_shadow/1805783-animals/png/045-parrot.png",
  BUTTERFLY: "circular/long_shadow/1805783-animals/png/043-butterfly.png",
  ZEBRA: "circular/long_shadow/1805783-animals/png/041-zebra.png",
  LADYBUG: "circular/long_shadow/1805783-animals/png/035-ladybug.png",
  FOX: "circular/long_shadow/1805783-animals/png/033-fox.png",
  RHINO: "circular/long_shadow/1805783-animals/png/029-rhino.png",
  HEDGEHOG: "circular/long_shadow/1805783-animals/png/026-hedgehog.png",
  COW: "circular/long_shadow/1805783-animals/png/021-cow.png",
  GIRAFFE: "circular/long_shadow/1805783-animals/png/013-giraffe.png",
  PANDA: "circular/long_shadow/1805783-animals/png/011-panda bear.png",
  PENGUIN: "circular/long_shadow/1805783-animals/png/010-penguin.png",
  LION: "circular/long_shadow/1805783-animals/png/009-lion.png",
  ELEPHANT: "circular/long_shadow/1805783-animals/png/008-elephant.png",
  LLAMA: "circular/long_shadow/1805783-animals/png/032-llama.png",
  BOAR: "circular/long_shadow/1805783-animals/png/017-boar.png",
  KRAKEN: "circular/long_shadow/1999469-pirates/png/014-kraken.png",
  WHALE: "circular/long_shadow/1999469-pirates/png/031-whale.png",
  // Business & teams.
  CONTRACT: "circular/long_shadow/262802-business-strategy/png/contract.png",
  DEAL: "circular/long_shadow/262802-business-strategy/png/deal.png",
  STRATEGY: "circular/long_shadow/262802-business-strategy/png/strategy-1.png",
  PUZZLE: "circular/long_shadow/262802-business-strategy/png/puzzle.png",
  LAW: "circular/long_shadow/1083496-teamwork/png/039-law-1.png",
  // Science & astronomy.
  ATOM: "circular/long_shadow/172006-scientific-study/png/atom.png",
  COMETS: "circular/long_shadow/172006-scientific-study/png/comets.png",
  COMPASS: "circular/long_shadow/172006-scientific-study/png/compass.png",
  EARTH_GLOBE:
    "circular/long_shadow/172006-scientific-study/png/earth-globe.png",
  ROCKET: "circular/long_shadow/172006-scientific-study/png/rocket.png",
  UNIVERSE: "circular/long_shadow/181751-space/png/universe.png",
  MAD_SCIENTIST:
    "circular/long_shadow/1048872-mad-science/png/017-mad-scientist.png",
  PULSE: "circular/long_shadow/1048872-mad-science/png/048-pulse.png",
  FLASK: "circular/long_shadow/1048872-mad-science/png/015-flask-1.png",
  THERMOMETER:
    "circular/long_shadow/1048872-mad-science/png/050-thermometer.png",
  // Games.
  GHOST: "circular/long_shadow/2076256-gaming/png/021-ghost.png",
  PACMAN: "circular/long_shadow/2076256-gaming/png/034-pacman.png",
  CHESS_BOARD: "circular/long_shadow/2076256-gaming/png/043-chess board.png",
  POOL: "circular/long_shadow/2076256-gaming/png/049-pool.png",
  FOOTBALL: "circular/long_shadow/2076256-gaming/png/017-football field.png",
};

export default a;
