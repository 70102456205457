import moment from 'moment'
import 'moment/locale/pt-br'

/*
 * Have a look at
 * http://www.buscabanco.org.br/feriados/feriados.asp
 */
export const holidays = [
  moment("2019-01-01"),
  moment("2019-03-04"),
  moment("2019-03-05"),
  moment("2019-04-19"),
  moment("2019-04-21"),
  moment("2019-05-01"),
  moment("2019-06-20"),
  moment("2019-09-07"),
  moment("2019-10-12"),
  moment("2019-11-02"),
  moment("2019-11-15"),
  moment("2019-12-25"),
  moment("2019-12-31"),

  moment("2020-01-01"),
  moment("2020-02-24"),
  moment("2020-02-25"),
  moment("2020-04-10"),
  moment("2020-04-21"),
  moment("2020-05-01"),
  moment("2020-06-11"),
  moment("2020-09-07"),
  moment("2020-10-12"),
  moment("2020-11-02"),
  moment("2020-11-15"),
  moment("2020-12-25"),
  moment("2020-12-31"),
]

export function isHoliday(date) {
  const d = moment(date).startOf('day')

  for (let i = 0; i < holidays.length; i++)
    if (holidays[i].startOf('day').isSame(d))
      return true

  return false
}

export function isBusinessDay(date) {
  return [1, 2, 3, 4, 5].includes(date.weekday()) && !isHoliday(date)
}

export function businessDayBackward(date = moment().subtract(1, 'day')) {
  return isBusinessDay(date) ?
    moment(date) :
    businessDayBackward(moment(date).subtract(1, 'day'))
}

export function businessDayForward(date = moment().add(1, 'day')) {
  return isBusinessDay(date) ?
    moment(date) :
    businessDayForward(moment(date).add(1, 'day'))
}
