import { gql } from 'apollo-boost'

import SettlementProps from '../fragments/settlement'

const GQL_SETTLEMENT = gql`
  query Settlement($id: ID!) {
    settlement(id: $id) {
      ...SettlementProps
    }
  }
  ${SettlementProps}
`

export default GQL_SETTLEMENT