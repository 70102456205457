import React from 'react'
import classNames from 'classnames'

import { Popup } from 'semantic-ui-react'
import { FaBackspace, FaEye, FaEyeSlash } from 'react-icons/fa'
import { MdWarning } from 'react-icons/md'

import style from './Input.module.css'

class Input extends React.Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      type: this.props.type || 'text'
    }

    this.handleClear = this.handleClear.bind(this)
    this.toggleDisplayPassword = this.toggleDisplayPassword.bind(this)
  }

  handleClear() {
    this.props.onChange({ target: { value: '' } })
  }

  toggleDisplayPassword() {
    this.setState({
      type: this.state.type === 'text' ? 'password' : 'text'
    })
  }

  textAlign() {
    switch (this.props.textAlign) {
      case 'left':
        return style.TextLeft
      case 'center':
        return style.TextCenter
      case 'right':
        return style.TextRight
      default:
        return style.TextLeft
    }
  }

  fontSize() {
    switch (this.props.fontSize) {
      case 'medium':
        return style.FontMedium
      case 'large':
        return style.FontLarge
      default:
        return style.FontMedium
    }
  }

  rightButton(value) {
    if (this.props.invalid && this.props.invalidMessage)
      return (
        <div className={style.RightBtnContainer}>
          <Popup trigger={
            <MdWarning className={style.InvalidMessage} />
          } header="Aviso" content={this.props.invalidMessage} inverted position="top right" />
        </div>
      )

    const shouldShowClearBtn = this.props.showClearBtn === 'undefined' ?
      false : this.props.showClearBtn
    if (shouldShowClearBtn && value.length > 0 && !this.props.disabled && !this.props.displayPassword)
      return (
        <div className={style.RightBtnContainer}>
          <FaBackspace className={style.ClearBtn} onClick={this.handleClear} />
        </div>
      )

    if (this.props.displayPassword)
      return (
        <div className={style.RightBtnContainer}>
          {
            this.state.type === 'text' &&
              <FaEyeSlash
                className={style.ClearBtn}
                onClick={this.toggleDisplayPassword} />
          }
          {
            this.state.type === 'password' &&
              <FaEye
                className={style.ClearBtn}
                onClick={this.toggleDisplayPassword} />
          }
        </div>
      )
  }

  render() {
    const disabledContainer = this.props.disabled ? style.NoBorder : style.HoverableIcon
    const disabledBtn = this.props.disabled ? style.Locked : null
    const invalidInput = this.props.invalid ? style.Invalid : null
    const value = this.props.value || ""
    const autoComplete = this.props.autoComplete !== null ?
      (this.props.autoComplete ? 'enabled' : 'disabled') : "disabled"
    const multiline = this.props.multiline
    const autofocus = this.props.autofocus || this.props.autoFocus
    const zoom = this.props.zoom ? String(this.props.zoom) : '100%'

    return (
      <div className={
        classNames(style.FieldContainer, disabledContainer, invalidInput)
      } style={{ transform: `scale(${zoom})` }}>
        {
          this.props.icon && (
            <div className={style.Icon}>
              { this.props.icon }
            </div>
          )
        }
        <div className={classNames("col pl-1 pr-1")}>
          {
            multiline ? (
              <textarea
                value={value}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                className={classNames(style.Text, this.textAlign(),
                  this.fontSize(), disabledBtn)}
                disabled={this.props.disabled}
                autoComplete={autoComplete}
                rows={3} style={{ resize: 'none' }}
                autoFocus={autofocus}
              />
            ) : (
              <input
                value={value}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                className={classNames(style.Text, this.textAlign(),
                  this.fontSize(), disabledBtn)}
                type={this.state.type}
                disabled={this.props.disabled}
                autoComplete={autoComplete}
                autoFocus={autofocus}
              />
            )
          }
        </div>
        { this.rightButton(value) }
      </div>
    )
  }
}

export default Input