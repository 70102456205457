import React from "react";
import { Redirect, useParams } from "react-router-dom";

export function SetToken(token, userHash) {
  if (userHash) {
    localStorage.setItem("user_hash", userHash);
  }
  localStorage.setItem("token", token);
}

export function SetTokenComponent() {
  const { token, userHash } = useParams();
  SetToken(token, userHash);
  return <Redirect to="/" />;
}
