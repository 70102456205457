import React from "react";
import { Link } from "react-router-dom";
import { Button, Grid, useTheme, useMediaQuery } from "@plipag/ceci-ui";
import Page from "../components/Page";
import FileCard from "../components/FileCard";
import Steps from "../components/Steps";

const Documents = () => {
  // eslint-disable-next-line
  const [canContinue, setCanContinue] = React.useState(false);
  function handleContinue() {
    setCanContinue(!!document || !!addressProof);
  }

  const [document, setDocument] = React.useState(null);
  function changeDocument(document) {
    setDocument(document);
    handleContinue();
  }

  const [addressProof, setAddressProof] = React.useState(null);
  function changeAddressProof(addressProof) {
    console.log("change,", addressProof.name, arguments);
    setAddressProof(addressProof);
    handleContinue();
  }

  function handleSubmit(e) {
    e.preventDefault();
  }
  const theme = useTheme();
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Page>
      <Steps activeStep={2} />
      <Grid
        container
        component="form"
        direction="column"
        flexGrow={largeDisplay ? 0 : 1}
        spacing={2}
        style={{ padding: 30, maxWidth: 480, margin: "0 auto" }}
        onSubmit={handleSubmit}
      >
        <Grid item>
          <FileCard
            title="Documento oficial com foto"
            subtitle="Vale RG (frente e verso), passaporte, CNH, etc."
            dialogTitle={
              <span>
                Agora manda pra gente um{" "}
                <strong>documento oficial com foto</strong>.
              </span>
            }
            dialogContent="Vale RG (frente e verso), passaporte, CNH, etc."
            value={document}
            onChange={(file) => changeDocument(file)}
          />
        </Grid>
        <Grid item>
          <FileCard
            title="Comprovante de residência"
            subtitle="Precisar de um comprovante de residência em seu nome ou de algum familiar."
            dialogTitle={
              <span>
                Envie um <strong>comprovante de residência</strong> em seu nome.
              </span>
            }
            dialogContent="Envie um comprovante de residência em seu nome."
            value={addressProof}
            onChange={(file) => changeAddressProof(file)}
          />
        </Grid>
        <Grid item sx={{ marginBottom: 0, marginTop: "auto" }}>
          <Button
            fullWidth
            size="large"
            color="secondary"
            variant="contained"
            to="/bank-informations"
            component={Link}
          >
            Continuar
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Documents;
