import React from "react";

import { Button } from "semantic-ui-react";
import { MdPersonAdd } from "react-icons/md";

export default function a() {
  return (
    <Button
      primary
      className="pill"
      style={{
        marginRight: 15,
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
        marginBottom: 20,
      }}
    >
      <MdPersonAdd size={22} />
      <span
        className="ml-2 a-m"
        style={{ paddingTop: 3, display: "inline-block" }}
      >
        Novo cliente
      </span>
    </Button>
  );
}
