import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'
import CustomerProps from '../fragments/customer'

const GQL_BILLINGS_CUSTOMERS = gql`
  query BillingsCustomers($startDueDate: Date, $endDueDate: Date) {
    billings(startDueDate: $startDueDate, endDueDate: $endDueDate) {
      ...BillingProps
      customer {
        ...CustomerProps
      }
    }
  }
  ${BillingProps}
  ${CustomerProps}
`

export default GQL_BILLINGS_CUSTOMERS
