import React from "react";
import { Mutation } from "react-apollo";
import _ from "lodash";
import classNames from "classnames";

import { Button, Divider, Header, Message } from "semantic-ui-react";
import { MdPeople } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

import Modal from "src/uikit/Modal";
import Input from "src/uikit/Forms/Input";
import ImgIcon from "src/uikit/ImgIcon";
import availableIcons from "src/uikit/ImgIcon/availableIcons";
import DestroyCgroupBtn from "./partials/DestroyCgroupBtn";

import style from "./MutateCgroupModal.module.css";

import GQL_UPDATE_CGROUP from "src/graphql/mutations/UpdateCgroup.gql";
import GQL_CGROUPS from "src/graphql/queries/Cgroups.gql";

class MutateCgroupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,

      name: this.props.cgroup.name,
      color: this.props.cgroup.color,
      icon: this.props.cgroup.icon,
    };

    this.getParams = this.getParams.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.playSFX = this.playSFX.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleIcon = this.handleIcon.bind(this);
    this.openCallback = this.openCallback.bind(this);
  }

  getParams() {
    return {
      id: this.props.cgroup.id,
      name: this.state.name,
      color: this.state.color,
      icon: this.state.icon,
    };
  }

  clearFields() {
    this.setState({
      name: this.props.cgroup.name,
      color: this.props.cgroup.color,
      icon: this.props.cgroup.icon,
    });
  }

  async submitForm() {
    await this.setState({
      name: this.state.name.trim(),
    });

    if (String(this.state.name).length < 3) {
      this.setState({
        error: "Dê um nome para o grupo de pelo menos 3 letras",
      });
      return false;
    }

    return true;
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Escolha um nome válido e um ícone para o grupo",
    });
  }

  handleMutationComplete() {}

  handleMutationUpdate(cache, { data: { updateCgroup } }) {
    cache.writeQuery({
      query: GQL_CGROUPS,
      data: {
        cgroups: _.concat(
          _.filter(
            cache.readQuery({ query: GQL_CGROUPS }).cgroups,
            (g) => g.id !== updateCgroup.id
          ),
          updateCgroup
        ),
      },
    });
  }

  playSFX() {
    this.setState({
      sfx: true,
    });

    return true;
  }

  handleName({ target: { value } }) {
    this.setState({
      name: value,
    });
  }

  handleIcon(key) {
    this.setState({
      icon: key,
    });
  }

  renderForm(submitCallback, loading, closeModal) {
    return (
      <div>
        {this.state.error && (
          <div className={style.ModalBody}>
            <Message negative>{this.state.error}</Message>
          </div>
        )}

        <div className={style.ModalBody}>
          <div className="p-4">
            <Input
              placeholder="Nome do grupo"
              icon={<MdPeople />}
              value={this.state.name}
              onChange={this.handleName}
              fluid
              autofocus
            />
          </div>
          <div className="mt-4 text-center">
            <Header size="small" color="grey">
              Selecione um ícone
            </Header>
            <div>
              {_.map(availableIcons, (val, key) => (
                <ImgIcon
                  iconPath={val}
                  className={classNames(
                    style.Icon,
                    this.state.icon === key ? style.IconSelected : null
                  )}
                  key={key}
                  onClick={() => this.handleIcon(key)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={style.ModalFooter}>
          <Button
            primary
            size="large"
            fluid
            onClick={submitCallback}
            disabled={loading}
          >
            <FaCheckCircle />
            <span className="a-m ml-2">
              {loading ? "Só um instante..." : "Atualizar"}
            </span>
          </Button>
          <Divider hidden />
          <DestroyCgroupBtn
            id={this.props.cgroup.id}
            submitCallback={closeModal}
            clearCgroupSelection={this.props.clearCgroupSelection}
          />
        </div>
      </div>
    );
  }

  openCallback() {
    this.clearFields();
  }

  render() {
    return (
      <Modal
        trigger={this.props.trigger}
        size="small"
        closeBtnDark
        openCallback={this.openCallback}
        onClick={(e) => e?.stopPropagation()}
      >
        {({ closeModal }) => (
          <Mutation
            mutation={GQL_UPDATE_CGROUP}
            variables={this.getParams()}
            onError={this.handleMutationError}
            onCompleted={this.handleMutationComplete}
            update={this.handleMutationUpdate}
            onClick={(e) => e?.stopPropagation()}
          >
            {(updateCgroup, { loading }) =>
              this.renderForm(
                async () =>
                  (await this.submitForm()) && updateCgroup() && closeModal(),
                loading,
                closeModal
              )
            }
          </Mutation>
        )}
      </Modal>
    );
  }
}

export default MutateCgroupModal;
