import React from "react";
import CreateReceivableGroup from "src/components/Customer/partials/CreateReceivableGroup";
import { CustomersContext } from "../../context";
import { Button } from "@plipag/ceci-ui";
import Modal from "src/uikit/Modal";
import { MdAdd } from "react-icons/md";
import { SuccessDialog } from "./SuccessDialog";
import { Link } from "react-router-dom";

export function CreateReceivable() {
  const { selecteds, filter } = React.useContext(CustomersContext);
  const [successOpen, setSuccessOpen] = React.useState<boolean>(false);
  const [billings, setBillings] = React.useState<Array<{ [key: string]: any }>>(
    []
  );

  function handleSuccess(billings: Array<{ [key: string]: any }>) {
    setSuccessOpen(true);
    setBillings(billings);
  }

  return (
    <>
      <Modal
        size="small"
        trigger={
          <Button
            size="large"
            variant="contained"
            color="success"
            component={Link}
            disabled={filter === "ARCHIVED"}
            to="#"
          >
            <MdAdd size={24} style={{ marginBottom: 2, marginRight: 10 }} />
            Nova cobrança
          </Button>
        }
      >
        {({ closeModal }: { closeModal: () => void }) => (
          <CreateReceivableGroup
            ids={selecteds.map((v) => ~~v)}
            closeModal={closeModal}
            handleSuccess={handleSuccess}
          />
        )}
      </Modal>
      {successOpen && (
        <SuccessDialog
          items={billings}
          onClose={() => setSuccessOpen(false)}
          close={() => {
            setSuccessOpen(false);
            setBillings([]);
          }}
        />
      )}
    </>
  );
}
