import _ from 'lodash'

export const sortingOptions = [
//  {
//    key: "none",
//    text: "Ordenar por",
//    value: "none",
//    disabled: true,
//  },
  {
    key: "dueDate",
    text: "por vencimento",
    value: "dueDate",
  },
  {
    key: "amountBilled",
    text: "por valor cobrado",
    value: "amountBilled",
  },
  {
    key: "status",
    text: "por situação",
    value: "status",
  },
  {
    key: "paymentDate",
    text: "por data de pagamento",
    value: "paymentDate",
  },
  {
    key: "amountPaid",
    text: "por valor pago",
    value: "amountPaid",
  },
]

export function applySorting(elements, criteria) {
  switch (criteria) {
    case "none":
      return elements
    case "dueDate":
      return _.reverse(_.sortBy(elements, "dueDate"))
    case "amountBilled":
      return _.reverse(_.sortBy(elements, "amountBilled"))
    case "status":
      return _.reverse(_.sortBy(elements, "status"))
    case "paymentDate":
      return _.reverse(_.sortBy(elements, ["status", "statusDate"]))
    case "amountPaid":
      return _.reverse(_.sortBy(elements, "amountPaid"))
    default:
      return elements
  }
}
