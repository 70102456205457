import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Typography, Box, useTheme } from "@plipag/ceci-ui";
import MerchantQuery from "src/graphql/queries/Merchant.gql";

const Card = (props) => (
  <Box
    {...props}
    sx={{
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      marginRight: 1,
    }}
  />
);

const Avatar = (props) => (
  <Box
    {...props}
    sx={{
      maxWidth: 50,
      minWidth: 50,
      height: 50,
      overflow: "hidden",
      marginRight: 1,
      background: "#fff",
      display: "flex",
      alignItems: "center",
    }}
  />
);

const Circle = (props) => (
  <Box
    {...props}
    sx={{
      width: 10,
      height: 10,
      marginRight: 1,
      borderRadius: "50%",
      alignSelf: "center",
    }}
  />
);

const Content = (props) => (
  <Box
    {...props}
    sx={{
      flexGrow: 1,
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignSelf: "center",
    }}
  />
);

const NoAvatarMerchant = ({ name }) => {
  const theme = useTheme();
  const [first, second] = name.split(" ");
  const abbr = first[0] + (second ? second[0] : first[1]);
  return (
    <Box
      sx={{
        background: theme.palette.secondary.main,
        width: "100%",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: "bold",
        fontSize: "1.3em",
      }}
    >
      {abbr.toUpperCase()}
    </Box>
  );
};

const MerchantCard = (props) => {
  const { data, loading, error } = useQuery(MerchantQuery);
  if (loading || error) return null;
  const { merchant } = data;
  const status = {
    ENABLED: "Conta Ativa",
    SUSPENDED: "Conta Sob Análise",
    BLOCKED: "Conta Sob Análise",
    LIMITED: "Conta Limitada",
    DEMO: "Conta Limitada",
    INACTIVE: "Conta Inativa",
  };

  const color = {
    ENABLED: "limegreen",
    SUSPENDED: "#7a00cc",
    BLOCKED: "#7a00cc",
    LIMITED: "#767676",
    DEMO: "#767676",
    INACTIVE: "#767676",
  };

  return (
    <Card {...props}>
      <Avatar>
        {!merchant.logo && <NoAvatarMerchant name={merchant.name} />}
        {merchant.logo && <img src={merchant.logo} width="100%" alt="" />}
      </Avatar>
      <Content>
        <Typography variant="p" style={{ whiteSpace: "nowrap" }}>
          {merchant.name}
        </Typography>
        <Typography
          component="div"
          style={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
        >
          <Circle style={{ background: color[merchant.status] }} />
          {status[merchant.status]}
        </Typography>
      </Content>
    </Card>
  );
};

export default MerchantCard;
