export function billingListable(b) {
  return [
    "PENDING",
    "CREATED_PAYMENT",
    "OPEN_PAYMENT",
    "NO_PAYMENT",
    "UNDERPAYMENT",
    "FULL_PAYMENT",
    "OVERPAYMENT",
    "PAID",

    // 'SUSPENDED',
    // 'EXPIRED',
    // 'DELETED',

    // Reserved for future usage.
    "REFUND",
    "CHARGEBACK",
    "ON_HOLD",
  ].includes(b.status);
}

export function billingWasIssued(b) {
  return [
    "CREATED_PAYMENT",
    "OPEN_PAYMENT",
    "NO_PAYMENT",
    "UNDERPAYMENT",
    "FULL_PAYMENT",
    "OVERPAYMENT",
    "PAID",

    // Reserved for future usage
    "REFUND",
    "CHARGEBACK",
    "ON_HOLD",
  ].includes(b.status);
}

export function billingReceivable(b) {
  return (
    ["PENDING", "CREATED_PAYMENT", "OPEN_PAYMENT"].includes(b.status) &&
    b.proxy === null
  );
}

export function billingHasPayment(b) {
  return [
    "CREATED_PAYMENT",
    "OPEN_PAYMENT",
    "NO_PAYMENT",
    "UNDERPAYMENT",
    "FULL_PAYMENT",
    "OVERPAYMENT",
    "PAID",

    // Reserved for future usage
    "REFUND",
    "CHARGEBACK",
    "ON_HOLD",
  ].includes(b.status);
}

export function billingMajorFieldsEditable(b) {
  return ["PENDING"].includes(b.status);
}

export function billingIsOpen(b) {
  return ["CREATED_PAYMENT", "OPEN_PAYMENT"].includes(b.status);
}

export function billingIsCancelled(b) {
  return ["NO_PAYMENT"].includes(b.status);
}

export function billingIsPaid(b) {
  return [
    "UNDERPAYMENT",
    "FULL_PAYMENT",
    "OVERPAYMENT",
    "PAID",

    // Reserved for future usage
    "REFUND",
    "CHARGEBACK",
  ].includes(b.status);
}

export function billingIssuedNotPaid(b) {
  return ["OPEN_PAYMENT", "NO_PAYMENT"].includes(b.status);
}

export function billingIsFinalState(b) {
  return [
    "NO_PAYMENT",
    "UNDERPAYMENT",
    "FULL_PAYMENT",
    "OVERPAYMENT",
    "PAID",

    // Reserved for future usage
    "REFUND",
    "CHARGEBACK",
  ].includes(b.status);
}

export function billingIsDestroyable(b) {
  return (
    b.proxy === null && ["PENDING", "OPEN_PAYMENT", "PAID"].includes(b.status)
  );
}
