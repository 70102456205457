import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'
import CustomerProps from '../fragments/customer'

const GQL_CGROUPS_CUSTOMERS = gql`
  query CgroupsCustomers {
    cgroups {
      ...CgroupProps
      customers { ...CustomerProps }
    }
  }
  ${CgroupProps}
  ${CustomerProps}
`

export default GQL_CGROUPS_CUSTOMERS