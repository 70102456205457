import React from 'react'

import { Grid } from 'semantic-ui-react'

import Slider from './partials/Slider'
import CreateBilling from './partials/CreateBilling'
import CreateRecurrence from './partials/CreateRecurrence'

import style from './CreateReceivable.module.css'

class CreateReceivable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      rangeValues: [1],
      cycles: 1,
    }

    this.sliderUpdate = this.sliderUpdate.bind(this)
    this.sliderChange = this.sliderChange.bind(this)
  }

  sliderUpdate(values) {
    this.setState({
      rangeValues: values
    }, this.setCycles)
  }

  sliderChange(values) {
    this.setState({
      rangeValues: values
    }, this.setCycles)
  }

  setCycles() {
    this.setState({
      cycles: this.state.rangeValues[0] === 25 ? -1 : this.state.rangeValues[0]
    })
  }

  render() {
    return (
      <div>
        <div className={style.ModalBody}>
          <Grid centered>
            <Grid.Row centered columns={1}>
              <Grid.Column width={14}>
                <Slider onChange={this.sliderChange} onUpdate={this.sliderUpdate} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          {
            this.state.cycles === 1 ?
              <CreateBilling customer={this.props.customer}  /> :
              <CreateRecurrence customer={this.props.customer} cycles={this.state.cycles} />
          }
        </div>
      </div>
    )
  }
}

export default CreateReceivable
