import { gql } from 'apollo-boost'

const SettlementProps = gql`
  fragment SettlementProps on Settlement {
    id
    status
    amountSettled
    dateCo
    consumedBalance
    tpv
    tpvFee
    refunds
    refundsFee
    chargebacks
    chargebacksFee
    otherEarnings
    otherEarningsDescription
    otherDeductions
    otherDeductionsDescription
    settlementFee
  }
`

export default SettlementProps