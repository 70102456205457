import React from "react";
import DayPicker from "react-day-picker";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";

import { holidays } from "src/utils/holidays";

import "react-day-picker/lib/style.css";

function Calendar(props) {
  const disabledDaysFunc = (d) => {
    if (!d) return true;

    if (d.getDay() === 0 || d.getDay() === 6)
      // Weekends.
      return true;

    if (
      moment(d).isBefore(
        moment().startOf("day").add(props.minDaysBillingAhead, "days")
      )
    )
      // Before.
      return true;

    if (moment(d).isAfter(moment().add(props.maxMonthsAhead, "months")))
      // After.
      return true;

    return _.map(holidays, (h) => moment(h).format("YYYY-MM-DD")).includes(
      moment(d).format("YYYY-MM-DD")
    ); // Holiday.
  };

  return (
    <DayPicker
      placeholder={props.placeholder || ""}
      locale="br"
      months={[
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ]}
      weekdaysLong={[
        "Domingo",
        "Segunda",
        "Terça",
        "Quarta",
        "Quinta",
        "Sexta",
        "Sábado",
      ]}
      weekdaysShort={["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]}
      firstDayOfWeek={0}
      labels={{ nextMonth: "Próximo mês", previousMonth: "Mês anterior" }}
      fixedWeeks={true}
      onDayClick={props.onChange}
      enableOutsideDays={false}
      disabledDays={disabledDaysFunc}
      selectedDays={props.value}
      fromMonth={moment().add(props.minDaysBillingAhead, "days").toDate()}
      toMonth={moment().add(props.maxMonthsAhead, "months").toDate()}
    />
  );
}

export default Calendar;
