import React from "react";
import moment from "moment";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import * as Sentry from "@sentry/browser";
import { get } from "axios";
import _ from "lodash";

import { getToken, userHash } from "./token";
import analytics from "src/plg/Segment";
import { saveFeatures, mapFeatures, getFeatures } from "src/plg/profile";

import GQL_MERCHANT_USER_SEGMENTATION from "src/graphql/queries/MerchantUserSegmentation.gql";

import { hotjar } from "react-hotjar";
import { clearFeatures } from "src/plg/profile";
import { SplashScreen } from "src/components/SplashScreen";
import { AppContext } from "./context";

function Authenticate({ children }) {
  const { error, loading, data } = useQuery(GQL_MERCHANT_USER_SEGMENTATION);
  const { networkError } = React.useContext(AppContext);
  if (process.env.NODE_ENV === "production") {
    hotjar.initialize(process.env.REACT_APP_HOTJAR_ID, null);

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DNS,
      environment: process.env.NODE_ENV,
    });
  }
  setInterval(setUserHash, 2000);

  clearFeatures();

  // Log out.
  if (!getToken())
    return <Redirect push to={process.env.REACT_APP_SIGNIN_URL} />;

  // Log in.
  if (loading || error || networkError)
    return <SplashScreen error={error} networkError={networkError} />;

  initialize(data);

  return children;
}

function initialize(data) {
  if (!data) {
    return;
  }
  const features = mapFeatures(data);

  saveFeatures(features);

  // Sentry.
  Sentry.setUser({ id: features.id, email: features.email });

  // Segment.
  analytics.identify(
    features.id,
    {
      email: features.email,
      merchantId: features.merchantId,
      merchant: features.merchant,
      status: features.status,
      legalType: features.legalType,
      enabledDate: features.enabledDate
        ? moment(String(features.enabledDate)).toDate()
        : null,
      liveDate: features.liveDate
        ? moment(String(features.liveDate)).toDate()
        : null,
    },
    {
      integrations: {
        All: false,
        Intercom: {
          user_hash: localStorage.getItem(userHash),
        },
      },
    }
  );

  // Intercom.
  intercom(features);
}

function intercom(features) {
  window.Intercom("boot", {
    app_id: window.APP_ID,
    user_id: features.id,
    email: features.email,
    user_hash: localStorage.getItem(userHash),
  });
  window.Intercom("update", Object.assign(features, { app_id: window.APP_ID }));
}

export function setUserHash() {
  if (!getToken()) return async () => {};

  if (!_.isEmpty(localStorage.getItem(userHash))) return async () => {};

  const url = `${process.env.REACT_APP_API_HOST}/plg/user_hash`;
  const headers = { headers: { Authorization: `Bearer ${getToken()}` } };

  return get(url, { ...headers, timeout: 5000 })
    .then((resp) => localStorage.setItem(userHash, resp.data.user_hash))
    .catch(() => {});
}

export function initialized() {
  return (
    !_.isEmpty(getFeatures("id")) && !_.isEmpty(localStorage.getItem(userHash))
  );
}

export default Authenticate;
