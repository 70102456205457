import React from "react";
import { useMutation } from "react-apollo";

import { Loader } from "semantic-ui-react";
import { MdWarning } from "react-icons/md";

import GQL_LEAVE_CGROUP from "src/graphql/mutations/LeaveCgroup.gql";
import GQL_CGROUP_CUSTOMERS from "src/graphql/queries/CgroupCustomers.gql";

function RemoveFromCgroup(props) {
  const [leaveCgroup, { error, loading }] = useMutation(GQL_LEAVE_CGROUP, {
    variables: {
      customerId: props.customerId,
      cgroupId: props.cgroupId,
    },
    onCompleted: props.onCompleted,
    refetchQueries: [{ query: GQL_CGROUP_CUSTOMERS }, "CustomersConnection"],
  });

  if (loading) return <Loader inverted size="small" indeterminate />;
  if (error) return <MdWarning />;

  return <span onClick={leaveCgroup}>{props.children}</span>;
}

export default RemoveFromCgroup;
