import React from "react";

import { Grid } from "semantic-ui-react";

import Slider from "./partials/Slider";
import CreateBilling from "./partials/CreateBilling";
import CreateRecurrence from "./partials/CreateRecurrence";

import style from "./CreateReceivableGroup.module.css";
import { SelectedsCustomers } from "./partials/SelectedsCustomers";
import { Customer, CustomersContext } from "src/modules/customers/context";

interface Props {
  ids: Array<number>;
  closeModal: () => void;
  handleSuccess: (billings: Array<any>) => void;
}

interface State {
  rangeValues: Array<number>;
  cycles: number;
}

export function CreateReceivableGroup({ ...props }: Props) {
  const { items } = React.useContext(CustomersContext);
  const [state, setState] = React.useState<State>({
    rangeValues: [1],
    cycles: 1,
  });

  function handleSuccess(billings: Array<any>) {
    props.handleSuccess(billings);
    props.closeModal();
  }

  function sliderUpdate(rangeValues: Array<number>) {
    setState((state: State) => ({
      ...state,
      rangeValues,
    }));
    setCycles();
  }

  function setCycles() {
    setState((state: State) => ({
      ...state,
      cycles: state.rangeValues[0] === 25 ? -1 : state.rangeValues[0],
    }));
  }

  const ids = items
    .filter(({ id }: Customer) => props.ids.includes(~~id))
    .map(({ id }) => id);

  return (
    <div>
      <div className={style.ModalBody}>
        <Grid centered>
          <Grid.Row columns={1} centered>
            <Grid.Column width={16}>
              <SelectedsCustomers />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered columns={1}>
            <Grid.Column width={14}>
              <Slider onChange={sliderUpdate} onUpdate={sliderUpdate} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {state.cycles === 1 ? (
          <CreateBilling ids={ids} handleSuccess={handleSuccess} />
        ) : (
          <CreateRecurrence
            ids={ids}
            cycles={state.cycles}
            handleSuccess={handleSuccess}
          />
        )}
      </div>
    </div>
  );
}

export default CreateReceivableGroup;
