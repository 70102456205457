import React from "react";
import {
  AppBar as CeciAppBar,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@plipag/ceci-ui";
import Logo from "./Logo";
import DesktopToolbar from "./DesktopToolbar";
import MobileToolbar from "./MobileToolbar";

export function AppBar() {
  const showDesktopToolbar = useMediaQuery("(min-width:960px)");
  const theme = useTheme();

  return (
    <CeciAppBar sx={{ background: theme.palette.primary.main }}>
      <Toolbar>
        <Logo />
        {showDesktopToolbar && <DesktopToolbar />}
        {!showDesktopToolbar && <MobileToolbar />}
      </Toolbar>
    </CeciAppBar>
  );
}
