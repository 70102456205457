import { Button, Grid } from "@plipag/ceci-ui";
import { MdAdd, MdImportExport } from "react-icons/md";
import { Filters } from "../Filters";

import CreateCustomerModal from "src/modals/CreateCustomerModal";
import ImportClientsButton from "./ImportClientsButton";

type Props = {
  children?: React.ReactNode;
};

export function SingleActions({ children }: Props) {
  return (
    <Grid container spacing={2}>
      <Grid item>
        <CreateCustomerModal>
          <Button size="large" variant="contained" color="secondary">
            <MdAdd size={24} style={{ marginBottom: 2, marginRight: 10 }} />
            Novo Cliente
          </Button>
        </CreateCustomerModal>
      </Grid>
      <Grid item>
        <ImportClientsButton>
          <Button size="large" variant="outlined">
            <MdImportExport
              size={24}
              style={{ marginBottom: 2, marginRight: 10 }}
            />
            Importar clientes
          </Button>
        </ImportClientsButton>
      </Grid>
      {children && (
        <Grid item flexGrow={1}>
          {children}
        </Grid>
      )}
      <Grid item flexGrow={children ? 0 : 1} textAlign="right">
        <Filters />
      </Grid>
    </Grid>
  );
}
