import React from "react";
import { ActionsBar } from "../ActionsBar";
import { PaginatedList } from "src/components/PaginatedList";
import { MobileCustomersListItem } from "../MobileListItem";
import { CustomersListItem } from "../ListItem";
import { useQuery } from "@apollo/react-hooks";
import { CustomersListEmpty } from "./Empty";
import { CustomersListSkeleton } from "./Skeleton";
import { Customer, CustomersContext } from "../context";
import { CustomersConnection } from "src/graphql/queries/CustomersPaginated.gql";
import { useMediaQuery } from "@plipag/ceci-ui";
import { LimitSelector } from "src/components/PaginatedList/LimitSelector";

export function CustomersList() {
  const { setItems, page, cgroupId, filter, order, limit, setLimit } =
    React.useContext(CustomersContext);

  const { data, loading, error, refetch } = useQuery(CustomersConnection, {
    variables: {
      limit: limit,
      cgroupId: cgroupId ? ~~cgroupId : undefined,
      skip: (page - 1) * limit,
      status: [filter],
      order: {
        newer: [{ fieldName: "customers.id", sortDir: "desc" }],
        older: [{ fieldName: "customers.id", sortDir: "asc" }],
        name: [{ fieldName: "customers.name", sortDir: "asc" }],
        nickname: [{ fieldName: "customers.nickname", sortDir: "asc" }],
      }[order],
    },
  });

  React.useEffect(() => {
    refetch();
    return () => {};
  }, [filter, page, order, cgroupId, refetch]);

  React.useEffect(() => {
    if (!data?.customersConnection) return;

    setItems(
      data.customersConnection.edges.map(
        (edge: { node: Customer }) => edge.node
      )
    );
  }, [data, setItems]);

  const isMobile = useMediaQuery("@media (max-width:850px)");
  const isLargeScreen = useMediaQuery("@media (min-width:1100px)");

  if (loading) {
    return <CustomersListSkeleton />;
  }

  if (error) {
    console.log("error", error);
    return null;
  }

  const total = data.customersConnection.totalCount;

  return (
    <>
      <ActionsBar>
        {isLargeScreen && (
          <LimitSelector total={total} limit={limit} setLimit={setLimit} />
        )}
      </ActionsBar>
      <PaginatedList
        limitSelector={!isLargeScreen}
        limit={limit}
        total={total}
        page={page}
        setLimit={setLimit}
      >
        {data.customersConnection.edges.map(
          ({ node: customer }: { node: Customer }) =>
            !isMobile ? (
              <CustomersListItem key={customer.id} customer={customer} />
            ) : (
              <MobileCustomersListItem key={customer.id} customer={customer} />
            )
        )}

        {data.customersConnection.edges.length === 0 && <CustomersListEmpty />}
      </PaginatedList>
    </>
  );
}
