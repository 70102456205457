import React from "react";

import { Message } from "semantic-ui-react";

import style from "../CreateCustomerModal.module.css";

export default function a(state) {
  if (!state.error) return null;

  return (
    <div className={style.ModalBody}>
      <Message negative>{state.error}</Message>
    </div>
  );
}
