import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";

import { routes } from "../../routes";

import EditRecurrence from "./EditRecurrence";

import GQL_RECURRENCE_BILLINGS from "src/graphql/queries/RecurrenceBillings.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

export default class Recurrence extends React.Component {
  render() {
    return (
      <Query query={GQL_RECURRENCE_BILLINGS} variables={{ id: this.props.id }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const { recurrence } = data;

          if (!data.recurrence)
            return (
              <Redirect
                to={routes.RECEIVABLES.getLink(this.props.customer.id)}
              />
            );

          return (
            <EditRecurrence
              recurrence={recurrence}
              customerId={this.props.customer.id}
            />
          );
        }}
      </Query>
    );
  }
}
