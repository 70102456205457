import { gql } from "apollo-boost";
import BillingProps from "../fragments/billing";

const GQL_CREATE_BILLING_FOR_CUSTOMERS = gql`
  mutation CreateBillingForCustomers(
    $customersIds: [ID!]!
    $dueDate: String!
    $amountBilled: Float!
    $description: String
    $anticipate: Boolean
    $interestPolicy: InterestPolicy!
    $discountPolicy: DiscountPolicy!
    $discountDays: Int
    $discountAmount: Float
  ) {
    createBillingForCustomers(
      customersIds: $customersIds
      dueDate: $dueDate
      amountBilled: $amountBilled
      description: $description
      interestPolicy: $interestPolicy
      discountPolicy: $discountPolicy
      discountDays: $discountDays
      discountAmount: $discountAmount
      anticipate: $anticipate
    ) {
      billings {
        ...BillingProps
      }
    }
  }
  ${BillingProps}
`;

export default GQL_CREATE_BILLING_FOR_CUSTOMERS;
