import { gql } from 'apollo-boost'

import RecurrenceProps from '../fragments/recurrence'
import BillingProps from '../fragments/billing'

const GQL_UPDATE_RECURRENCE = gql`
  mutation UpdateRecurrence(
    $id: ID!,
    $amount: Float!,
    $description: String,
    $interestPolicy: InterestPolicy!,
    $discountAmount: Float,
    $discountDays: Int
  ) {
    updateRecurrence(
      id: $id,
      amount: $amount,
      description: $description,
      interestPolicy: $interestPolicy,
      discountAmount: $discountAmount,
      discountDays: $discountDays
    ) {
      ...RecurrenceProps
      billings {
        ...BillingProps
      }
    }
  }
  ${RecurrenceProps}
  ${BillingProps}
`

export default GQL_UPDATE_RECURRENCE