import React from "react";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { BillingsContext, FiltersKeys, OrdersKeys } from "./context";
import { FirstBillingCTA } from "src/components/FirstBillingCTA";
import { List } from "./List";
import { Toolbar } from "./Toolbar";
import moment, { Moment } from "moment";
import "moment/locale/pt-br";
import { Grid } from "@plipag/ceci-ui";
import TabBillings from "src/components/TabBillings";
import BillingsStatistics from "src/components/BillingsStatistics";
import { businessDayBackward } from "src/utils/holidays";

import { BillingDetails } from "./BillingDetails";

function dueDateRange(currentMonth: string) {
  return {
    startDueDate: moment(currentMonth).startOf("month").format("YYYY-MM-DD"),

    endDueDate: moment(currentMonth).endOf("month").format("YYYY-MM-DD"),
  };
}

// Months navigator.
function lateDueDateRange(currentMonth: Moment) {
  const lateRef = businessDayBackward(
    businessDayBackward(currentMonth).subtract(1, "day")
  );

  return {
    endDueDate: lateRef.format("YYYY-MM-DD"),
    startDueDate: lateRef.startOf("month").format("YYYY-MM-DD"),
  };
}

function queryVariables({
  order,
  limit,
  page,
  filter,
  currentMonth,
}: {
  order: OrdersKeys;
  limit: number;
  page: number;
  filter: FiltersKeys;
  currentMonth: string;
}) {
  return {
    order: [
      {
        dueDate: { fieldName: "due_date", sortDir: "asc" },
        amountBilled: { fieldName: "amount_billed", sortDir: "asc" },
        status: { fieldName: "status", sortDir: "asc" },
        paymentDate: { fieldName: "payment_date", sortDir: "asc" },
        amountPaid: { fieldName: "amount_paid", sortDir: "asc" },
      }[order],
    ],
    limit,
    skip: (page - 1) * limit,
    ...dueDateRange(currentMonth),
    proxy: null,
    ...{
      all: {
        status: [
          "PENDING",

          "CREATED_PAYMENT",
          "OPEN_PAYMENT",
          "NO_PAYMENT",
          "UNDERPAYMENT",
          "FULL_PAYMENT",
          "OVERPAYMENT",

          "PAID",
        ],
      },
      scheduled: { status: ["PENDING", "CREATED_PAYMENT"] },
      open: { status: ["OPEN_PAYMENT"] },
      paid: {
        status: ["UNDERPAYMENT", "FULL_PAYMENT", "OVERPAYMENT", "PAID"],
      },
      cancelled: { status: ["NO_PAYMENT"] },
      deleted: {
        status: [
          "PENDING",
          "CREATED_PAYMENT",
          "OPEN_PAYMENT",
          "NO_PAYMENT",
          "UNDERPAYMENT",
          "FULL_PAYMENT",
          "OVERPAYMENT",
          "PAID",
          "SUSPENDED",
          "DELETED",
          "EXPIRED",
          "REFUND",
          "CHARGEBACK",
          "ON_HOLD",
        ],
        proxy: "0",
      },
      late: {
        status: ["OPEN_PAYMENT"],
        ...lateDueDateRange(moment(currentMonth)),
      },
    }[filter],
  };
}

export function BillingsModule() {
  const { billingId, ...params }: { [key: string]: string } = useParams();
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const p = search.get("p");
  const page = p ? ~~p : 1;
  const _limit = ~~(localStorage.getItem("billings.limit") || 0);
  const [limit, _setLimit] = React.useState<number>(_limit > 50 ? _limit : 50);
  const [filter, _setFilter] = React.useState<FiltersKeys>("all");
  const [order, _setOrder] = React.useState<OrdersKeys>(
    (localStorage.getItem("billings.order") as OrdersKeys) || "all"
  );
  const [currentMonth, _setCurrentMonth] = React.useState<string>(
    moment().format("YYYY-MM-DD")
  );

  function setFilter(filter: FiltersKeys) {
    _setFilter(filter);
    localStorage.setItem("billings.filter", filter);
    history.push(location.pathname);
  }

  function setLimit(limit: number) {
    _setLimit(limit);
    localStorage.setItem("billings.limit", `${limit}`);
    history.push(location.pathname);
  }

  function setOrder(order: OrdersKeys) {
    _setOrder(order);
    localStorage.setItem("billings.order", order);
    history.push(location.pathname);
  }

  function setCurrentMonth(currentMonth: string) {
    _setCurrentMonth(currentMonth);
    history.push(location.pathname);
  }

  const state = React.useMemo(
    () => ({
      limit,
      page,
      filter,
      order,
      currentMonth,
    }),
    [limit, page, filter, order, currentMonth]
  );

  const variables = React.useMemo(() => queryVariables(state), [state]);

  const context = {
    ...state,
    setLimit,
    setFilter,
    setOrder,
    setCurrentMonth,
    variables,
  };

  return (
    <BillingsContext.Provider value={context}>
      <Grid container direction="column">
        <Grid item>
          <div className="mb-5 fadein" style={{ padding: 50 }}>
            <FirstBillingCTA />
            <TabBillings />

            <div
              className="pt-5"
              style={{ background: "#fff", borderLeft: "1px solid #d4d4d5" }}
            >
              <Toolbar />

              <div>
                <BillingsStatistics
                  currentMonth={currentMonth}
                  filter={filter}
                  variables={variables}
                />
              </div>
              <List />

              {billingId && (
                <BillingDetails id={billingId} page={params.page} />
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </BillingsContext.Provider>
  );
}
