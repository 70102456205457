import React from 'react'
import _ from 'lodash'
import classNames from 'classnames'

import { Header, Item } from 'semantic-ui-react'

import style from './IssuePolicy.module.css'

const policies = {
  BEFORE: {
    name: 'BEFORE',
    available: true,
    title: 'Na fatura',
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/2021165-magic/png/015-spell.png",
    meta: 'Emitir a nota fiscal junto com a fatura.',
    extra: "Ideal quando a cobrança tem um contrato vinculado com compromisso de pagamento do cliente.",
  },
  AFTER: {
    name: 'AFTER',
    available: true,
    title: 'No pagamento',
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/123368-color-startups-and-new-business/png/money-bag.png",
    meta: 'Emitir a nota fiscal assim que o pagamento for realizado. Se não houver pagamento, a nota fiscal não é emitida.',
    extra: "Ideal quando o pagamento da cobrança é incerto ou não há um contrato vinculado.",
  },
  MANUAL: {
    name: 'MANUAL',
    available: true,
    title: 'Manualmente',
    icon: "https://assets.plipag.com.br/conta/images/icons/circular/overflow/1205481-design-thinking/png/027-note.png",
    meta: 'A emissão não é automática, porém a opção de emissão é dada na própria cobrança.',
    extra: "Ideal quando é necessário um controle mais granular da emissão ou já existe um fluxo externo de emissões de notas fiscais.",
  }
}

function IssuePolicy({ policy }) {
  return (
    <div>
      <Header content="Quando emitir a nota fiscal?" />
      <div>
        {
          _.map(policies, cs =>
            <div className={classNames(style.Item,
              cs.name === policy ? style.Selected : (
                cs.available ? style.Available : style.Disabled
              ),
            )} key={_.uniqueId()}>
              <Item.Group>
                <Item>
                  <Item.Image src={cs.icon} size="mini"/>
                  <Item.Content verticalAlign="middle">
                    <Item.Header content={cs.title}/>
                    <Item.Meta content={cs.meta}/>
                    <Item.Extra content={cs.extra}/>
                  </Item.Content>
                </Item>
              </Item.Group>
            </div>,
          )
        }
      </div>
    </div>
  )
}

export default IssuePolicy