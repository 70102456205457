import { gql } from 'apollo-boost'

const BankAccountProps = gql`
  fragment BankAccountProps on BankAccount {
    id
    bank {
      code
      name
    }
    branch
    accno
    acctype
    document
    holderName
    defaultAccount
  }
`

export default BankAccountProps