import { gql } from 'apollo-boost'

import NoteProps from '../fragments/note'

const GQL_NOTES = gql`
  query Notes($customerId: ID) {
    notes(customerId: $customerId) {
      ...NoteProps
    }
  }
  ${NoteProps}
`

export default GQL_NOTES
