import React from "react";

export type State = {
  networkError: boolean;
};

export type AppContextType = State & {
  setNetworkError: (networkError: boolean) => void;
};

export const AppContext = React.createContext<AppContextType>({
  networkError: false,
  setNetworkError: () => {},
});

export const ContextRef = React.createRef();
