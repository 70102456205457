import { gql } from "apollo-boost";
import CustomerProps from "../fragments/customer";

export const CustomersConnection = gql`
  query CustomersConnection(
    $cgroupId: Int
    $limit: Int
    $order: [OrderInput!]
    $skip: Int
    $status: [CustomerStatus!]
  ) {
    customersConnection(
      cgroupId: $cgroupId
      limit: $limit
      orderBy: $order
      skip: $skip
      status: $status
    ) {
      totalCount
      edges {
        cursor
        node {
          ...CustomerProps
        }
      }
    }
  }
  ${CustomerProps}
`;
