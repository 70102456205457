import React from "react";
import { CustomersContext } from "../context";
import { Button, Grid } from "@plipag/ceci-ui";
import { MdClose, MdCheck } from "react-icons/md";
import { Filters } from "../Filters";
import { CreateReceivable } from "./CreateReceivable";
import { ArchiveCustomers } from "./ArchiveCustomers";
import { RestoreCustomers } from "./RestoreCustomers";

export function GroupActions() {
  const { selecteds, setSelecteds, filter, items } =
    React.useContext(CustomersContext);

  const allSelected =
    items.filter((customer) => selecteds.includes(customer.id)).length ===
    items.length;

  function handleClick() {
    if (allSelected) {
      setSelecteds([]);
    } else {
      selectAll();
    }
  }

  function selectAll() {
    const all = [...selecteds];

    items.forEach((customer) => {
      if (!selecteds.includes(customer.id)) {
        all.push(customer.id);
      }
    });

    setSelecteds(all);
  }

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button size="large" variant="outlined" onClick={handleClick}>
          {!allSelected && (
            <MdCheck size={24} style={{ marginBottom: 2, marginRight: 10 }} />
          )}
          {allSelected && (
            <MdClose size={24} style={{ marginBottom: 2, marginRight: 10 }} />
          )}
          {allSelected ? "Limpar seleção" : "Selecionar todos"}
        </Button>
      </Grid>
      <Grid item>
        <CreateReceivable />
      </Grid>
      <Grid item>
        {filter === "ACTIVE" && <ArchiveCustomers />}

        {filter === "ARCHIVED" && <RestoreCustomers />}
      </Grid>
      <Grid item flexGrow={1} textAlign="right">
        <Filters />
      </Grid>
    </Grid>
  );
}
