import _ from "lodash";

export const PLGSTATUS_KEY = "plgf";

export const defaultFeatures = {
  // Unique identifier.
  id: null,
  email: null,

  // Merchant.
  merchantId: null,
  merchant: null,
  status: null,
  document: null,
  legalType: null,
  enabledDate: null,

  // Account activity.
  fakeBillingIssued: null,
  billingsCreated: null,
  billingsIssued: null,
  billingsPaid: null,
  billingsCancelled: null,
  recurrencesCreated: null,
  customersCreated: null,
  settlementAvailable: null,
  settlementsConfirmed: null,

  // Account setup.
  logoUploaded: null,
  documentSet: null,
  legalRepresentativeSet: null,
  compliancePoiStatus: null,
  compliancePoaStatus: null,
  complianceConstitutionStatus: null,
  complianceContractSigned: null,
};

export function mapFeatures(data) {
  let o = {
    ...data?.user,
    ...data?.merchant,
    ...data?.plg,
    ...data?.setup,
  };
  delete o.__typename;
  return o;
}

export function saveFeatures(features) {
  setFeatures(Object.assign(getFeatures(), features));
}

export function setFeatures(obj) {
  return localStorage.setItem(PLGSTATUS_KEY, encode(JSON.stringify(obj)));
}

export function clearFeatures() {
  return localStorage.setItem(
    PLGSTATUS_KEY,
    encode(JSON.stringify(defaultFeatures))
  );
}

export function getFeatures(key = null) {
  try {
    const obj = Object.assign(
      _.clone(defaultFeatures),
      JSON.parse(decode(localStorage.getItem(PLGSTATUS_KEY)) || "{}")
    );
    return key ? obj[key] : obj;
  } catch (e) {
    return {};
  }
}

function encode(x) {
  return x;
} // btoa
function decode(x) {
  return x;
} // atob
