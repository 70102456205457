import { docFormat, docValid } from "src/utils/document";
import { validEmail } from "src/utils/email";
import { asPhone, PHONE_FORMATS } from "src/utils/phone";
import { enrichCustomer } from "./enrichment";
import { trim } from "src/utils/typing";
import {
  ignoreTimeout,
  validateEmail,
  validateMobile,
} from "src/utils/enrichment";

// Fields handling.
export function handleNickname(e, setState) {
  setState({
    nickname: e.target.value,
  });
}

export function handleDocument(e, setState, performEnrichment = true) {
  const doc = e.target.value;
  setState(
    {
      document: docFormat(doc),
    },
    () => {
      let documentState = !docValid(doc)
        ? { documentInvalid: true }
        : { documentInvalid: false };

      setState(
        Object.assign(documentState, {
          address: null,
        })
      );

      clearSuggestions(setState);

      if (performEnrichment) enrichCustomer(doc, setState);

      unlockFields(setState);
    }
  );
}

export function handleName(e, setState) {
  setState({
    name: e.target.value,
  });
}

export function handleEmail(e, setState) {
  const val = e.target.value;
  setState(
    {
      email: val,
      emailInvalid: false,
      emailEnrichment: null,
    },
    () => {
      setState({ emailInvalid: false });

      if (!validEmail(val)) {
        setState({ emailInvalid: true });
      }

      if (String(val) === "" || !validEmail(val)) return null;

      validateEmail(val)
        .then((resp) => {
          const enrichment = resp.data.data;
          if (enrichment.validMailbox === false)
            setState({ emailInvalid: true });
          else setState({ emailInvalid: false });
        })
        .catch(ignoreTimeout);
    }
  );
}

export function handleMobile(e, setState) {
  const phone = asPhone(e.target.value, PHONE_FORMATS.LOCAL, true);

  setState(
    {
      mobile: phone.e164,
      mobileFace: phone.formatted,
      mobileInvalid: !phone.valid,
      mobileEnrichment: null,
    },
    () => {
      if (phone.valid) {
        setState({ mobileInvalid: false }, () => {
          validateMobile(phone.e164)
            .then((resp) => {
              const enrichment = resp.data.data;
              console.log(enrichment.isValid);
              setState({ mobileInvalid: enrichment.isValid === false });
            })
            .catch(ignoreTimeout);
        });
      } else {
        setState({ mobileInvalid: String(phone.digits).length === 11 });
      }
    }
  );
}

export function handleAddress(e, setState) {
  setState({
    address: e.target.value,
  });
}

export function clearFields(setState) {
  setState({
    document: "",
    name: "",
    nickname: "",
    email: "",
    mobile: "",
    mobileFace: "",
    street: "",
    district: "",
    city: "",
    province: "",
    zipcode: "",
    address: "",

    nameLocked: false,
    emailLocked: false,
    mobileLocked: false,
    addressLocked: true,

    documentInvalid: false,
    emailInvalid: false,
    mobileInvalid: false,

    emailSuggestions: [],
    mobileSuggestions: [],
    addressSuggestions: [],
  });
}

// Field locking/unlocking.
export function lockField(name, setState) {
  setState({
    [name + "Locked"]: true,
  });
}

export function unlockFields(setState) {
  setState({
    nameLocked: false,
    emailLocked: false,
    mobileLocked: false,
    addressLocked: true,
  });
}

// Suggestions.
export function setSuggestion(key, value, setState) {
  switch (key) {
    case "email":
      setState({
        email: validEmail(value) ? value : null,
      });
      break;
    case "mobile":
      const m = asPhone(value, PHONE_FORMATS.LOCAL, true);
      setState({
        mobile: m.e164,
        mobileFace: m.formatted,
      });
      break;
    default:
      setState({
        [key]: value,
      });
      break;
  }
}

export function clearSuggestions(setState) {
  setState({
    emailSuggestions: [],
    mobileSuggestions: [],
    addressSuggestions: [],
  });
}

// Fetch.
export function getParams(state) {
  return {
    document: state.document,
    name: state.name,
    nickname: state.nickname,
    email: state.email,
    mobile: state.mobile,
    street: state.street,
    district: state.district,
    city: state.city,
    province: state.province,
    zipcode: state.zipcode,
  };
}

// Formatting.
export function addressOneLiner(fields) {
  return String(fields.street) === "" ||
    String(fields.district) === "" ||
    String(fields.city) === "" ||
    String(fields.province) === "" ||
    String(fields.zipcode) === ""
    ? ""
    : `${fields.street}, ${fields.district}, ${fields.city} - ${fields.province}, ${fields.zipcode}`;
}

export async function trimFields(state, setState) {
  await setState({
    nickname: trim(state.nickname),
    document: trim(state.document),
    name: trim(state.name),
    email: trim(state.email),
    mobile: trim(state.mobile),
  });
}
