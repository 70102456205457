import React from "react";
import { useHistory } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import { Wrapper, SearchField } from "./styles";

function HeaderSearchBox() {
  const history = useHistory();

  return (
    <Wrapper onClick={() => history.push("/procurar")} component={Wrapper}>
      <SearchField
        placeholder="Procurar"
        left={<MdSearch />}
        variant="standard"
      />
    </Wrapper>
  );
}

export default HeaderSearchBox;
