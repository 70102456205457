import React from "react";
import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from "@plipag/ceci-ui";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCardContent-root": {
      textAlign: "center",
      background: theme.palette.neutral.light,
      borderRadius: 4,
      padding: 24,
      border: "none",
    },

    "& .Mui-CardActionArea-root": {
      border: "none",
    },
  },
}));

export const SummaryCard = React.forwardRef(
  ({ label, value, ...props }, ref) => {
    const classes = useStyles();
    return (
      <Card classes={classes}>
        <CardActionArea ref={ref} {...props} sx={{ border: "none" }}>
          <CardContent>
            <Typography variant="h5" color="neutral-dark">
              {value}
            </Typography>
            <Typography variant="p" color="primary">
              {label}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
);
