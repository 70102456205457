import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Grid, Typography, Box, ButtonBase } from "@plipag/ceci-ui";
import { MdAdd } from "react-icons/md";
import GQL_CGROUPS_CUSTOMERS from "src/graphql/queries/CgroupsCustomers.gql";
import { CustomersContext, Cgroup } from "../context";
import { CustomerGroupsBarSkeleton } from "./Skeleton";
import { CustomersGroupCard } from "../GroupCard";
import CreateCgroupModal from "src/modals/CreateCgroupModal";
import { Tooltip } from "src/components/Tooltip";
import { useHistory } from "react-router";

export function CustomersGroupsBar() {
  const { data, loading, error } = useQuery(GQL_CGROUPS_CUSTOMERS);
  const { setCgroups, cgroups } = React.useContext(CustomersContext);
  const history = useHistory();

  React.useEffect(() => {
    if (!data) return;

    setCgroups(data.cgroups);
  }, [data, setCgroups]);

  if (loading || error) return <CustomerGroupsBarSkeleton />;

  return (
    <Grid container spacing={1} sx={{ m: 3 }}>
      <Grid item>
        <CreateCgroupModal
          trigger={
            <Tooltip content="Clique para criar um novo grupo de clientes">
              <ButtonBase
                sx={{
                  display: "block",
                  width: 72,
                  textAlign: "center",
                  outline: "none",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 56,
                    height: 56,
                    border: "1px solid #CFD6E5",
                    borderRadius: 50,
                  }}
                >
                  <Typography color="neutral-dark">
                    <MdAdd size={30} />
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    mt: 1,
                    fontSize: 14,
                  }}
                >
                  novo grupo
                </Typography>
              </ButtonBase>
            </Tooltip>
          }
          clearCgroupSelection={() => history.push("/clientes")}
          key="cgroup:create"
        />
      </Grid>

      {cgroups.map((cgroup: Cgroup) => (
        <Grid item key={cgroup.id}>
          <CustomersGroupCard cgroup={cgroup} />
        </Grid>
      ))}
    </Grid>
  );
}
