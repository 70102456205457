import React from "react";

import { MdPerson } from "react-icons/md";

import style from "../CreateCustomerModal.module.css";

export default function a() {
  return (
    <div className={style.ModalHeader}>
      <ul className="horizontal relaxed middle">
        <li>
          <MdPerson size={64} />
        </li>
        <li>
          <div className={style.ModalSubtitle}>Gestão de clientes</div>
          <div className={style.ModalTitle}>Novo cliente</div>
        </li>
      </ul>
    </div>
  );
}
