import React from "react";
import { TextField, makeStyles, Box } from "@plipag/ceci-ui";

export const Wrapper = (props) => (
  <Box
    {...props}
    sx={{
      margin: "0 60px 0 0",
      maxWidth: 230,
    }}
  />
);

const SearchFieldStyles = makeStyles((theme) => ({
  root: {
    background: "rgba(255, 255, 255, 0.3)",
    borderRadius: 4,
    padding: "4px 10px",
    "& .MuiInput-root": {
      background: "none",

      "&:after, &:before": {
        borderColor: "transparent !important",
      },

      "& .MuiInputAdornment-root": {
        fontSize: 24,
        color: "#fff",
      },

      "& input::placeholder, & input": {
        color: "#fff",
        opacity: 0.8,
      },
    },
  },
}));

export const SearchField = (props) => {
  const classes = SearchFieldStyles();
  return <TextField {...props} classes={classes} />;
};
