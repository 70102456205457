import { gql } from 'apollo-boost'

const MerchantProps = gql`
  fragment MerchantProps on Merchant {
    createdAt
    name
    legalName
    document
    legalType
    status
    
    logo
    
    email
    phone
    street
    district
    city
    province
    zipcode

    microSite

    enabledDate
    liveDate
  }
`

export default MerchantProps