import React from "react";
import classNames from "classnames";
import _ from "lodash";

import { Popup } from "semantic-ui-react";

import style from "./CustomerInline.module.css";

import Modal from "src/uikit/Modal";
import AddToCgroupModal from "./partials/AddToCgroupModal";
import ImgIcon from "src/uikit/ImgIcon";
import RemoveFromCgroup from "./partials/RemoveFromCgroup";
import { Cgroup, Customer } from "src/modules/customers/context";

type Props = {
  [key: string]: any;
  c: Customer;
  cgroups: Array<Cgroup>;
  cgroupsCount: number;
  selectCgroup: (cgroup: Cgroup | null) => void;
  selectedCgroup: Cgroup | null;
};

type State = {
  show: boolean;
};

class CustomerInline extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { show: true };

    this.hide = this.hide.bind(this);
  }

  hide() {
    this.setState({ show: false });
  }

  cgroupsInline() {
    const { c, cgroups, cgroupsCount, selectCgroup, selectedCgroup, ...props } =
      this.props;
    return (
      <ul className={classNames(style.Cgroups)} {...props}>
        {_.map(cgroups || [], (cgroup) => (
          <li key={`cgroup:${cgroup.id}`}>
            <Popup
              inverted
              header={cgroup.name.toLocaleUpperCase()}
              trigger={
                <div>
                  <ImgIcon
                    iconKey={cgroup.icon}
                    className={style.CgroupIcon}
                    onClick={() => selectCgroup(cgroup)}
                  />
                </div>
              }
              content="Ver outros clientes deste grupo."
            />
          </li>
        ))}

        <li>
          <Modal
            trigger={
              <Popup
                inverted
                trigger={
                  <div>
                    <ImgIcon
                      iconPath="open/gradient/1387863-teamwork/png/026-add-group.png"
                      className={style.CgroupIcon}
                    />
                  </div>
                }
                content="Colocar cliente em um grupo"
              />
            }
            size="small"
            borderStyle="ssss"
            closeBtnDark
          >
            {({ closeModal }: { [key: string]: any }) => (
              <AddToCgroupModal
                customer={c}
                usedCgroups={cgroups}
                callback={closeModal}
              />
            )}
          </Modal>
        </li>
      </ul>
    );
  }

  cgroupActions() {
    if (!this.props.cgroups || _.isEmpty(this.props.cgroups)) return null;

    return (
      <ul className={classNames(style.Cgroups)}>
        {!!this.props.selectedCgroup && (
          <li>
            <Popup
              inverted
              header="Tirar cliente do grupo?"
              trigger={
                <RemoveFromCgroup
                  cgroupId={this.props.selectedCgroup.id}
                  customerId={this.props.c.id}
                  onCompleted={this.hide}
                >
                  <ImgIcon
                    iconPath="open/gradient/747818-ui-interface/png/018-remove-user.png"
                    className={style.CgroupIcon}
                  />
                </RemoveFromCgroup>
              }
              content={`Remover cliente do grupo ${this.props.selectedCgroup.name}`}
            />
          </li>
        )}
        <li className="ml-3">
          <Popup
            header="Limpar seleção"
            trigger={
              <div>
                <ImgIcon
                  iconPath="open/gradient/747818-ui-interface/png/066-refresh.png"
                  className={style.CgroupIcon}
                  onClick={() => this.props.selectCgroup(null)}
                />
              </div>
            }
            content={`Voltar a mostrar todos os clientes`}
            inverted
          />
        </li>
      </ul>
    );
  }

  render() {
    if (!this.props.c || !this.state.show) return null;

    return this.props.selectedCgroup
      ? this.cgroupActions()
      : this.cgroupsInline();
  }
}

export default CustomerInline;
