import { gql } from 'apollo-boost'

const CgroupProps = gql`
  fragment CgroupProps on Cgroup {
    id
    name
    icon
    status
    createdAt
    updatedAt
  }
`

export default CgroupProps