import React from "react";
import { Dropdown } from "semantic-ui-react";

export function FiltersSelect({ handleFilter, value }) {
  const options = [
    { key: "null", value: "all", text: "Nenhum" },
    {
      key: "scheduled",
      value: "scheduled",
      text: "Agendadas (a serem enviadas)",
    },
    { key: "open", value: "open", text: "Emitidas (em aberto)" },
    { key: "late", value: "late", text: "Em atraso" },
    { key: "paid", value: "paid", text: "Pagas" },
    { key: "cancelled", value: "cancelled", text: "Canceladas (baixadas)" },
    { key: "deleted", value: "deleted", text: "Excluídas" },
  ];

  function handleChange(_, { value }) {
    handleFilter(value);
  }

  return (
    <Dropdown
      direction="left"
      basic
      options={options}
      value={value}
      onChange={handleChange}
      placeholder="Nenhum"
    />
  );
}
