import { gql } from 'apollo-boost'

import RecurrenceProps from '../fragments/recurrence'
import BillingProps from '../fragments/billing'

const GQL_RECURRENCES_BILLINGS = gql`
  query RecurrencesBillings($customerIds: [ID!]) {
    recurrences(customerId: $customerIds) {
      ...RecurrenceProps
      billings {
        ...BillingProps
      }
    }
  }
  ${RecurrenceProps}
  ${BillingProps}
`

export default GQL_RECURRENCES_BILLINGS