import { gql } from 'apollo-boost'

const PreferencesProps = gql`
  fragment PreferencesProps on MerchantPreferences {
    communicationsSetup
    automaticCancel
    webhookUrl
    settlementMdays
  }
`

export default PreferencesProps