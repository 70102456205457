export default function toDecimal(str, debug = false) {
  const num = String(str).replace(new RegExp(/[^0-9]/, 'g'), '')
  const z = num.length === 0 ? 0 : parseInt(num)
  const floatValue = z / 100
  const decimalValue = floatValue.toLocaleString('pt-BR', {
    // style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

  if (debug)
    console.log({
      floatValue: floatValue,
      decimalValue: decimalValue,
      debug: {
        input: str,
        integers: z,
        afterRepl: num,
      },
    })

  return { floatValue, decimalValue }
}

export function floatToDecimal(floatValue) {
  if (!floatValue)
    return null

  return floatValue.toLocaleString('pt-BR', {
    // style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

export function asMoney(value) {
  return new Intl.NumberFormat(
    'pt-BR',
    {
      style: 'decimal',
      currency: 'BRL',
      minimumFractionDigits: 2,
    },
  ).format(value)
}