import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@plipag/ceci-ui";
import AddFileIcon from "../Icons/AddFile";
import { ThemeProps as theme } from "@plipag/ceci-ui/";
import Dialog from "../DropFileDialog";

const FileCard = (props) => {
  const hasFile = !!props.value;
  const [open, setOpen] = React.useState(false);

  const cardStyle = {
    borderRadius: 8,
  };
  if (hasFile) {
    cardStyle.borderColor = theme.palette.success.main;
  }
  const iconColor = hasFile ? "success" : "neutral";

  return (
    <Card variant="outlined" style={cardStyle} onClick={() => setOpen(true)}>
      <Grid container flexWrap="nowrap">
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography variant="subtitle1">{props.title}</Typography>
          <Typography variant="subtitle2">{props.subtitle}</Typography>
        </CardContent>
        <CardMedia sx={{ minWidth: 90, margin: 2, textAlign: "center" }}>
          <div style={{ marginLeft: 6 }}>
            <AddFileIcon color={iconColor} style={{ fontSize: 30 }} />
          </div>
          <Typography variant="caption" component="p">
            {hasFile ? "Arquivo enviado" : "Enviar arquivo"}
          </Typography>
        </CardMedia>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        title={props.dialogTitle}
        content={props.dialogContent}
        value={props.value}
        onChange={props.onChange}
      />
    </Card>
  );
};

export default FileCard;
