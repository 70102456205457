import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import style from "./AccountMenu.module.css";

import BankAccountsModal from "src/modals/BankAccountsModal";
import HelpModal from "src/modals/HelpModal";
import ExportModal from "src/modals/ExportModal";
import { LogoutButton } from "src/components/LogoutButton";
import ConfigsModal from "src/modals/ConfigsModal";

const AccountMenu = ({ close }) => {
  const history = useHistory();
  return (
    <ul className={classNames("vertical", style.List)}>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <span
          onClick={() => {
            close();
            history.push("/fatura");
          }}
          className={classNames(style.ListItemLink)}
        >
          Configurar fatura
        </span>
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <BankAccountsModal
          trigger={
            <span className={classNames(style.ListItemLink)}>
              Dados bancários
            </span>
          }
        />
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <ExportModal
          trigger={
            <span className={classNames(style.ListItemLink)}>
              Exportar dados
            </span>
          }
        />
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <ConfigsModal
          trigger={
            <span className={classNames(style.ListItemLink)}>
              Régua de cobrança
            </span>
          }
        />
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <span
          onClick={() => {
            close();
            history.push("/indicar");
          }}
          className={classNames(style.ListItemLink)}
        >
          Indique e ganhe
        </span>
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <HelpModal
          trigger={
            <span className={classNames(style.ListItemLink)}>Ajuda</span>
          }
        />
      </li>
      <li className={classNames(style.ListItem, "fadeInUp")}>
        <LogoutButton className={classNames("link", style.ListItemLink)}>
          Sair
        </LogoutButton>
      </li>
    </ul>
  );
};

export default AccountMenu;
