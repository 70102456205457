import React from "react";
import SearchBox from "./SearchBox";
import Account from "./Account";
import Menu from "./Menu";
import { ActivateAccountButton } from "./ActivateAccountButton";

const DesktopToolbar = () => {
  return (
    <>
      <Menu />
      <ActivateAccountButton />
      <SearchBox />
      <Account />
    </>
  );
};

export default DesktopToolbar;
