import { gql } from 'apollo-boost'

import CustomerProps from '../fragments/customer'

const GQL_UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $id: ID!,
    $name: String!,
    $nickname: String,
    $email: Email!,
    $mobile: String
  ) {
    updateCustomer(
      id: $id,
      name: $name,
      nickname: $nickname,
      email: $email,
      mobile: $mobile
    ) {
      ...CustomerProps
    }
  }
  ${CustomerProps}
`

export default GQL_UPDATE_CUSTOMER