import { Box, Typography } from "@plipag/ceci-ui";
import { MdAutorenew } from "react-icons/md";

export default function BillingsStatisticsError({ refetch }) {
  return (
    <Box
      onClick={() => refetch()}
      sx={{
        borderRadius: 2,
        margin: 2,
        background: "#F5F7FA",
        cursor: "pointer",

        height: 104,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">Erro ao carregar!</Typography>
      <Typography>
        <MdAutorenew /> Tentar novamente
      </Typography>
    </Box>
  );
}
