export function strippedZipcode(zipcode) {
  return String(zipcode).replace(new RegExp(/[^0-9]/, 'g'), '')
}

export function formatZipcode(zipcode) {
  const num = String(zipcode).replace(new RegExp(/[^0-9]/, 'g'), '')

  if (num.length < 8)
    return num

  return `${num.substr(0, 2)}.${num.substr(2, 3)}-${num.substr(5, 3)}`
}

export function zipcodeValid(zipcode) {
  const num = String(zipcode).replace(new RegExp(/[^0-9]/, 'g'), '')
  return num.length === 8
}