import React from "react";
import classNames from "classnames";

import { Button, Divider, Header } from "semantic-ui-react";
import { MdPlace } from "react-icons/md";

import style from "./ShowAddress.module.css";
const a = ({ customer, changePage }) => {
  const invalid =
    String(customer.street).length <= 5 ||
    String(customer.city).length <= 2 ||
    String(customer.province).length !== 2 ||
    String(customer.zipcode).length <= 7;

  const addressEmpty = (
    <div className="text-center">
      <MdPlace size={128} className="lighter-grey" />
      <Header
        as="h2"
        disabled
        color="grey"
        className="mt-0"
        content="Sem endereço"
      />
    </div>
  );
  const addressPresent = (
    <div>
      {customer.street}
      <br />
      {customer.district}
      <br />
      {customer.city} - {customer.province}
      <br />
      {customer.zipcode}
    </div>
  );

  const click = () => {
    changePage("EDIT");
  };

  return (
    <div>
      {invalid ? addressEmpty : addressPresent}
      <Divider className="mt-3" />
      <div className="text-right">
        <ul className={classNames("horizontal")}>
          <li className={classNames(style.ListItem, "fadeInUp", "text-right")}>
            <Button primary size="small" onClick={click}>
              Alterar endereço
            </Button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default a;
