import { gql } from 'apollo-boost'

const UserProps = gql`
  fragment UserProps on User {
    id
    name
    email
  }
`

export default UserProps