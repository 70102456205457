import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'
import CustomerProps from '../fragments/customer'

const GQL_JOIN_CGROUP = gql`
  mutation JoinCgroup($customerId: ID!, $cgroupId: ID!) {
    joinCgroup(cgroupId: $cgroupId, customerId: $customerId) {
      ...CgroupProps
      customers { ...CustomerProps }
    }
  }
  ${CustomerProps}
  ${CgroupProps}
`

export default GQL_JOIN_CGROUP