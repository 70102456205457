import { gql } from "apollo-boost";

import BillingProps from "../fragments/billing";
import CustomerProps from "../fragments/customer";

export const BillingsConnection = gql`
  query BillingsConnection(
    $skip: Int
    $startDueDate: Date
    $endDueDate: Date
    $status: [BillingStatus!]
    $proxy: ID
    $limit: Int
    $order: [OrderInput!]
  ) {
    billingsConnection(
      limit: $limit
      skip: $skip
      startDueDate: $startDueDate
      endDueDate: $endDueDate
      status: $status
      proxy: $proxy
      orderBy: $order
    ) {
      totalCount
      nodes {
        ...BillingProps

        customer {
          ...CustomerProps
        }
      }
    }
  }
  ${BillingProps}
  ${CustomerProps}
`;
