import React from "react";
import "moment/locale/pt-br";
import { PaginatedList } from "src/components/PaginatedList";
import { useQuery } from "react-apollo";
import { Billing, BillingsContext } from "../context";
import { BillingsConnection } from "src/graphql/queries/BillingsPaginated.gql";
import { EmptyList } from "./EmptyList";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import BillingInline from "src/components/BillingsNavigator/partials/BillingInline";
import { useHistory } from "react-router-dom";

export function List() {
  const history = useHistory();
  const { page, limit, setLimit, variables } =
    React.useContext(BillingsContext);

  const { data, loading, error, refetch } = useQuery(BillingsConnection, {
    variables,
  });

  React.useEffect(() => {
    if (!refetch) return;
    refetch(variables);
    document.getElementById("page-container")?.scrollTo(0, 0);
    return () => {};
  }, [refetch, variables]);

  function openBilling(id: string | number) {
    history.push(`/cobrancas/${id}`);
  }

  if (loading) {
    return <GqlLoadingIcon />;
  }

  if (error) {
    console.log("error", error);
    return null;
  }

  return (
    <div style={{ paddingRight: 16 }}>
      <PaginatedList
        limitSelector={true}
        page={page}
        limit={limit}
        setLimit={setLimit}
        total={data?.billingsConnection?.totalCount || 0}
      >
        {data?.billingsConnection?.nodes?.map((billing: Billing) => (
          <BillingInline
            key={billing.id.toString()}
            billing={billing}
            openBilling={openBilling}
          />
        ))}

        {data?.billingsConnection?.nodes?.length === 0 && <EmptyList />}
      </PaginatedList>
    </div>
  );
}
