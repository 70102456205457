import React from "react";
import { Query } from "react-apollo";
import _ from "lodash";
import moment from "moment";
import "moment/locale/pt-br";

import { Container, Grid, Tab } from "semantic-ui-react";
import { MdSwapVerticalCircle, MdToday, MdExpandMore } from "react-icons/md";
import { FaRegCalendarCheck, FaHourglassHalf } from "react-icons/fa";

import { asMoney } from "src/utils/decimal";

import SettlementCard from "../SettlementCard";

import style from "./SettlementsNavigator.module.css";

import GQL_SETTLEMENTS from "src/graphql/queries/Settlements.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

class SettlementsNavigator extends React.Component {
  static renderNoSettlement() {
    return (
      <Container fluid style={{ background: "#fff", marginTop: "1rem" }}>
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column textAlign="center">
              <MdSwapVerticalCircle size={144} className="lightest-grey" />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column textAlign="center">
              <h4>Nenhuma transferência até o momento</h4>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }

  static settlementTitle(s) {
    switch (s.status) {
      case "PE":
        return (
          <ul className="horizontal text-right">
            <li className="a-t pl-3">
              <div className={style.AmountPE}>{asMoney(s.amountSettled)}</div>
              <div className={style.DatePE}>A transferir</div>
            </li>
            <li className="a-t pl-3">
              <MdToday size={36} className={style.IconPE} />
            </li>
          </ul>
        );
      case "PR":
        return (
          <ul className="horizontal text-right">
            <li className="a-t pl-3">
              <div className={style.AmountPR}>{asMoney(s.amountSettled)}</div>
              <div className={style.DatePR}>Em processamento com o banco</div>
            </li>
            <li className="a-t pl-3">
              <FaHourglassHalf size={24} className={style.IconPR} />
            </li>
          </ul>
        );
      case "CO":
        const text =
          moment(s.dateCo).year() === moment().year()
            ? moment(s.dateCo).month() === moment().month()
              ? moment(s.dateCo).format("[dia ]DD")
              : `${moment(s.dateCo).format("DD")} de ${moment(s.dateCo)
                  .format("MMMM")
                  .toLowerCase()}`
            : moment(s.dateCo).format("DD[/]MM[/]YYYY");

        return (
          <ul className="horizontal text-right">
            <li className="a-t pl-3">
              <div className={style.AmountCO}>{asMoney(s.amountSettled)}</div>
              <div className={style.DateCO}>{text}</div>
            </li>
            <li className="a-t pl-3">
              <FaRegCalendarCheck size={24} className={style.IconCO} />
            </li>
          </ul>
        );
      default:
        return <div>Em processamento, tente novamente mais tarde</div>;
    }
  }

  tabPanes(settlements, fetchMore) {
    return _.concat(
      _.map(settlements, (s) => {
        return {
          menuItem: {
            key: s.id,
            content: SettlementsNavigator.settlementTitle(s),
          },
          render: () => (
            <Tab.Pane as="div" content={<SettlementCard id={s.id} />} />
          ),
        };
      }),
      // Last item is a button to fetch more results
      {
        menuItem: {
          key: -1,
          content: fetchMore,
        },
        render: null,
      }
    );
  }

  render() {
    const limit = 20;
    return (
      <Query query={GQL_SETTLEMENTS} variables={{ first: limit, skip: 0 }}>
        {({ loading, error, data, refetch, fetchMore }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const { settlements } = data;

          const fetchMoreBtn = data.settlements.length % limit === 0 && (
            <div
              className="text-right"
              onClick={() =>
                fetchMore({
                  variables: { skip: settlements.length, first: limit },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    return {
                      settlements: [
                        ...prev.settlements,
                        ...fetchMoreResult.settlements,
                      ],
                    };
                  },
                })
              }
            >
              Todas as transferências
              <MdExpandMore size={32} className={style.ShowMore} />
            </div>
          );

          return _.isEmpty(settlements) ? (
            SettlementsNavigator.renderNoSettlement()
          ) : (
            <Container fluid>
              <Grid padded="horizontally">
                <Grid.Row>
                  <Grid.Column
                    stretched
                    mobile={16}
                    tablet={16}
                    only="mobile tablet"
                  >
                    <SettlementCard id={settlements[0].id} />
                  </Grid.Column>
                  <Grid.Column
                    stretched
                    computer={16}
                    only="computer"
                    className={style.Tab}
                    style={{ background: "white" }}
                  >
                    <Tab
                      menu={{ vertical: true, fluid: true, secondary: true }}
                      panes={this.tabPanes(settlements, fetchMoreBtn)}
                    />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          );
        }}
      </Query>
    );
  }
}

export default SettlementsNavigator;
