import React from "react";
import { Typography, ButtonBase, Badge, Box } from "@plipag/ceci-ui";
import { MdSettings } from "react-icons/md";
import ImgIcon from "src/uikit/ImgIcon";
import { CustomersContext, Cgroup } from "../context";
import MutateCgroupModal from "src/modals/MutateCgroupModal";
import { Tooltip } from "src/components/Tooltip";
import { useHistory } from "react-router-dom";

type Props = {
  cgroup: Cgroup;
};

export function CustomersGroupCard({ cgroup }: Props) {
  const { cgroupId } = React.useContext(CustomersContext);
  const history = useHistory();
  const [badgeInvisible, setBadgeInvisible] = React.useState<boolean>(true);

  function toggleSelectedCgroup(id: string) {
    if (cgroupId === id) {
      history.push("/clientes");
    } else {
      history.push(`/clientes/grupo/${id}`);
    }
  }

  return (
    <Box
      sx={{ position: "relative" }}
      onMouseEnter={() => setBadgeInvisible(false)}
      onMouseLeave={() => setBadgeInvisible(true)}
    >
      <MutateCgroupModal
        trigger={
          <Tooltip content="Editar nome e icone do grupo">
            <Badge
              badgeContent={<MdSettings />}
              invisible={badgeInvisible}
              color="secondary"
              sx={{
                position: "absolute",
                top: 8,
                right: 13,
                cursor: "pointer",
              }}
              onClick={() => setBadgeInvisible(true)}
            />
          </Tooltip>
        }
        cgroup={cgroup}
        clearCgroupSelection={() => history.push("/clientes")}
      />
      <Tooltip
        title="Grupo de clientes"
        content={
          <>
            <span>* Clique para ver os clientes neste grupo</span>
            <br />
            <span>* Clique novamente para voltar a ver todos os clientes</span>
          </>
        }
      >
        <ButtonBase
          sx={{
            display: "block",
            width: 72,
            textAlign: "center",
            outline: "none !important",
          }}
          disableRipple
          onClick={() => toggleSelectedCgroup(cgroup.id)}
        >
          <ImgIcon
            iconKey={cgroup.icon}
            width={56}
            height={56}
            sx={{ m: "0 auto" }}
          />

          <Typography
            sx={{
              whiteSpace: "nowrap",
              textAlign: "center",
              overflow: "hidden",
              mt: 1,
              fontSize: 14,
              fontWeight: cgroupId === cgroup.id ? "bold" : "normal",
            }}
          >
            {cgroup.name}
          </Typography>
        </ButtonBase>
      </Tooltip>
    </Box>
  );
}
