import React from "react";

import { Step } from "semantic-ui-react";

import ImgIcon from "../../uikit/ImgIcon";

import style from "./BillingSetup.module.css";

import Logo from "./partials/Logo";
import Profile from "./partials/Profile";
import Review from "./partials/Review";

const pages = {
  LOGO: "LOGO",
  PROFILE: "PROFILE",
  REVIEW: "REVIEW",
};

const iconsPaths = {
  LOGO: "circular/overflow/1205481-design-thinking/png/035-puzzle.png",
  PROFILE: "circular/overflow/1205481-design-thinking/png/006-clipboard.png", // 024-layout.png
  REVIEW: "circular/overflow/1205481-design-thinking/png/037-rocket.png", // 041-solution.png
};

class BillingSetup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: pages.LOGO,
    };

    this.changePageFun = this.changePageFun.bind(this);
  }

  changePageFun(page) {
    return () => {
      this.setState({ page });
    };
  }

  logoCompleted() {
    return String(this.props.merchant.logo).length > 0;
  }

  profileCompleted() {
    const m = this.props.merchant;

    return (
      String(m.street).length > 0 &&
      String(m.district).length > 0 &&
      String(m.city).length > 0 &&
      String(m.province).length > 0 &&
      String(m.zipcode).length > 0 &&
      String(m.email).length > 0 &&
      String(m.phone).length > 0
    );
  }

  reviewable() {
    return this.profileCompleted();
  }

  renderSteps() {
    return (
      <Step.Group stackable="tablet" fluid>
        <Step
          active={this.state.page === pages.LOGO}
          completed={this.logoCompleted()}
          link={this.state.page !== pages.LOGO}
          onClick={this.changePageFun(pages.LOGO)}
        >
          <ImgIcon iconPath={iconsPaths.LOGO} className={style.Icon} />
          <Step.Content className="noselect">
            <Step.Title>Logotipo</Step.Title>
            <Step.Description>
              Envie seu logo ou pule esta etapa
            </Step.Description>
          </Step.Content>
        </Step>
        <Step
          active={this.state.page === pages.PROFILE}
          completed={this.profileCompleted()}
          link={this.state.page !== pages.PROFILE}
          onClick={this.changePageFun(pages.PROFILE)}
        >
          <ImgIcon iconPath={iconsPaths.PROFILE} className={style.Icon} />
          <Step.Content className="noselect">
            <Step.Title>Informações</Step.Title>
            <Step.Description>Quem está cobrando?</Step.Description>
          </Step.Content>
        </Step>
        <Step
          active={this.state.page === pages.REVIEW}
          disabled={!this.reviewable()}
          link={this.state.page !== pages.REVIEW}
          onClick={this.changePageFun(pages.REVIEW)}
        >
          <ImgIcon iconPath={iconsPaths.REVIEW} className={style.Icon} />
          <Step.Content className="noselect">
            <Step.Title>Revisar</Step.Title>
            <Step.Description>Enviar email de teste</Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    );
  }

  render() {
    return (
      <div>
        <div>{this.renderSteps()}</div>
        <div>
          {this.state.page === pages.LOGO && (
            <Logo merchant={this.props.merchant} />
          )}
          {this.state.page === pages.PROFILE && (
            <Profile
              merchant={this.props.merchant}
              nextPage={this.changePageFun(pages.REVIEW)}
            />
          )}
          {this.state.page === pages.REVIEW && (
            <Review merchant={this.props.merchant} />
          )}
        </div>
      </div>
    );
  }
}

export default BillingSetup;
