import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'

const GQL_PAY_BILLING = gql`
  mutation PayBilling($id: ID!, $payday: String!, $amountPaid: Float!) {
    payBilling(id: $id, payday: $payday, amountPaid: $amountPaid) {
      ...BillingProps
    }
  }
  ${BillingProps}
`

export default GQL_PAY_BILLING