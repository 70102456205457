import React from "react";
import moment from "moment";
import classNames from "classnames";

import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";

import style from "./FirstDuePicker.module.css";

function getCandidates(firstDueDate, mday) {
  let previous = moment(firstDueDate).clone().subtract(1, "month");
  if (mday <= 0) previous = previous.endOf("month").startOf("day");

  let next = moment(firstDueDate).clone().add(1, "month");
  if (mday <= 0) next = next.endOf("month").startOf("day");

  return { previous, next };
}

export default function FirstDuePicker({
  firstDueDateSuggested,
  firstDueDate,
  mday,
  onChange,
}) {
  const { previous, next } = getCandidates(firstDueDate, mday);

  const previousLocked = previous.isBefore(firstDueDateSuggested, "day");
  const nextLocked = next.isBefore(firstDueDateSuggested, "day");

  const previousFun = () => !previousLocked && onChange(previous);
  const nextFun = () => !nextLocked && onChange(next);

  return (
    <div className="text-center">
      <ul className="horizontal">
        <li>
          <FaChevronCircleLeft
            size={36}
            className={classNames(
              style.Handle,
              previousLocked ? style.Inactive : style.Active
            )}
            onClick={previousFun}
          />
        </li>
        <li className="ml-2">
          <FaChevronCircleRight
            size={36}
            className={classNames(
              style.Handle,
              nextLocked ? style.Inactive : style.Active
            )}
            onClick={nextFun}
          />
        </li>
      </ul>
    </div>
  );
}
