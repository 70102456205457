import React from "react";

import { Popup } from "semantic-ui-react";

import PriceTag from "../../uikit/PriceTag";
import { billingOneValue, billingOneValueDescription } from "./oneValue";

export default function a({ billing }) {
  return (
    <Popup
      trigger={
        <span>
          <PriceTag value={billingOneValue(billing)} />
        </span>
      }
      inverted
      content={billingOneValueDescription(billing)}
    />
  );
}
