import React from 'react'
import { Divider } from 'semantic-ui-react'

import IssuePolicy from './NotaFiscal/IssuePolicy'
import CertificateStatus from './NotaFiscal/CertificateStatus'

class NotaFiscal extends React.Component {
  render() {
    return (
      <div>
        <CertificateStatus />
        <Divider section hidden />
        <IssuePolicy policy="AFTER" />
      </div>
    )
  }
}

export default NotaFiscal
