import moment from 'moment'

export function shortDate(d) {
  return moment(d).year() === moment().year() ?
    moment(d).format('DD[/]MM') :
    moment(d).format('DD[/]MM[/]YYYY')
}

export function longDate(d) {
  return moment(d).year() === moment().year() ?
    moment(d).format('DD[ de ]MMMM').toLowerCase() :
    moment(d).format('DD[ de ]MMMM[ de ]YYYY').toLowerCase()
}