import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Radio,
  Select,
  MaskedInput,
  TextField,
  Typography,
  DatePicker,
  Autocomplete,
} from "@plipag/ceci-ui";
import { validateCNPJ, validateCPF, validatePhone } from "validations-br";
import * as yup from "yup";
import Page from "../components/Page";
import Steps from "../components/Steps";

const Informations = () => {
  const history = useHistory();
  const [publicPerson, setPublicPerson] = React.useState(false);
  const [acceptTerms, setAcceptTerms] = React.useState(false);

  const validationSchema = yup.object({
    occupationArea: yup.string().required("Campo obrigatorio"),
    clientsNumber: yup.string().required("Campo obrigatorio"),

    documentNumber: yup
      .string()
      .required("Campo obrigatorio")
      .test("is-document", "Documento invalido", (value) => {
        if (values.documentType === "cnpj") {
          return validateCNPJ(value);
        }

        return validateCPF(value);
      }),

    responsibleName: yup.string().required("Campo obrigatorio"),

    responsibleDocument: yup
      .string()
      .required("Campo obrigatorio")
      .test("is-cpf", "CPF invalido", (value) => validateCPF(value)),

    responsibleBirthday: yup.mixed().required("Campo obrigatorio"),
    responsiblePhone: yup
      .string()
      .required("Campo obrigatorio")
      .test("is-phone", "Celular invalido", (value) =>
        validatePhone(value || "")
      ),
    responsibleMotherName: yup.string().required("Campo obrigatorio"),
  });

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    errors,
    touched,
    values,
    setFieldValue,
  } = useFormik({
    initialValues: {
      occupationArea: "",
      clientsNumber: "",
      documentType: "cnpj",
      documentNumber: "",
      responsibleName: "",
      responsibleDocument: "",
      responsibleBirthday: null,
      responsiblePhone: "",
      responsibleMotherName: "",
    },
    validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
      history.push("/documents");
    },
  });

  return (
    <Page back={false}>
      <Steps activeStep={1} />
      <Box sx={{ maxWidth: 470, width: "100%", margin: "0 auto" }}>
        <Grid
          onSubmit={handleSubmit}
          component="form"
          container
          direction="column"
          spacing={3}
          padding={3}
        >
          <Grid item>
            <Typography>Qual é a sua área de atuação</Typography>
            <Autocomplete
              name="occupationArea"
              error={touched.occupationArea && Boolean(errors.occupationArea)}
              helperText={touched.occupationArea && errors.occupationArea}
              onChange={(_, value) => setFieldValue("occupationArea", value)}
              onBlur={handleBlur}
              options={[
                "Agência/Marketing",
                "Educação",
                "Fitness",
                "Contabilidade",
                "Advocacia",
                "Tecnologia/SaaS",
                "Associação/Sindicato",
                "Segurança/Manutenção",
                "Provedor de Internet",
                "Aluguel/Assinatura",
                "Outro",
              ]}
            />
          </Grid>
          <Grid item>
            <Typography>Quantos clientes você tem?</Typography>
            <Select
              defaultValue=""
              name="clientsNumber"
              error={touched.clientsNumber && Boolean(errors.clientsNumber)}
              helperText={touched.clientsNumber && errors.clientsNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              options={["1-10", "11-30", "31-100", "101-500", "+500"]}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography paragraph>
              Você quer ativar a sua conta como empresa (CNPJ) ou autônomo
              (CPF)?
            </Typography>
            <Radio
              color="success"
              label="Empresa (CNPJ)"
              name="documentType"
              value="cnpj"
              checked={values.documentType === "cnpj"}
              onChange={handleChange}
            />
            <Radio
              color="success"
              label="Autônomo (CPF)"
              name="documentType"
              value="cpf"
              checked={values.documentType === "cpf"}
              onChange={handleChange}
            />
          </Grid>
          <Grid item>
            <MaskedInput
              maskChar={null}
              mask={
                {
                  cpf: "999.999.999-99",
                  cnpj: "99.999.999/9999-99",
                }[values.documentType]
              }
              label={values.documentType.toLocaleUpperCase()}
              name="documentNumber"
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              error={touched.documentNumber && Boolean(errors.documentNumber)}
              helperText={touched.documentNumber && errors.documentNumber}
            />
          </Grid>
          <Grid item>
            <Typography paragraph>
              Informações do sócio responsável pela empresa
            </Typography>
            <TextField
              label="Nome do responsável"
              name="responsibleName"
              error={touched.responsibleName && Boolean(errors.responsibleName)}
              helperText={touched.responsibleName && errors.responsibleName}
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item>
            <MaskedInput
              maskChar={null}
              mask="999.999.999-99"
              label="CPF do responsável"
              name="responsibleDocument"
              error={
                touched.responsibleDocument &&
                Boolean(errors.responsibleDocument)
              }
              helperText={
                touched.responsibleDocument && errors.responsibleDocument
              }
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item>
            {
              <DatePicker
                label="Data de nascimento"
                value={values.responsibleBirthday}
                error={
                  touched.responsibleBirthday &&
                  Boolean(errors.responsibleBirthday)
                }
                helperText={
                  touched.responsibleBirthday && errors.responsibleBirthday
                }
                onChange={(value) =>
                  setFieldValue("responsibleBirthday", value)
                }
                onBlur={handleBlur}
                fullWidth
              />
            }
          </Grid>
          <Grid item>
            <MaskedInput
              maskChar={null}
              mask="(99) 99999-9999"
              label="Número celular"
              name="responsiblePhone"
              error={
                touched.responsiblePhone && Boolean(errors.responsiblePhone)
              }
              helperText={touched.responsiblePhone && errors.responsiblePhone}
              onChange={(value) => {
                setFieldValue("responsiblePhone", value.currentTarget.value);
              }}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item>
            <TextField
              label="Nome completo da mãe"
              name="responsibleMotherName"
              error={
                touched.responsibleMotherName &&
                Boolean(errors.responsibleMotherName)
              }
              helperText={
                touched.responsibleMotherName && errors.responsibleMotherName
              }
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Checkbox
              color="success"
              checked={publicPerson}
              onChange={() => setPublicPerson(!publicPerson)}
              label={
                <Typography variant="body2">
                  Assinale aqui se você é uma pessoa{" "}
                  <Link href="/" color="info">
                    políticamente exposta
                  </Link>
                  .
                </Typography>
              }
            />
            <Checkbox
              color="success"
              checked={acceptTerms}
              onChange={() => setAcceptTerms(!acceptTerms)}
              label={
                <Typography variant="body2">
                  Li e aceito os{" "}
                  <Link href="/" color="info">
                    termos de uso
                  </Link>{" "}
                  e a{" "}
                  <Link href="/" color="info">
                    política de privacidade
                  </Link>
                  .
                </Typography>
              }
            />
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              fullWidth
              size="large"
              disabled={!acceptTerms}
            >
              Continuar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
};

export default Informations;
