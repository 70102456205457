import React from "react";
import { Query } from "react-apollo";
import { Redirect } from "react-router-dom";

import { routes } from "../../routes";

import EditBilling from "./EditBilling";

import GQL_BILLING from "src/graphql/queries/Billing.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

class Receivable extends React.Component {
  render() {
    return (
      <Query query={GQL_BILLING} variables={{ id: this.props.id }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const { billing } = data;

          if (!data.billing)
            return (
              <Redirect
                to={routes.RECEIVABLES.getLink(this.props.customer.id)}
              />
            );

          return (
            <EditBilling
              billing={billing}
              customerId={this.props.customer.id}
              mode="CRM"
            />
          );
        }}
      </Query>
    );
  }
}

export default Receivable;
