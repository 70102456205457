import { Typography } from "@plipag/ceci-ui";
import React from "react";
import { CustomersContext } from "../context";

export function CustomersListEmpty() {
  const { cgroupId } = React.useContext(CustomersContext);

  const hasFilters = !!cgroupId;

  return (
    <Typography variant="h4" textAlign="center" sx={{ p: 2, pt: 4 }}>
      Nenhum cliente {hasFilters ? "correspondente aos filtros" : ""}
    </Typography>
  );
}
