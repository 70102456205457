import React from "react";
import { Query, Mutation } from "react-apollo";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";

import { FaCheckCircle, FaPaperPlane } from "react-icons/fa";
import { Grid, Header, Popup, Message } from "semantic-ui-react";

import { routes } from "../../routes";
import settings from "src/app/settings";
import { whatsAppApi } from "src/utils/whatsapp";

import PriceTag from "src/uikit/PriceTag";

import style from "./CreatedBilling.module.css";

import GQL_BILLING_CUSTOMER from "src/graphql/queries/BillingCustomer.gql";
import GQL_DESTROY_OR_CANCEL_BILLING from "src/graphql/mutations/DestroyOrCancelBilling.gql";
import GQL_EXPRESS_BILLING from "src/graphql/mutations/ExpressBilling.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

class CreatedBilling extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
    };

    this.goto = this.goto.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.renderExpressBilling = this.renderExpressBilling.bind(this);
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  renderExpressBilling(billing) {
    if (
      billing.anticipate ||
      moment(billing.dueDate).diff(moment(), "days") <=
        settings.BILLING_EXPRESS_WINDOW
    )
      return (
        <Grid.Row columns={1}>
          <Grid.Column width={12} textAlign="center">
            <Message positive className="text-center">
              <Message.Header className="mb-2">
                Em processo de emissão
              </Message.Header>
              <Message.Content>
                A cobrança será emitida em breve e o seu cliente notificado por
                e-mail e SMS.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      );

    const lowerBound = moment(billing.dueDate)
      .subtract(settings.BILLING_EXPRESS_WINDOW, "days")
      .format("DD[/]MM");

    const upperBound = moment(billing.dueDate)
      .subtract(settings.BILLING_EXPRESS_WINDOW - 2, "days")
      .format("DD[/]MM");

    return (
      <Grid.Row columns={1}>
        <Grid.Column width={12} textAlign="center">
          <Mutation
            mutation={GQL_EXPRESS_BILLING}
            variables={{ id: billing.id }}
            onError={this.handleMutationError}
          >
            {(expressBilling, { loading, error, data }) => {
              if (loading) return null;
              if (error) return null;

              const express = () => {
                if (
                  window.confirm(
                    "A cobrança será emitida em breve. Ao prosseguir, não será possível reverter este processo. Continuar?"
                  )
                )
                  expressBilling();
              };

              return (
                <Popup
                  trigger={
                    <FaPaperPlane
                      size={32}
                      className={style.Send}
                      onClick={express}
                    />
                  }
                  inverted
                  header="Cobrança expressa"
                  content={
                    <div>
                      <p>
                        A cobrança está programada para ser emitida conforme a
                        régua de cobrança inteligente entre os dias {lowerBound}{" "}
                        e {upperBound}.
                      </p>
                      <p>Para cobrá-lo, clique no botão.</p>
                    </div>
                  }
                />
              );
            }}
          </Mutation>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <Query query={GQL_BILLING_CUSTOMER} variables={{ id: this.props.id }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const {
            billing,
            billing: { customer },
          } = data;

          return (
            <div className={style.ModalBody}>
              <div className="mt-1 mb-3">
                <Link
                  to={routes.RECEIVABLES.getLink(customer.id)}
                  className={classNames(style.ListItemLink, "fadeInUp")}
                >
                  &laquo; Voltar para cobranças
                </Link>
              </div>

              <Grid centered className="pb-4">
                <Grid.Row columns={1}>
                  <Grid.Column width={12} textAlign="center">
                    <FaCheckCircle className={style.SuccessIcon} />
                    <Header size="large" color="green">
                      Feito!
                      <Header.Subheader className="mt-2">
                        Cobrança de <PriceTag value={billing.amountBilled} />{" "}
                        com vencimento para{" "}
                        <Popup
                          trigger={
                            <span>
                              {moment(billing.dueDate)
                                .format("DD[ de ]MMMM")
                                .toLowerCase()}
                            </span>
                          }
                          inverted
                          position="bottom center"
                          content={moment(billing.dueDate).fromNow()}
                        />
                        .
                      </Header.Subheader>
                    </Header>
                  </Grid.Column>
                </Grid.Row>
                {this.renderExpressBilling(billing)}
                <Grid.Row columns={1}>
                  <Grid.Column width={16} textAlign="center">
                    <ul className={classNames("vertical", style.List)}>
                      <li className={classNames(style.ListItem, "fadeInUp")}>
                        <a
                          href={whatsAppApi(billing.url, null)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classNames(style.ListItemLink)}
                        >
                          Enviar por WhatsApp
                        </a>
                      </li>
                      {billing.status === "PENDING" && (
                        <li className={classNames(style.ListItem, "fadeInUp")}>
                          <Link
                            to={routes.RECEIVABLE.getLink(
                              customer.id,
                              billing.id
                            )}
                            className={classNames(style.ListItemLink)}
                          >
                            Editar cobrança
                          </Link>
                        </li>
                      )}
                      <Mutation
                        mutation={GQL_DESTROY_OR_CANCEL_BILLING}
                        variables={{ id: billing.id }}
                        onError={() =>
                          window.alert(
                            "Não foi possível cancelar, tente novamente mais tarde ou entre em contato com o suporte."
                          )
                        }
                        onCompleted={() =>
                          this.goto(routes.RECEIVABLES.getLink(customer.id))
                        }
                      >
                        {(destroyOrCancelBilling, { loading, error, data }) => {
                          if (loading) return <GqlLoadingIcon />;
                          if (error) return <GqlError error={error} />;

                          const confirm = () => {
                            if (window.confirm("Tem certeza?"))
                              destroyOrCancelBilling();
                          };

                          if (billing.status !== "PENDING") return null;

                          return (
                            <li
                              className={classNames(
                                style.ListItem,
                                "fadeInUp",
                                style.ListItemLink,
                                style.ListItemNegative
                              )}
                            >
                              <span onClick={confirm}>Cancelar cobrança</span>
                            </li>
                          );
                        }}
                      </Mutation>
                    </ul>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CreatedBilling;
