import { gql } from 'apollo-boost'

import CustomerWithFieldsProps from '../fragments/customer_with_fields'

const GQL_CUSTOMER_WITH_FIELDS = gql`
  query Customer($id: ID!) {
    customer(id: $id) {
      ...CustomerWithFieldsProps
    }
  }
  ${CustomerWithFieldsProps}
`

export default GQL_CUSTOMER_WITH_FIELDS
