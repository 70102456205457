import { gql } from 'apollo-boost'

import NoteProps from '../fragments/note'

const GQL_CREATE_NOTE = gql`
  mutation CreateNote($customerId: ID!, $text: String!) {
    createNote(customerId: $customerId, text: $text) {
      ...NoteProps
    }
  }
  ${NoteProps}
`

export default GQL_CREATE_NOTE