import React from "react";
import classNames from "classnames";
import moment from "moment";
import "moment/locale/pt-br";

import {
  billingOneValue,
  billingOneValueDescription,
} from "../../../BillingFields/oneValue";
import { asMoney } from "src/utils/decimal";
import { nameShortner } from "src/utils/naming";
import { shortDate } from "src/utils/date";

import { Grid, Popup } from "semantic-ui-react";

import BillingStatus from "../../../BillingFields/BillingStatus";
import PriceTag from "src/uikit/PriceTag";

import style from "./BillingInline.module.css";

class BillingInline extends React.Component {
  static amountPaid(b) {
    return b.amountPaid && b.amountPaid > 0 ? (
      <Popup
        trigger={
          <div>
            <PriceTag value={b.amountPaid} smallCents={true} smallFont={true} />
          </div>
        }
        inverted
        content={
          <div>
            {
              <table>
                <tbody>
                  <tr>
                    <td>Valor da fatura</td>
                    <td className="pl-2 text-right">{asMoney(b.netBilled)}</td>
                  </tr>
                  {b.discountPolicy === "FIXED" && b.amountPaid < b.netBilled && (
                    <tr>
                      <td>
                        Desconto até{" "}
                        {moment(b.dueDate)
                          .subtract(b.discountDays)
                          .format("DD[/]MM")}
                      </td>
                      <td className="pl-2 text-right">
                        ({asMoney(b.discountAmount)})
                      </td>
                    </tr>
                  )}
                  {b.interestPolicy !== "NO_INTEREST" &&
                    b.amountPaid > b.netBilled && (
                      <tr>
                        <td>Juros no período</td>
                        <td className="pl-2 text-right">
                          {asMoney(b.amountPaid - b.netBilled)}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="font-weight-bold">Recebido</td>
                    <td className="pl-2 text-right font-weight-bold">
                      {asMoney(b.amountPaid)}
                    </td>
                  </tr>
                </tbody>
              </table>
            }
          </div>
        }
      />
    ) : null;
  }

  static statusDetails(b) {
    const paymentDate = (
      <Popup
        trigger={
          <span className={style.StatusDate}>{shortDate(b.statusDate)}</span>
        }
        inverted
        header="Reconhecimento do pagamento"
        content="PLI reconhece o pagamento geralmente em D+1 da data do pagamento."
        position="top right"
      />
    );

    const payDate = (
      <Popup
        trigger={
          <span className={style.StatusDate}>{shortDate(b.statusDate)}</span>
        }
        inverted
        header="Recebimento manual"
        content="Recebimento feito de forma manual."
        position="top right"
      />
    );

    switch (b.status) {
      case "PENDING":
        return null;
      case "CREATED_PAYMENT":
        return null;
      case "OPEN_PAYMENT":
        return null;
      case "NO_PAYMENT":
        return null;
      case "UNDERPAYMENT":
        return paymentDate;
      case "FULL_PAYMENT":
        return paymentDate;
      case "OVERPAYMENT":
        return paymentDate;

      case "PAID":
        return payDate;
      case "SUSPENDED":
        return null;
      case "EXPIRED":
        return null;
      case "DELETED":
        return null;

      // Reserved for future usage.
      case "REFUND":
        return null;
      case "CHARGEBACK":
        return null;
      case "ON_HOLD":
        return null;
      default:
        break;
    }
  }

  static customerName(customer) {
    return (
      <div className="text-left">
        <span className={classNames(style.Name, style[customer.status])}>
          {nameShortner(customer.nickname || customer.name, "Full")}
        </span>
        <span className={classNames(style.Nickname, style[customer.status])}>
          {nameShortner(customer.name, "First Last")}
        </span>
      </div>
    );
  }

  renderMobile() {
    const b = this.props.billing;

    return (
      <div
        className={classNames(style.Row, "fadeInUp")}
        onClick={() => this.props.openBilling(b.id)}
      >
        <div>{BillingInline.customerName(b.customer)}</div>
        <Grid padded="horizontally" centered doubling>
          <Grid.Row columns={4}>
            <Grid.Column width={4} verticalAlign="middle" textAlign="left">
              {shortDate(b.dueDate)}
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle" textAlign="right">
              <Popup
                trigger={
                  <div>
                    <PriceTag value={billingOneValue(b)} smallCents={true} />
                  </div>
                }
                inverted
                content={billingOneValueDescription(b)}
                position="top right"
              />
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              <BillingStatus b={b} iconSize={24} />
            </Grid.Column>
            <Grid.Column width={4} verticalAlign="middle">
              {BillingInline.statusDetails(b)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  renderDesktop() {
    const b = this.props.billing;

    return (
      <div
        className={classNames(style.Row, "fadeInUp")}
        onClick={() => this.props.openBilling(b.id)}
      >
        <Grid padded="horizontally" centered doubling>
          <Grid.Row columns={5}>
            <Grid.Column width={8} verticalAlign="middle">
              {BillingInline.customerName(b.customer)}
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="middle">
              {shortDate(b.dueDate)}
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="middle" textAlign="right">
              <Popup
                trigger={
                  <div>
                    <PriceTag value={billingOneValue(b)} smallCents={true} />
                  </div>
                }
                inverted
                content={billingOneValueDescription(b)}
                position="top right"
              />
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="middle">
              <BillingStatus b={b} iconSize={24} />
            </Grid.Column>
            <Grid.Column width={2} verticalAlign="middle">
              {BillingInline.statusDetails(b)}
            </Grid.Column>
          </Grid.Row>
          {(b.description || "").length > 0 && (
            <Grid.Row columns={1} className={style.Description}>
              <Grid.Column width={16}>{b.description}</Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </div>
    );
  }

  render() {
    return window.innerWidth <= 768
      ? this.renderMobile()
      : this.renderDesktop();
  }
}

export default BillingInline;
