import React from 'react'
import { withRouter } from 'react-router-dom'

import analytics from './index'
import settings from '../../app/settings'

// Installation instructions: Place it as immediate child of <BrowserRouter>
class SegmentVisit extends React.Component {
  constructor(props) {
    super(props)

    this.state = { pathname: '' }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname !== state.pathname) {
      if (settings.SEGMENT_TRACK_PAGEVIEWS)
        analytics.page()

      return { pathname: props.location.pathname }
    }

    return null
  }

  render() {
    return (
      <div>
        { this.props.children }
      </div>
    )
  }
}

export default withRouter(SegmentVisit)