import React from "react";
import { useMutation } from "@apollo/react-hooks";
import toDecimal from "../../../../utils/decimal";
import GQL_PAY_BILLING from "../../../../graphql/mutations/BillingPay.qql";
import GQL_CUSTOMER_WITH_FIELDS from "../../../../graphql/queries/CustomerWithFields.gql";
import GQL_BILLING from "../../../../graphql/queries/Billing.gql";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import DatePicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import moment from "moment";

const toFloat = (value) =>
  parseFloat(`${value}`.replaceAll(".", "").replace(",", "."), 2);

export function PayModal({ id, customerId, onCompleted, billing }) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    amountBilled: "0,00",
    amount: "0,00",
    dueDate: "",
    payday: "",
    ...billing,
  });
  const [errors, setErrors] = React.useState({});

  function validate(key, value) {
    const validations = {
      amountBilled: (value) => {
        if (toFloat(value) <= 0) {
          setErrors((errors) => ({
            ...errors,
            amountBilled: "Valor inválido",
          }));
          return false;
        }

        setErrors(({ amountBilled, ...errors }) => errors);
        return true;
      },
      amountPaid: (value) => {
        if (toFloat(value) <= 0) {
          setErrors((errors) => ({ ...errors, amountPaid: "Valor inválido" }));
          return false;
        }

        setErrors(({ amountPaid, ...errors }) => errors);
        return true;
      },
      dueDate: (value) => {
        if (!value) {
          setErrors((errors) => ({ ...errors, dueDate: "Data inválida" }));
          return false;
        }
        setErrors(({ dueDate, ...errors }) => errors);
        return true;
      },
      payday: (value) => {
        if (!value) {
          setErrors((errors) => ({ ...errors, payday: "Data inválida" }));
          return false;
        }
        setErrors(({ payday, ...errors }) => errors);
        return true;
      },
    };

    if (key) {
      return !!validations[key](value);
    }

    return Object.keys(validations)
      .map((key) => validations[key](values[key]))
      .reduce((acc, curr) => (!curr ? false : acc), true);
  }

  const [confirmPayment, { loading }] = useMutation(GQL_PAY_BILLING, {
    variables: {
      id,
      description: values.description,
      payday: values.payday,
      amountPaid: toFloat(values.amountPaid),
    },
    onCompleted,
    onError(error) {
      console.error(error);
      alert(error.message);
    },
    refetchQueries: [
      { query: GQL_BILLING, variables: { id } },
      { query: GQL_CUSTOMER_WITH_FIELDS, variables: { id: customerId } },
    ],
  });

  function changeDescription({ target: { value: description } }) {
    setValues((values) => ({ ...values, description }));
  }

  function changeAmountPaid(e) {
    const amountPaid = toDecimal(e.target.value).decimalValue;
    setValues((values) => ({ ...values, amountPaid }));
    validate("amountPaid", amountPaid);
  }

  function changePaidDate(_, data) {
    setValues((values) => ({ ...values, payday: data?.value }));
    validate("payday", data?.value);
  }

  function confirm() {
    const valid = validate();
    if (valid) confirmPayment();
  }

  return (
    <Modal
      size="mini"
      dimmer="blurring"
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<span>Marcar como paga</span>}
      style={{
        height: "auto",
        overflow: "visible",
        width: 500,
        maxWidth: "100%",
      }}
    >
      <Modal.Header>Informações do pagamento</Modal.Header>
      <Modal.Content style={{ textAlign: "center" }}>
        <Modal.Description>
          <Form.Field style={{ marginBottom: 20 }}>
            <p>
              <strong>Valor cobrado</strong>
            </p>
            <p>R$ {values.amountBilled}</p>
          </Form.Field>
          <Form.Field style={{ marginBottom: 20 }}>
            <p>
              <strong>Data de vencimento</strong>
            </p>
            <p>{moment(values.dueDate).format("DD/MM/YYYY")}</p>
          </Form.Field>
          <Form.Field style={{ marginBottom: 30 }}>
            <Input
              value={values.description}
              onChange={changeDescription}
              placeholder="Descrição"
              size="big"
              showClearBtn
            />
            {errors.description && (
              <p style={{ color: "red" }}>{errors.description}</p>
            )}
          </Form.Field>

          <Form.Field style={{ marginBottom: 30 }}>
            <DatePicker
              datePickerOnly={true}
              format="DD/MM/YYYY"
              locale="pt-BR"
              maxDate={new Date()}
              disabled={loading}
              placeholder="Data do pagamento"
              size="big"
              value={values.payday ? moment(values.payday).toDate() : null}
              onChange={changePaidDate}
            />
            {errors.payday && <p style={{ color: "red" }}>{errors.payday}</p>}
          </Form.Field>
          <Form.Field style={{ marginBottom: 10 }}>
            <Input
              disabled={loading}
              label="R$"
              placeholder="Valor pago"
              size="big"
              type="decimal"
              fontSize="large"
              value={values.amountPaid || ""}
              onChange={changeAmountPaid}
            />
            {errors.amountPaid && (
              <p style={{ color: "red" }}>{errors.amountPaid}</p>
            )}
          </Form.Field>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)} disabled={loading}>
          Cancelar
        </Button>
        <Button
          disabled={loading || !!Object.keys(errors).length}
          content="Confirmar pagamento"
          labelPosition="right"
          icon="checkmark"
          onClick={confirm}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
}
