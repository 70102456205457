import React from "react";
import { Query } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import classNames from "classnames";

import { Button, Header, Grid } from "semantic-ui-react";
import { FaCheckCircle, FaChevronCircleRight } from "react-icons/fa";

import { routes } from "../../routes";

import style from "./CreatedRecurrence.module.css";

import GQL_RECURRENCE_BILLINGS from "src/graphql/queries/RecurrenceBillings.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";
import { floatToDecimal } from "src/utils/decimal";

class CreatedRecurrence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,
    };

    this.goto = this.goto.bind(this);
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const customer = this.props.customer;

    return (
      <Query query={GQL_RECURRENCE_BILLINGS} variables={{ id: this.props.id }}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const { recurrence } = data;

          return (
            <div className={style.ModalBody}>
              <div className="mt-1">
                <Link
                  to={routes.RECEIVABLES.getLink(customer.id)}
                  className={classNames(style.ListItemLink, "fadeInUp")}
                >
                  &laquo; Voltar para cobranças
                </Link>
              </div>

              <div className="mt-2 pb-2 text-center">
                <FaCheckCircle className={style.SuccessIcon} />
                <Grid centered className="mt-4">
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      <Header size="large" color="green">
                        Feito!
                      </Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="center">
                      Mensalidade de R$ {floatToDecimal(recurrence.amount)}{" "}
                      programadas com vencimentos{" "}
                      {recurrence.mday <= 0
                        ? "no último dia de cada mês"
                        : `no dia ${recurrence.mday} de cada mês`}
                      .
                    </Grid.Column>
                  </Grid.Row>
                  {recurrence.totalCycles <= 0 && (
                    <React.Fragment>
                      <Grid.Row columns={1} textAlign="left">
                        <Grid.Column width={16}>
                          <Header>Próximas cobranças</Header>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1} textAlign="left">
                        <Grid.Column width={16}>
                          <p>
                            Serão geradas cobranças para os próximos 12 meses.
                          </p>
                          <p>
                            As cobranças com vencimento posterior serão geradas
                            mês a mês.
                          </p>
                        </Grid.Column>
                      </Grid.Row>
                    </React.Fragment>
                  )}
                </Grid>
              </div>

              <div className="pt-3">
                <Link
                  to={routes.RECURRENCE.getLink(customer.id, recurrence.id)}
                  className={style.Link}
                >
                  <Button primary fluid size="large">
                    <span className="a-m mr-2">
                      {loading ? "Só um instante..." : "Ver cobranças"}
                    </span>
                    <FaChevronCircleRight />
                  </Button>
                </Link>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default CreatedRecurrence;
