import { gql } from "apollo-boost";

import RecurrenceProps from "../fragments/recurrence";
import BillingProps from "../fragments/billing";

const GQL_CREATE_RECURRENCE = gql`
  mutation CreateRecurrence(
    $customerId: ID!
    $mday: Int!
    $amount: Float!
    $description: String
    $interestPolicy: InterestPolicy!
    $totalCycles: Int!
    $firstDueDate: Date!
    $discountAmount: Float
    $discountDays: Int
  ) {
    createRecurrence(
      customerId: $customerId
      mday: $mday
      amount: $amount
      description: $description
      interestPolicy: $interestPolicy
      totalCycles: $totalCycles
      firstDueDate: $firstDueDate
      discountAmount: $discountAmount
      discountDays: $discountDays
    ) {
      ...RecurrenceProps
      billings {
        ...BillingProps
      }
    }
  }
  ${RecurrenceProps}
  ${BillingProps}
`;

export default GQL_CREATE_RECURRENCE;
