import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
} from "@plipag/ceci-ui";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { CustomersContext } from "../../context";
import { floatToDecimal } from "src/utils/decimal";

type Props = {
  recurrences: Array<{ [key: string]: any }>;
};

export function RecurrenceSuccess({ recurrences }: Props) {
  const { items: customers } = React.useContext(CustomersContext);
  return (
    <>
      <Typography sx={{ p: 3 }}>
        Mensalidade de R$ {floatToDecimal(recurrences[0]?.amount)} programadas
        com vencimentos{" "}
        {recurrences[0]?.mday <= 0
          ? "no último dia de cada mês"
          : `no dia ${recurrences[0]?.mday} de cada mês`}
        .
      </Typography>
      <Typography>
        Acesse individualmente as mensalidades de cada cliente:
      </Typography>
      <List sx={{ m: 1, mt: 2, mb: 2 }}>
        {recurrences.map((billing: { [key: string]: any }) => (
          <ListItem disableGutters disablePadding key={billing.id}>
            <ListItemButton
              component={Link}
              to={`/clientes/${billing.customerId}/mensalidades/${billing.id}`}
              target="_blank"
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <FaExternalLinkAlt />
              </ListItemIcon>
              <ListItemText
                primary={
                  (customers.find((c) => c.id === billing.customerId) || {})
                    .name
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
