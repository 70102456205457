import React from "react";
import classNames from "classnames";
import _ from "lodash";

import {
  MdPerson,
  MdSupervisorAccount,
  MdAccountBox,
  MdEmail,
  MdPhone,
} from "react-icons/md";
import { Header } from "semantic-ui-react";
import Input from "src/uikit/Forms/Input";

import {
  handleName,
  handleNickname,
  handleEmail,
  handleMobile,
  handleDocument,
  setSuggestion,
} from "../fields";

import style from "../CreateCustomerModal.module.css";

function renderNickname(state, setState) {
  return (
    <div className={classNames(style.FieldSep)}>
      <Input
        value={state.nickname}
        onChange={(e) => handleNickname(e, setState)}
        icon={<MdSupervisorAccount />}
        placeholder="Apelido"
        autoComplete={false}
        showClearBtn
        autofocus
      />
    </div>
  );
}

function renderDocument(state, setState) {
  return (
    <div className={classNames(style.FieldSep)}>
      <Input
        value={state.document}
        onChange={(e) => handleDocument(e, setState)}
        icon={<MdAccountBox />}
        invalid={state.documentInvalid}
        invalidMessage="Documento inválido"
        placeholder="CPF ou CNPJ"
        autoComplete={false}
        showClearBtn
      />
    </div>
  );
}

function renderName(state, setState) {
  return (
    <div className={classNames(style.FieldSep)}>
      <Input
        value={state.name}
        onChange={(e) => handleName(e, setState)}
        icon={<MdPerson />}
        placeholder="Nome"
        disabled={state.nameLocked}
        autoComplete={false}
        showClearBtn
      />
    </div>
  );
}

function renderEmail(state, setState) {
  const e = _.isEmpty(state.emailSuggestions)
    ? null
    : state.emailSuggestions[0];

  return (
    <div className={classNames(style.FieldSep)}>
      <Input
        value={state.email}
        onChange={(e) => handleEmail(e, setState)}
        icon={<MdEmail />}
        invalid={state.emailInvalid}
        invalidMessage="Este email não existe, as mensagens provavelmente não serão entregues"
        placeholder="Email"
        type="email"
        autoComplete={false}
        showClearBtn
      />
      {state.emailEnrichment && (
        <div>
          {state.emailEnrichment.validSyntax === false && (
            <div>
              Parece que tem algum erro de digitação no email, verifique.
            </div>
          )}
          {state.emailEnrichment.validMailbox === false && (
            <div>Este email não existe, tente outro.</div>
          )}
          {state.emailEnrichment.didYouMean && (
            <div>
              <span className="mr-2">Você quis dizer:</span>
              <span
                className={style.SuggestionLink}
                onClick={() => {
                  setSuggestion(
                    "email",
                    state.emailEnrichment.didYouMean,
                    setState
                  );
                  setSuggestion("error", null, setState);
                  setSuggestion("emailEnrichment", [], setState);
                  setState({ emailInvalid: false });
                }}
              >
                {state.emailEnrichment.didYouMean}
              </span>
              ?
            </div>
          )}
        </div>
      )}
      {e && (
        <div>
          <span className="pr-2">Sugestão:</span>
          <ul className="horizontal inline-block">
            <li
              className={style.SuggestionLink}
              onClick={() => {
                setSuggestion("email", e, setState);
              }}
            >
              {e}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

function renderMobile(state, setState) {
  const e = _.isEmpty(state.mobileSuggestions)
    ? null
    : state.mobileSuggestions[0];

  return (
    <div className={classNames(style.FieldSep)}>
      <Input
        value={state.mobileFace}
        onChange={(e) => handleMobile(e, setState)}
        icon={<MdPhone />}
        invalid={state.mobileInvalid}
        invalidMessage="Este celular não existe, as mensagens provavelmente não serão entregues"
        placeholder="Celular"
        autoComplete={false}
        showClearBtn
      />
      {e && (
        <div>
          <span className="pr-2">Sugestão:</span>
          <ul className="horizontal inline-block">
            <li
              key={e}
              className={style.SuggestionLink}
              onClick={() => setSuggestion("mobile", e, setState)}
            >
              {e}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

// https://www.youtube.com/watch?v=xLlIgokKiLc
// https://www.youtube.com/watch?v=xIYAV6IP4gA
export default function a(state, setState) {
  return (
    <div className={style.ModalBody}>
      {renderNickname(state, setState)}
      <div className="mt-3">
        <Header size="medium">Cliente</Header>
      </div>
      {renderDocument(state, setState)}
      {renderName(state, setState)}
      <div className="mt-3">
        <Header size="medium">Contato</Header>
      </div>
      {renderEmail(state, setState)}
      {renderMobile(state, setState)}
    </div>
  );
}
