import moment from 'moment'
import _ from 'lodash'
import settings from '../../app/settings'

export function simulateRecurrenceBillings(mday, amount, cycles, first) {
  const generateObject = (dueDate) => ({
    amountBilled: amount, dueDate: moment(dueDate), status: 'PENDING'
  })

  const generatedCycles = cycles <= 0 ? 12 : cycles

  let list = [], last = moment(first)
  for (let i = 0; i < generatedCycles; i++) {
    list = _.concat(list, [last])
    last = moment(last).clone().add(1, 'month')
    if (mday <= 0)
      last = last.endOf('month')
  }

  const objs = _.map(list, generateObject)

  return cycles > 0 ? objs :
    _.concat(objs, [{ amountBilled: 0, dueDate: null, status: 'PENDING' }])
}

export function defaultRecurrenceStartDate(mday) {
  const earliest = moment().add(settings.BILLING_MIN_DAYS_AHEAD, 'days')

  const candidate = mday <= 0 ?
    moment().endOf('month').startOf('day') :
    moment().startOf('month').add(mday - 1, 'days')

  const candidateNextMonth = mday <= 0 ?
    candidate.clone().endOf('month') :
    candidate.clone().add(1, 'month')

  return candidate.isSameOrAfter(earliest, 'day') ?
    candidate :
    candidateNextMonth
}

export function defaultRecurrenceStartDateShifted(mday) {
  const earliest = moment().add(settings.BILLING_MIN_DAYS_AHEAD, 'days')

  const candidate = mday <= 0 ?
    moment().endOf('month').startOf('day') :
    moment().startOf('month').add(mday - 1, 'days')

  return candidate.isBefore(earliest, 'day')
}
