import React from "react";
import { Skeleton } from "@plipag/ceci-ui";
import { List, ListItem, ListItemText, Divider } from "@plipag/ceci-ui";

export function CustomersListSkeleton() {
  return (
    <List sx={{ mt: 2, mb: 2 }}>
      {Array.from({ length: 5 }).map((_, index) => (
        <React.Fragment key={index}>
          <ListItem disableGutters disablePadding>
            <ListItemText
              primary={<Skeleton variant="text" width={200} />}
              secondary={<Skeleton variant="text" width={300} />}
              sx={{ mt: 1, mb: 1 }}
            />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
}
