import React from "react";
import classNames from "classnames";

import style from "./PriceTag.module.css";

export default function a({
  value,
  smallCents = true,
  smallFont = false,
  color = "#444444",
}) {
  const amount = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

  const amountInt = amount.split(",")[0];
  const amountCents = amount.split(",")[1];

  const currencyStyle = classNames(
    style.AmountCurrency,
    smallFont ? style.AmountCurrencySmall : null
  );
  const amountIntStyle = classNames(
    style.AmountInt,
    smallFont ? style.AmountIntSmall : null
  );
  const amountCentsStyle = classNames(
    style.AmountCents,
    smallFont ? style.AmountCentsSmall : null
  );
  const amountStyle = classNames(
    style.Amount,
    smallFont ? style.AmountSmall : null
  );

  return (
    <React.Fragment>
      <span className={currencyStyle} style={{ color }}>
        R$
      </span>
      {smallCents ? (
        <React.Fragment>
          <span className={amountIntStyle} style={{ color }}>
            {amountInt}
          </span>
          <span className={amountCentsStyle} style={{ color }}>
            ,{amountCents}
          </span>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <span className={amountStyle} style={{ color }}>
            {amount}
          </span>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
