import React from "react";
import { Mutation, Query } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";

import { Header, Message, Popup } from "semantic-ui-react";
import { FaUserTimes, FaTrash } from "react-icons/fa";

import { routes } from "../../routes";

import analytics from "src/plg/Segment";

import ImgIcon from "src/uikit/ImgIcon";

import style from "./ArchiveCustomer.module.css";

import GQL_ARCHIVE_CUSTOMER from "src/graphql/mutations/ArchiveCustomer.gql";
import GQL_DESTROY_CUSTOMER from "src/graphql/mutations/DestroyCustomer.gql";
import GQL_CUSTOMERS from "src/graphql/queries/Customers.gql";
import GQL_CUSTOMER_DESTROYABLE from "src/graphql/queries/CustomerDestroyable.gql";
import GqlError from "src/uikit/GqlError";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";

export default class ArchiveCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      page: "INTRO",
    };

    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleDestroyUpdate = this.handleDestroyUpdate.bind(this);
    this.handleArchiveUpdate = this.handleArchiveUpdate.bind(this);
    this.renderDestroySuccess = this.renderDestroySuccess.bind(this);
    this.renderArchiveSuccess = this.renderArchiveSuccess.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changePage(page) {
    this.setState({ page });
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Não foi possível completar a operação, tente novamente",
    });
  }

  handleDestroyUpdate(cache, { data: { destroyCustomer } }) {
    const deletedCustomerId = this.props.customer.id;

    cache.writeQuery({
      query: GQL_CUSTOMERS,
      data: {
        customers: _.filter(
          cache.readQuery({ query: GQL_CUSTOMERS }).customers,
          (customer) =>
            destroyCustomer ? customer.id !== deletedCustomerId : true
        ),
      },
    });
  }

  handleArchiveUpdate(
    cache,
    {
      data: {
        archiveCustomer: { id, status, recurrences, billings },
      },
    }
  ) {}

  renderDestroyButton() {
    return (
      <Mutation
        mutation={GQL_DESTROY_CUSTOMER}
        variables={{ id: this.props.customer.id }}
        refetchQueries={["CustomersConnection"]}
        onError={this.handleMutationError}
        onCompleted={() => {
          analytics.track("Customer Deleted");
          this.changePage("DESTROY_SUCCESS");
        }}
      >
        {(destroyCustomer, { error, loading, data }) => (
          <Popup
            trigger={
              <FaTrash
                className={style.Icon}
                onClick={() => {
                  if (window.confirm("Tem certeza?")) {
                    destroyCustomer();
                  }
                }}
              />
            }
            open
            inverted
            content="Excluir cliente"
            position="right center"
          />
        )}
      </Mutation>
    );
  }

  renderDestroyable() {
    return (
      <div>
        <Header textAlign="center" size="large">
          Excluir cliente
        </Header>
        <div>
          <Query
            query={GQL_CUSTOMER_DESTROYABLE}
            variables={{ id: this.props.customer.id }}
            fetchPolicy="no-cache"
          >
            {({ loading, error, data }) => {
              if (loading) return <GqlLoadingIcon />;
              if (error) return <GqlError error={error} />;

              const { customerDestroyable } = data;

              if (customerDestroyable)
                return (
                  <div>
                    <p>
                      Esta é uma operação permanente. Uma vez excluído, não será
                      possível reaver suas informações cadastrais, anotações e
                      outros registros históricos.
                    </p>
                    <div className="text-center mt-2 mb-2">
                      {this.renderDestroyButton()}
                    </div>
                  </div>
                );

              return (
                <div>
                  <p>
                    Um cliente somente pode ser exclúido quando não houver
                    histórico de cobranças.
                  </p>
                  <p>
                    Como <b>{this.props.customer.name}</b> já teve cobrança
                    emitida, ele pode ser arquivado.
                  </p>
                </div>
              );
            }}
          </Query>
        </div>
      </div>
    );
  }

  renderArchiveButton() {
    return (
      <Mutation
        mutation={GQL_ARCHIVE_CUSTOMER}
        variables={{ id: this.props.customer.id }}
        refetchQueries={["CustomersConnection"]}
        update={this.handleArchiveUpdate}
        onError={this.handleMutationError}
        onCompleted={() => {
          analytics.track("Customer Archived");
          this.changePage("ARCHIVE_SUCCESS");
        }}
      >
        {(archiveCustomer, { error, loading, data }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          return (
            <Popup
              trigger={
                <FaUserTimes
                  className={style.Icon}
                  onClick={() => {
                    if (window.confirm("Tem certeza?")) {
                      archiveCustomer();
                    }
                  }}
                />
              }
              open
              inverted
              content="Arquivar o cliente"
              position="right center"
            />
          );
        }}
      </Mutation>
    );
  }

  renderArchive() {
    return (
      <div>
        <Header textAlign="center" size="large">
          Arquivar cliente
        </Header>
        <div>
          <p>
            Você pode arquivar um cliente que não está mais ativo, mantendo o
            histórico do relacionamento, anotações e informações cadastrais.
            Você poderá encontrá-lo na aba Clientes, filtrando por "Arquivados".
          </p>
          <p>
            Ao arquivar um cliente, todas as cobranças programadas e
            mensalidades são imediatamente suspensas e as faturas abertas devem
            ser encerradas manualmente.
          </p>
          <div className="text-center mt-2 mb-2">
            {this.renderArchiveButton()}
          </div>
        </div>
      </div>
    );
  }

  renderError(errors) {
    if (!errors) return null;

    return <Message negative>Erro, cheque se os dados são válidos.</Message>;
  }

  renderDestroySuccess() {
    return (
      <div className="text-center">
        <ImgIcon
          iconPath="open/gradient/747818-ui-interface/png/133-trash.png"
          className={style.Icon}
        />
        <Header
          content="Cliente excluído"
          textAlign="center"
          className="cursor-default"
        />
      </div>
    );
  }

  renderArchiveSuccess() {
    const comp = this;
    setTimeout(() => {
      comp.setState({ page: "REDIRECT" });
    }, 2000);

    return (
      <div className="text-center">
        <ImgIcon
          iconPath="open/gradient/747818-ui-interface/png/018-remove-user.png"
          className={style.IconStatic}
        />
        <Header
          content="Cliente arquivado"
          textAlign="center"
          className="cursor-default"
        />
      </div>
    );
  }

  renderArchived() {
    const comp = this;
    setTimeout(() => {
      comp.setState({ page: "REDIRECT" });
    }, 2000);

    return (
      <div className="text-center">
        <ImgIcon
          iconPath="open/gradient/747818-ui-interface/png/018-remove-user.png"
          className={style.IconStatic}
        />
        <Header
          content="Cliente arquivado"
          textAlign="center"
          className="cursor-default"
        />
      </div>
    );
  }

  renderIntro() {
    return (
      <div>
        {this.state.error && (
          <div className="mt-3 mb-3">{this.renderError(this.state.error)}</div>
        )}
        <div className="pt-3">{this.renderArchive()}</div>
        <div className="pt-5">{this.renderDestroyable()}</div>
      </div>
    );
  }

  render() {
    if (this.state.page === "REDIRECT")
      return <Redirect to={routes.CUSTOMER.getLink(this.props.customer.id)} />;

    let body;
    switch (this.props.customer.status) {
      case "ACTIVE":
        switch (this.state.page) {
          case "INTRO":
            body = this.renderIntro();
            break;
          case "DESTROY_SUCCESS":
            body = this.renderDestroySuccess();
            break;
          case "ARCHIVE_SUCCESS":
            body = this.renderArchiveSuccess();
            break;
          default:
            break;
        }
        break;
      case "ARCHIVED":
        body = this.renderArchived();
        break;
      default:
        break;
    }

    return (
      <div className={style.ModalBody}>
        <div className="mt-1 mb-3">
          <Link
            to={routes.CUSTOMER.getLink(this.props.customer.id)}
            className={classNames(style.ListItemLink, "fadeInUp")}
          >
            &laquo; Voltar
          </Link>
        </div>
        {body}
      </div>
    );
  }
}
