import React from "react";
import { Mutation, Query } from "react-apollo";
import _ from "lodash";

import { asPhone, PHONE_FORMATS } from "src/utils/phone";
import { validEmail } from "src/utils/email";
import { formatZipcode, zipcodeValid } from "src/utils/address";
import { DOC_TYPE, docType, docValid, docFormat } from "src/utils/document";
import {
  addressZipcode,
  customerName,
  customerCompanyFull,
} from "src/utils/enrichment";

import { Button, Grid, Header, Message } from "semantic-ui-react";
import {
  MdEmail,
  MdPhone,
  MdPlace,
  MdBusiness,
  MdAccountBox,
} from "react-icons/md";

import Input from "src/uikit/Forms/Input";

import style from "./Profile.module.css";

import GQL_MERCHANT_UPDATE_REQUEST from "src/graphql/mutations/MerchantUpdateRequest.gql";
import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

const pages = {
  INTRO: "INTRO",
  FULL_PROFILE: "FULL_PROFILE",
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: pages.INTRO,
      error: null,

      name: this.props.merchant.name || "",

      legalName: this.props.merchant.legalName,
      legalNameLocked: false,

      document: "",

      email: this.props.merchant.email || "",
      phone: this.props.merchant.phone || "",
      phoneFace:
        asPhone(this.props.merchant.phone, PHONE_FORMATS.E164, false)
          .formatted || "",
      phoneValid: true,

      street: this.props.merchant.street || "",
      district: this.props.merchant.district || "",
      city: this.props.merchant.city || "",
      province: this.props.merchant.province || "",
      zipcode: this.props.merchant.zipcode || "",
    };

    this.handleName = this.handleName.bind(this);
    this.handleDocument = this.handleDocument.bind(this);
    this.handleLegalName = this.handleLegalName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleStreet = this.handleStreet.bind(this);
    this.handleDistrict = this.handleDistrict.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleProvince = this.handleProvince.bind(this);
    this.handleZipcode = this.handleZipcode.bind(this);
    this.enrichDocument = this.enrichDocument.bind(this);
    this.enrichZipcode = this.enrichZipcode.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationCompleted = this.handleMutationCompleted.bind(this);
  }

  enrichDocument() {
    if (!docValid(this.state.document)) return null;

    customerName(this.state.document)
      .then((data) => {
        const content = data.data.data;
        if (!content) return null;

        this.setState({
          name: content.name,
          legalName: content.name,
          legalNameLocked: String(content.name).length > 5,
        });
      })
      .catch(console.log);

    if (docType(this.state.document) === DOC_TYPE.CNPJ) {
      customerCompanyFull(this.state.document)
        .then((data) => {
          const content = data.data.data;
          if (!content) return null;

          this.setState({
            // email: content.email,
            // phone: content.phone,
            street: content.address.street,
            district: content.address.district,
            city: content.address.city,
            province: content.address.province,
            zipcode: content.address.zipcode,
          });
        })
        .catch(console.log);
    }
  }

  enrichZipcode() {
    if (!zipcodeValid(this.state.zipcode)) return null;

    addressZipcode(this.state.zipcode)
      .then((data) => {
        const content = data.data.data;
        if (!content) return null;

        this.setState({
          street: content.street,
          district: content.district,
          city: content.city,
          province: content.province,
        });
      })
      .catch(console.log);
  }

  handleName(e) {
    this.setState({ name: e.target.value });
  }

  handleLegalName(e) {
    this.setState({ legalName: e.target.value });
  }

  handleDocument(e) {
    this.setState(
      {
        document: docFormat(e.target.value),
        legalNameLocked: false,
      },
      this.enrichDocument
    );
  }

  handleEmail(e) {
    this.setState({ email: e.target.value });
  }

  handlePhone(e) {
    const phone = asPhone(e.target.value, PHONE_FORMATS.LOCAL, false);

    this.setState({
      phone: phone.e164,
      phoneFace: phone.formatted,
      phoneValid: phone.valid,
    });
  }

  handleStreet(e) {
    const street = String(e.target.value)
      .replace(new RegExp(/[^A-Za-z0-9, -]/, "g"), "")
      .substr(0, 64);

    this.setState({ street });
  }

  handleDistrict(e) {
    this.setState({ district: e.target.value });
  }

  handleCity(e) {
    this.setState({ city: e.target.value });
  }

  handleProvince(e) {
    this.setState({
      province: String(e.target.value).toUpperCase().substr(0, 2),
    });
  }

  handleZipcode(e) {
    this.setState(
      {
        zipcode: formatZipcode(e.target.value),
      },
      this.enrichZipcode
    );
  }

  getVars() {
    return {
      name: this.state.name,
      legalName: this.state.legalName,
      document: this.state.document,

      email: this.state.email,
      phone: this.state.phone,

      street: this.state.street,
      district: this.state.district,
      city: this.state.city,
      province: this.state.province,
      zipcode: this.state.zipcode,
    };
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  handleMutationCompleted(resp) {
    this.props.nextPage();
  }

  renderConfirm() {
    return (
      <Mutation
        mutation={GQL_MERCHANT_UPDATE_REQUEST}
        variables={this.getVars()}
        onError={this.handleMutationError}
        onCompleted={this.handleMutationCompleted}
        refetchQueries={[{ query: GQL_MERCHANT }]}
      >
        {(requestMerchantUpdate, { loading, error }) => {
          const f = () => {
            if (!loading && !error) requestMerchantUpdate();
          };

          return (
            <Button
              primary
              size="large"
              onClick={f}
              disabled={loading || error}
            >
              Confirmar dados
            </Button>
          );
        }}
      </Mutation>
    );
  }

  renderBlockedDataAlert() {
    return (
      <Grid.Row columns={1}>
        <Grid.Column>
          <h5
            style={{
              color: "red",
              textAlign: "center",
              padding: "20px 0",
            }}
          >
            Não é possível alterar informações sensíveis após a ativação da
            conta. Caso necessite alterar algum dos dados a seguir, entre em
            contato com a equipe.
          </h5>
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderFullProfile() {
    return (
      <Query query={GQL_MERCHANT}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <OrbitsLoader />;
          if (error) return <GqlError error={error} />;

          const merchantEnabled = data.merchant.status === "ENABLED";

          return (
            <Grid centered className="mt-5" doubling stackable>
              {this.state.error && (
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <Message negative>{this.state.error}</Message>
                  </Grid.Column>
                </Grid.Row>
              )}
              {merchantEnabled && this.renderBlockedDataAlert()}
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    placeholder="Nome comercial do negócio"
                    onChange={this.handleName}
                    value={this.state.name}
                    fontSize="large"
                    disabled={merchantEnabled}
                  />
                  <div className={style.FieldSep} />
                  <Input
                    placeholder="CNPJ ou CPF"
                    onChange={this.handleDocument}
                    icon={<MdAccountBox />}
                    value={this.state.document}
                    invalid={!docValid(this.state.document)}
                    disabled={merchantEnabled}
                    fontSize="large"
                  />
                  <div className={style.FieldSep} />
                  <Input
                    placeholder="Razão social ou nome completo"
                    onChange={this.handleLegalName}
                    icon={<MdBusiness />}
                    value={this.state.legalName}
                    disabled={this.state.legalNameLocked || merchantEnabled}
                    fontSize="large"
                  />
                </Grid.Column>
                <Grid.Column>
                  <Header content="Endereço" />
                  <div>
                    <Input
                      placeholder="CEP"
                      onChange={this.handleZipcode}
                      icon={<MdPlace />}
                      value={this.state.zipcode}
                      disabled={merchantEnabled}
                    />
                    <Input
                      placeholder="Rua, número, complemento"
                      onChange={this.handleStreet}
                      value={this.state.street}
                      multiline
                      disabled={merchantEnabled}
                    />
                    <Input
                      placeholder="Bairro"
                      onChange={this.handleDistrict}
                      value={this.state.district}
                      disabled={merchantEnabled}
                    />
                    <Input
                      placeholder="Cidade"
                      onChange={this.handleCity}
                      value={this.state.city}
                      disabled={merchantEnabled}
                    />
                    <Input
                      placeholder="Estado"
                      onChange={this.handleProvince}
                      value={this.state.province}
                      disabled={merchantEnabled}
                    />
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column>
                  <Header content="Pontos de comunicação" />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={2}>
                <Grid.Column>
                  <Input
                    placeholder="Email"
                    onChange={this.handleEmail}
                    value={this.state.email}
                    icon={<MdEmail />}
                    invalid={!validEmail(this.state.email)}
                    disabled={merchantEnabled}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Input
                    placeholder="Telefone ou celular"
                    onChange={this.handlePhone}
                    value={this.state.phoneFace}
                    icon={<MdPhone />}
                    invalid={!this.state.phoneValid}
                    disabled={merchantEnabled}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column textAlign="center">
                  {this.renderConfirm()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Query>
    );
  }

  renderIntro() {
    return (
      <Query query={GQL_MERCHANT}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <OrbitsLoader />;
          if (error) return <GqlError error={error} />;

          const merchantEnabled = data.merchant.status === "ENABLED";

          return (
            <Grid centered className="mt-5" doubling stackable>
              {merchantEnabled && this.renderBlockedDataAlert()}

              <Grid.Row columns={1}>
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={8}
                  widescreen={6}
                >
                  <div className={style.Question}>
                    Qual é o nome do negócio?
                  </div>
                  <Input
                    placeholder="Nome comercial"
                    onChange={this.handleName}
                    value={this.state.name}
                    fontSize="large"
                    disabled={merchantEnabled}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1}>
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={8}
                  widescreen={6}
                >
                  <div className={style.Question}>
                    É empresa ou profissional liberal?
                  </div>
                  <Input
                    placeholder="CNPJ ou CPF"
                    onChange={this.handleDocument}
                    value={this.state.document}
                    fontSize="large"
                    disabled={merchantEnabled}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} className="mt-5">
                <Grid.Column
                  mobile={16}
                  tablet={12}
                  computer={8}
                  widescreen={6}
                  textAlign="center"
                >
                  <Button
                    primary
                    size="large"
                    onClick={() => this.setState({ page: pages.FULL_PROFILE })}
                  >
                    Continuar
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Query>
    );
  }

  render() {
    return this.state.page === pages.INTRO
      ? this.renderIntro()
      : this.renderFullProfile();
  }
}

export default Profile;
