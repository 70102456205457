import { gql } from 'apollo-boost'

import CustomerProps from '../fragments/customer'
import BillingProps from '../fragments/billing'

const GQL_REACTIVATE_CUSTOMER = gql`
  mutation ReactivateCustomer($id: ID!) {
    reactivateCustomer(id: $id) {
      ...CustomerProps
      billings { ...BillingProps }
    }
  }
  ${CustomerProps}
  ${BillingProps}
`

export default GQL_REACTIVATE_CUSTOMER