import { get } from "axios";
import { getToken } from "src/app/token";

export function ignoreTimeout(error) {}

function buildHeaders() {
  return { headers: { Authorization: `Bearer ${getToken()}` } };
}

export async function validateEmail(email) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/validate_email?email=${email}`;
  return await get(url, { ...buildHeaders(), timeout: 5000 });
}

export async function validateMobile(mobile) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/validate_mobile?mobile=${mobile}`;
  return await get(url, { ...buildHeaders(), timeout: 5000 });
}

export async function customerName(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_name?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function customerCompanyFull(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_company_full?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 60000 });
}

export async function customerPersonHead(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_head?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 15000 });
}

export async function customerPersonEmails(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_emails?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function customerPersonMobiles(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_mobiles?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function customerPersonAddresses(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_addresses?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function customerPersonCollections(document) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_collections?document=${document}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function customerPersonCreditScore(collections) {
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/customer_person_credit_score?collections=${collections}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}

export async function addressZipcode(zipcode) {
  const tidy = String(zipcode).replace(new RegExp(/[^0-9]/, "g"), "");
  const url = `${process.env.REACT_APP_API_HOST}/enrichment/address/${tidy}`;
  return await get(url, { ...buildHeaders(), timeout: 10000 });
}
