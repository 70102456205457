import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Grid,
  TextField,
  Typography,
  Autocomplete,
  MaskedInput,
  useMediaQuery,
  useTheme,
} from "@plipag/ceci-ui";
import Page from "../components/Page";
import Steps from "../components/Steps";

const Informations = () => {
  const [state, setState] = React.useState({
    agencia: "",
    account: "",
    cnpj: "",
  });
  const bankOptions = ["001 - Banco do Brasil"];

  function handleSubmit(e) {
    e.preventDefault();
  }
  const theme = useTheme();
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Page>
      <Steps activeStep={3} />
      <Grid
        component="form"
        onSubmit={handleSubmit}
        container
        direction="column"
        spacing={3}
        flexGrow={largeDisplay ? 0 : 1}
        sx={{ maxWidth: 480, margin: "40px auto" }}
      >
        <Grid item>
          <Typography component="p" paragraph>
            Em qual banco você quer receber os seus pagamentos?
          </Typography>
          <Autocomplete options={bankOptions} />
        </Grid>
        <Grid item>
          <Typography component="p" paragraph>
            Informe os dados da sua conta bancária.
          </Typography>
          <TextField
            label="Agência"
            value={state.agencia}
            onChange={(e) => setState({ ...state, agencia: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item>
          <TextField
            label="Conta bancária"
            value={state.account}
            onChange={(e) => setState({ ...state, account: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item>
          <MaskedInput
            mask="99.999.999/9999-99"
            label="CNPJ da empresa"
            value={state.cnpj}
            onChange={(e) => setState({ ...state, cnpj: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item sx={{ marginBottom: 0, marginTop: "auto" }}>
          <Button
            variant="contained"
            color="success"
            fullWidth
            size="large"
            to="/success"
            component={Link}
          >
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default Informations;
