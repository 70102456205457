import React from "react";
import {
  Button,
  Checkbox,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemButton,
  useMediaQuery,
} from "@plipag/ceci-ui";
import { Link } from "react-router-dom";
import { routes } from "src/components/Customer/routes";
import CustomerInline from "src/components/CustomerInline";
import { CustomersContext, Customer } from "../context";
import { MoreActions } from "./MoreActions";
import { useHistory } from "react-router-dom";

type Props = {
  customer: Customer;
};

export function CustomersListItem({ customer }: Props) {
  const { selecteds, toggleItem, cgroups, cgroupId } =
    React.useContext(CustomersContext);
  const selected = selecteds.findIndex((id) => customer.id === id) > -1;
  const selecting = selecteds.length > 0;

  const history = useHistory();

  function navigate(id: string | null) {
    if (cgroupId === id) {
      history.push("/clientes");
    } else {
      history.push(`/clientes/grupo/${id}`);
    }
  }

  const groups = cgroups.filter(
    (cgroup) => !!cgroup.customers.find((c) => c.id === customer.id)
  );

  const breakLayout = useMediaQuery("@media (max-width: 600px)");

  return (
    <>
      <ListItem
        selected={selected}
        disableGutters
        disablePadding
        secondaryAction={
          !selecting && (
            <>
              {!breakLayout && (
                <CustomerInline
                  c={customer}
                  cgroups={groups}
                  cgroupsCount={groups.length}
                  selectCgroup={(cgroup) =>
                    cgroup ? navigate(cgroup.id) : navigate(null)
                  }
                  selectedCgroup={
                    cgroups.find((cgroup) => cgroup.id === cgroupId) || null
                  }
                />
              )}
              {customer.status === "ACTIVE" && (
                <Button
                  component={Link}
                  to={routes.CREATE_RECEIVABLE.getLink(customer.id)}
                  variant="contained"
                  color="success"
                  size="large"
                  sx={{ ml: 1 }}
                >
                  Nova cobrança
                </Button>
              )}

              <MoreActions customer={customer} />
            </>
          )
        }
      >
        <ListItemButton
          to={`/clientes/${customer.id}`}
          component={Link}
          disableGutters
        >
          <ListItemAvatar
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              toggleItem(customer.id);
            }}
          >
            <Checkbox checked={selected} />
          </ListItemAvatar>
          <ListItemText
            primary={customer.nickname ? customer.nickname : customer.name}
            secondary={customer.name}
          />
          {breakLayout && (
            <ListItemText>
              <CustomerInline
                c={customer}
                cgroups={groups}
                cgroupsCount={groups.length}
                selectCgroup={(cgroup) =>
                  cgroup ? navigate(cgroup.id) : navigate(null)
                }
                selectedCgroup={
                  cgroups.find((cgroup) => cgroup.id === cgroupId) || null
                }
              />
            </ListItemText>
          )}
        </ListItemButton>
      </ListItem>
      <Divider />
    </>
  );
}
