import React from "react";
class GqlError extends React.Component {
  render() {
    return (
      <React.Fragment>
        Ocorreu um erro. Por favor, tente novamente.
      </React.Fragment>
    );
  }
}

export default GqlError;
