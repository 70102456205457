import React from "react";
import { Query } from "react-apollo";

import { Box } from "@plipag/ceci-ui";

import BillingSetup from "src/components/BillingSetup";

import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";

import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

class BillingSetupContainer extends React.Component {
  render() {
    document.title = "Configurar fatura | PLI";

    return (
      <Box sx={{ p: 5 }}>
        <Query query={GQL_MERCHANT}>
          {({ loading, error, data, refetch }) => {
            if (loading) return <OrbitsLoader />;
            if (error) return <GqlError error={error} />;

            const { merchant } = data;

            return <BillingSetup merchant={merchant} />;
          }}
        </Query>
      </Box>
    );
  }
}

export default BillingSetupContainer;
