import { gql } from 'apollo-boost'
import MerchantProps from '../fragments/merchant'

const GQL_MERCHANT = gql`
  query Merchant {
    merchant {
      ...MerchantProps
    }
  }
  ${MerchantProps}
`

export default GQL_MERCHANT