const BillingPolicy = require("@plipag/billing-policy");

export function billingOneValue(b) {
  var bil = new BillingPolicy(
    b.amountBilled,
    b.amountPaid,
    b.dueDate,
    b.interestPolicy,
    b.discountPolicy,
    b.discountAmount,
    b.discountDays,
    b.status
  );
  const serverDate = localStorage.getItem("date");
  const date = serverDate ? new Date(serverDate) : new Date();
  return bil.calcTotalAmount(date, true);
}

export function billingOneValueDescription(b) {
  const statusList = {
    PENDING: "Valor cobrado",
    CREATED_PAYMENT: "Valor cobrado",
    OPEN_PAYMENT:
      "Valor atualizado da cobrança, levando em conta instruções de juros e desconto",
    NO_PAYMENT:
      "Valor atualizado da cobrança, levando em conta instruções de juros e desconto",
    UNDERPAYMENT: "Valor pago",
    FULL_PAYMENT: "Valor pago",
    OVERPAYMENT: "Valor pago",
    PAID: "Valor Pago",
    SUSPENDED: "Valor cobrado",
    EXPIRED: "Valor cobrado",
    DELETED: "Valor cobrado",
    // Reserved for future usage.
    CHARGEBACK: "Valor cobrado",
    REFUND: "Valor cobrado",
    ON_HOLD: "Valor cobrado",
  };

  return statusList[b.status] || "Valor cobrado";
}
