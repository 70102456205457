import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'
import CustomerProps from '../fragments/customer'

const GQL_BILLING_CUSTOMER = gql`
  query BillingCustomer($id: ID!) {
    billing(id: $id) {
      ...BillingProps
      customer {
        ...CustomerProps
      }
    }
  }
  ${BillingProps}
  ${CustomerProps}
`

export default GQL_BILLING_CUSTOMER
