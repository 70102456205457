import React from "react";
import { Route } from "react-router-dom";
import { getToken } from "src/app/token";

export function ProtectedRoute({
  layout = true,
  component: Component,
  ...props
}) {
  if (!getToken()) {
    window.location.href = process.env.REACT_APP_SIGNIN_URL;
    return null;
  }

  return <Route {...props} render={(props) => <Component {...props} />} />;
}
