import React from "react";

import { Grid } from "semantic-ui-react";

import { asMoney } from "src/utils/decimal";

import Tooltip from "src/components/Tooltip";
import { SummaryCard } from "src/components/SummaryCard";
import axios from "axios";
import { getToken } from "src/app/token";
import Skeleton from "./Skeleton";
import Error from "./Error";

class BillingsStatistics extends React.Component {
  state = {
    data: null,
    loading: true,
    error: false,
  };

  constructor(props) {
    super(props);

    this.load = this.load.bind(this);
  }

  componentDidMount() {
    this.load();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentMonth !== this.props.currentMonth) this.load();
    if (prevProps.filter !== this.props.filter) this.load();
  }

  load() {
    this.setState({ loading: true });
    axios
      .get(
        `${
          process.env.REACT_APP_FUNCTIONS_URL
        }/billings-statistics/?token=${getToken()}&variables=${encodeURIComponent(
          JSON.stringify(this.props.variables)
        )}`
      )
      .then(({ data }) => {
        console.log(data);
        this.setState({ loading: false, data });
      })
      .catch((error) => {
        this.setState({ error: true });
        console.error(error);
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    if (this.state.loading) return <Skeleton />;
    if (this.state.error) return <Error refetch={this.load} />;

    const { billingsCount, netBilled, amountPaid, receivables } =
      this.state.data;

    return (
      <Grid centered relaxed stackable doubling padded>
        <Grid.Row columns={4}>
          <Grid.Column width={4} textAlign="center">
            <SummaryCard label="Faturas" value={billingsCount} />
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <Tooltip title="Valor bruto" content="Sem encargos ou descontos.">
              <SummaryCard label="Cobrado" value={asMoney(netBilled)} />
            </Tooltip>
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <SummaryCard label="Recebido" value={asMoney(amountPaid)} />
          </Grid.Column>
          <Grid.Column width={4} textAlign="center">
            <Tooltip title="Valor bruto" content="Sem encargos ou descontos.">
              <SummaryCard label="A receber" value={asMoney(receivables)} />
            </Tooltip>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default BillingsStatistics;
