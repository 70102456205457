import { gql } from 'apollo-boost'

const GQL_MERCHANT_USER_SEGMENTATION = gql`
  query MerchantUserSegmentation {
    user {
      id
      email
    }
    merchant {
      merchantId merchant:name status document legalType enabledDate liveDate
    }
    plg {
      fakeBillingIssued billingsCreated billingsIssued billingsCancelled billingsPaid
      recurrencesCreated
      customersCreated
      settlementAvailable settlementsCo
    }
    setup {
      logoUploaded documentSet legalRepresentativeSet
      compliancePoiStatus compliancePoaStatus complianceConstitutionStatus
      complianceContractSigned
    }
  }
`

export default GQL_MERCHANT_USER_SEGMENTATION
