import React from "react";
import classNames from "classnames";

import { Grid } from "semantic-ui-react";
import InlineEdit from "@atlaskit/inline-edit";

import Input from "../../../../uikit/Forms/Input";

import style from "./EditBilling.module.css";
import { billingOneValue } from "../../../BillingFields/oneValue";

function Amount({
  b,
  billing,
  state,
  handleAmountBilled,
  onConfirm,
  editPlaceholder,
  showOneValue,
  loading,
  width,
}) {
  const val = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(
    showOneValue || b.status === "PAID" ? b.amountBilled : billingOneValue(b)
  );

  return b.status === "PENDING" ? (
    <InlineEdit
      defaultValue={state.amountBilledFace}
      readView={() =>
        loading && b.amountBilled !== billing.amountBilled ? (
          "Carregando..."
        ) : (
          <div className={classNames(style.FieldView, style.AmountBilled)}>
            {billing.amountBilledFace || editPlaceholder}
          </div>
        )
      }
      editView={(fieldProps) => (
        <Input
          type="decimal"
          placeholder="Valor"
          onChange={handleAmountBilled}
          value={state.amountBilledFace}
          textAlign="center"
          fontSize="large"
        />
      )}
      onCancel={(_e) => {}}
      onConfirm={onConfirm}
    />
  ) : (
    <Grid.Column
      width={width ? width : showOneValue ? 6 : 12}
      textAlign={showOneValue ? "right" : "center"}
      className="pr-1"
    >
      <div className={classNames(style.AmountBilled, "cursor-default")}>
        {val}
      </div>
    </Grid.Column>
  );
}

export default Amount;
