import React from "react";

export type Billing = {
  [key: string]: any;
  id: number;
  name: string;
  status: string;
  dueDate: string;
  createdAt: string;
};

export type OrdersKeys =
  | "dueDate"
  | "amountBilled"
  | "status"
  | "paymentDate"
  | "amountPaid";

export type FiltersKeys =
  | "all"
  | "scheduled"
  | "open"
  | "late"
  | "paid"
  | "cancelled";

export type State = {
  limit: number;
  page: number;
  filter: FiltersKeys;
  order: OrdersKeys;
  currentMonth: string;
};

export type BillingsContextType = State & {
  setLimit: (limit: number) => void;
  setFilter: (filter: FiltersKeys) => void;
  setOrder: (order: OrdersKeys) => void;
  setCurrentMonth: (currentMonth: string) => void;
  variables: { [key: string]: any };
};

export const BillingsContext = React.createContext<BillingsContextType>({
  limit: 50,
  setLimit: () => {},
  page: 1,
  order: "dueDate",
  filter: "all",
  currentMonth: "",
  setCurrentMonth: () => {},
  setFilter: () => {},
  setOrder: () => {},
  variables: {},
});
