import React from 'react'
import moment from 'moment'
import { Redirect } from 'react-router-dom'

import { Grid } from 'semantic-ui-react'

import { routes } from '../../routes'

import BillingStatus from '../../../BillingFields/BillingStatus'
import BillingOneAmount from '../../../BillingFields/BillingOneAmount'

import style from './InlineReceivable.module.css'

class InlineReceivable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectTo: null
    }

    this.goto = this.goto.bind(this)
  }

  goto(urn) {
    this.setState({ redirectTo: urn })
  }

  render() {
    if (this.state.redirectTo)
      return <Redirect to={this.state.redirectTo} />

    const b = this.props.billing

    const date = moment(b.dueDate).year() === moment().year() ?
      moment(b.dueDate).format('DD[/]MM') :
      moment(b.dueDate).format('DD[/]MM[/]YYYY')

    const link = routes.RECEIVABLE.getLink(b.customerId, b.id)

    return (
      <Grid.Row columns={3} className={style.Link} onClick={() => this.goto(link)}>
        <Grid.Column textAlign="center" width={4}>
          { date }
        </Grid.Column>
        <Grid.Column textAlign="center" width={4}>
          <BillingStatus b={b} iconSize={24} />
        </Grid.Column>
        <Grid.Column textAlign="right" width={4}>
          <BillingOneAmount billing={b} />
        </Grid.Column>
      </Grid.Row>
    )
  }
}

export default InlineReceivable