import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import {
  Box,
  Card,
  CardContent,
  Grid,
  useMediaQuery,
  useTheme,
} from "@plipag/ceci-ui";
import Dashboard from "./components/Dashboard";
import MerchantCard from "./components/MerchantCard";
import { ActivateAccountCTA } from "src/components/ActivateAccountCTA";
import Features from "./components/Features";

const SideBar = (props) => {
  return (
    <Box
      sx={{ background: "white", maxWidth: 350, minWidth: 350 }}
      {...props}
    />
  );
};

export default function Overview() {
  document.title = "Visão Geral | PLI";
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { data, loading, error } = useQuery(gql`
    query Merchant {
      merchant {
        status
      }
    }
  `);

  if (loading || error) return null;

  return (
    <>
      {!isMobile && (
        <Grid
          container
          flexWrap="nowrap"
          sx={{ background: "#F5F7FA", minHeight: "100%" }}
        >
          <Grid item component={SideBar}>
            <MerchantCard />
          </Grid>
          <Grid item flexGrow={1} sx={{ p: 4, pt: 3 }}>
            <ActivateAccountCTA />
            {data.merchant.status === "ENABLED" ? <Dashboard /> : <Features />}
          </Grid>
        </Grid>
      )}

      {isMobile && (
        <Grid container spacing={3} direction="column" sx={{ p: 3 }}>
          <Grid item sm={1}>
            <ActivateAccountCTA />
          </Grid>
          <Grid item sm={1}>
            <Card sx={{ width: "100%" }}>
              <CardContent>
                <MerchantCard />
              </CardContent>
            </Card>
          </Grid>
          <Grid item sm={1}>
            {data.merchant.status === "ENABLED" ? <Dashboard /> : <Features />}
          </Grid>
        </Grid>
      )}
    </>
  );
}
