import React from 'react'

import { Table } from 'semantic-ui-react'

const DaySelector = (props) => {
  return (
    <div>
      <div className="grey text-center cursor-default">
        { props.title }
      </div>
      <Table collapsing unstackable className="dayselector">
        <Table.Body>
          <Table.Row>
            <Table.Cell className={props.value === 1 ? 'active' : ''} onClick={() => props.onClick(1)}>
              1
            </Table.Cell>
            <Table.Cell className={props.value === 2 ? 'active' : ''} onClick={() => props.onClick(2)}>
              2
            </Table.Cell>
            <Table.Cell className={props.value === 3 ? 'active' : ''} onClick={() => props.onClick(3)}>
              3
            </Table.Cell>
            <Table.Cell className={props.value === 4 ? 'active' : ''} onClick={() => props.onClick(4)}>
              4
            </Table.Cell>
            <Table.Cell className={props.value === 5 ? 'active' : ''} onClick={() => props.onClick(5)}>
              5
            </Table.Cell>
            <Table.Cell className={props.value === 6 ? 'active' : ''} onClick={() => props.onClick(6)}>
              6
            </Table.Cell>
            <Table.Cell className={props.value === 7 ? 'active' : ''} onClick={() => props.onClick(7)}>
              7
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={props.value === 8 ? 'active' : ''} onClick={() => props.onClick(8)}>
              8
            </Table.Cell>
            <Table.Cell className={props.value === 9 ? 'active' : ''} onClick={() => props.onClick(9)}>
              9
            </Table.Cell>
            <Table.Cell className={props.value === 10 ? 'active' : ''} onClick={() => props.onClick(10)}>
              10
            </Table.Cell>
            <Table.Cell className={props.value === 11 ? 'active' : ''} onClick={() => props.onClick(11)}>
              11
            </Table.Cell>
            <Table.Cell className={props.value === 12 ? 'active' : ''} onClick={() => props.onClick(12)}>
              12
            </Table.Cell>
            <Table.Cell className={props.value === 13 ? 'active' : ''} onClick={() => props.onClick(13)}>
              13
            </Table.Cell>
            <Table.Cell className={props.value === 14 ? 'active' : ''} onClick={() => props.onClick(14)}>
              14
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={props.value === 15 ? 'active' : ''} onClick={() => props.onClick(15)}>
              15
            </Table.Cell>
            <Table.Cell className={props.value === 16 ? 'active' : ''} onClick={() => props.onClick(16)}>
              16
            </Table.Cell>
            <Table.Cell className={props.value === 17 ? 'active' : ''} onClick={() => props.onClick(17)}>
              17
            </Table.Cell>
            <Table.Cell className={props.value === 18 ? 'active' : ''} onClick={() => props.onClick(18)}>
              18
            </Table.Cell>
            <Table.Cell className={props.value === 19 ? 'active' : ''} onClick={() => props.onClick(19)}>
              19
            </Table.Cell>
            <Table.Cell className={props.value === 20 ? 'active' : ''} onClick={() => props.onClick(20)}>
              20
            </Table.Cell>
            <Table.Cell className={props.value === 21 ? 'active' : ''} onClick={() => props.onClick(21)}>
              21
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={props.value === 22 ? 'active' : ''} onClick={() => props.onClick(22)}>
              22
            </Table.Cell>
            <Table.Cell className={props.value === 23 ? 'active' : ''} onClick={() => props.onClick(23)}>
              23
            </Table.Cell>
            <Table.Cell className={props.value === 24 ? 'active' : ''} onClick={() => props.onClick(24)}>
              24
            </Table.Cell>
            <Table.Cell className={props.value === 25 ? 'active' : ''} onClick={() => props.onClick(25)}>
              25
            </Table.Cell>
            <Table.Cell className={props.value === 26 ? 'active' : ''} onClick={() => props.onClick(26)}>
              26
            </Table.Cell>
            <Table.Cell className={props.value === 27 ? 'active' : ''} onClick={() => props.onClick(27)}>
              27
            </Table.Cell>
            <Table.Cell className={props.value === 28 ? 'active' : ''} onClick={() => props.onClick(28)}>
              28
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell colSpan={7} className={props.value === -1 ? 'active squared' : 'squared'} onClick={() => props.onClick(-1)}>
              <div className="a-c">
                Último dia do mês
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}

export default DaySelector