import { gql } from "apollo-boost";

const RecurrenceProps = gql`
  fragment RecurrenceProps on Recurrence {
    id
    customerId
    status
    archived
    amount
    discountAmount
    discountDays
    interestPolicy
    description
    mday
    freq
    totalCycles
    consumedCycles
    firstDueDate
  }
`;

export default RecurrenceProps;
