import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import _ from "lodash";
import classNames from "classnames";

import InlineEdit from "@atlaskit/inline-edit";
import { Popup, Message, Divider } from "semantic-ui-react";
import {
  MdEdit,
  MdPerson,
  MdSupervisorAccount,
  MdEmail,
  MdPhone,
} from "react-icons/md";

import { DOC_TYPE, docType } from "../../../../utils/document";
import { asPhone, PHONE_FORMATS } from "../../../../utils/phone";
import tabs from "../CustomerTabs/tabs";

import Input from "../../../../uikit/Forms/Input";
import OptionsMenu from "./Options";
import CustomerTabs from "../CustomerTabs/CustomerTabs";

import style from "./Card.module.css";

import GQL_CUSTOMER_WITH_FIELDS from "../../../../graphql/queries/CustomerWithFields.gql";
import GQL_UPDATE_CUSTOMER from "../../../../graphql/mutations/UpdateCustomer.gql";
import GQL_UPSERT_CUSTOMER_EXTRA_FIELDS from "../../../../graphql/mutations/UpsertCustomerExtraFields.gql";
import GqlLoadingIcon from "../../../../uikit/GqlLoadingIcon";
import GqlError from "../../../../uikit/GqlError";

export default class CustomerContact extends React.Component {
  constructor(props) {
    super(props);

    this.state = Object.assign(
      {
        ...this.props.customer,
      },
      {
        mobileDisplay: asPhone(
          this.props.customer.mobile,
          PHONE_FORMATS.E164,
          true
        ).formatted,
        mobileValid: true,
      }
    );

    this.handleNickname = this.handleNickname.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleMobile = this.handleMobile.bind(this);
    this.handleExtraField = this.handleExtraField.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.getFieldVal = this.getFieldVal.bind(this);
    this.getVars = this.getVars.bind(this);
    this.getFieldsVars = this.getFieldsVars.bind(this);
    this.addField = this.addField.bind(this);
    this.deleteField = this.deleteField.bind(this);
    this.goto = this.goto.bind(this);
  }

  handleNickname(e) {
    this.setState({
      nickname: e.target.value,
    });
  }

  handleName(e) {
    this.setState({
      name: e.target.value,
    });
  }

  handleEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleMobile(e) {
    const mobile = asPhone(e.target.value, PHONE_FORMATS.LOCAL, true);

    this.setState({
      mobile: mobile.e164,
      mobileDisplay: mobile.formatted,
      mobileValid: mobile.valid,
    });
  }

  handleExtraField(e, id, val) {
    this.setState({
      extraFields: _.map(this.state.extraFields, (f) => {
        if (f.id !== id) return f;

        return Object.assign(f, {
          [val]: e.target.value,
        });
      }),
    });
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  getFieldVal(id, val) {
    const el = _.find(this.state.extraFields, (x) => x.id === id);
    return el["val"];
  }

  getVars() {
    return {
      id: this.state.id,
      nickname: this.state.nickname,
      name: this.state.name,
      email: this.state.email,
      mobile: this.state.mobile,
    };
  }

  getFieldsVars() {
    return {
      id: this.state.id,
      extraFields: _.map(this.state.extraFields, (f) => ({
        name: f.name,
        value: f.value,
      })),
    };
  }

  addField() {
    this.setState({
      extraFields: _.concat(this.state.extraFields, {
        id: `Customer:${this.props.customer.id}:NewField_${_.uniqueId()}`,
        name: "Novo campo",
        value: "",
      }),
    });
  }

  deleteField(id, callback = () => {}) {
    this.setState(
      {
        extraFields: _.filter(this.state.extraFields, (f) => f.id !== id),
      },
      callback
    );
  }

  renderError(errors) {
    if (!errors) return null;

    return <Message negative>Erro, cheque se os dados são válidos.</Message>;
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const editPlaceholder = <MdEdit className={style.EditPlaceholder} />;
    const customer = this.state;
    const closeModal = this.props.closeModal;

    return (
      <Mutation
        mutation={GQL_UPDATE_CUSTOMER}
        variables={this.getVars()}
        onError={this.handleMutationError}
      >
        {(updateCustomer, { loading, error, data }) => {
          if (loading) return <GqlLoadingIcon />;

          return (
            <div>
              <CustomerTabs
                activeTab={tabs.CARD}
                customer={this.props.customer}
                goto={this.goto}
              />
              <div className={classNames(style.ModalBody, "mt-4")}>
                {this.renderError(error)}

                <div>
                  <div className={style.FieldLabel}>Apelido</div>
                  <InlineEdit
                    defaultValue={customer.nickname}
                    readView={() =>
                      loading &&
                      this.props.customer.nickname !== customer.nickname ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {customer.nickname || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(fieldProps) => (
                      <Input
                        {...fieldProps}
                        value={customer.nickname}
                        onChange={this.handleNickname}
                        icon={<MdSupervisorAccount />}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _e) => updateCustomer()}
                  />
                </div>

                <div className={style.FieldSep}>
                  <div className={style.FieldLabel}>Nome</div>
                  <InlineEdit
                    defaultValue={customer.name}
                    readView={() =>
                      loading && this.props.customer.name !== customer.name ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {customer.name || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(fieldProps) => (
                      <Input
                        {...fieldProps}
                        value={customer.name}
                        onChange={this.handleName}
                        icon={<MdPerson />}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _e) => updateCustomer()}
                  />
                </div>

                <div className={style.FieldSep}>
                  <div className={style.FieldLabel}>
                    {docType(customer.document) === DOC_TYPE.CNPJ
                      ? "CNPJ"
                      : "CPF"}
                  </div>
                  <div className={style.FieldView}>
                    <Popup
                      trigger={<span>{customer.document}</span>}
                      inverted
                      header="Documento não pode ser trocado"
                      content="As informações informações estão atreladas ao documento, portanto, não pode ser alterado."
                    />
                  </div>
                </div>

                <div className={style.FieldSep}>
                  <div className={style.FieldLabel}>Email</div>
                  <InlineEdit
                    defaultValue={customer.email}
                    readView={() =>
                      loading &&
                      this.props.customer.email !== customer.email ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {customer.email || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(fieldProps) => (
                      <Input
                        {...fieldProps}
                        value={customer.email}
                        onChange={this.handleEmail}
                        icon={<MdEmail />}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _e) => updateCustomer()}
                  />
                </div>

                <div className={style.FieldSep}>
                  <div className={style.FieldLabel}>Celular</div>
                  <InlineEdit
                    defaultValue={customer.mobile}
                    readView={() =>
                      loading &&
                      this.props.customer.mobile !== customer.mobile ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {customer.mobileDisplay || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(fieldProps) => (
                      <Input
                        {...fieldProps}
                        value={customer.mobileDisplay}
                        onChange={this.handleMobile}
                        icon={<MdPhone />}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _) =>
                      this.state.mobileValid ? updateCustomer() : {}
                    }
                  />
                </div>

                <Mutation
                  mutation={GQL_UPSERT_CUSTOMER_EXTRA_FIELDS}
                  variables={this.getFieldsVars()}
                  onError={this.handleMutationError}
                  refetchQueries={[
                    {
                      query: GQL_CUSTOMER_WITH_FIELDS,
                      variables: this.getFieldsVars(),
                    },
                  ]}
                >
                  {(upsertExtraFields, { loading, error, data }) => {
                    if (loading) return <GqlLoadingIcon />;
                    if (error) return <GqlError error={error} />;

                    return (
                      <div>
                        {_.map(customer.extraFields, (f) => (
                          <div className={style.FieldSep} key={f.id}>
                            <div className={style.FieldLabel}>
                              <InlineEdit
                                defaultValue=""
                                readView={() =>
                                  loading &&
                                  this.getFieldVal(f.id, "name") !== f.name ? (
                                    "Carregando..."
                                  ) : (
                                    <Popup
                                      trigger={
                                        <div className={style.FieldLabel}>
                                          {f.name || editPlaceholder}
                                        </div>
                                      }
                                      inverted
                                      content="Clique para editar o nome do campo"
                                    />
                                  )
                                }
                                editView={(fieldProps) => (
                                  <div>
                                    <div className="text-right">
                                      <span
                                        onClick={() =>
                                          this.deleteField(
                                            f.id,
                                            upsertExtraFields
                                          )
                                        }
                                        className={style.DeleteField}
                                      >
                                        Excluir campo
                                      </span>
                                    </div>
                                    <Input
                                      {...fieldProps}
                                      value={f.name}
                                      onChange={(e) =>
                                        this.handleExtraField(e, f.id, "name")
                                      }
                                    />
                                  </div>
                                )}
                                onCancel={(_e) => {}}
                                onConfirm={(value, _e) => upsertExtraFields()}
                              />
                            </div>
                            <div className={style.FieldView}>
                              <InlineEdit
                                defaultValue=""
                                readView={() =>
                                  loading &&
                                  this.getFieldVal(f.id, "value") !==
                                    f.value ? (
                                    "Carregando..."
                                  ) : (
                                    <div className={style.FieldView}>
                                      {f.value || editPlaceholder}
                                    </div>
                                  )
                                }
                                editView={(fieldProps) => (
                                  <Input
                                    {...fieldProps}
                                    value={f.value}
                                    onChange={(e) =>
                                      this.handleExtraField(e, f.id, "value")
                                    }
                                  />
                                )}
                                onCancel={(_e) => {}}
                                onConfirm={(value, _e) => upsertExtraFields()}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    );
                  }}
                </Mutation>

                <Divider className="mt-5" />
                <OptionsMenu
                  customer={this.props.customer}
                  closeModal={closeModal}
                  newField={this.addField}
                />
              </div>
            </div>
          );
        }}
      </Mutation>
    );
  }
}
