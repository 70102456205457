import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'

const GQL_UPDATE_CGROUP = gql`
  mutation UpdateCgroup($id: ID!, $name: String, $color: String, $icon: String) {
    updateCgroup(id: $id, name: $name, color: $color, icon: $icon) {
      ...CgroupProps
    }
  }
  ${CgroupProps}
`

export default GQL_UPDATE_CGROUP