import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Routes } from "./Routes";
import Authenticate from "./Authenticate";
import { Store } from "../store";
import { ThemeProvider } from "@plipag/ceci-ui";
import { Layout } from "../components/Layout";
import "./styles";
import { SetTokenComponent } from "./SetToken";
import { ToastContainer } from "react-toastify";
import Auth from "src/modules/auth";
import { AppContext, ContextRef } from "./context";

const App = () => {
  const [networkError, setNetworkError] = React.useState(false);

  const state = {
    networkError,
  };

  const context = {
    ...state,
    setNetworkError,
  };

  React.useImperativeHandle(ContextRef, () => context);

  return (
    <AppContext.Provider value={context} ref={ContextRef}>
      <Store>
        <ThemeProvider>
          <BrowserRouter>
            <Switch>
              <Route
                path={["/set_token/:token", "/set_token/:token/:user-hash"]}
                component={SetTokenComponent}
              />
              <Route path="/entrar" component={Auth} />
              <Route path="/">
                <Authenticate>
                  <Layout>
                    <Switch>
                      <Routes />
                    </Switch>
                  </Layout>
                </Authenticate>
              </Route>
            </Switch>
          </BrowserRouter>
          <ToastContainer />
        </ThemeProvider>
      </Store>
    </AppContext.Provider>
  );
};

export default App;
