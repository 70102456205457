import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import moment from "moment";
import _ from "lodash";

import { routes } from "../../../routes";
import toDecimal from "src/utils/decimal";
import settings from "src/app/settings";
import {
  defaultRecurrenceStartDate,
  defaultRecurrenceStartDateShifted,
  simulateRecurrenceBillings,
} from "../../../../BillingFields/recurring";
import { longDate, shortDate } from "src/utils/date";

import {
  Message,
  Grid,
  Button,
  Popup,
  Checkbox,
  Header,
} from "semantic-ui-react";
import { MdInfo, MdMoreVert } from "react-icons/md";
import { FaChevronCircleRight, FaEdit, FaCheckCircle } from "react-icons/fa";

import Input from "src/uikit/Forms/Input";
import DaySelector from "src/uikit/Forms/DaySelector";
import PriceTag from "src/uikit/PriceTag";
import FirstDuePicker from "./FirstDuePicker";

import GQL_CREATE_RECURRENCE from "src/graphql/mutations/CreateRecurrence.gql";
import GQL_BILLINGS from "src/graphql/queries/Billings.gql";
import GQL_RECURRENCE_BILLINGS from "src/graphql/queries/RecurrenceBillings.gql";
import GQL_RECURRENCES_BILLINGS from "src/graphql/queries/RecurrencesBillings.gql";
import analytics from "src/plg/Segment";

class CreateRecurrence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: "FORM",

      customerId: this.props.customer.id,
      amount: 0,
      amountFace: "",
      dayselectorDay: 5,
      description: "",
      interest: false,
      interestPolicy: "NO_INTEREST",
      discountAmount: 0,
      discountAmountFace: "",
      firstDueDate: defaultRecurrenceStartDate(5),
      firstDueDateSuggested: defaultRecurrenceStartDate(5),
    };

    this.goto = this.goto.bind(this);
    this.pagePreview = this.pagePreview.bind(this);
    this.pageForm = this.pageForm.bind(this);
    this.handleAmount = this.handleAmount.bind(this);
    this.dayselectorClick = this.dayselectorClick.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleInterest = this.handleInterest.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.getVars = this.getVars.bind(this);
    this.submittable = this.submittable.bind(this);
    this.handleFirstDue = this.handleFirstDue.bind(this);
  }

  handleAmount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amount: floatValue,
      amountFace: decimalValue,
    });
  }

  dayselectorClick(num) {
    this.setState({
      dayselectorDay: num,
      firstDueDateSuggested: defaultRecurrenceStartDate(num),
      firstDueDate: defaultRecurrenceStartDate(num),
    });
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleInterest(_) {
    this.setState(
      {
        interest: !this.state.interest,
      },
      () => {
        this.setState({
          interestPolicy: this.state.interest ? "MARKET" : "NO_INTEREST",
        });
      }
    );
  }

  handleDiscount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      discountAmount: floatValue,
      discountAmountFace: decimalValue,
    });
  }

  getVars() {
    return {
      customerId: this.state.customerId,
      totalCycles: this.props.cycles,
      amount: this.state.amount,
      mday: this.state.dayselectorDay,
      description: this.state.description,
      interestPolicy: this.state.interestPolicy,
      discountAmount: this.state.discountAmount,
      discountDays: 0,
      firstDueDate: this.state.firstDueDate,
    };
  }

  handleFirstDue(day) {
    this.setState({
      firstDueDate: moment(day).format("YYYY-MM-DD"),
    });
  }

  handleMutationUpdate(cache, { data: { createRecurrence } }) {
    const customerId = this.props.customer.id;

    cache.writeQuery({
      query: GQL_RECURRENCE_BILLINGS,
      variables: { id: createRecurrence.id },
      data: createRecurrence,
    });

    cache.writeQuery({
      query: GQL_BILLINGS,
      variables: { customerIds: [customerId] },
      data: {
        billings: _.concat(
          cache.readQuery({
            query: GQL_BILLINGS,
            variables: { customerIds: [customerId] },
          }).billings,
          createRecurrence.billings
        ),
      },
    });
  }

  handleMutationComplete(resp) {
    const {
      createRecurrence: { id },
    } = resp;
    analytics.track("Recurrence Created", {
      from: "Recurrence Modal",
      amount: this.state.amount,
    });
    this.goto(routes.CREATED_RECURRENCE.getLink(this.props.customer.id, id));
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  renderError(errors) {
    if (!errors) return null;

    return <Message negative>Erro, cheque se os dados são válidos.</Message>;
  }

  submittable() {
    if (
      this.state.discountAmount > 0 &&
      this.state.amount > 0 &&
      this.state.discountAmount >= this.state.amount
    )
      return false;

    if (this.props.customer.status !== "ACTIVE") return false;

    if (!this.state.dayselectorDay) return false;

    return (
      (this.state.dayselectorDay === -1 || this.state.dayselectorDay >= 1) &&
      this.state.amount > 0
    );
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  pageForm() {
    this.setState({
      page: "FORM",
    });
  }

  pagePreview() {
    this.setState({
      page: "PREVIEW",
    });
  }

  renderForm() {
    return (
      <Grid centered>
        {this.props.customer.status !== "ACTIVE" && (
          <Grid.Row columns={1} centered>
            <Grid.Column width={14}>
              <Message warning>
                <Message.Header>
                  Cliente{" "}
                  <span className="font-weight-extra-bold">arquivado</span>
                </Message.Header>
                <Message.Content>
                  Um cliente arquivado não pode emitir novas cobranças.
                </Message.Content>
              </Message>
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={1} centered>
          <Grid.Column width={12}>
            <Input
              value={this.state.amountFace}
              onChange={this.handleAmount}
              placeholder="Valor"
              textAlign="center"
              fontSize="large"
              autofocus
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column textAlign="center">
            <DaySelector
              value={this.state.dayselectorDay}
              onClick={this.dayselectorClick}
              title="Dia do vencimento"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1} centered>
          <Grid.Column width={12}>
            <Input
              value={this.state.description}
              onChange={this.handleDescription}
              placeholder="Descrição"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2} className="pb-0">
          <Grid.Column width={8}>
            Juros&nbsp;
            <Popup
              trigger={<MdInfo size={16} />}
              header="Juros de mercado"
              content="2% de multa no atraso acrescido de 1% de mora ao mês"
              inverted
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <Checkbox
              toggle
              fitted
              onChange={this.handleInterest}
              checked={this.state.interest}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered columns={2} className="pt-0">
          <Grid.Column width={6}>
            <Popup
              trigger={<span>Desconto</span>}
              content="Desconto até o vencimento"
              inverted
            />
          </Grid.Column>
          <Grid.Column width={6} textAlign="right">
            <Input
              onChange={this.handleDiscount}
              value={this.state.discountAmountFace}
              textAlign="right"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row centered>
          <Grid.Column width={16}>
            <Button
              size="large"
              fluid
              onClick={this.pagePreview}
              disabled={!this.submittable()}
            >
              <span className="a-m mr-2">Continuar</span>
              <FaChevronCircleRight />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderPreview() {
    return (
      <Mutation
        mutation={GQL_CREATE_RECURRENCE}
        variables={this.getVars()}
        onError={this.handleMutationError}
        onCompleted={this.handleMutationComplete}
        refetchQueries={[
          {
            query: GQL_RECURRENCES_BILLINGS,
            variables: { customerIds: [this.props.customer.id] },
          },
          {
            query: GQL_BILLINGS,
            variables: { customerIds: [this.props.customer.id] },
          },
        ]}
      >
        {(createRecurrence, { loading, error, data }) => {
          return (
            <div>
              <Grid centered>
                {error && (
                  <Grid.Row columns={1} centered>
                    <Grid.Column width={16}>
                      {this.renderError(error)}
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <Header content="Primeira cobrança" />
                    {defaultRecurrenceStartDateShifted(
                      this.state.dayselectorDay
                    ) && (
                      <p>
                        A primeira cobrança deve respeitar um prazo mínimo de{" "}
                        {settings.BILLING_MIN_DAYS_AHEAD} dias.
                      </p>
                    )}
                    <p>Escolha quando a primeira cobrança deve começar.</p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <FirstDuePicker
                      firstDueDateSuggested={this.state.firstDueDateSuggested}
                      firstDueDate={this.state.firstDueDate}
                      mday={this.state.dayselectorDay}
                      onChange={this.handleFirstDue}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    Primeira cobrança programada para{" "}
                    {longDate(this.state.firstDueDate)}.
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              <Header content="Programação" className="pb-2" />
              {this.renderList()}
              <Grid centered>
                <Grid.Row columns={1}>
                  <Grid.Column width={16}>
                    <Button
                      size="large"
                      fluid
                      onClick={this.pageForm}
                      disabled={loading}
                    >
                      <span className="a-m mr-2">Alterar</span>
                      <FaEdit />
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row centered>
                  <Grid.Column width={16}>
                    <Button
                      primary
                      size="large"
                      fluid
                      onClick={createRecurrence}
                      disabled={loading || !this.submittable()}
                    >
                      <FaCheckCircle />
                      <span className="a-m ml-2">Criar mensalidade</span>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          );
        }}
      </Mutation>
    );
  }

  renderListItem(b) {
    if (b.dueDate === null || b.amountBilled <= 0)
      return (
        <Grid.Row columns={1} key={_.uniqueId()}>
          <Grid.Column textAlign="center" width={12}>
            <MdMoreVert size={24} />
          </Grid.Column>
        </Grid.Row>
      );

    return (
      <Grid.Row columns={3} key={_.uniqueId()}>
        <Grid.Column textAlign="right" width={4}>
          <PriceTag value={b.amountBilled} />
        </Grid.Column>
        <Grid.Column textAlign="center" width={6}>
          agendada para
        </Grid.Column>
        <Grid.Column textAlign="center" width={4}>
          {shortDate(b.dueDate)}
        </Grid.Column>
      </Grid.Row>
    );
  }

  renderList() {
    return (
      <Grid centered>
        {_.map(
          simulateRecurrenceBillings(
            this.state.dayselectorDay,
            this.state.amount,
            this.props.cycles,
            this.state.firstDueDate
          ),
          (b) => this.renderListItem(b)
        )}
        {this.props.cycles <= 0 && (
          <Grid.Row>
            <Grid.Column>
              Serão geradas cobranças para os próximos 12 meses. As cobranças
              com vencimento posterior serão geradas mês a mês.
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div>
        {this.state.page === "FORM" && this.renderForm()}
        {this.state.page === "PREVIEW" && this.renderPreview()}
      </div>
    );
  }
}

export default CreateRecurrence;
