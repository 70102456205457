import { gql } from 'apollo-boost'

import MerchantProps from '../fragments/merchant'

const GQL_MERCHANT_UPDATE_REQUEST = gql`
  mutation MerchantUpdateRequest(
    $name: String,
    $legalName: String,
    $document: String,
    $email: Email,
    $phone: String,
    $street: String,
    $district: String,
    $city: String,
    $province: String,
    $zipcode: String
  ) {
    requestMerchantUpdate(
      name: $name,
      legalName: $legalName,
      document: $document,
      email: $email,
      phone: $phone,
      street: $street,
      district: $district,
      city: $city,
      province: $province,
      zipcode: $zipcode
    ) {
      ...MerchantProps
    }
  }
  ${MerchantProps}
`

export default GQL_MERCHANT_UPDATE_REQUEST