import React from "react";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";

import { Button, Divider, Icon, Item } from "semantic-ui-react";
import { MdCloudDownload } from "react-icons/md";
import Modal from "../../uikit/Modal";

import style from "./ExportModal.module.css";

import Picker from "react-month-picker";
import "react-month-picker/css/month-picker.css";
import { exportCustomers, exportBillings } from "./actions";

import dateIcon from "./date-icon.png";
import LoadingOverlay from "react-loading-overlay";
import { Portal } from "react-portal";

const downloads = {
  CUSTOMERS: {
    name: "CUSTOMERS",
    available: true,
    title: "Clientes",
    icon: "https://assets.plipag.com.br/conta/images/icons/circular/long_shadow/308826-ecommerce/png/user.png",
    meta: "Planilha de clientes ativos e arquivados",
    extra: "",
    action: exportCustomers,
  },
  BILLINGS: {
    name: "BILLINGS",
    available: true,
    title: "Cobranças",
    icon: "https://assets.plipag.com.br/conta/images/icons/circular/long_shadow/308826-ecommerce/png/invoice.png",
    meta: "Planilha completa de cobranças",
    extra: "",
    action: exportBillings,
  },
};

function ConfigsModal(props) {
  const ref = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const to = (() => {
    const date = moment();
    return { year: ~~date.format("YYYY"), month: ~~date.format("M") };
  })();
  const from = (() => {
    const date = moment().subtract(1, "months");
    return { year: ~~date.format("YYYY"), month: ~~date.format("M") };
  })();
  const [value, setValue] = React.useState({ to, from });

  function csClick(name) {
    let promise = null;
    setLoading(true);
    if (
      value.from.month &&
      value.from.year &&
      value.to.month &&
      value.to.year
    ) {
      const start = `${value.from.year}-${
        value.from.month < 10 ? "0" + value.from.month : value.from.month
      }-01`;
      const end = moment(
        `${value.to.year}-${
          value.to.month < 10 ? "0" + value.to.month : value.to.month
        }-01`
      )
        .endOf("month")
        .format("YYYY-MM-DD");

      promise = downloads[name].action(start, end);
    } else {
      promise = downloads[name].action();
    }

    promise.finally(() => setLoading(false));
  }

  const lang = {
    months: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    from: "De",
    to: "Até",
  };

  const makeText = (m) => {
    if (m && m.year && m.month) return lang.months[m.month - 1] + ". " + m.year;
    return "?";
  };

  function handleDismiss(value) {
    setValue(value);
  }

  function clear(e) {
    const value = { to: {}, from: {} };
    setValue(value);
    e?.stopPropagation();
  }

  function open() {
    ref.current.show();
  }

  return (
    <>
      {loading && (
        <Portal>
          <div
            style={{
              position: "absolute",
              zIndex: "99999",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          >
            <LoadingOverlay
              active={true}
              spinner
              text="Exportando seus dados."
            />
          </div>
        </Portal>
      )}
      <Modal trigger={props.trigger} size="medium" borderStyle="ssss">
        {(closeModal) => {
          return (
            <div>
              <div className={style.ModalHeader}>
                <ul className="horizontal relaxed middle">
                  <li>
                    <MdCloudDownload size={64} />
                  </li>
                  <li>
                    <div className={style.ModalSubtitle}>Minha conta</div>
                    <div className={style.ModalTitle}>Exportar dados</div>
                  </li>
                </ul>
              </div>
              <div className={style.ModalBody}>
                <div
                  className={classNames(style.Item)}
                  key={_.uniqueId()}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Item.Group>
                    <Item style={{ paddingBottom: 0 }} onClick={open}>
                      <Item.Image src={dateIcon} size="mini" />
                      <Item.Content verticalAlign="middle">
                        <Item.Header
                          content="Período"
                          style={{ marginRight: 20 }}
                        />
                        <Picker
                          years={{
                            min: { year: 2017, month: 1 },
                            max: { year: 2024, month: 12 },
                          }}
                          ref={ref}
                          value={value}
                          lang={lang}
                          theme="light"
                          onChange={() => {}}
                          onDismiss={handleDismiss}
                        >
                          <div className="box">
                            {value.to.month && value.from.month ? (
                              <div>
                                <span style={{ padding: "5px 10px 5px 0" }}>
                                  {makeText(value.from) +
                                    " ~ " +
                                    makeText(value.to)}
                                </span>
                                <Button icon onClick={clear}>
                                  <Icon name="close" />
                                </Button>
                              </div>
                            ) : (
                              <>
                                <span style={{ padding: "5px 10px 5px 0" }}>
                                  Todo periodo
                                </span>
                                <Button icon>
                                  <Icon name="edit" />
                                </Button>
                              </>
                            )}
                          </div>
                        </Picker>
                        <Divider />
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </div>
                {_.map(downloads, (cs) => (
                  <div
                    className={classNames(
                      style.Item,
                      cs.available ? style.Available : style.Disabled
                    )}
                    key={_.uniqueId()}
                    onClick={() => {}}
                  >
                    <Item.Group>
                      <Item onClick={() => csClick(cs.name)}>
                        <Item.Image src={cs.icon} size="mini" />
                        <Item.Content verticalAlign="middle">
                          <Item.Header content={cs.title} />
                          <Item.Meta content={cs.meta} />
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </div>
                ))}
              </div>
            </div>
          );
        }}
      </Modal>
    </>
  );
}

export default ConfigsModal;
