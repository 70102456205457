import React from "react";
import { Skeleton, Grid, Box } from "@plipag/ceci-ui";

export function CustomerGroupsBarSkeleton() {
  return (
    <Grid container spacing={1} sx={{ m: 3 }}>
      {Array.from({ length: 4 }).map((_, index) => (
        <Grid item key={index}>
          <Box sx={{ width: 72 }}>
            <Skeleton
              variant="circular"
              width={56}
              height={56}
              sx={{ m: "0 auto" }}
            />
            <Skeleton
              variant="text"
              width={43}
              height={21}
              sx={{ m: "0 auto", mt: 1 }}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}
