import React from "react";
import moment from "moment";

import settings from "../../app/settings";

import { Popup } from "semantic-ui-react";

import { MdPause, MdDoneAll } from "react-icons/md";
import {
  FaHourglassHalf,
  FaCheckCircle,
  FaHandHoldingUsd,
  FaRecycle,
  FaPauseCircle,
  FaExclamationCircle,
  FaHistory,
  FaEraser,
  FaBan,
  FaSkullCrossbones,
  FaQuestionCircle,
} from "react-icons/fa";

import style from "./BillingStatus.module.css";

export default function a({ b, iconSize = 48 }) {
  switch (b.status) {
    case "PENDING":
      return (
        <Popup
          trigger={<MdPause size={iconSize} className={style.StatusPending} />}
          header={b.anticipate ? "Cobrança expressa" : "Agendada"}
          content={
            b.anticipate ? (
              "Cobrança entrará em processo de emissão em breve e o cliente será notificado."
            ) : moment().add(15, "days") < moment(b.dueDate) ? (
              "PLI enviará muito em breve, no momento mais oportuno para cobrar"
            ) : (
              <div className="mt-2">
                <p>
                  A cobrança será enviada entre uma e duas semanas da data de
                  vencimento.
                </p>
                <p>
                  PLI escolhe a melhor data para cobrar de modo a diminuir a
                  chance de inadimplência e melhorar seu fluxo de caixa.
                </p>
              </div>
            )
          }
          inverted
        />
      );
    case "CREATED_PAYMENT":
      return (
        <Popup
          trigger={
            <FaHourglassHalf size={iconSize} className={style.StatusCreated} />
          }
          content="Em processamento de emissão"
          inverted
        />
      );
    case "OPEN_PAYMENT":
      if (b.proxy && b.proxy === "0")
        return (
          <Popup
            trigger={
              <FaEraser size={iconSize} className={style.StatusCancelling} />
            }
            header="Em cancelamento"
            content={
              <div>
                <p>
                  O processo de cancelamento pode levar vários dias na rede
                  bancária.
                </p>
                <p>
                  O cliente não receberá futuras notificações sobre esta
                  cobrança.
                </p>
                {b.seen && (
                  <p>
                    <MdDoneAll size={18} className={style.Seen} />
                    <span className="ml-2">Fatura foi visualizada.</span>
                  </p>
                )}
                {!b.seen && (
                  <p>
                    <MdDoneAll size={18} className={style.NotSeen} />
                    <span className="ml-2">Fatura não foi visualizada.</span>
                  </p>
                )}
              </div>
            }
            inverted
          />
        );

      return b.seen ? (
        <Popup
          trigger={<MdDoneAll size={iconSize} className={style.Seen} />}
          header="Visualizado"
          content="Cliente já abriu a fatura"
          inverted
        />
      ) : (
        <Popup
          trigger={<MdDoneAll size={iconSize} className={style.NotSeen} />}
          header="Não visualizado"
          content="Fatura ainda não foi aberta pelo cliente"
          inverted
        />
      );
    case "NO_PAYMENT":
      return (
        <Popup
          trigger={
            <FaHistory size={iconSize} className={style.StatusNopayment} />
          }
          header="Cobrança encerrada sem pagamento"
          content="Esta cobrança não foi paga"
          inverted
        />
      );
    case "UNDERPAYMENT":
      return (
        <Popup
          trigger={
            <FaCheckCircle
              size={iconSize}
              className={style.StatusUnderpayment}
            />
          }
          content="Pagamento parcial"
          inverted
        />
      );
    case "PAID":
      return (
        <Popup
          trigger={
            <FaCheckCircle size={iconSize} className={style.StatusPaid} />
          }
          content="Recebimento Manual"
          inverted
        />
      );
    case "FULL_PAYMENT":
      return (
        <Popup
          trigger={
            <FaCheckCircle
              size={iconSize}
              className={style.StatusFullpayment}
            />
          }
          content="Pagamento recebido"
          inverted
        />
      );
    case "OVERPAYMENT":
      return (
        <Popup
          trigger={
            <FaCheckCircle
              size={iconSize}
              className={style.StatusOverpayment}
            />
          }
          inverted
          header="Pagamento excedente"
          content="Cliente pagou um valor superior ao cobrado"
        />
      );
    case "SUSPENDED":
      const revertibleToPending = moment(b.dueDate).isSameOrAfter(
        moment().add(settings.BILLING_MIN_DAYS_AHEAD, "days"),
        "day"
      );

      const actionMessage = revertibleToPending
        ? "Se a mensalidade for reativada, ela será reprogramada"
        : "Se a mensalidade for reativada, ela será expirada";

      return (
        <Popup
          trigger={<FaBan size={iconSize} className={style.StatusSuspended} />}
          inverted
          header="Suspensa"
          content={`
                 Esta cobrança faz parte de uma mensalidade que foi pausada.
                 ${actionMessage}.`}
        />
      );
    case "EXPIRED":
      return (
        <Popup
          trigger={
            <FaRecycle size={iconSize} className={style.StatusExpired} />
          }
          inverted
          header="Expirado"
          content="Cobrança não foi emitida antes do vencimento e foi
                 automaticamente marcada como expirada."
        />
      );
    case "DELETED":
      return (
        <Popup
          trigger={
            <FaSkullCrossbones
              size={iconSize}
              className={style.StatusDeleted}
            />
          }
          inverted
          content={
            b.recurrenceId
              ? "Esta cobrança foi excluída e fazia parte de uma mensalidade."
              : "Esta cobrança foi excluída."
          }
        />
      );
    // Reserved for future usage.
    case "REFUND":
      return (
        <Popup
          trigger={
            <FaHandHoldingUsd size={iconSize} className={style.StatusRefund} />
          }
          inverted
          header="Estorno"
          content="Valor estornado para o cliente"
        />
      );
    case "CHARGEBACK":
      return (
        <Popup
          trigger={
            <FaExclamationCircle
              size={iconSize}
              className={style.StatusChargeback}
            />
          }
          inverted
          header="Contestação"
          content="Cliente contestou o valor cobrado"
        />
      );
    case "ON_HOLD":
      return (
        <Popup
          trigger={
            <FaPauseCircle size={iconSize} className={style.StatusExpired} />
          }
          inverted
          header="Em espera"
          content="Cobrança colocada em estado de espera e está em tratamento"
        />
      );
    default:
      return (
        <Popup
          trigger={
            <FaQuestionCircle size={iconSize} className={style.StatusUnknown} />
          }
          inverted
          header="Em tratamento"
          content={`Cobrança com status ${b.status} em tratamento`}
        />
      );
  }
}
