import React from 'react'

import EditBilling from '../../Customer/partials/Receivable/EditBilling'

export default class Billing extends React.Component {
  render() {
    return (
      <EditBilling
        billing={this.props.billing}
        customerId={this.props.billing.customerId}
        mode="RECEIVABLES" />
    )
  }
}
