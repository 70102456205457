import React from "react";
import { Box, Stepper, Step, useTheme, useMediaQuery } from "@plipag/ceci-ui";

const Steps = ({ activeStep }) => {
  const steps = [
    "Criação de conta",
    "Informações",
    "Documentos",
    "Dados bancários",
  ];

  const theme = useTheme();
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box
      style={{
        width: "100%",
        maxWidth: 540,
        margin: "35px auto 20px",
      }}
    >
      <Stepper activeStep={activeStep}>
        {steps.map((item, index) => (
          <Step
            key={index}
            done={index < activeStep}
            active={index === activeStep}
          >
            {largeDisplay && item}
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default Steps;
