import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import _ from "lodash";

import analytics from "src/plg/Segment";

import Modal from "src/uikit/Modal";
import { SFX_ADD } from "src/utils/sfx";

import { routes } from "src/components/Customer/routes";
import { validateFields, checkDeliverable } from "./validations";
import { getParams, clearFields, trimFields } from "./fields";
import { renderCTA, renderForm } from "./partials";

import GQL_CREATE_CUSTOMER from "src/graphql/mutations/CreateCustomer.gql";
import { CustomersConnection } from "src/graphql/queries/CustomersPaginated.gql";

class CreateCustomerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      closeModal: null,
      redirectTo: null,

      enriching: false,
      error: false,
      sfx: false,

      document: "",
      name: "",
      nickname: "",
      email: "",
      mobile: "",
      mobileFace: "",
      street: "",
      district: "",
      city: "",
      province: "",
      zipcode: "",
      address: "",

      nameLocked: false,
      emailLocked: false,
      mobileLocked: false,
      addressLocked: true,

      emailInvalid: false,
      mobileInvalid: false,

      emailSuggestions: [],
      mobileSuggestions: [],
      addressSuggestions: [],

      emailEnrichment: null,
      mobileEnrichment: null,
    };

    this.setState = this.setState.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.playSFX = this.playSFX.bind(this);
    this.openCallback = this.openCallback.bind(this);
  }

  async submitForm() {
    await trimFields(this.state, this.setState);

    if (!validateFields(this.state, this.setState)) return false;

    if (!(await checkDeliverable(this.state, this.setState))) return false;

    return true;
  }

  handleMutationError({ graphQLErrors, ...e }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  handleMutationComplete({ createCustomer: { id } }) {
    // setTimeout(() => this.setState({ sfx: false }), 5 * 1000)
    if (this.closeModal) this.closeModal();

    this.setState({
      redirectTo: routes.CUSTOMER_ADDRESS.getLink(id),
    });

    analytics.track("Customer Created", { page: "Customer Modal" });
  }

  handleMutationUpdate(cache, { data: { createCustomer } }) {
    console.log(
      createCustomer,
      cache.readQuery({ query: CustomersConnection })
    );
    cache.writeQuery({
      query: CustomersConnection,
      data: {
        customers: _.concat(
          cache.readQuery({ query: CustomersConnection }).customersConnection
            .edges,
          { node: createCustomer }
        ),
      },
    });
  }

  playSFX() {
    this.setState({
      sfx: true,
    });

    return true;
  }

  openCallback() {
    clearFields(this.setState);
  }

  render() {
    return (
      <div>
        {this.state.redirectTo && <Redirect to={this.state.redirectTo} />}
        {this.state.sfx && <audio autoPlay={false} src={SFX_ADD} />}
        <Modal
          trigger={this.props.children || renderCTA()}
          size="small"
          openCallback={this.openCallback}
        >
          {({ closeModal }) => {
            this.closeModal = closeModal;
            return (
              <Mutation
                mutation={GQL_CREATE_CUSTOMER}
                variables={getParams(this.state)}
                onError={this.handleMutationError}
                onCompleted={this.handleMutationComplete}
                refetchQueries={["CustomersConnection"]}
              >
                {(createCustomer, { loading }) =>
                  renderForm(
                    this.state,
                    this.setState,
                    async () =>
                      (await this.submitForm()) &&
                      createCustomer() &&
                      this.playSFX(),
                    loading
                  )
                }
              </Mutation>
            );
          }}
        </Modal>
      </div>
    );
  }
}

export default CreateCustomerModal;
