import React from "react";
import { Mutation } from "react-apollo";
import _ from "lodash";
import classNames from "classnames";

import { Button, Header, Message } from "semantic-ui-react";
import { MdPeople } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

import analytics from "src/plg/Segment";

import Modal from "src/uikit/Modal";
import Input from "src/uikit/Forms/Input";
import ImgIcon from "src/uikit/ImgIcon";
import { SFX_ADD } from "src/utils/sfx";
import availableIcons from "src/uikit/ImgIcon/availableIcons";

import style from "./CreateCgroupModal.module.css";

import GQL_CREATE_CGROUP from "src/graphql/mutations/CreateCgroup.gql";
import GQL_CGROUPS_CUSTOMERS from "src/graphql/queries/CgroupsCustomers.gql";

class CreateCgroupModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      sfx: false,

      name: "",
      color: "",
      icon: "",
    };

    this.getParams = this.getParams.bind(this);
    this.clearFields = this.clearFields.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.playSFX = this.playSFX.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleIcon = this.handleIcon.bind(this);
    this.openCallback = this.openCallback.bind(this);
  }

  getParams() {
    return {
      name: this.state.name,
      color: this.state.color,
      icon: this.state.icon,
    };
  }

  clearFields() {
    this.setState({
      name: "",
      color: "",
      icon: "",
    });
  }

  async submitForm() {
    await this.setState({
      name: this.state.name.trim(),
    });

    if (String(this.state.name).length < 3) {
      this.setState({
        error: "Dê um nome para o grupo de pelo menos 3 letras",
      });
      return false;
    }

    if (String(this.state.icon).length === 0) {
      this.setState({
        error: "Escolha um ícone, assim fica mais fácil reconhecer o grupo",
      });
      return false;
    }

    return true;
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Escolha um nome válido e um ícone para o grupo",
    });
  }

  handleMutationComplete() {
    setTimeout(() => this.setState({ sfx: false }), 5 * 1000);

    analytics.track("Cgroup Created", { page: "Cgroup modal" });
  }

  handleMutationUpdate(cache, { data: { createCgroup } }) {
    cache.writeQuery({
      query: GQL_CGROUPS_CUSTOMERS,
      data: {
        cgroups: [
          ...cache.readQuery({ query: GQL_CGROUPS_CUSTOMERS }).cgroups,
          { ...createCgroup, customers: [] },
        ],
      },
    });
  }

  playSFX() {
    this.setState({
      sfx: true,
    });

    return true;
  }

  handleName({ target: { value } }) {
    this.setState({
      name: value,
    });
  }

  handleIcon(key) {
    this.setState({
      icon: key,
    });
  }

  renderForm(submitCallback, loading) {
    return (
      <div>
        {this.state.error && (
          <div className={style.ModalBody}>
            <Message negative>{this.state.error}</Message>
          </div>
        )}

        <div className={style.ModalBody}>
          <div className="p-4">
            <Input
              placeholder="Nome do grupo"
              icon={<MdPeople />}
              value={this.state.name}
              onChange={this.handleName}
              fluid
              autofocus
            />
          </div>
          <div className="mt-4 text-center">
            <Header size="small" color="grey">
              Selecione um ícone
            </Header>
            <div>
              {_.map(availableIcons, (val, key) => (
                <ImgIcon
                  iconPath={val}
                  className={classNames(
                    style.Icon,
                    this.state.icon === key ? style.IconSelected : null
                  )}
                  key={key}
                  onClick={() => this.handleIcon(key)}
                />
              ))}
            </div>
          </div>
        </div>

        <div className={style.ModalFooter}>
          <Button
            primary
            size="large"
            fluid
            onClick={submitCallback}
            disabled={loading}
          >
            <FaCheckCircle />
            <span className="a-m ml-2">
              {loading ? "Só um instante..." : "Criar"}
            </span>
          </Button>
        </div>
      </div>
    );
  }

  openCallback() {
    this.clearFields();
  }

  render() {
    return (
      <div>
        {this.state.sfx && <audio autoPlay={false} src={SFX_ADD} />}
        <Modal
          trigger={this.props.trigger}
          size="small"
          closeBtnDark
          openCallback={this.openCallback}
        >
          {({ closeModal }) => (
            <Mutation
              mutation={GQL_CREATE_CGROUP}
              variables={this.getParams()}
              onError={this.handleMutationError}
              onCompleted={this.handleMutationComplete}
              update={this.handleMutationUpdate}
            >
              {(createCgroup, { loading }) =>
                this.renderForm(
                  async () =>
                    (await this.submitForm()) &&
                    createCgroup() &&
                    this.playSFX() &&
                    (this.props.clearCgroupSelection() || true) &&
                    closeModal(),
                  loading
                )
              }
            </Mutation>
          )}
        </Modal>
      </div>
    );
  }
}

export default CreateCgroupModal;
