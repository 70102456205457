import React from "react";
import { SummaryCard } from "src/components/SummaryCard";
import Tooltip from "src/components/Tooltip";
import { Link } from "react-router-dom";

const SettledThisMonth = ({ value }) => {
  const formatedValue = `R$ ${new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)}
  `;
  return (
    <Tooltip
      title="Recebido neste mês"
      content="Valor total transferido para a sua conta bancária neste mês. Clique para ver relatórios."
    >
      <SummaryCard
        to={"/financeiro"}
        value={formatedValue}
        label="Recebido"
        component={Link}
      />
    </Tooltip>
  );
};

export default SettledThisMonth;
