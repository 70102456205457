import React from "react";
import { Box } from "@plipag/ceci-ui";
import SearchForm from "../../components/SearchForm";
import Context from "../../layouts/Context";

const Wrapper = (props) => (
  <Box
    {...props}
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      zIndex: 3300,
      background: "#fff",
      padding: 4,
    }}
  />
);
class SearchContainer extends React.Component {
  state = {};

  constructor(props) {
    super(props);

    this.escFunction = this.escFunction.bind(this);
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.history.goBack();
    }
  }
  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }
  static getDerivedStateFromProps(props, state) {
    return {};
  }

  render() {
    document.title = "Procurar | PLI";

    return (
      <Wrapper>
        <Context goBack={this.props.history.goBack}>
          <SearchForm />
        </Context>
      </Wrapper>
    );
  }
}

export default SearchContainer;
