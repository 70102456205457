import React from "react";
import { AppBar } from "../AppBar";
import { Container } from "./Container";
import { Wrapper } from "./Wrapper";

export function Layout({ children }) {
  return (
    <Wrapper>
      <AppBar />
      <Container>{children}</Container>
    </Wrapper>
  );
}
