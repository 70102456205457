import React from "react";
import PropTypes from "prop-types";

import { getFeatures } from "./profile";

class PlgSwitchCond extends React.Component {
  render() {
    return this.props.condition(getFeatures())
      ? this.props.positive
      : this.props.negative;
  }
}

PlgSwitchCond.propTypes = {
  condition: PropTypes.func.isRequired,
  positive: PropTypes.element.isRequired,
  negative: PropTypes.element.isRequired,
};

export default PlgSwitchCond;
