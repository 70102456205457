import { gql } from 'apollo-boost'

import RecurrenceProps from '../fragments/recurrence'
import BillingProps from '../fragments/billing'

const GQL_RESUME_RECURRENCE = gql`
  mutation ResumeRecurrence($id: ID!) {
    resumeRecurrence(id: $id) {
      ...RecurrenceProps
      billings {
        ...BillingProps
      }
    }
  }
  ${RecurrenceProps}
  ${BillingProps}
`

export default GQL_RESUME_RECURRENCE