import React from "react";
import { Query } from "react-apollo";
import _ from "lodash";

import { Segment, Message } from "semantic-ui-react";

import ImgIcon from "src/uikit/ImgIcon";
import Uploader from "src/uikit/Uploader/Uploader";
import entities from "src/uikit/Uploader/entities";

import style from "./Logo.module.css";

import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

const pages = {
  LOGO: "LOGO",
  ERROR: "ERROR",
};

class Logo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: pages.LOGO,
      errors: null,
    };

    this.changePage = this.changePage.bind(this);
    this.setErrors = this.setErrors.bind(this);
  }

  changePage(page) {
    this.setState({ page });
  }

  setErrors(errors) {
    this.setState({ errors, page: pages.ERROR });
  }

  renderErrors() {
    return (
      <div className="text-center">
        {_.map(this.state.errors, (e) => (
          <Message negative key={_.uniqueId()}>
            <Message.Header>{e.code} - erro</Message.Header>
            {e.message}
          </Message>
        ))}
      </div>
    );
  }

  renderLogo() {
    return (
      <Query query={GQL_MERCHANT} fetchPolicy="network-only">
        {({ loading, error, data, refetch }) => {
          if (loading) return <OrbitsLoader />;
          if (error) return <GqlError error={error} />;

          const placeholder = data.merchant.logo
            ? "Trocar logo"
            : "Escolher arquivo";

          const uploader = (
            <Uploader
              entity={entities.LOGO}
              placeholder={placeholder}
              onError={this.setErrors}
              onSuccess={refetch}
              className="text-center"
              reset
            />
          );

          const existingLogo = (
            <div className="text-center">
              <div className={style.LogoContainer}>
                <img src={data.merchant.logo} className={style.Logo} alt="" />
              </div>
              <div className="mt-4">{uploader}</div>
            </div>
          );

          const missingLogo = (
            <Segment placeholder>
              <div className="text-center p-4">
                <ImgIcon
                  iconPath="open/flat/2021165-magic/png/032-shaman.png"
                  className={style.LogoPlaceholder}
                />
              </div>
              <div className={style.Header}>Nenhuma imagem selecionada</div>
              <div className="mt-4">{uploader}</div>
            </Segment>
          );

          return data.merchant.logo ? existingLogo : missingLogo;
        }}
      </Query>
    );
  }

  render() {
    return (
      <div className="mt-5">
        {this.state.page === pages.ERROR && this.renderErrors()}
        {this.state.page === pages.LOGO && this.renderLogo()}
      </div>
    );
  }
}

export default Logo;
