import { Link } from "react-router-dom";
import { Button, Grid, Header } from "semantic-ui-react";
import { getFeatures } from "src/plg/profile";
import { FaCommentDollar } from "react-icons/fa";
import classNames from "classnames";
import style from "src/components/BillingsNavigator/BillingsNavigator.module.css";
import analytics from "src/plg/Segment";

export function EmptyList() {
  return (
    <Grid centered>
      <Grid.Row>
        <Grid.Column textAlign="center" className="noselect mb-5">
          <FaCommentDollar size={192} className="lighter-grey" />
          <Header as="h2" disabled color="grey" content="Nenhuma cobrança" />
        </Grid.Column>
      </Grid.Row>
      {getFeatures()["status"] === "DEMO" && (
        <Grid.Row columns={1}>
          <Grid.Column width={8} textAlign="center">
            <ul className={classNames("vertical", style.List)}>
              <li className={classNames(style.ListItem, "fadeInUp")}>
                <Link to="/ativacao" className={classNames(style.ListItemLink)}>
                  <Button
                    primary
                    size="huge"
                    onClick={() =>
                      analytics.track("Activation Started", {
                        from: "Billings",
                      })
                    }
                  >
                    Ativar a conta
                  </Button>
                </Link>
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  );
}
