import React from "react";
import { CustomersContext } from "../context";
import { GroupActions } from "../GroupActions";
import { SingleActions } from "../SingleActions";

type Props = {
  children?: React.ReactNode;
};

export function ActionsBar(props: Props) {
  const { selecteds, items } = React.useContext(CustomersContext);
  const selecting = items.filter(({ id }) => selecteds.includes(id)).length > 0;
  return (
    <>
      {!selecting && <SingleActions {...props} />}
      {selecting && <GroupActions />}
    </>
  );
}
