import { gql } from 'apollo-boost'

const CustomerProps = gql`
  fragment CustomerProps on Customer {
    id
    status
    createdAt
    updatedAt
    document
    name
    nickname
    email
    mobile
    
    street
    district
    city
    province
    zipcode
    lat
    lng
  }
`

export default CustomerProps