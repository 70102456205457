import { Button } from "semantic-ui-react";
import { MdGroupAdd } from "react-icons/md";
import TypeFormModal from "src/components/TypeFormModal";

const ImportClientsButton = ({ children }) => {
  return (
    <TypeFormModal url="https://contapli.typeform.com/to/PiJwCACp">
      {children || (
        <Button
          secondary
          style={{
            marginRight: 15,
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          }}
        >
          <MdGroupAdd size="22" style={{ marginRight: 5 }} />
          <span style={{ marginTop: 2 }}>Importar clientes</span>
        </Button>
      )}
    </TypeFormModal>
  );
};

export default ImportClientsButton;
