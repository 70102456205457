import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { Query } from "react-apollo";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";

import { Button, Popup } from "semantic-ui-react";
import { MdPerson } from "react-icons/md";
import { FaCommentDollar, FaHandHoldingUsd } from "react-icons/fa";

import Modal from "../../uikit/Modal";
import { routes, matchRoute } from "./routes";

import style from "./Customer.module.css";

import GQL_CUSTOMER_WITH_FIELDS from "../../graphql/queries/CustomerWithFields.gql";
import GqlLoadingIcon from "../../uikit/GqlLoadingIcon";
import GqlError from "../../uikit/GqlError";

class Customer extends React.Component {
  constructor(props) {
    super(props);

    this.renderHeader = this.renderHeader.bind(this);
    this.renderTabs = this.renderTabs.bind(this);
  }

  renderHeader({ customer }) {
    return (
      <ul className="horizontal relaxed middle">
        <li>
          <Link
            to={routes.CUSTOMER.getLink(customer.id)}
            className={style.ProfileLink}
          >
            <MdPerson className={style.ContactIcon} />
          </Link>
        </li>
        <li className={style.StretchedItem}>
          <div className={classNames(style.ModalTitle, "cursor-default")}>
            {customer.nickname || customer.name}
          </div>
          <div className={classNames(style.ModalSubtitle, "cursor-default")}>
            Cliente {moment(customer.createdAt).fromNow()}
            {customer.status === "ARCHIVED" && (
              <Popup
                trigger={
                  <Link
                    to={routes.REACTIVATE_CUSTOMER.getLink(customer.id)}
                    className={style.ArchivedTag}
                  >
                    arquivado
                  </Link>
                }
                inverted
                content="Reativar cliente"
                position="left center"
              />
            )}
          </div>
        </li>
      </ul>
    );
  }

  renderTabs({ customer, body, route }) {
    return (
      <div className={style.TabContainer}>
        <Popup
          trigger={
            <Button
              primary={route.name !== "CREATE_RECEIVEMENT"}
              as={Link}
              to={routes.CREATE_RECEIVABLE.getLink(customer.id)}
            >
              <FaCommentDollar className="mr-2" />
              <span className="a-m">Gerar Cobrança</span>
            </Button>
          }
          inverted
          position="top center"
          header="Cobrar"
          content="Lançar uma nova cobrança ou criar uma nova mensalidade"
        />
        <Popup
          trigger={
            <Button
              primary={route.name === "CREATE_RECEIVEMENT"}
              style={{ marginTop: 10 }}
              as={Link}
              to={routes.CREATE_RECEIVEMENT.getLink(customer.id)}
            >
              <FaHandHoldingUsd className="mr-2" />

              <span className="a-m">Registrar recebimento</span>
            </Button>
          }
          inverted
          position="top center"
          header="Registrar recebimento"
          content="Lançar uma nova cobrança feita por fora do PLI"
        />
        <div className={style.TabContainer}>
          <div className={style.TabBtn}>
            <Button
              className={classNames(
                style.ButtonLeftEdge,
                [
                  "CUSTOMER",
                  "ARCHIVE_CUSTOMER",
                  "CUSTOMER_ADDRESS",
                  "CUSTOMER_NOTES",
                ].includes(route.name)
                  ? style.ButtonSelected
                  : null
              )}
              as={Link}
              to={routes.CUSTOMER.getLink(customer.id)}
            >
              <MdPerson className="mr-2" />
              <span className="a-m">Cliente</span>
            </Button>
          </div>
          <div className={style.TabBtn}>
            <Button
              className={classNames(
                style.ButtonRightEdge,
                ["RECEIVABLES"].includes(route.name)
                  ? style.ButtonSelected
                  : null
              )}
              as={Link}
              to={routes.RECEIVABLES.getLink(customer.id)}
            >
              <FaCommentDollar className="mr-2" />
              <span className="a-m">Cobranças</span>
            </Button>
          </div>
        </div>
      </div>
    );
  }

  addFieldsIds(customer) {
    if (!customer) return null;

    if (!customer.extraFields) return customer;

    const fields = customer.extraFields;
    const ef = _.map(fields, (field) => {
      return {
        id: _.uniqueId(`Customer:${customer.id}:Field_`),
        name: field.name,
        value: field.value,
      };
    });

    return Object.assign({}, customer, { extraFields: ef });
  }

  render() {
    const route = matchRoute(this.props.match.params);

    if (!route) return null;

    return (
      <Modal
        directRender={true}
        size="small"
        closeBtnDark={true}
        borderStyle={route.configs.modalBorders || "ssss"}
        closeCallback={() => this.props.history.push("/clientes")}
      >
        {(closeModal) => (
          <Query
            query={GQL_CUSTOMER_WITH_FIELDS}
            variables={{ id: this.props.customerId }}
          >
            {({ loading, error, data, refetch }) => {
              if (loading) return <GqlLoadingIcon />;
              if (error) return <GqlError error={error} />;

              if (!data.customer) return <Redirect to="/clientes" />;

              const customer = this.addFieldsIds(data.customer);
              const body = route.getBody(
                closeModal,
                customer,
                this.props.match.params.id
              );

              return (
                <div>
                  {route.configs.header && (
                    <div className={style.ModalHeader}>
                      {route.configs.header && this.renderHeader({ customer })}
                      {route.configs.tabs &&
                        this.renderTabs({ customer, body, route })}
                    </div>
                  )}
                  {body}
                </div>
              );
            }}
          </Query>
        )}
      </Modal>
    );
  }
}

export default withRouter(Customer);
