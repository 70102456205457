import React from 'react'
import _ from 'lodash'

import Billing from './partials/Billing'

export const routes = {
  BILLING: {
    name: 'BILLING',
    route: { module: undefined, id: undefined, page: undefined, },
    configs: { header: false, tabs: false, modalBorders: 'ssss' },
    getLink: (billingId, id) => buildLink(routes.BILLING.route, { billingId, id }),
    getBody: (closeModal, billing, id) => <Billing billing={billing} id={id} closeModal={closeModal} />,
  },
}

// Format is /cobrancas/:billingId/:module/:id/:page
export function matchRoute(params) {
  return _.find(routes, r => {
    return (
      params.module === r.route.module && (
        (params.id === undefined && r.route.id === undefined) ||
        (params.id && r.route.id)
      ) &&
      params.page === r.route.page
    )
  })
}

function buildLink(routeParams, { billingId, id = null }) {
  const partRoot = `/cobrancas/${billingId}`
  const partModule = routeParams.module ? `/${routeParams.module}` : ''
  const partId = routeParams.id ? `/${id}` : ''
  const partPage = routeParams.page ? `/${routeParams.page}` : ''

  return `${partRoot}${partModule}${partId}${partPage}`
}
