import React from 'react'
import { Dimmer, Loader } from 'semantic-ui-react'

class GqlLoadingIcon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 0
    }
  }

  componentDidMount() {
    this._isMounted = true
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  static phrases(i) {
    return [
      'Carregando...',
      'Quase lá...',
      'Só mais um instante...',
      'Demorando demais, talvez deveríamos recarregar a página'
    ][i]
  }

  render() {
    switch (this.state.step) {
      case 0:
        setTimeout(
          () => this._isMounted && this.setState({ step: 1 }),
          3000
        )
        break;
      case 1:
        setTimeout(
          () => this._isMounted && this.setState({ step: 2 }),
          5000
        )
        break;
      case 2:
        setTimeout(
          () => this._isMounted && this.setState({ step: 3 }),
          12000
        )
        break;
      default:
        this.setState({ step: 0 })
        break;
    }

    return (
      <Dimmer active inverted>
        <Loader inverted size="small" indeterminate
                content={GqlLoadingIcon.phrases(this.state.step)} />
      </Dimmer>
    )
  }
}

export default GqlLoadingIcon
