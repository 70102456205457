import React from "react";
import TypeFormModal from "../../TypeFormModal";

const CreateUserButton = (props) => {
  return (
    <TypeFormModal url="https://contapli.typeform.com/to/RfL9ZFtJ">
      <span {...props}>Novo usuário</span>
    </TypeFormModal>
  );
};

export default CreateUserButton;
