import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  from,
} from "@apollo/client";
import fetch from "node-fetch";
import { onError } from "apollo-link-error";
import { getToken } from "src/app/token";
import { ContextRef } from "src/app/context";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });

const storeBackendDatetime = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    localStorage.setItem("date", context.response.headers.get("date"));
    return response;
  });
});

const handleInvalidToken = onError(
  ({ graphQLErrors, operation, forward, networkError }) => {
    if (networkError) {
      if (ContextRef.current?.setNetworkError) {
        // ContextRef.current?.setNetworkError(true);
      }
    }
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // handle errors differently based on its error code
        if (err.extensions)
          switch (err.extensions.code) {
            case "UNAUTHENTICATED":
              // old token has expired throwing AuthenticationError,
              // one way to handle is to obtain a new token and
              // add it to the operation context
              const headers = operation.getContext().headers;
              operation.setContext({
                headers: {
                  ...headers,
                  authorization: null,
                },
              });

              // Remove token if any
              localStorage.removeItem("token");

              window.location.href = process.env.REACT_APP_SIGNIN_URL;
              // Now, pass the modified operation to the next link
              // in the chain. This effectively intercepts the old
              // failed request, and retries it with a new token
              return forward(operation);
            default:
              break;
          }
      }
    }
  }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: "Bearer " + getToken(),
    },
  }));

  return forward(operation);
});

export const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      // customers: (_, args, { getCacheKey }) => getCacheKey({ __typename: 'Customer', id: args.id })
    },
  },
});

const client = new ApolloClient({
  cache,
  fetch,
  link: from([
    authMiddleware,
    storeBackendDatetime,
    handleInvalidToken,
    httpLink,
  ]),
});

export default client;
