import React from "react";

import availableIcons from "./availableIcons";

type Props = {
  [key: string]: any;
  iconPath?: string;
  iconKey?: string | keyof typeof availableIcons;
  className?: string;
  onClick?: () => void;
};

function ImgIcon({ iconPath, iconKey, className, onClick, ...props }: Props) {
  const link = iconPath
    ? `https://assets.plipag.com.br/conta/images/icons${
        iconPath[0] === "/" ? iconPath : "/" + iconPath
      }`
    : iconKey
    ? // @ts-ignore
      `https://assets.plipag.com.br/conta/images/icons/${availableIcons[iconKey]}`
    : "";

  const onClickFun = onClick ? onClick : () => {};

  return (
    <img
      alt=""
      src={link}
      className={className}
      onClick={onClickFun}
      {...props}
    />
  );
}

export default ImgIcon;
