import { gql } from 'apollo-boost'

import CustomerProps from '../fragments/customer'

const GQL_CUSTOMER_UPDATE_ADDRESS = gql`
  mutation UpdateCustomer(
    $id: ID!,
    $street: String,
    $district: String,
    $city: String,
    $province: String,
    $zipcode: String
  ) {
    updateCustomer(
      id: $id,
      street: $street,
      district: $district,
      city: $city,
      province: $province,
      zipcode: $zipcode
    ) {
      ...CustomerProps
    }
  }
  ${CustomerProps}
`

export default GQL_CUSTOMER_UPDATE_ADDRESS