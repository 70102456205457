import { gql } from 'apollo-boost'

const GQL_MERCHANT_STATUS = gql`
  query MerchantStatus {
    merchant {
      status
    }
  }
`

export default GQL_MERCHANT_STATUS
