import React from "react";
import { Mutation, Query } from "react-apollo";
import moment from "moment";
import "moment/locale/pt-br";

import { Button, Popup } from "semantic-ui-react";

import { businessDayForward } from "src/utils/holidays";
import analytics from "src/plg/Segment";

import GQL_GET_SETTLEMENT_REQUEST from "src/graphql/queries/GetSettlementRequest.gql";
import GQL_REQUEST_SETTLEMENT from "src/graphql/mutations/RequestSettlement.gql";
import GQL_CANCEL_SETTLEMENT from "src/graphql/mutations/CancelSettlement.gql";
import GqlError from "src/uikit/GqlError";

class SettlementRequest extends React.Component {
  static renderRequested(sr) {
    const createdAt = moment(sr.createdAt);

    const term = businessDayForward(
      businessDayForward(createdAt).add(1, "day")
    ).format("DD[/]MM");

    return (
      <Mutation
        mutation={GQL_CANCEL_SETTLEMENT}
        refetchQueries={[{ query: GQL_GET_SETTLEMENT_REQUEST }]}
      >
        {(mutation, { loading, error, data }) => {
          if (loading) return SettlementRequest.renderLoading();
          if (error) return <GqlError error={error} />;

          const f = () => {
            mutation();
            analytics.track("Settlement Canceled", { type: "ui" });
          };

          return (
            <Popup
              inverted
              header={`Solicitada em ${createdAt.format("DD[/]MM")}`}
              content={`A transferência está em processamento e será realizada em até ${term}`}
              position="bottom center"
              trigger={
                <Button secondary size="large" onClick={f}>
                  Cancelar pedido
                </Button>
              }
            />
          );
        }}
      </Mutation>
    );
  }

  static renderRequest() {
    return (
      <Mutation
        mutation={GQL_REQUEST_SETTLEMENT}
        refetchQueries={[{ query: GQL_GET_SETTLEMENT_REQUEST }]}
      >
        {(removeSR, { loading, error, data }) => {
          if (loading) return SettlementRequest.renderLoading();
          if (error) return <GqlError error={error} />;

          const f = () => {
            removeSR();
            analytics.track("Settlement Requested", { type: "ui" });
          };

          return (
            <Button primary size="large" onClick={f}>
              Solicitar transferência
            </Button>
          );
        }}
      </Mutation>
    );
  }

  static renderLoading() {
    return (
      <Button secondary size="large" disabled>
        Aguarde...
      </Button>
    );
  }

  render() {
    return (
      <div>
        <Query query={GQL_GET_SETTLEMENT_REQUEST}>
          {({ loading, error, data }) => {
            if (loading) return SettlementRequest.renderLoading();
            if (error) return <GqlError error={error} />;

            return data.settlementRequest
              ? SettlementRequest.renderRequested(data.settlementRequest)
              : SettlementRequest.renderRequest();
          }}
        </Query>
      </div>
    );
  }
}

export default SettlementRequest;
