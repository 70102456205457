import { get } from "axios";
import { getToken } from "src/app/token";

export function exportCustomers(start, end) {
  return download(addRangeToURL("customers.xlsx", start, end));
}

export function exportBillings(start, end) {
  return download(addRangeToURL("billings.xlsx", start, end));
}

function addRangeToURL(url, start, end) {
  if (start && end) {
    return `${url}?start_date=${start}&end_date=${end}`;
  }
  return url;
}

function download(url) {
  return get(url, {
    headers: { Authorization: `Bearer ${getToken()}` },
    timeout: 60000,
    responseType: "blob",
    baseURL: `${process.env.REACT_APP_API_HOST}/export/`,
  })
    .then(async (data) => {
      const content = new Blob([data.data]);
      const downloadUrl = window.URL.createObjectURL(content);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "Cobrancas - PLIPAG.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(async (e) => {
      try {
        const text = await e.response.data.text();
        if (JSON.parse(text).errors[0].code === 401) {
          window.location.href = "/auth";
        }
      } catch (e) {
        alert(
          "Erro ao exportar dados. Tente novamente selecionando um período mais curto."
        );
      }
    });
}
