import { gql } from 'apollo-boost'

import PreferencesProps from '../fragments/preferences'

const GQL_PREFERENCES = gql`
  query MerchantPreferences {
    preferences {
      ...PreferencesProps
    }
  }
  ${PreferencesProps}
`

export default GQL_PREFERENCES
