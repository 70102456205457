export const tokenName = "token";
export const userHash = "user_hash";

export function getToken() {
  return process.env.REACT_APP_TOKEN || localStorage.getItem(tokenName);
}

export function saveToken(token) {
  localStorage.setItem(tokenName, token);
}

export function deleteToken() {
  localStorage.removeItem(tokenName);
  localStorage.removeItem(userHash);
}

export function existsToken() {
  return (
    localStorage.getItem(tokenName) !== null &&
    localStorage.getItem(tokenName) !== undefined
  );
}
