import React from "react";
import { Query } from "react-apollo";
import moment from "moment";
import "moment/locale/pt-br";

import { Table, Popup, Icon, Divider, Button, Card } from "semantic-ui-react";
import { MdSwapVerticalCircle } from "react-icons/md";

import { asMoney } from "src/utils/decimal";

import SettlementRequest from "../SettlementRequest";

import style from "./SettlementCard.module.css";

import GQL_SETTLEMENT from "src/graphql/queries/Settlement.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

class SettlementCard extends React.Component {
  static relativeDate(date) {
    const days = moment().diff(moment(date), "days");

    if (days === 0) return "completada hoje";
    else if (days === 1) return "completada ontem";
    else if (days === 2) return "completada anteontem";
    else return `completada ${moment(date).fromNow()}`;
  }

  static finalBalance(s) {
    return (
      s.consumedBalance +
      s.tpv -
      s.tpvFee -
      s.refunds -
      s.refundsFee -
      s.chargebacks -
      s.chargebacksFee +
      s.otherEarnings -
      s.otherDeductions -
      s.settlementFee -
      s.amountSettled
    );
  }

  static renderCard(s) {
    return (
      <Card fluid centered className="p-3" style={{ boxShadow: "none" }}>
        {s.status === "PE" && (
          <div className="text-right">
            <SettlementRequest />
          </div>
        )}
        <div className="text-center">
          <MdSwapVerticalCircle size={128} className="lightest-grey" />
          <div className="mt-2">
            <span className={style.Currency}>R$</span>
            <span className={style.AmountSettled}>
              {asMoney(s.amountSettled)}
            </span>
          </div>
          <div className={style.DateCO}>
            {s.dateCo ? SettlementCard.relativeDate(s.dateCo) : "A transferir"}
          </div>
        </div>
        <Divider hidden />
        <Table basic="very">
          <Table.Body>
            {parseFloat(s.consumedBalance || 0) === 0 ? null : (
              <Table.Row>
                <Table.Cell className="pl-2">Saldo anterior</Table.Cell>
                <Table.Cell className="pr-2" textAlign="right">
                  {asMoney(s.consumedBalance)}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row>
              <Table.Cell className="pl-2">Cobranças</Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.tpv - s.tpvFee)}
              </Table.Cell>
            </Table.Row>
            <Table.Row negative>
              <Table.Cell className="pl-2">Estornos</Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.refunds)}
              </Table.Cell>
            </Table.Row>
            <Table.Row negative>
              <Table.Cell className="pl-2">Contestações</Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.chargebacks)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="pl-2">
                {s.otherEarningsDescription ? (
                  <Popup
                    trigger={<span>Outros proventos</span>}
                    header={s.otherEarningsDescription}
                    inverted
                  />
                ) : (
                  <span>Outros proventos</span>
                )}
              </Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.otherEarnings)}
              </Table.Cell>
            </Table.Row>
            <Table.Row negative>
              <Table.Cell className="pl-2">
                {s.otherDeductionsDescription ? (
                  <Popup
                    trigger={<span>Outras deduções</span>}
                    header={s.otherDeductionsDescription}
                    inverted
                  />
                ) : (
                  <span>Outras deduções</span>
                )}
              </Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.otherDeductions)}
              </Table.Cell>
            </Table.Row>
            <Table.Row negative>
              <Table.Cell className="pl-2">Tarifa de transferência</Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(s.settlementFee)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="pl-2">
                <b>Transferido</b>&nbsp;
                <Popup trigger={<Icon name="info circle" />} inverted>
                  <Popup.Header>Para onde foi o dinheiro?</Popup.Header>
                  <Popup.Content>
                    <p>
                      Para a conta corrente indicada na sua conta no dia&nbsp;
                      {moment(s.date_co).format("DD/MM/YYYY")}.
                    </p>
                    <p>
                      {/*Cód. banco: { smba.bank_code }<br />*/}
                      {/*Agência: { smba.branch }<br />*/}
                      {/*Conta { smba.acctype }: { smba.accno }*/}
                    </p>
                  </Popup.Content>
                </Popup>
              </Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                <b>{asMoney(s.amountSettled)}</b>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell className="pl-2">
                Saldo&nbsp;
                <Popup trigger={<Icon name="info circle" />} inverted>
                  <Popup.Header>O que é o saldo?</Popup.Header>
                  <Popup.Content>
                    Saldo é um crédito a seu favor, automaticamente incluso na
                    transferência seguinte. Ele só é diferente de zero quando o
                    valor transferido é diferente dos créditos totais, isso
                    acontece quando existe restrições bancárias em dias de
                    grande movimentação financeira ou o valor a ser transferido
                    é maior do que o limite diário permitido.
                  </Popup.Content>
                </Popup>
              </Table.Cell>
              <Table.Cell className="pr-2" textAlign="right">
                {asMoney(SettlementCard.finalBalance(s))}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <Divider hidden />
        <div className="text-center">
          <a
            href={`${process.env.REACT_APP_PINE_HOST}/transferencias/${s.id}/relatorio`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Abrir relatório</Button>
          </a>
        </div>
      </Card>
    );
  }

  render() {
    return this.props.settlement ? (
      SettlementCard.renderCard(this.props.settlement)
    ) : (
      <Query query={GQL_SETTLEMENT} variables={{ id: this.props.id }}>
        {({ loading, error, data, refetch, fetchMore }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          return SettlementCard.renderCard(data.settlement);
        }}
      </Query>
    );
  }
}

export default SettlementCard;
