export const PHONE_FORMATS = {
  E164: 'E164',
  LOCAL: 'local'
}

export function asPhone(phone, format, mobileOnly) {
  return {
    original: phone,
    digits: phoneToDigits(phone),
    formatted: formatPhone(phone, format, mobileOnly),
    e164: toE164(phone, format, mobileOnly),
    valid: validPhone(phone, format, mobileOnly)
  }
}

export function phoneToDigits(phone) {
  return String(phone).replace(new RegExp(/[^0-9]/, 'g'), '')
}

export function toE164(phone, format, mobileOnly) {
  const x = phoneToDigits(phone)

  switch (format) {
    case PHONE_FORMATS.E164:
      return mobileOnly ?
        (x[4] === '9' && x.length === 13 ? x : null) :
        ([12, 13].includes(x.length) ? x : null)
    case PHONE_FORMATS.LOCAL:
      return mobileOnly ?
        (x[2] === '9' && x.length === 11 ? `55${x}` : null) :
        ([10, 11].includes(x.length) ? `55${x}` : null)
    default:
      return null
  }
}

export function validPhone(phone, format, mobileOnly) {
  const x = phoneToDigits(phone)

  switch (format) {
    case PHONE_FORMATS.E164:
      return mobileOnly ?
        (x[4] === '9' && x.length === 13) :
        [12, 13].includes(x.length)
    case PHONE_FORMATS.LOCAL:
      return mobileOnly ?
        (x[2] === '9' && x.length === 11) :
        [10, 11].includes(x.length)
    default:
      return false
  }
}

export function formatPhone(phone, format, mobileOnly) {
  const x = phoneToDigits(phone)

  let ddd, num, divider, local
  switch (format) {
    case PHONE_FORMATS.E164:
      if ((mobileOnly && x.length !== 13) || (!mobileOnly && ![12, 13].includes(x.length)))
        return phone

      ddd = x.substr(2, 2)
      num = x.substr(4, x.length - 4)
      divider = num.length === 9 ? 5 : 4
      local = `${num.substr(0, divider)} ${num.substr(divider, num.length - 2)}`

      return `(${ddd}) ${local}`
    case PHONE_FORMATS.LOCAL:
      if ((mobileOnly && x.length !== 11) || (!mobileOnly && ![10, 11].includes(x.length)))
        return phone

      ddd = x.substr(0, 2)
      num = x.substr(2, x.length - 2)
      divider = num.length === 9 ? 5 : 4
      local = `${num.substr(0, divider)} ${num.substr(divider, num.length - 2)}`

      return `(${ddd}) ${local}`
    default:
      return false
  }
}
