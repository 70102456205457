import React from "react";
import { Query } from "react-apollo";
import _ from "lodash";
import moment from "moment";

import { Grid, Header, Popup } from "semantic-ui-react";
import { MdExpandMore, MdExpandLess } from "react-icons/md";

import { billingListable } from "../../../BillingFields/scopes";

import InlineReceivable from "./InlineReceivable";
import ReceivablesSummary from "./Summary";

import style from "./Receivables.module.css";

import GQL_BILLINGS from "src/graphql/queries/Billings.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";
import InlineRecurrences from "./InlineRecurrences";

class Receivables extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
    };

    this.scheduledBlockSize = 3;

    this.expandToggle = this.expandToggle.bind(this);
  }

  static renderEmptyBlock() {
    return (
      <Grid.Row columns={1}>
        <Grid.Column className="cursor-default">
          <i>Nenhuma até o momento.</i>
        </Grid.Column>
      </Grid.Row>
    );
  }

  expandToggle() {
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  render() {
    return (
      <Query
        query={GQL_BILLINGS}
        variables={{ customerIds: [this.props.customer.id] }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const billings = _.filter(data.billings, billingListable);

          const scheduledBillings = _.sortBy(
            _.filter(billings, (b) => b.status === "PENDING"),
            (b) => moment(b.dueDate).format("YYYYMMDD")
          );

          const issuedBillings = _.reverse(
            _.sortBy(
              _.filter(billings, (b) => b.status !== "PENDING"),
              (b) => moment(b.dueDate).format("YYYYMMDD")
            )
          );

          const scheduledBillingsDisplay = _.slice(
            scheduledBillings,
            0,
            this.state.expanded
              ? _.size(scheduledBillings)
              : this.scheduledBlockSize
          );

          return (
            <div className={style.Body}>
              <div>
                <ReceivablesSummary billings={data.billings} />
              </div>
              <div className="mt-3">
                <InlineRecurrences customerId={this.props.customer.id} />
              </div>
              <Header
                content="Programadas"
                className="pt-3 pb-3 cursor-default"
              />
              <Grid centered>
                {_.map(scheduledBillingsDisplay, (b) => (
                  <InlineReceivable billing={b} key={`Billing:${b.id}`} />
                ))}
                {_.isEmpty(scheduledBillings) && Receivables.renderEmptyBlock()}
                {_.size(scheduledBillings) > this.scheduledBlockSize && (
                  <div>
                    {this.state.expanded ? (
                      <Popup
                        inverted
                        content="Mostrar apenas os mais próximos"
                        trigger={
                          <MdExpandLess
                            onClick={this.expandToggle}
                            className={style.Expand}
                          />
                        }
                      />
                    ) : (
                      <Popup
                        inverted
                        content="Mostrar todas"
                        trigger={
                          <MdExpandMore
                            onClick={this.expandToggle}
                            className={style.Expand}
                          />
                        }
                      />
                    )}
                  </div>
                )}
              </Grid>
              <Header
                content="Emitidas & histórico"
                className="pt-3 pb-3 cursor-default"
              />
              <Grid centered>
                {_.map(issuedBillings, (b) => (
                  <InlineReceivable billing={b} key={`Billing:${b.id}`} />
                ))}
                {_.isEmpty(issuedBillings) && Receivables.renderEmptyBlock()}
              </Grid>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Receivables;
