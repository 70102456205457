import { gql } from 'apollo-boost'

const CustomerWithFieldsProps = gql`
  fragment CustomerWithFieldsProps on Customer {
    id
    status
    document
    name
    nickname
    email
    mobile

    street
    district
    city
    province
    zipcode

    createdAt
    
    extraFields {
      name
      value
    }
  }
`

export default CustomerWithFieldsProps