import React from "react";
import { IconButton, Menu, MenuItem } from "@plipag/ceci-ui";
import { MdMoreVert } from "react-icons/md";
import { ArchiveButton } from "./ArchiveButton";
import { RestoreButton } from "./RestoreButton";
import { DestroyButton } from "./DestroyButton";
import { Customer } from "../context";
import { Link } from "react-router-dom";
import { routes } from "src/components/Customer/routes";

type Props = {
  customer: Customer;
};

export function MoreActions({ customer }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton sx={{ ml: 1 }} onClick={handleClick}>
        <MdMoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
      >
        {customer.status === "ACTIVE" && (
          <ArchiveButton id={customer.id} onClick={handleClose} />
        )}
        {customer.status === "ARCHIVED" && (
          <>
            <RestoreButton id={customer.id} onClick={handleClose} />
            <DestroyButton id={customer.id} onClick={handleClose} />
          </>
        )}
        <MenuItem
          component={Link}
          to={routes.CREATE_RECEIVEMENT.getLink(customer.id)}
        >
          Registrar recebimento
        </MenuItem>
      </Menu>
    </>
  );
}
