import React from "react";

import SettlementsNavigator from "../../components/SettlementsNavigator";
import TabBillings from "../../components/TabBillings";
import { Grid } from "@plipag/ceci-ui";
import { FirstBillingCTA } from "src/components/FirstBillingCTA";

class FinanceContainer extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    return {};
  }

  render() {
    document.title = "Financeiro | PLI";

    return (
      <Grid container direction="column">
        <Grid item>
          <div className="mb-5 fadein" style={{ padding: 50 }}>
            <FirstBillingCTA />

            <TabBillings match={this.props.match} />
            <SettlementsNavigator />
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default FinanceContainer;
