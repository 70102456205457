import React from "react";
import { Grid } from "@plipag/ceci-ui";

const Page = ({ children, back = true }) => {
  return (
    <Grid container direction="column">
      <Grid container flexGrow={1} direction="column">
        {children}
      </Grid>
    </Grid>
  );
};

export default Page;
