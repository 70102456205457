import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";

import { Header, Item, Popup } from "semantic-ui-react";
import { MdAccountBalanceWallet, MdAccountBalance } from "react-icons/md";

import Modal from "src/uikit/Modal";
import { icon } from "src/utils/banks";

import style from "./BankAccountsModal.module.css";

import GQL_BANK_ACCOUNTS from "src/graphql/queries/BankAccounts.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";
import EditBankInformationsButton from "./EditBankInformationsButton";

class BankAccountsModal extends React.Component {
  static acctype(code) {
    switch (code) {
      case "CHECKING":
        return "corrente";
      case "SAVINGS":
        return "poupança";
      case "FACIL":
        return "fácil";
      default:
        return "";
    }
  }

  static renderNoBankAccount() {
    return (
      <div className="text-center">
        <Header
          icon={<MdAccountBalance className={style.BankPlaceholder} />}
          content="Nenhuma conta configurada"
          color="grey"
        />
        <div>{this.renderAddAccount()}</div>
      </div>
    );
  }

  static renderMainAccount(ba) {
    if (!ba) return null;

    return (
      <div>
        <Item.Group>
          <Item>
            <Popup
              trigger={<Item.Image src={icon(ba.bank.code)} size="tiny" />}
              inverted
              content={ba.bank.name}
            />
            <Item.Content>
              <Item.Meta
                content={`conta ${BankAccountsModal.acctype(ba.acctype)}`}
              />
              <Item.Header content={ba.accno} />
              <Item.Meta content={`agência ${ba.branch}`} />
            </Item.Content>
          </Item>
        </Item.Group>
      </div>
    );
  }

  static renderSecondaryAccount(ba) {
    return (
      <Popup
        trigger={
          <img src={icon(ba.bank.code)} alt="" className={style.SecBankLogo} />
        }
        inverted
        header={ba.bank.name}
        content={
          <div>
            <div>
              conta {BankAccountsModal.acctype(ba.acctype)} {ba.accno}
            </div>
            <div>agência {ba.branch}</div>
          </div>
        }
      />
    );
  }

  static renderSecondaryAccounts(bas) {
    if (!bas || _.isEmpty(bas)) return null;

    return (
      <div className="pt-5">
        <ul className="horizontal">
          {_.map(bas, (ba) => (
            <li key={_.uniqueId()} className={style.SecBankListItem}>
              {BankAccountsModal.renderSecondaryAccount(ba)}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  static renderAddAccount() {
    return (
      <div className="mt-5">
        Quando sua conta estiver ativa, seus dados bancários aparecerão aqui.
      </div>
    );
  }

  renderContent() {
    return (
      <div>
        <div className={style.ModalHeader}>
          <ul className="horizontal relaxed middle">
            <li>
              <MdAccountBalanceWallet size={64} />
            </li>
            <li>
              <div className={style.ModalSubtitle}>Gestão da conta</div>
              <div className={style.ModalTitle}>Dados bancários</div>
            </li>
          </ul>
        </div>
        <div className={style.ModalBody}>
          <Query query={GQL_BANK_ACCOUNTS}>
            {({ loading, error, data, refetch }) => {
              if (loading) return <GqlLoadingIcon />;
              if (error) return <GqlError error={error} />;

              const bankAccounts = _.filter(
                data.bankAccounts,
                (x) => x.bank.code !== "065"
              );

              if (_.isEmpty(bankAccounts))
                return BankAccountsModal.renderNoBankAccount();

              const mainAccount = _.find(
                data.bankAccounts,
                (x) => x.defaultAccount
              );
              const secondaryAccounts = _.filter(
                data.bankAccounts,
                (x) => !x.defaultAccount
              );

              return (
                <div>
                  {BankAccountsModal.renderMainAccount(mainAccount)}
                  {BankAccountsModal.renderSecondaryAccounts(secondaryAccounts)}
                  {BankAccountsModal.renderAddAccount()}
                </div>
              );
            }}
          </Query>

          <EditBankInformationsButton />
        </div>
      </div>
    );
  }

  openCallback() {}

  render() {
    return (
      <Modal
        trigger={this.props.trigger}
        size="small"
        borderStyle="ssss"
        openCallback={this.openCallback}
      >
        {(closeModal) => this.renderContent()}
      </Modal>
    );
  }
}

export default BankAccountsModal;
