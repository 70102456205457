import { gql } from 'apollo-boost'

const NoteProps = gql`
  fragment NoteProps on Note {
    id
    customerId
    text
    createdAt
    updatedAt
  }
`

export default NoteProps