import React from "react";
import {
  Button,
  Checkbox,
  Card,
  CardActionArea,
  CardHeader,
  CardActions,
} from "@plipag/ceci-ui";
import { Link } from "react-router-dom";
import { routes } from "src/components/Customer/routes";
import CustomerInline from "src/components/CustomerInline";
import { CustomersContext, Customer } from "../context";
import { MoreActions } from "../ListItem/MoreActions";
import { useHistory } from "react-router-dom";

type Props = {
  customer: Customer;
};

export function MobileCustomersListItem({ customer }: Props) {
  const { selecteds, toggleItem, cgroups, cgroupId } =
    React.useContext(CustomersContext);

  const selected = selecteds.findIndex((id) => customer.id === id) > -1;
  const selecting = selecteds.length > 0;

  const history = useHistory();

  function cgroupNavigate(id: string | null) {
    if (cgroupId === id) {
      history.push("/clientes");
    } else {
      history.push(`/clientes/grupo/${id}`);
    }
  }

  function customerNavigate() {
    history.push(`/clientes/${customer.id}`);
  }

  const groups = cgroups.filter(
    (cgroup) => !!cgroup.customers.find((c) => c.id === customer.id)
  );

  return (
    <Card sx={{ mb: 2 }}>
      <CardActionArea component="span" onClick={customerNavigate}>
        <CardHeader
          avatar={
            <div
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation();
                toggleItem(customer.id);
              }}
            >
              <Checkbox checked={selected} />
            </div>
          }
          title={customer.nickname ? customer.nickname : customer.name}
          subheader={customer.name}
          action={!selecting && <MoreActions customer={customer} />}
        />
        {!selecting && (
          <CardActions onClick={(e: React.MouseEvent) => e.stopPropagation()}>
            {customer.status === "ACTIVE" && (
              <Button
                component={Link}
                to={routes.CREATE_RECEIVABLE.getLink(customer.id)}
                variant="contained"
                color="success"
                size="large"
              >
                Nova cobrança
              </Button>
            )}
            <CustomerInline
              c={customer}
              cgroups={groups}
              cgroupsCount={groups.length}
              selectCgroup={(cgroup) =>
                cgroup ? cgroupNavigate(cgroup.id) : cgroupNavigate(null)
              }
              selectedCgroup={
                cgroups.find((cgroup) => cgroup.id === cgroupId) || null
              }
              style={{ display: "flex", flexDirection: "row-reverse" }}
            />
          </CardActions>
        )}
      </CardActionArea>
    </Card>
  );
}
