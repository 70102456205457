import React from "react";
import { Switch } from "react-router-dom";
import SearchContainer from "../containers/Search";
import { ProtectedRoute } from "./ProtectedRoute";

import Overview from "src/modules/overview";
import Onboard from "../containers/OnboardTypeform";
import NewOnboard from "src/modules/onboard";
import Customers from "src/modules/customers";
// import Receivables from "../containers/Receivables";
import { BillingsModule } from "src/modules/billings";
import Finance from "../containers/Finance";
import Referral from "../containers/ReferralTypeform";
import BillingSetup from "../containers/BillingSetup";
import FirstBilling from "../containers/FirstBilling";

export function Routes() {
  return (
    <Switch>
      <ProtectedRoute path="/" exact component={Overview} />
      <ProtectedRoute path="/ativacao" component={Onboard} />
      <ProtectedRoute path="/new-onboard" component={NewOnboard} />
      <ProtectedRoute
        exact
        path={[
          "/clientes",
          "/clientes/grupo/:cgroupId",
          "/clientes/:customerId",
          "/clientes/:customerId/:module",
          "/clientes/:customerId/:module/:id",
          "/clientes/:customerId/:module/:id/:page",
        ]}
        component={Customers}
      />
      <ProtectedRoute
        exact
        path={[
          "/cobrancas",
          "/cobrancas/:billingId",
          "/cobrancas/:billingId/:module",
          "/cobrancas/:billingId/:module/:id",
          "/cobrancas/:billingId/:module/:id/:page",
        ]}
        component={BillingsModule}
      />
      <ProtectedRoute exact path="/financeiro" component={Finance} />
      <ProtectedRoute
        exact
        path="/procurar"
        component={SearchContainer}
        layout={false}
      />
      <ProtectedRoute exact path="/indicar" component={Referral} />
      <ProtectedRoute exact path="/fatura" component={BillingSetup} />
      <ProtectedRoute
        exact
        path="/primeira-cobranca"
        component={FirstBilling}
      />
    </Switch>
  );
}
