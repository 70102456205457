import { gql } from 'apollo-boost'

import BankAccountProps from '../fragments/bank_account'

const GQL_BANK_ACCOUNTS = gql`
  query BankAccounts {
    bankAccounts {
      ...BankAccountProps
    }
  }
  ${BankAccountProps}
`

export default GQL_BANK_ACCOUNTS