import React from "react";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Typography,
} from "@plipag/ceci-ui";
import PriceTag from "src/uikit/PriceTag";
import { Popup } from "semantic-ui-react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaExternalLinkAlt } from "react-icons/fa";
import { CustomersContext } from "../../context";

type Props = {
  billings: Array<{ [key: string]: any }>;
};

export function BillingSuccess({ billings }: Props) {
  const { items: customers } = React.useContext(CustomersContext);
  return (
    <>
      <Typography sx={{ p: 3 }}>
        Cobrança de <PriceTag value={billings[0]?.amountBilled} />
        <br />
        com vencimento para{" "}
        <Popup
          trigger={
            <span>
              {moment(billings[0]?.dueDate)
                .format("DD[ de ]MMMM")
                .toLowerCase()}
            </span>
          }
          inverted
          position="bottom center"
          content={moment(billings[0]?.dueDate).fromNow()}
        />
      </Typography>
      <Typography>
        Acesse individualmente as cobranças de cada cliente:
      </Typography>
      <List sx={{ m: 1, mt: 2, mb: 2 }}>
        {billings.map((billing: { [key: string]: any }) => (
          <ListItem disableGutters disablePadding key={billing.id}>
            <ListItemButton
              component={Link}
              to={`/clientes/${billing.customerId}/cobrancas/${billing.id}`}
              target="_blank"
            >
              <ListItemIcon sx={{ minWidth: 30 }}>
                <FaExternalLinkAlt />
              </ListItemIcon>
              <ListItemText
                primary={
                  (customers.find((c) => c.id === billing.customerId) || {})
                    .name
                }
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </>
  );
}
