import React from "react";

import { Redirect } from "react-router-dom";

import { Mutation } from "react-apollo";

import moment from "moment";

import _ from "lodash";

import { routes } from "src/components/Customer/routes";

import toDecimal from "src/utils/decimal";

import { Message, Grid, Button } from "semantic-ui-react";

import { FaCheckCircle } from "react-icons/fa";

import analytics from "src/plg/Segment";

import Input from "src/uikit/Forms/Input";

import DatePicker from "react-semantic-ui-datepickers";

import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";

import GQL_CREATE_RECEIVEMENT from "src/graphql/mutations/CreateReceivement.gql";

import BillingProps from "src/graphql/fragments/billing";

import GQL_BILLINGS from "src/graphql/queries/Billings.gql";

import GQL_BILLINGS_CUSTOMERS from "src/graphql/queries/BillingsCustomers.gql";

class CreateBilling extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,

      description: "",

      customerId: this.props.customer.id,

      amountBilled: 0,

      amountBilledFace: 0,

      amountPaid: 0,

      amountPaidFace: 0,

      dueDate: "",

      payDay: "",
    };

    this.goto = this.goto.bind(this);

    this.handleDescription = this.handleDescription.bind(this);

    this.handleAmountBilled = this.handleAmountBilled.bind(this);

    this.handleAmountPaid = this.handleAmountPaid.bind(this);

    this.changePayDay = this.changePayDay.bind(this);

    this.changeDueDate = this.changeDueDate.bind(this);

    this.submittable = this.submittable.bind(this);

    this.getVars = this.getVars.bind(this);

    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);

    this.handleMutationComplete = this.handleMutationComplete.bind(this);

    this.handleMutationError = this.handleMutationError.bind(this);
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleAmountBilled(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amountBilled: floatValue,

      amountBilledFace: decimalValue,
    });
  }

  handleAmountPaid(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amountPaid: floatValue,

      amountPaidFace: decimalValue,
    });
  }

  changeDueDate(_, data) {
    this.setState({
      dueDate: data.value,
    });
  }

  changePayDay(_, data) {
    this.setState({
      payDay: data.value,
    });
  }

  submittable() {
    return (
      this.state.dueDate &&
      this.state.payDay &&
      this.state.amountBilled > 0 &&
      this.state.amountPaid > 0
    );
  }

  getVars() {
    return {
      customerId: this.state.customerId,

      description: this.state.description,

      amountBilled: this.state.amountBilled,

      amountPaid: this.state.amountPaid,

      dueDate: this.state.dueDate,

      payDay: this.state.payDay,
    };
  }

  handleMutationUpdate(cache, { data: { createBilling } }) {
    cache.writeFragment({
      id: `Billing:${createBilling.id}`,

      fragment: BillingProps,

      data: Object.assign(createBilling, {
        __typename: "Billing",
      }),
    });

    cache.writeQuery({
      query: GQL_BILLINGS,

      variables: { customersIds: [createBilling.customerId] },

      data: {
        billings: _.concat(
          cache.readQuery({
            query: GQL_BILLINGS,

            variables: { customerIds: [createBilling.customerId] },
          }).billings,

          createBilling
        ),
      },
    });
  }

  handleMutationComplete(resp) {
    const {
      createReceivement: { id },
    } = resp;

    analytics.track("Receivement Created", {
      from: "Billing Modal",

      amount: this.state.amountBilled,
    });

    this.goto(routes.RECEIVABLE.getLink(this.props.customer.id, id));
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  renderError(error) {
    if (!error) return null;

    return (
      <Message
        header="Dados de cobrança inválidos."
        negative
        list={error.graphQLErrors.map((e) => e.message)}
      />
    );
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <Mutation
        mutation={GQL_CREATE_RECEIVEMENT}
        variables={this.getVars()}
        onCompleted={this.handleMutationComplete}
        onError={this.handleMutationError}
        refetchQueries={[
          {
            query: GQL_BILLINGS,

            variables: { customerIds: [this.props.customer.id] },
          },

          {
            query: GQL_BILLINGS_CUSTOMERS,

            variables: {
              startDueDate: moment(this.state.dueDate)
                .startOf("month")

                .format("YYYY-MM-DD"),

              endDueDate: moment(this.state.dueDate)
                .endOf("month")

                .format("YYYY-MM-DD"),
            },
          },
        ]}
      >
        {(createBilling, { loading, error, data }) => {
          return (
            <Grid centered>
              {error && (
                <Grid.Row columns={1} centered>
                  <Grid.Column width={16}>
                    {this.renderError(error)}
                  </Grid.Column>
                </Grid.Row>
              )}

              {this.props.customer.status !== "ACTIVE" && (
                <Grid.Row columns={1} centered>
                  <Grid.Column width={14}>
                    <Message warning>
                      <Message.Header>
                        Cliente{" "}
                        <span className="font-weight-extra-bold">
                          arquivado
                        </span>
                      </Message.Header>

                      <Message.Content>
                        Um cliente arquivado não pode emitir novas cobranças.
                      </Message.Content>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )}

              <Grid.Row columns={1} centered>
                <Grid.Column width={9}>
                  <Input
                    type="decimal"
                    placeholder="Valor da cobrança"
                    onChange={this.handleAmountBilled}
                    value={this.state.amountBilledFace}
                    textAlign="center"
                    autofocus
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={14} textAlign="center">
                  <DatePicker
                    onChange={this.changeDueDate}
                    value={this.state.dueDate}
                    placeholder="Data de Vencimento"
                    format="DD/MM/YYYY"
                    locale="pt-BR"
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1} centered>
                <Grid.Column width={14}>
                  <Input
                    value={this.state.description}
                    onChange={this.handleDescription}
                    placeholder="Descrição"
                    showClearBtn
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row>
                <Grid.Column width={14} textAlign="center">
                  <DatePicker
                    onChange={this.changePayDay}
                    value={this.state.payDay}
                    placeholder="Data do Pagamento"
                    format="DD/MM/YYYY"
                    locale="pt-BR"
                    maxDate={new Date()}
                    fluid
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row columns={1} centered>
                <Grid.Column width={9}>
                  <Input
                    type="decimal"
                    placeholder="Valor Pago"
                    onChange={this.handleAmountPaid}
                    value={this.state.amountPaidFace}
                    textAlign="center"
                    autofocus
                  />
                </Grid.Column>
              </Grid.Row>

              <Grid.Row centered>
                <Grid.Column>
                  <Button
                    primary
                    size="large"
                    fluid
                    onClick={createBilling}
                    disabled={loading || !this.submittable()}
                  >
                    {loading ? (
                      "Carregando..."
                    ) : (
                      <div>
                        <FaCheckCircle />

                        <span className="a-m ml-2">Registrar Recebimento </span>
                      </div>
                    )}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Mutation>
    );
  }
}

export default CreateBilling;
