import React from "react";
import { Mutation } from "react-apollo";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { Button, Container, Grid, Header, Image } from "semantic-ui-react";
import { MdEmail, MdNavigateNext, MdPerson } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import Input from "src/uikit/Forms/Input";

import { getFeatures, saveFeatures } from "src/plg/profile";
import analytics, { trackf } from "src/plg/Segment";
import { validEmail } from "src/utils/email";
import toDecimal from "src/utils/decimal";

import style from "./FirstBilling.module.css";

import GQL_BILLING_CREATE_FAKE from "src/graphql/mutations/BillingCreateFake.gql";
import GQL_GET_FAKE_BILLING_ISSUED from "src/graphql/queries/FakeBillingIssued.gql";
import GQL_BILLING_FAKE_ISSUED from "src/graphql/queries/BillingFakeIssued.gql";

const splash = `https://assets.plipag.com.br/conta/images/illustrations/3255469-splash-girl-rocket-purple.jpg`;

const pages = {
  INITIAL: "INITIAL",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
};

class FirstBilling extends React.Component {
  constructor(props) {
    super(props);

    const initialAmount = 0;

    this.state = {
      page: pages.INITIAL,

      name: getFeatures()["merchant"],
      email: getFeatures()["email"],
      amount: 0, // initialAmount,
      amountFace: null, // toDecimal(initialAmount).decimalValue,
      validName: (getFeatures()["merchant"] || "").trim().length > 3,
      validEmail: validEmail(getFeatures()["email"]),
      validAmount: initialAmount > 0,
      url: null,
    };

    this.handleName = this.handleName.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleAmount = this.handleAmount.bind(this);
    this.setPage = this.setPage.bind(this);
    this.setUrl = this.setUrl.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
  }

  setPage(page) {
    this.setState({ page });
  }

  setUrl(url) {
    this.setState({ url });
  }

  handleName(e) {
    this.setState({
      name: e.target.value,
      validName: (e.target.value || "").trim().length > 3,
    });
  }

  handleEmail(e) {
    this.setState({
      email: e.target.value,
      validEmail: validEmail(e.target.value || ""),
    });
  }

  handleAmount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amount: floatValue,
      amountFace: decimalValue,
      validAmount: floatValue > 0,
    });
  }

  checkValid() {
    return (
      this.state.validName && this.state.validEmail && this.state.validAmount
    );
  }

  getVars() {
    return {
      name: this.state.name,
      email: this.state.email,
      amount: this.state.amount,
    };
  }

  handleMutationComplete(data) {
    const {
      createFakeBilling: { urlPreview },
    } = data;
    const { amount } = this.getVars();

    analytics.track("Fake Billing Issued", { amount: amount });

    saveFeatures({ fakeBillingIssued: true });

    this.setUrl(urlPreview);
    this.setPage(pages.SUCCESS);
  }

  handleMutationError(error) {}

  renderHead() {
    return (
      <div className={style.HeadInfo}>
        <div className="mt-3 mb-2 font-weight-bold h1 dark-grey">
          Cobrança teste
        </div>
        <div className="darker-grey">
          Vamos emitir uma cobrança teste para você mesmo, para que veja como é
          rápido e fácil usar o PLI.
        </div>
      </div>
    );
  }

  renderForm() {
    return (
      <div className={style.Form}>
        <div className="mt-5">
          <Input
            type="decimal"
            placeholder="Valor cobrado"
            onChange={this.handleAmount}
            value={this.state.amountFace}
            textAlign="center"
            fontSize="large"
            autofocus
          />
        </div>
        <div className="mt-3">
          <Input
            value={this.state.name}
            onChange={this.handleName}
            icon={<MdPerson />}
            placeholder="Nome"
            disabled={false}
            autoComplete={false}
            showClearBtn
          />
        </div>
        <div className="mt-3">
          <Input
            value={this.state.email}
            onChange={this.handleEmail}
            icon={<MdEmail />}
            invalidMessage="Este email não existe, as mensagens provavelmente não serão entregues"
            placeholder="Email"
            type="email"
            autoComplete={false}
            showClearBtn
          />
        </div>
      </div>
    );
  }

  renderCTA() {
    return (
      <Mutation
        mutation={GQL_BILLING_CREATE_FAKE}
        variables={this.getVars()}
        onCompleted={this.handleMutationComplete}
        onError={this.handleMutationError}
        refetchQueries={[
          { query: GQL_GET_FAKE_BILLING_ISSUED },
          { query: GQL_BILLING_FAKE_ISSUED },
        ]}
      >
        {(createBilling, { loading, error, data }) => {
          const trigger = this.checkValid() ? createBilling : () => {};

          return (
            <div className="pt-5">
              <Button
                primary
                size="large"
                fluid
                onClick={trigger}
                disabled={!this.checkValid()}
              >
                <FaCheckCircle />
                <span className="a-m ml-2">Cobrar</span>
              </Button>
            </div>
          );
        }}
      </Mutation>
    );
  }

  renderInitial() {
    return (
      <Container>
        <Grid stackable container relaxed>
          {!window || !window.innerWidth || window.innerWidth >= 992 ? (
            <Grid.Row>
              <Grid.Column width={8}>
                {this.renderHead()}
                {this.renderForm()}
                {this.renderCTA()}
              </Grid.Column>
              <Grid.Column width={8}>
                <Image src={splash} className={style.Splash} />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <Grid.Row>
              <Grid.Column width={16}>
                {this.renderHead()}
                {this.renderForm()}
                {this.renderCTA()}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }

  renderError() {}

  renderSuccess() {
    return (
      <div className={style.Success}>
        <Grid centered className="pb-4 mt-5">
          <Grid.Row columns={1}>
            <Grid.Column width={8} textAlign="center">
              <FaCheckCircle className={style.SuccessIcon} />
              <Header size="large" color="green">
                Feito!
                <Header.Subheader className="mt-2">
                  Cobrança emitida, confira o seu email para ver como enviamos
                  as faturas e automatizamos os seus recebimentos.
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={8} textAlign="center">
              <ul className={classNames("vertical", style.List)}>
                <li className={classNames(style.ListItem, "fadeInUp")}>
                  <a
                    href={this.state.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classNames(style.ListItemLink)}
                  >
                    <Button
                      secondary
                      size="big"
                      onClick={trackf("Fake Billing Opened")}
                    >
                      Abrir fatura
                    </Button>
                  </a>
                </li>
              </ul>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={8} textAlign="center">
              <Header size="large" color="green">
                <Header.Subheader className="mt-2">
                  Ative agora a sua Conta PLIPAG para gerenciar os seus clientes
                  e simplificar as suas cobranças. É rápido e grátis.
                </Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column width={8} textAlign="center">
              <ul className={classNames("vertical", style.List)}>
                <li className={classNames(style.ListItem, "fadeInUp")}>
                  <Link
                    to="/ativacao"
                    className={classNames(style.ListItemLink)}
                  >
                    <Button
                      primary
                      size="large"
                      onClick={() =>
                        analytics.track("Activation Started", {
                          from: "Fake Billing",
                        })
                      }
                    >
                      Ativar minha conta
                      <MdNavigateNext size={14} className="a-m ml-4" />
                    </Button>
                  </Link>
                </li>
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }

  render() {
    switch (this.state.page) {
      case pages.INITIAL:
        return this.renderInitial();
      case pages.ERROR:
        return this.renderError();
      case pages.SUCCESS:
        return this.renderSuccess();
      default:
        break;
    }
  }
}

export default FirstBilling;
