import { gql } from 'apollo-boost'

import RecurrenceProps from '../fragments/recurrence'

const GQL_RECURRENCE_UNARCHIVE = gql`
  mutation UnarchiveRecurrence($id: ID!) {
    unarchiveRecurrence(id: $id) {
      ...RecurrenceProps
    }
  }
  ${RecurrenceProps}
`

export default GQL_RECURRENCE_UNARCHIVE