import React from "react";

export type Customer = {
  id: number;
  name: string;
  nickname: string;
  status: string;
  createdAt: string;
};

export type OrdersKeys = "nickname" | "name" | "older" | "newer";
export type FiltersKeys = "ACTIVE" | "ARCHIVED";

export type Cgroup = {
  id: string;
  name: string;
  icon: string;
  customers: Array<Customer>;
};

export type State = {
  limit: number;
  page: number;
  cgroupId: string | null;
  items: Array<Customer>;
  selecteds: Array<number>;
  cgroups: Array<Cgroup>;
  filter: FiltersKeys;
  order: OrdersKeys;
};

export type CustomerContextType = State & {
  setItems: (items: Array<Customer>) => void;
  setLimit: (limit: number) => void;
  setSelecteds: (selecteds: Array<number>) => void;
  toggleItem: (id: number) => void;
  setCgroups: (cgroups: Array<Cgroup>) => void;
  setFilter: (filter: FiltersKeys) => void;
  setOrder: (order: OrdersKeys) => void;
};

export const CustomersContext = React.createContext<CustomerContextType>({
  limit: 50,
  setLimit: () => {},
  page: 1,
  cgroupId: null,
  items: [],
  setItems: () => {},
  selecteds: [],
  setSelecteds: () => {},
  toggleItem: () => {},
  cgroups: [],
  setCgroups: () => {},
  filter: "ACTIVE",
  setFilter: () => {},
  order: "older",
  setOrder: () => {},
});
