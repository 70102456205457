import { useQuery } from "@apollo/react-hooks";
import GQL_BILLING from "src/graphql/queries/Billing.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import Receivable from "src/components/Receivable";

export function BillingDetails({ id, page }: { id: string; page: string }) {
  const { data, loading, error } = useQuery(GQL_BILLING, { variables: { id } });
  if (loading) {
    return <OrbitsLoader />;
  }

  if (error) {
    console.error(error);
    return null;
  }

  const { billing } = data;

  return <Receivable billing={billing} page={page} />;
}
