import { gql } from "apollo-boost";

import BillingProps from "../fragments/billing";

const GQL_CREATE_RECEIVEMENT = gql`
  mutation CreateReceivement(
    $customerId: ID!
    $dueDate: String!
    $amountBilled: Float!
    $description: String
    $amountPaid: Float!
    $payDay: String!
  ) {
    createReceivement(
      customerId: $customerId
      dueDate: $dueDate
      amountBilled: $amountBilled
      description: $description
      amountPaid: $amountPaid
      statusDate: $payDay
    ) {
      ...BillingProps
    }
  }
  ${BillingProps}
`;

export default GQL_CREATE_RECEIVEMENT;
