import { gql } from 'apollo-boost'

const GQL_CHANGE_PASSWORD = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changePassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      id
    }
  }
`

export default GQL_CHANGE_PASSWORD