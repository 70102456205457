import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import _ from "lodash";

import { Button } from "semantic-ui-react";
import { MdNavigateBefore } from "react-icons/md";
import Input from "src/uikit/Forms/Input";

import { formatZipcode, strippedZipcode } from "src/utils/address";
import { addressZipcode } from "src/utils/enrichment";
import { routes } from "../../routes";

import style from "./EditAddress.module.css";

import GQL_CUSTOMER_UPDATE_ADDRESS from "src/graphql/mutations/CustomerUpdateAddress.gql";

export default class EditAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,

      zipcode: "",
      streetPt1: "",
      streetPt2: "",
      street: "",
      district: "",
      city: "",
      province: "",
    };

    this.handleZipcode = this.handleZipcode.bind(this);
    this.handleStreetPt2 = this.handleStreetPt2.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
  }

  handleZipcode(e) {
    this.setState(
      {
        zipcode: formatZipcode(e.target.value),
      },
      () => {
        const z = strippedZipcode(this.state.zipcode);
        if (z.length !== 8) return null;

        addressZipcode(z)
          .then(
            ({
              data: {
                data: { street, district, city, province },
              },
            }) => {
              this.setState({
                streetPt1: street,
                street,
                district,
                city,
                province,
              });
            }
          )
          .catch(console.log);
      }
    );
  }

  handleStreetPt2(e) {
    this.setState(
      {
        streetPt2: String(e.target.value)
          .replace(new RegExp(/[^A-Za-z0-9, ]/, "g"), "")
          .substr(0, 32),
      },
      () => {
        const pt2 =
          String(this.state.streetPt2).length > 0
            ? `, ${this.state.streetPt2}`
            : "";
        this.setState({
          street: `${this.state.streetPt1}${pt2}`,
        });
      }
    );
  }

  getVars() {
    return {
      id: this.props.customer.id,
      street: this.state.street,
      district: this.state.district,
      city: this.state.city,
      province: this.state.province,
      zipcode: this.state.zipcode,
    };
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  handleMutationComplete(response) {
    this.setState({
      redirectTo: routes.CUSTOMER.getLink(this.props.customer.id),
    });
  }

  submittable() {
    return (
      String(this.state.street).length > 5 &&
      String(this.state.district).length > 2 &&
      String(this.state.city).length > 2 &&
      String(this.state.province).length === 2 &&
      String(this.state.zipcode).length >= 8 &&
      String(this.state.streetPt2).length > 0
    );
  }

  showAddress() {
    if (
      String(this.state.street).length <= 5 ||
      String(this.state.city).length <= 2 ||
      String(this.state.province).length !== 2 ||
      String(this.state.zipcode).length <= 7
    ) {
      return <div>Nenhum endereço cadastrado.</div>;
    }

    return (
      <div>
        {this.state.street}
        <br />
        {this.state.district}
        <br />
        {this.state.city} - {this.state.province}
        <br />
        {this.state.zipcode}
      </div>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div>
        <div>
          <MdNavigateBefore
            className={style.BackBtn}
            onClick={() => this.props.changePage("SHOW")}
          />
        </div>
        <div className={style.FieldSep}>
          <ul className="horizontal">
            <li style={{ width: "35%", marginRight: "10px" }}>
              <Input
                value={this.state.zipcode}
                onChange={this.handleZipcode}
                placeholder="CEP"
                textAlign="center"
                autoComplete={false}
              />
            </li>
            <li style={{ width: "60%" }}>
              <Input
                value={this.state.streetPt2}
                onChange={this.handleStreetPt2}
                placeholder="Número, complemento"
                textAlign="center"
                autoComplete={false}
              />
            </li>
          </ul>
        </div>
        <div className={style.FieldSep}>{this.showAddress()}</div>
        <div className={style.FieldSep}>
          <Mutation
            mutation={GQL_CUSTOMER_UPDATE_ADDRESS}
            variables={this.getVars()}
            onError={this.handleMutationError}
            onCompleted={this.handleMutationComplete}
          >
            {(updateCustomer, { loading }) => (
              <Button
                primary
                size="large"
                fluid
                onClick={updateCustomer}
                disabled={loading || !this.submittable()}
              >
                Salvar endereço
              </Button>
            )}
          </Mutation>
        </div>
      </div>
    );
  }
}
