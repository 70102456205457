import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'

const GQL_CREATE_CGROUP = gql`
  mutation CreateCgroup(
    $name: String!,
    $color: String,
    $icon: String!
  ) {
    createCgroup(name: $name, color: $color, icon: $icon) {
      ...CgroupProps
    }
  }
  ${CgroupProps}
`

export default GQL_CREATE_CGROUP