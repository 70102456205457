import React from "react";
import { Tooltip as CeciTooltip, Typography } from "@plipag/ceci-ui";

export const Tooltip = ({ title = "", content, children, ...props }) => (
  <CeciTooltip
    title={
      <>
        {title !== "" && <Typography variant="h6">{title}</Typography>}
        <Typography>{content}</Typography>
      </>
    }
    arrow={true}
    {...props}
  >
    {children}
  </CeciTooltip>
);

export default Tooltip;
