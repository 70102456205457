import React from "react";
import ReactHighcharts from "react-highcharts";
import chartConfig from "./config.json";
import axios from "axios";
import { getToken } from "src/app/token";
import { Skeleton } from "@plipag/ceci-ui";

function config(data) {
  const colors = {
    positive: "#4CACC5",
    negative: "#444444",
  };

  return {
    ...chartConfig,

    xAxis: {
      categories: data.categories,
    },
    series: [
      {
        name: "Recebimentos",
        data: data.receivables,
        color: colors.positive,
      },
      {
        name: "Cobranças vencidas",
        data: data.pastDue,
        color: colors.negative,
      },
    ],
    credits: { enabled: false },
    height: 480,
    width: "100%",
  };
}

const RevenueChart = () => {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_FUNCTIONS_URL
        }/revenue-chart/?token=${getToken()}`
      )
      .then((response) => setData(response.data))
      .catch((error) => console.error(error));
  }, []);
  if (window.innerWidth <= 480) return null;

  if (!data)
    return (
      <div style={{ height: 480 }}>
        <Skeleton
          variant="rect"
          height={440}
          width="calc(100% - 40px)"
          style={{ margin: 20 }}
        />
      </div>
    );

  return (
    <div style={{ height: 480 }}>
      <ReactHighcharts height={480} config={config(data)} />
    </div>
  );
};

export default RevenueChart;
