import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'
import CustomerProps from '../fragments/customer'

const GQL_CGROUP_CUSTOMERS = gql`
  query CgroupCustomers($id: ID!) {
    cgroup(id: $id) {
      ...CgroupProps
      customers {
        ...CustomerProps
      }
    }
  }
  ${CustomerProps}
  ${CgroupProps}
`

export default GQL_CGROUP_CUSTOMERS