import React from "react";

import { Tab } from "semantic-ui-react";

import { routes } from "../../routes";
import tabs from "./tabs";

import style from "./CustomerTabs.module.css";

export default function a({ activeTab, customer, goto }) {
  return (
    <div className={style.Tab}>
      <Tab
        menu={{ pointing: true, secondary: true }}
        panes={[
          { menuItem: "Cadastro", render: () => {} },
          { menuItem: "Endereço", render: () => {} },
          { menuItem: "Anotações", render: () => {} },
        ]}
        activeIndex={activeTab}
        onTabChange={(e, data) => {
          if (activeTab === data.activeIndex) return null;

          let urn;
          switch (data.activeIndex) {
            case tabs.CARD: // Cadastro
              urn = routes.CUSTOMER.getLink(customer.id);
              break;
            case tabs.ADDRESS: // Endereço
              urn = routes.CUSTOMER_ADDRESS.getLink(customer.id);
              break;
            case tabs.NOTES: // Anotações
              urn = routes.CUSTOMER_NOTES.getLink(customer.id);
              break;
            default:
              urn = routes.CUSTOMER.getLink(customer.id);
              break;
          }

          goto(urn);
        }}
      />
    </div>
  );
}
