import React from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";

import { routes } from "../../routes";
import { recurrenceListable } from "../../../RecurrenceFields/scopes";
import { billingWasIssued } from "../../../BillingFields/scopes";
import { mdayText } from "../../../RecurrenceFields/misc";
import { getUiPref, saveUiPref } from "src/utils/uiPreferences";

import { Grid, Header, Popup } from "semantic-ui-react";
import {
  WiMoonNew as Moon0,
  WiMoonWaxingCrescent1 as Moon1,
  WiMoonWaxingCrescent2 as Moon2,
  WiMoonWaxingCrescent3 as Moon3,
  WiMoonWaxingCrescent4 as Moon4,
  WiMoonWaxingCrescent5 as Moon5,
  WiMoonFirstQuarter as Moon7,
  WiMoonWaxingGibbous1 as Moon8,
  WiMoonWaxingGibbous2 as Moon9,
  WiMoonWaxingGibbous3 as Moon10,
  WiMoonWaxingGibbous4 as Moon11,
  WiMoonWaxingGibbous5 as Moon12,
  WiMoonFull as Moon14,
  WiEarthquake as MoonRepeat,
} from "react-icons/wi";

import PriceTag from "src/uikit/PriceTag";

import style from "./InlineRecurrences.module.css";

import GQL_RECURRENCES_BILLINGS from "src/graphql/queries/RecurrencesBillings.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

class InlineRecurrences extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showArchived: getUiPref("InlineRecurrencesShowArchived", false),
    };

    this.handleArchived = this.handleArchived.bind(this);
  }

  handleArchived(e) {
    this.setState(
      {
        showArchived: !this.state.showArchived,
      },
      () => {
        saveUiPref("InlineRecurrencesShowArchived", this.state.showArchived);
      }
    );
  }

  static moonProportion(consumed, total) {
    return Math.round((14 * consumed) / total);
  }

  static findMoon(consumed, total) {
    let moon;

    if (!total || total === 0) moon = <MoonRepeat className={style.Moon} />;
    else
      switch (InlineRecurrences.moonProportion(consumed || 0, total)) {
        case 0:
          moon = <Moon0 className={style.Moon} />;
          break;
        case 1:
          moon = <Moon1 className={style.Moon} />;
          break;
        case 2:
          moon = <Moon2 className={style.Moon} />;
          break;
        case 3:
          moon = <Moon3 className={style.Moon} />;
          break;
        case 4:
          moon = <Moon4 className={style.Moon} />;
          break;
        case 5:
          moon = <Moon5 className={style.Moon} />;
          break;
        case 6:
          moon = <Moon5 className={style.Moon} />;
          break;
        case 7:
          moon = <Moon7 className={style.Moon} />;
          break;
        case 8:
          moon = <Moon8 className={style.Moon} />;
          break;
        case 9:
          moon = <Moon9 className={style.Moon} />;
          break;
        case 10:
          moon = <Moon10 className={style.Moon} />;
          break;
        case 11:
          moon = <Moon11 className={style.Moon} />;
          break;
        case 12:
        case 13:
          moon = <Moon12 className={style.Moon} />;
          break;
        case 14:
          moon = <Moon14 className={style.Moon} />;
          break;
        default:
          moon = <MoonRepeat className={style.Moon} />;
          break;
      }

    return (
      <div className={style.MoonStripe}>
        <Popup
          trigger={moon}
          inverted
          header={total > 0 ? `${total} cobranças` : `Repetir todos os meses`}
          content={
            total > 0
              ? `${consumed} das ${total} cobranças geradas`
              : `${consumed} cobranças geradas`
          }
        />
      </div>
    );
  }

  static mdayStripe(r) {
    return <div className={style.StatusStripe}>{mdayText(r, true)}</div>;
  }

  static countIssuedBillings(billings) {
    return _.size(_.filter(billings, billingWasIssued));
  }

  renderItem(r) {
    return (
      <Link
        to={routes.RECURRENCE.getLink(this.props.customerId, r.id)}
        className={style.Link}
      >
        <div className={classNames(style.Item, style.ACTIVE)}>
          <div className={style.PriceTag}>
            <PriceTag
              value={r.amount}
              smallFont={false}
              smallCents={true}
              color="#FFFFFF"
            />
          </div>
          {InlineRecurrences.mdayStripe(r)}
          {InlineRecurrences.findMoon(
            InlineRecurrences.countIssuedBillings(r.billings),
            r.totalCycles
          )}
        </div>
      </Link>
    );
  }

  render() {
    return (
      <Query
        query={GQL_RECURRENCES_BILLINGS}
        variables={{ customerIds: [this.props.customerId] }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          if (_.isEmpty(data.recurrences)) return null;

          const anyArchived = !_.isEmpty(
            _.filter(data.recurrences, (r) => r.archived)
          );
          const recurrences = _.filter(data.recurrences, (r) =>
            recurrenceListable(r, this.state.showArchived)
          );
          const items = _.map(recurrences, (r) => (
            <li key={_.uniqueId()}>{this.renderItem(r)}</li>
          ));

          return (
            <Grid centered>
              <Grid.Row columns={1} className="pb-0">
                <Grid.Column width={16}>
                  <Header className="mb-0 pb-0">
                    Mensalidades
                    {anyArchived && (
                      <div
                        className={style.ShowArchived}
                        onClick={this.handleArchived}
                      >
                        {this.state.showArchived
                          ? "Esconder arquivadas"
                          : "Mostrar todas"}
                      </div>
                    )}
                  </Header>
                  {!_.isEmpty(recurrences) && (
                    <div>
                      <ul className="horizontal">{items}</ul>
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Query>
    );
  }
}

export default InlineRecurrences;
