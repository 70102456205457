import React from "react";

import FirstBilling from "../../components/FirstBilling";

class FirstBillingContainer extends React.Component {
  state = {};

  static getDerivedStateFromProps(props, state) {
    return {};
  }

  render() {
    document.title = "Primeira cobrança | PLI";

    return <FirstBilling />;
  }
}

export default FirstBillingContainer;
