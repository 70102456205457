import React from "react";
import { Mutation } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import _ from "lodash";
import classNames from "classnames";

import { routes } from "../../routes";
import settings from "src/app/settings";
import toDecimal, { floatToDecimal } from "src/utils/decimal";
import { whatsAppApi } from "src/utils/whatsapp";
import {
  billingOneValue,
  billingOneValueDescription,
} from "../../../BillingFields/oneValue";
import { billingIsDestroyable } from "../../../BillingFields/scopes";

import InlineEdit from "@atlaskit/inline-edit";
import { Message, Popup, Checkbox, Grid } from "semantic-ui-react";
import { MdInfo, MdEdit } from "react-icons/md";
import { FaPaperPlane } from "react-icons/fa";

import Input from "src/uikit/Forms/Input";
import Calendar from "src/uikit/Forms/Calendar";
import BillingStatus from "../../../BillingFields/BillingStatus";
import Amount from "./Amount";

import style from "./EditBilling.module.css";

import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";
import GQL_UPDATE_BILLING from "src/graphql/mutations/UpdateBilling.gql";
import GQL_DESTROY_OR_CANCEL_BILLING from "src/graphql/mutations/DestroyOrCancelBilling.gql";
import GQL_EXPRESS_BILLING from "src/graphql/mutations/ExpressBilling.gql";
import BillingProps from "src/graphql/fragments/billing";
import { PayModal } from "./PayModal";

class EditBilling extends React.Component {
  constructor(props) {
    super(props);

    const billing = this.props.billing || {};

    this.state = {
      redirectTo: null,

      id: billing.id,
      status: billing.status,
      amountBilled: billing.amountBilled,
      amountBilledFace: floatToDecimal(billing.amountBilled),
      daypickerDay: moment(billing.dueDate).toDate(),
      dueDate: moment(billing.dueDate).format("YYYY[-]MM[-]DD"),
      description: billing.description,
      interest: billing.interestPolicy !== "NO_INTEREST",
      interestPolicy: billing.interestPolicy,
      discountAmount: billing.discountAmount,
      discountAmountFace: floatToDecimal(billing.discountAmount),
      discountDays: billing.discountDays,
      discountPolicy: billing.discountPolicy,
      anticipateFlag: billing.anticipate !== null,
      anticipate: billing.anticipate,
      statusDate: billing.statusDate,
    };

    this.handleAmountBilled = this.handleAmountBilled.bind(this);
    this.daypickerClick = this.daypickerClick.bind(this);
    this.payDayClick = this.payDayClick.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleInterest = this.handleInterest.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.handleAnticipate = this.handleAnticipate.bind(this);
    this.submittable = this.submittable.bind(this);
    this.getVars = this.getVars.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleDestroyUpdate = this.handleDestroyUpdate.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.goto = this.goto.bind(this);
    this.renderExpressBilling = this.renderExpressBilling.bind(this);
  }

  handlePayBilling(cache, { data: { payBilling } }) {
    const id = this.props.billing.id;

    const customerId = this.props.billing.customerId;

    cache.writeFragment({
      id: `Billing:${id}`,

      fragment: BillingProps,

      data: Object.assign(payBilling, {
        __typename: "Billing",

        status: "PAID",
      }),
    });

    this.goto(routes.RECEIVABLES.getLink(customerId));
  }

  handleAmountBilled(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amountBilled: floatValue,
      amountBilledFace: decimalValue,
    });
  }

  daypickerClick(day, { selected, disabled }) {
    this.setState(
      {
        daypickerDay: selected || disabled ? undefined : day,
        dueDate:
          selected || disabled
            ? undefined
            : moment(day).format("YYYY[-]MM[-]DD"),
      },
      () => {
        if (
          this.state.dueDate &&
          moment(this.state.dueDate).diff(moment(), "days") <=
            settings.BILLING_EXPRESS_WINDOW
        )
          this.setState({
            anticipate: null,
          });
      }
    );
  }

  payDayClick(day, { selected, disabled }) {
    this.setState(
      {
        daypickerDay: selected || disabled ? undefined : day,
        payDay:
          selected || disabled
            ? undefined
            : moment(day).format("YYYY[-]MM[-]DD"),
      },
      () => {
        if (
          this.state.payDay &&
          moment(this.state.payDay).diff(moment(), "days") <=
            settings.BILLING_EXPRESS_WINDOW
        )
          this.setState({
            anticipate: null,
          });
      }
    );
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleInterest(value, callback) {
    const val = !this.state.interest;

    this.setState(
      {
        interest: val,
        interestPolicy: val ? "MARKET" : "NO_INTEREST",
      },
      callback
    );
  }

  handleDiscount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      discountAmount: floatValue,
      discountAmountFace: decimalValue,
      discountPolicy: floatValue > 0 ? "FIXED" : "NO_DISCOUNT",
    });
  }

  handleAnticipate(e) {
    const val = !this.state.anticipate;

    this.setState({
      anticipateFlag: val,
      anticipate: val ? true : null,
    });
  }

  submittable() {
    if (
      this.state.discountAmount > 0 &&
      this.state.amountBilled > 0 &&
      this.state.discountAmount >= this.state.amountBilled
    )
      return false;

    if (this.props.customer.status !== "ACTIVE") return false;

    return this.state.dueDate && this.state.amountBilled > 0;
  }

  getVars() {
    return {
      id: this.props.billing.id,
      customerId: this.state.customerId,
      amountBilled: this.state.amountBilled,
      dueDate: this.state.dueDate,
      description: this.state.description,
      interestPolicy: this.state.interestPolicy,
      discountAmount: this.state.discountAmount,
      discountDays: this.state.discountDays,
      discountPolicy: this.state.discountPolicy,
      anticipate: this.state.anticipate,
    };
  }

  handleMutationUpdate(cache, { data: { updateBilling } }) {
    cache.writeFragment({
      id: `Billing:${updateBilling.id}`,
      fragment: BillingProps,
      data: Object.assign(updateBilling, { __typename: "Billing" }),
    });
  }

  handleMutationComplete(resp) {}

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  handleDestroyUpdate(cache, { data: { destroyBilling } }) {
    const id = this.props.billing.id;
    const customerId = this.props.billing.customerId;
    cache.writeFragment({
      id: `Billing:${id}`,
      fragment: BillingProps,
      data: { ...destroyBilling, __typename: "Billing", status: "DELETED" },
    });

    this.goto(routes.RECEIVABLES.getLink(customerId));
  }

  renderError(errors) {
    if (!errors) return null;

    return (
      <Message negative>
        Erro, cheque se os dados são válidos e os campos estão preenchidos.
      </Message>
    );
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  renderExpressBilling(billing) {
    if (billing.status !== "PENDING") return null;

    if (
      billing.anticipate ||
      moment(billing.dueDate).diff(moment(), "days") <=
        settings.BILLING_EXPRESS_WINDOW
    )
      return (
        <Grid.Row columns={1}>
          <Grid.Column width={12} textAlign="center">
            <Message positive className="text-center">
              <Message.Header className="mb-2">
                Em processo de emissão
              </Message.Header>
              <Message.Content>
                A cobrança será emitida em breve e o cliente notificado.
              </Message.Content>
            </Message>
          </Grid.Column>
        </Grid.Row>
      );

    const lowerBound = moment(billing.dueDate)
      .subtract(settings.BILLING_EXPRESS_WINDOW, "days")
      .format("DD[/]MM");

    const upperBound = moment(billing.dueDate)
      .subtract(settings.BILLING_EXPRESS_WINDOW - 2, "days")
      .format("DD[/]MM");

    return (
      <Grid.Row columns={1}>
        <Grid.Column width={12} textAlign="center">
          <Mutation
            mutation={GQL_EXPRESS_BILLING}
            variables={{ id: billing.id }}
            onError={this.handleMutationError}
          >
            {(expressBilling, { loading, error, data }) => {
              if (loading) return null;
              if (error) return null;

              const express = () => {
                const message =
                  "A cobrança será emitida em breve. " +
                  "Ao prosseguir, não será possível reverter este processo. " +
                  "Continuar?";

                if (window.confirm(message)) expressBilling();

                this.setState({
                  anticipate: true,
                });
              };

              return (
                <Popup
                  trigger={
                    <FaPaperPlane
                      size={32}
                      className={style.Send}
                      onClick={express}
                    />
                  }
                  inverted
                  header="Cobrança expressa"
                  content={
                    <div>
                      <p>
                        A cobrança está programada para ser emitida conforme a
                        régua de cobrança inteligente entre os dias {lowerBound}{" "}
                        e {upperBound}.
                      </p>
                      <p>Para cobrá-lo, clique no botão.</p>
                    </div>
                  }
                />
              );
            }}
          </Mutation>
        </Grid.Column>
      </Grid.Row>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    if (!["CRM", "RECEIVABLES"].includes(this.props.mode)) {
      console.log("EditBilling: Mode not set");
      return null;
    }

    if (!this.state.id)
      return (
        <Redirect
          to={
            this.props.customerId
              ? routes.CUSTOMER.getLink(this.props.customerId)
              : "/clientes"
          }
        />
      );

    if (!["CRM", "RECEIVABLES"].includes(this.props.mode)) {
      console.log("EditBilling: Mode not set");
      return null;
    }

    const editPlaceholder = <MdEdit className={style.EditPlaceholder} />;
    const b = this.props.billing;
    const billing = this.state;

    const oneValue = billingOneValue(billing);

    const showOneValueTitle = ["OPEN_PAYMENT", "NO_PAYMENT"].includes(b.status);

    const showOneValue =
      showOneValueTitle && Math.abs(oneValue - b.amountBilled) >= 0.01;

    const url =
      this.props.billing.status === "PENDING"
        ? this.props.billing.urlPreview
        : this.props.billing.url;

    const showReceivementAmountPaid =
      b.status === "PAID" && b.amountPaid !== b.amount;

    console.log(billing, b);

    return (
      <Mutation
        mutation={GQL_UPDATE_BILLING}
        variables={this.getVars()}
        onCompleted={this.handleMutationComplete}
        onError={this.handleMutationError}
      >
        {(updateBilling, { loading, error, data }) => {
          return (
            <div className={style.ModalBody}>
              {this.props.mode === "CRM" && (
                <div className="mt-1 mb-3">
                  <ul className="vertical">
                    {this.props.billing.recurrenceId && (
                      <li>
                        <Link
                          to={routes.RECURRENCE.getLink(
                            this.props.billing.customerId,
                            this.props.billing.recurrenceId
                          )}
                          className={classNames(style.ListItemLink, "fadeInUp")}
                        >
                          &laquo; Ver mensalidade
                        </Link>
                      </li>
                    )}
                    <li>
                      <Link
                        to={routes.RECEIVABLES.getLink(
                          this.props.billing.customerId
                        )}
                        className={classNames(style.ListItemLink, "fadeInUp")}
                      >
                        &laquo; Mais cobranças deste cliente
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
              {this.props.mode === "RECEIVABLES" && (
                <div className="mt-1 mb-3">
                  <Link
                    to={routes.RECEIVABLES.getLink(
                      this.props.billing.customerId
                    )}
                    className={classNames(style.ListItemLink, "fadeInUp")}
                  >
                    &laquo; Mais cobranças deste cliente
                  </Link>
                </div>
              )}

              {this.renderError(error)}

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "text-center")}>
                  <BillingStatus b={this.props.billing} iconSize={48} />
                </div>
              </div>

              <div className="mt-3">
                <div
                  className={classNames(
                    style.FieldLabel,
                    "text-center",
                    "cursor-default"
                  )}
                >
                  Valor {showOneValueTitle && "/ valor atualizado"}
                  {showReceivementAmountPaid && "/ valor pago"}
                </div>
                <div>
                  <Grid centered verticalAlign="middle">
                    <Grid.Row
                      centered
                      textAlign="center"
                      columns={
                        showOneValue || showReceivementAmountPaid ? 2 : 1
                      }
                    >
                      <Amount
                        b={b}
                        billing={billing}
                        state={this.state}
                        handleAmountBilled={this.handleAmountBilled}
                        onConfirm={(value, _) => updateBilling()}
                        editPlaceholder={editPlaceholder}
                        showOneValue={showOneValue || showReceivementAmountPaid}
                        loading={loading}
                        width="auto"
                      />

                      {showReceivementAmountPaid && (
                        <Grid.Column
                          width={6}
                          textAlign="left"
                          className="pl-1"
                        >
                          <Popup
                            trigger={
                              <div
                                className={classNames(
                                  style.FieldView,
                                  style.AmountOne
                                )}
                              >
                                / {floatToDecimal(b.amountPaid)}
                              </div>
                            }
                            inverted
                            content={billingOneValueDescription(billing)}
                            position="top center"
                          />
                        </Grid.Column>
                      )}
                      {showOneValue && (
                        <Grid.Column
                          width={6}
                          textAlign="left"
                          className="pl-1"
                        >
                          <Popup
                            trigger={
                              <div
                                className={classNames(
                                  style.FieldView,
                                  style.AmountOne
                                )}
                              >
                                / {floatToDecimal(oneValue)}
                              </div>
                            }
                            inverted
                            content={billingOneValueDescription(billing)}
                            position="top center"
                          />
                        </Grid.Column>
                      )}
                    </Grid.Row>
                  </Grid>
                </div>
              </div>

              {b.statusDate !== null &&
                ["UNDERPAYMENT", "FULL_PAYMENT", "OVERPAYMENT"].includes(
                  b.status
                ) && (
                  <div className="mt-3">
                    <div
                      className={classNames(
                        style.FieldLabel,
                        "text-center",
                        "cursor-default"
                      )}
                    >
                      Data do pagamento
                    </div>
                    <div className="text-center">
                      <Popup
                        trigger={
                          <div
                            className={classNames(
                              style.DueDate,
                              "cursor-default"
                            )}
                          >
                            {b.statusDate
                              ? moment(b.statusDate).format("DD[/]MM[/]YYYY")
                              : null}
                          </div>
                        }
                        inverted
                        position="top center"
                        content={
                          <div>
                            <p>
                              Esta data é o dia útil em que pagamento foi
                              realizado.
                            </p>
                            <p>
                              Pagamentos realizados em finais de semana ou
                              feriados são considerados como pagos somente no
                              próximo dia útil.
                            </p>
                          </div>
                        }
                      />
                    </div>
                  </div>
                )}

              <div className="mt-3">
                <div
                  className={classNames(
                    style.FieldLabel,
                    "text-center",
                    "cursor-default"
                  )}
                >
                  Vencimento
                </div>
                <div className="text-center">
                  {b.status === "PENDING" ? (
                    <InlineEdit
                      defaultValue={
                        billing.dueDate
                          ? moment(billing.dueDate).format("DD[/]MM[/]YYYY")
                          : null
                      }
                      readView={() =>
                        loading && b.dueDate !== billing.dueDate ? (
                          "Carregando..."
                        ) : (
                          <div
                            className={classNames(
                              style.FieldView,
                              style.DueDate
                            )}
                          >
                            {(billing.dueDate
                              ? moment(billing.dueDate).format("DD[/]MM[/]YYYY")
                              : null) || editPlaceholder}
                          </div>
                        )
                      }
                      editView={(fieldProps) => (
                        <Calendar
                          onChange={this.daypickerClick}
                          value={moment(billing.dueDate).toDate()}
                          placeholder="Vencimento"
                          minDaysBillingAhead={settings.BILLING_MIN_DAYS_AHEAD}
                          maxMonthsAhead={12}
                        />
                      )}
                      onCancel={(_e) => {}}
                      onConfirm={(value, _) => updateBilling()}
                    />
                  ) : (
                    <div
                      className={classNames(style.DueDate, "cursor-default")}
                    >
                      {billing.dueDate
                        ? moment(billing.dueDate).format("DD[/]MM[/]YYYY")
                        : null}
                    </div>
                  )}
                </div>
              </div>

              {b.status === "PAID" && (
                <div className="mt-3">
                  <div
                    className={classNames(
                      style.FieldLabel,
                      "text-center",
                      "cursor-default"
                    )}
                  >
                    Pagamento
                  </div>
                  <div className="text-center">
                    {b.status === "PENDING" ? (
                      <InlineEdit
                        defaultValue={
                          billing.statusDate
                            ? moment(billing.statusDate).format(
                                "DD[/]MM[/]YYYY"
                              )
                            : null
                        }
                        readView={() => (
                          <div
                            className={classNames(
                              style.FieldView,
                              style.DueDate
                            )}
                          >
                            {moment(billing.statusDate).format(
                              "DD[/]MM[/]YYYY"
                            )}
                          </div>
                        )}
                        editView={(fieldProps) => (
                          <Calendar
                            onChange={this.payDayClick}
                            value={this.state.statusDate}
                            placeholder="Pagamento"
                            minDaysBillingAhead={
                              settings.BILLING_MIN_DAYS_AHEAD
                            }
                            maxMonthsAhead={12}
                          />
                        )}
                        onCancel={(_e) => {}}
                        onConfirm={(value, _) => updateBilling()}
                      />
                    ) : (
                      <div
                        className={classNames(
                          style.statusDate,
                          "cursor-default"
                        )}
                      >
                        {billing.statusDate
                          ? moment(billing.statusDate).format("DD[/]MM[/]YYYY")
                          : null}
                      </div>
                    )}
                  </div>
                </div>
              )}

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Descrição
                </div>
                <InlineEdit
                  defaultValue={billing.description}
                  readView={() =>
                    loading && b.description !== billing.description ? (
                      "Carregando..."
                    ) : (
                      <div className={style.FieldView}>
                        {billing.description || editPlaceholder}
                      </div>
                    )
                  }
                  editView={(fieldProps) => (
                    <Input
                      value={this.state.description}
                      onChange={this.handleDescription}
                      placeholder="Descrição"
                      showClearBtn
                    />
                  )}
                  onCancel={(_e) => {}}
                  onConfirm={(value, _) => updateBilling()}
                />
              </div>

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Juros&nbsp;
                  <Popup
                    trigger={<MdInfo size={12} />}
                    header="Juros de mercado"
                    content="2% de multa no atraso acrescido de 1% de mora ao mês"
                    inverted
                  />
                </div>
                {b.status === "PENDING" ? (
                  <Checkbox
                    toggle
                    fitted
                    onChange={(value, _) =>
                      this.handleInterest(value, updateBilling)
                    }
                    checked={this.state.interest}
                  />
                ) : (
                  <div className="cursor-default">
                    {this.state.interest ? "Sim" : "Não"}
                  </div>
                )}
              </div>

              <div className="mt-3">
                <div className={classNames(style.FieldLabel, "cursor-default")}>
                  Desconto até o vencimento
                </div>
                {b.status === "PENDING" ? (
                  <InlineEdit
                    defaultValue={billing.discountAmountFace}
                    readView={() =>
                      loading && b.discountAmount !== billing.discountAmount ? (
                        "Carregando..."
                      ) : (
                        <div className={style.FieldView}>
                          {billing.discountAmountFace || editPlaceholder}
                        </div>
                      )
                    }
                    editView={(fieldProps) => (
                      <Input
                        onChange={this.handleDiscount}
                        value={this.state.discountAmountFace}
                      />
                    )}
                    onCancel={(_e) => {}}
                    onConfirm={(value, _) => updateBilling()}
                  />
                ) : (
                  <div className="cursor-default">
                    {billing.discountAmountFace || "0,00"}
                  </div>
                )}
              </div>

              <Grid centered className="pt-3">
                {this.renderExpressBilling(billing)}
              </Grid>

              <Grid centered className="pb-4">
                <Grid.Row columns={1}>
                  <Grid.Column width={16} textAlign="center">
                    <ul className={classNames("vertical", style.List)}>
                      {!["CREATED_PAYMENT"].includes(billing.status) && (
                        <Popup
                          on="click"
                          inverted
                          trigger={
                            <li
                              className={classNames(style.ListItem, "fadeInUp")}
                            >
                              <button
                                type="button"
                                onClick={() =>
                                  navigator.clipboard.writeText(url)
                                }
                                className={classNames(style.ListItemLink)}
                              >
                                {[
                                  "FULL_PAYMENT",
                                  "UNDERPAYMENT",
                                  "OVERPAYMENT",

                                  "PAID",
                                ].includes(billing.status)
                                  ? "Copiar link do comprovante"
                                  : "Copiar link da fatura"}
                              </button>
                            </li>
                          }
                          content="Link copiado!"
                          position="top center"
                        />
                      )}

                      <li className={classNames(style.ListItem, "fadeInUp")}>
                        <a
                          href={whatsAppApi(this.props.billing.url, null)}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={classNames(style.ListItemLink)}
                        >
                          Enviar por WhatsApp
                        </a>
                      </li>
                      {billingIsDestroyable(b) && (
                        <Mutation
                          mutation={GQL_DESTROY_OR_CANCEL_BILLING}
                          variables={{ id: b.id }}
                          onError={() =>
                            window.alert(
                              "Não foi possível cancelar, tente novamente mais tarde ou entre em contato com o suporte."
                            )
                          }
                          update={this.handleDestroyUpdate}
                        >
                          {(
                            destroyOrCancelBilling,
                            { loading, error, data }
                          ) => {
                            if (loading) return <GqlLoadingIcon />;
                            if (error) return <GqlError error={error} />;

                            const confirm = () => {
                              if (window.confirm("Tem certeza?"))
                                destroyOrCancelBilling();
                            };

                            return (
                              <li
                                className={classNames(
                                  style.ListItem,
                                  "fadeInUp",
                                  style.ListItemLink,
                                  style.ListItemNegative
                                )}
                              >
                                <span onClick={confirm}>
                                  Cancelar
                                  {["PAID"].includes(billing.status)
                                    ? " recebimento"
                                    : " cobrança"}
                                </span>
                              </li>
                            );
                          }}
                        </Mutation>
                      )}
                      {billingIsDestroyable(b) && b.status !== "PAID" && (
                        <li
                          className={classNames(
                            style.ListItem,

                            "fadeInUp",

                            style.ListItemLink,

                            style.ListItemNegative
                          )}
                        >
                          <PayModal
                            id={b.id}
                            billing={b}
                            customerId={b.customerId}
                            onCompleted={() =>
                              this.setState({ status: "PAID" })
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
          );
        }}
      </Mutation>
    );
  }
}

export default EditBilling;
