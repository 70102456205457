import React from "react";
import { Box } from "@plipag/ceci-ui";

export function Container(props: any) {
  return (
    <Box
      sx={{ background: "#fff", borderRadius: 2, p: 3, m: 3, mt: 2, mb: 2 }}
      {...props}
    />
  );
}
