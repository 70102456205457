import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  useTheme,
} from "@plipag/ceci-ui";

export function CTACard({
  title,
  description,
  link,
  buttonText,
  sx,
  ...props
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Card
      {...props}
      sx={{
        mb: isMobile ? 0 : 4,
        ...sx,
      }}
    >
      <CardActionArea
        sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}
        component={Link}
        to={link}
      >
        <CardContent sx={{ pt: 3, flexGrow: 1 }}>
          <Typography variant="h5" color="primary" sx={{ m: 1, ml: 2 }}>
            {title}
          </Typography>
          <Typography sx={{ ml: 2 }}>{description}</Typography>
        </CardContent>
        <CardActions
          sx={{
            m: isMobile ? 3 : 0,
            mt: 0,
            mr: !isMobile ? 2 : 0,
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="success"
            sx={{ ml: 3 }}
          >
            {buttonText}
          </Button>
        </CardActions>
      </CardActionArea>
    </Card>
  );
}
