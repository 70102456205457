import { Grid } from "semantic-ui-react";
import { Skeleton } from "@plipag/ceci-ui";

export default function BillingsStatisticsSkeleton() {
  return (
    <Grid centered relaxed stackable doubling padded>
      <Grid.Row columns={4}>
        <Grid.Column width={4} textAlign="center">
          <Skeleton variant="rectangular" height={104} />
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Skeleton variant="rectangular" height={104} />
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Skeleton variant="rectangular" height={104} />
        </Grid.Column>
        <Grid.Column width={4} textAlign="center">
          <Skeleton variant="rectangular" height={104} />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
}
