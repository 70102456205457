import { gql } from "apollo-boost";

export const CustomersReactivate = gql`
  mutation CustomersReactivate($customersIds: [ID!]!) {
    reactivateCustomers(customersIds: $customersIds) {
      customers {
        id
      }
    }
  }
`;
