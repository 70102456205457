import { BrowserRouter as Router, Switch } from "react-router-dom";
import Routes from "./Routes";
import { Layout } from "./components/Layout";

const App = () => {
  console.log("App mounted");
  return (
    <Layout>
      <Router basename="entrar">
        <Switch>
          <Routes />
        </Switch>
      </Router>
    </Layout>
  );
};

export default App;
