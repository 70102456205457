import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { getFeatures } from "../plg/profile";
import { CTACard } from "./CTACard";

export function FirstBillingCTA() {
  const { data, loading, error } = useQuery(gql`
    query Merchant {
      merchant {
        status
      }
    }
  `);

  const fakeBillingIssued = getFeatures("fakeBillingIssued");

  if (error || loading) return null;

  if (["ENABLED", "SUSPENDED", "BLOCKED"].includes(data.merchant.status))
    return null;

  if (fakeBillingIssued) return null;

  return (
    <CTACard
      sx={{ m: 3 }}
      link="/primeira-cobranca"
      title="Emita uma cobrança teste."
      description="Vamos emitir uma cobrança teste para você mesmo, para que veja como é rápido e fácil usar o PLI."
      buttonText="EMITIR COBRANÇA TESTE"
    />
  );
}
