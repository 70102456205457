import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'

const GQL_DESTROY_OR_CANCEL_BILLING = gql`
  mutation DestroyOrCancelBilling($id: ID!) {
    destroyBilling(id: $id) {
      ...BillingProps
    }
  }
  ${BillingProps}
`

export default GQL_DESTROY_OR_CANCEL_BILLING