import React from "react";
import { useQuery } from "react-apollo";
import { Link } from "react-router-dom";
import { Button, Box } from "@plipag/ceci-ui";
import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";

export function ActivateAccountButton() {
  const { loading, data } = useQuery(GQL_MERCHANT);
  if (
    loading ||
    ["ENABLED", "SUSPENDED", "BLOCKED"].includes(data?.merchant?.status)
  )
    return null;

  return (
    <Box sx={{ textAlign: "center", flexGrow: 1 }}>
      <Button
        component={Link}
        to="/ativacao"
        variant="contained"
        color="success"
      >
        ATIVE SUA CONTA
      </Button>
    </Box>
  );
}
