import React from 'react'

import { Header } from 'semantic-ui-react'

function CertificateStatus() {
  return (
    <div>
      <Header content="Sem certificado digital" />
      <div>
        Para emitir notas fiscais eletrônicas para seus clientes, é necessário
        um certificado digital.
        Vamos configurar?
      </div>
    </div>
  )
}

export default CertificateStatus
