import React from 'react'
import _ from 'lodash'

import { Tab } from 'semantic-ui-react'
import { MdSettings } from 'react-icons/md'
import Modal from '../../uikit/Modal'

import style from './ConfigsModal.module.css'

import BillingAutomation from './partials/BillingAutomation'
import Portal from './partials/Portal'
import API from './partials/API'
import NotaFiscal from './partials/NotaFiscal'

const pages = {
  BILLING_AUTOMATION: { name: 'BILLING_AUTOMATION', index: 0 },
  NOTA_FISCAL: { name: 'NOTA_FISCAL', index: 1 },
  PORTAL: { name: 'PORTAL', index: 2 },
  API: { name: 'API', index: 3 },
}

class ConfigsModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      page: pages.BILLING_AUTOMATION
    }

    this.changePage = this.changePage.bind(this)
  }

  changePage(page) {
    this.setState({ page })
  }

  renderTabs() {
    return (
      <div className={style.Tab}>
        <Tab menu={{ pointing: true, secondary: true }} panes={[
          { menuItem: 'Régua de cobranças', render: () => { } },
          // { menuItem: 'Nota Fiscal', render: () => {} },
          // { menuItem: 'Portal', render: () => {} },
          // { menuItem: 'API', render: () => {} },
        ]} activeIndex={this.state.page.index} onTabChange={(e, data) => {
          this.changePage(_.find(pages, page => page.index === data.activeIndex))
        }} />
      </div>
    )
  }

  renderContent() {
    return (
      <div>
        <div className={style.ModalHeader}>
          <ul className="horizontal relaxed middle">
            <li>
              <MdSettings size={64} />
            </li>
            <li>
              <div className={style.ModalSubtitle}>
                Gestão da conta
              </div>
              <div className={style.ModalTitle}>
                Configurações
              </div>
            </li>
          </ul>
        </div>
        <div>
          {this.renderTabs()}
        </div>
        <div className={style.ModalBody}>
          {this.state.page.name === 'BILLING_AUTOMATION' && <BillingAutomation />}
          {this.state.page.name === 'PORTAL' && <Portal />}
          {this.state.page.name === 'API' && <API />}
          {this.state.page.name === 'NOTA_FISCAL' && <NotaFiscal />}
        </div>
      </div>
    )
  }

  openCallback() {}

  render() {
    return (
      <Modal trigger={this.props.trigger} size="medium" borderStyle="ssss"
        openCallback={this.openCallback}>
        {(closeModal) => this.renderContent()}
      </Modal>
    )
  }
}

export default ConfigsModal
