import React from "react";
import { Mutation } from "react-apollo";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import classNames from "classnames";

import { Header, Message, Popup } from "semantic-ui-react";
import { FaUserCheck } from "react-icons/fa";

import { routes } from "../../routes";
import analytics from "src/plg/Segment";

import ImgIcon from "src/uikit/ImgIcon";

import style from "./ReactivateCustomer.module.css";

import GQL_REACTIVATE_CUSTOMER from "src/graphql/mutations/ReactivateCustomer.gql";
import GqlError from "src/uikit/GqlError";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";

export default class ReactivateCustomer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      page: "INTRO",
    };

    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleReactivateUpdate = this.handleReactivateUpdate.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changePage(page) {
    this.setState({ page });
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Não foi possível completar a operação, tente novamente",
    });
  }

  handleReactivateUpdate(
    cache,
    {
      data: {
        reactivateCustomer: { id, status, billings },
      },
    }
  ) {}

  renderReactivateButton() {
    return (
      <Mutation
        mutation={GQL_REACTIVATE_CUSTOMER}
        variables={{ id: this.props.customer.id }}
        refetchQueries={["CustomersConnection"]}
        onError={this.handleMutationError}
        onCompleted={() => {
          analytics.track("Customer Reactivated");
          this.changePage("SUCCESS");
        }}
      >
        {(archiveCustomer, { error, loading, data }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          return (
            <Popup
              trigger={
                <FaUserCheck className={style.Icon} onClick={archiveCustomer} />
              }
              open
              inverted
              content="Reativar o cliente"
              position="right center"
            />
          );
        }}
      </Mutation>
    );
  }

  renderReactivate() {
    return (
      <div>
        <Header textAlign="center" size="large">
          Reativar cliente
        </Header>
        <div>
          <p>
            Ao reativar o cliente, as cobranças que estavam programadas irão
            reaparecer. As cobranças vencidas serão dadas como expiradas e as
            demais ficarão programadas normalmente. As mensalidades ficarão
            pausadas e podem ser reativadas manualmente.
          </p>
          <div className="text-center mt-2 mb-2">
            {this.renderReactivateButton()}
          </div>
        </div>
      </div>
    );
  }

  renderError(errors) {
    if (!errors) return null;

    return <Message negative>Erro, cheque se os dados são válidos.</Message>;
  }

  renderSuccess() {
    const comp = this;
    setTimeout(() => {
      comp.setState({ page: "REDIRECT" });
    }, 2000);

    return (
      <div className="text-center">
        <ImgIcon
          iconPath="open/gradient/747818-ui-interface/png/002-smile-1.png"
          className={style.IconStatic}
        />
        <Header
          content="Cliente ativo novamente"
          textAlign="center"
          className="cursor-default"
        />
      </div>
    );
  }

  renderActive() {
    const comp = this;
    setTimeout(() => {
      comp.setState({ page: "REDIRECT" });
    }, 2000);

    return (
      <div className="text-center">
        <ImgIcon
          iconPath="open/gradient/747818-ui-interface/png/002-smile-1.png"
          className={style.IconStatic}
        />
        <Header
          content="Cliente está ativo"
          textAlign="center"
          className="cursor-default"
        />
      </div>
    );
  }

  renderIntro() {
    return (
      <div>
        <div className="text-center fadein">
          <img
            src="https://assets.plipag.com.br/conta/images/illustrations/archive-1.png"
            alt=""
            className={style.Splash}
          />
        </div>
        {this.state.error && (
          <div className="mt-3 mb-3">{this.renderError(this.state.error)}</div>
        )}
        <div className="pt-3">{this.renderReactivate()}</div>
      </div>
    );
  }

  render() {
    if (this.state.page === "REDIRECT")
      return <Redirect to={routes.CUSTOMER.getLink(this.props.customer.id)} />;

    let body;
    switch (this.props.customer.status) {
      case "ARCHIVED":
        switch (this.state.page) {
          case "INTRO":
            body = this.renderIntro();
            break;
          case "SUCCESS":
            body = this.renderSuccess();
            break;
          default:
            break;
        }
        break;
      case "ACTIVE":
        body = this.renderActive();
        break;
      default:
        break;
    }

    return (
      <div className={style.ModalBody}>
        <div className="mt-1 mb-3">
          <Link
            to={routes.CUSTOMER.getLink(this.props.customer.id)}
            className={classNames(style.ListItemLink, "fadeInUp")}
          >
            &laquo; Voltar
          </Link>
        </div>
        {body}
      </div>
    );
  }
}
