import { gql } from "apollo-boost";

export const CustomersArchive = gql`
  mutation CustomersArchive($customersIds: [ID!]!) {
    archiveCustomers(customersIds: $customersIds) {
      customers {
        id
      }
    }
  }
`;
