import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { routes } from '../../routes'

import { Container, Grid, Popup } from 'semantic-ui-react'
import { MdAddCircle } from 'react-icons/md'
import { FaUserSlash, FaUserCheck } from 'react-icons/fa'

import style from './Options.module.css'

class Options extends React.Component {
  render() {
    return (
      <Container fluid>
        <Grid>
          <Grid.Row columns={2} centered>
            <Grid.Column width={8} className="p-0">
              <ul className={classNames('horizontal')}>
                <li className={classNames(style.ListItem, 'fadeInUp')}>
                  <Popup trigger={
                    <MdAddCircle size={32} onClick={() => {
                      this.props.newField()
                    }} />
                  } inverted content="Novo campo" />
                </li>
              </ul>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right" className="p-0">
              <ul className={classNames('horizontal')}>
                {
                  this.props.customer.status === 'ACTIVE' && (
                    <Link to={routes.ARCHIVE_CUSTOMER.getLink(this.props.customer.id)}>
                      <li className={classNames(style.ListItem, 'fadeInUp', style.Negative)}>
                        <Popup trigger={
                          <FaUserSlash size={24} />
                        } inverted content="Arquivar ou excluir o cliente" />
                      </li>
                    </Link>
                  )
                }
                {
                  this.props.customer.status === 'ARCHIVED' && (
                    <Link to={routes.REACTIVATE_CUSTOMER.getLink(this.props.customer.id)}>
                      <li className={classNames(style.ListItem, 'fadeInUp', style.Positive)}>
                        <Popup trigger={
                          <FaUserCheck size={24} />
                        } inverted content="Reativar cliente" />
                      </li>
                    </Link>
                  )
                }
              </ul>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

export default Options