import React from 'react'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'

import style from './BackButton.module.css'

import { FiArrowLeftCircle } from 'react-icons/fi'
import { MdClose } from 'react-icons/md'

class Account extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      link: null
    }

    this.go = this.go.bind(this)
  }

  go() {
    if (this.props.goTo)
      this.setState({ link: this.props.goTo })
    else
      this.props.goBack()
  }

  render() {
    if (this.state.link)
      return <Redirect to={this.state.link} />

    if (this.props.close)
      return (
        <button className={classNames(style.NavigateBack, 'horizontal')}
                onClick={this.go}>
          <MdClose size={54} />
        </button>
      )

    return (
      <button className={classNames(style.NavigateBack, 'horizontal')}
              onClick={this.go}>
        <FiArrowLeftCircle size={20} />
        <span className="ml-2 a-m font-weight-extra-bold">VOLTAR</span>
      </button>
    )
  }
}

export default Account
