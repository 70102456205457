import React from "react";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DropArea,
  Typography,
} from "@plipag/ceci-ui";

const api = axios.create({
  baseURL: "http://localhost:8080",
  headers: {
    "Content-type": "application/json",
  },
});

function upload(file, onUploadProgress) {
  let formData = new FormData();

  formData.append("file", file);

  return api.post("/upload", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    onUploadProgress,
  });
}

const DropFileDialog = ({ title, content, value, ...props }) => {
  const [file, setFile] = React.useState(value);

  React.useCallback(() => {
    handleUpload(file);
  }, [file]);

  function handleSubmit() {
    console.log("submit", file);
    if (!file) return;
    props.onChange(file);
    props.onClose();
  }

  function handleUpload(file) {
    upload(file, (event) => {
      if (!file) return;

      setFile({
        ...file,
        uploaded: Math.round((100 * event.loaded) / event.total),
      });
    })
      .then((response) => {
        console.log("done", response);
        return response.data;
      })
      .then((file) => {
        setFile(file);
      })
      .catch((e) => {
        console.log("error", e);
      });
    setFile(null);
  }
  return (
    <Dialog {...props}>
      <DialogTitle disableTypography={true}>
        <Typography variant="h6">{title}</Typography>
      </DialogTitle>
      <DialogContent>
        {content}
        <Box sx={{ marginBottom: 3 }}>
          <DropArea
            value={file}
            onChange={(file) => {
              setFile(file);
            }}
          />
        </Box>
        <Button
          fullWidth
          size="large"
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Selecionar arquivo
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DropFileDialog;
