import { Box, Typography, Select } from "@plipag/ceci-ui";

type Props = {
  [key: string]: any;
  total: number;
  limit: number;
  setLimit: (limit: number) => void;
};

export function LimitSelector({ limit, setLimit, total, sx }: Props) {
  const options = [
    { value: 50, label: "50" },
    { value: 100, label: "100" },
    { value: 200, label: "200" },
  ].filter((option) => total > option.value);

  if (!options.length) return null;

  options.push({ value: total, label: "Tudo" });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <Typography>Exibindo</Typography>
      <Select
        sx={{ mx: 2 }}
        size="small"
        options={options}
        value={limit}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setLimit(~~e.target.value)
        }
      />
      <Typography>de {total}.</Typography>
    </Box>
  );
}
