import React from "react";
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@plipag/ceci-ui";
import { ReactComponent as HeadingImage } from "../assets/success.svg";
import Page from "../components/Page";

const SuccessScreen = () => {
  const theme = useTheme();
  const largeDisplay = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Page back={false}>
      <Grid
        container
        direction="column"
        textAlign="center"
        flexGrow={largeDisplay ? 0 : 1}
        sx={{ padding: 3, maxWidth: 475, margin: "0 auto" }}
      >
        <Grid item>
          <HeadingImage style={{ margin: "0 auto" }} />
        </Grid>
        <Grid item>
          <Typography
            variant="h1"
            component="h1"
            sx={{ marginTop: 4, marginBottom: 3 }}
          >
            Cadastro finalizado com sucesso!
          </Typography>
        </Grid>
        <Grid item>
          <Typography component="p" sx={{ marginBottom: 5 }}>
            Enquanto analisamos os documentos e ativamos sua conta, aproveite
            para cadastrar seu clientes e cobranças.
          </Typography>
        </Grid>
        <Grid item sx={{ marginBottom: 0, marginTop: "auto" }}>
          <Button
            variant="outlined"
            color="neutral"
            textColor="primary"
            fullWidth
            size="large"
          >
            Cadastrar clientes
          </Button>
        </Grid>
      </Grid>
    </Page>
  );
};

export default SuccessScreen;
