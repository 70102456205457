import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'

const GQL_CREATE_BILLING = gql`
  mutation CreateBilling(
    $customerId: ID!,
    $dueDate: String!,
    $amountBilled: Float!,
    $description: String,
    $anticipate: Boolean,
    $interestPolicy: InterestPolicy!,
    $discountPolicy: DiscountPolicy!,
    $discountDays: Int,
    $discountAmount: Float
  ) {
    createBilling(
      customerId: $customerId,
      dueDate: $dueDate,
      amountBilled: $amountBilled,
      description: $description,
      interestPolicy: $interestPolicy,
      discountPolicy: $discountPolicy,
      discountDays: $discountDays,
      discountAmount: $discountAmount,
      anticipate: $anticipate
    ) {
      ...BillingProps
    }
  }
  ${BillingProps}
`

export default GQL_CREATE_BILLING