import { gql } from 'apollo-boost'
import SettlementRequestProps from '../fragments/settlement_request'

const GQL_REQUEST_SETTLEMENT = gql`
  mutation RequestSettlement {
    requestSettlement {
      ...SettlementRequestProps
    }
  }
  ${SettlementRequestProps}
`

export default GQL_REQUEST_SETTLEMENT