export const minimumStrength = 2

export function passwordStrengthLabel(score) {
  switch (score) {
    case 0:
      return 'Muito fraca';
    case 1:
      return 'Fraca';
    case 2:
      return 'Aceitável';
    case 3:
      return 'Boa';
    case 4:
      return 'Forte';
    default:
      return 'Sem recomendação';
  }
}
