import React from "react";
import { Query } from "react-apollo";

import { nameShortner } from "src/utils/naming";

import { GridColumn } from "semantic-ui-react";
import { MdAccountCircle } from "react-icons/md";

import style from "./UserCard.module.css";

import GQL_CURRENT_USER from "src/graphql/queries/CurrentUser.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";
import UserChangePasswordModal from "src/modals/UserChangePasswordModal";
import CreateUserButton from "../CreateUserButton";

class UserCard extends React.Component {
  render() {
    return (
      <GridColumn align="center">
        <ul className="horizontal dark-grey nowrap">
          <li className="a-m">
            <MdAccountCircle size={64} />
          </li>
          <li className="a-m ml-3">
            <Query query={GQL_CURRENT_USER}>
              {({ loading, error, data, refetch }) => {
                if (loading) return <OrbitsLoader />;
                if (error) return <GqlError error={error} />;

                const user = data.user;

                return (
                  <React.Fragment>
                    <div className={style.Name}>
                      {nameShortner(user.name, "First")}
                    </div>
                    <div className={style.Email}>{user.email}</div>
                    <div
                      className="text-left"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <UserChangePasswordModal
                        user={user}
                        trigger={
                          <span className={style.ChangePassword}>
                            Alterar senha
                          </span>
                        }
                      />

                      <span style={{ marginLeft: 15 }}>
                        <CreateUserButton className={style.ChangePassword} />
                      </span>
                    </div>
                  </React.Fragment>
                );
              }}
            </Query>
          </li>
        </ul>
      </GridColumn>
    );
  }
}

export default UserCard;
