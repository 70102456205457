import React from "react";
import { Chip, Grid, Typography } from "@plipag/ceci-ui";
import { Customer, CustomersContext } from "src/modules/customers/context";

export function SelectedsCustomers() {
  const { selecteds, items, toggleItem } = React.useContext(CustomersContext);
  const customers = items.filter((customer: Customer) =>
    selecteds.includes(customer.id)
  );

  function handleDelete(id: number) {
    toggleItem(id);
  }

  return (
    <Grid spacing={1} container sx={{ mt: 1 }}>
      <Typography variant="h6" sx={{ fontSize: 16, height: "auto", pl: 1 }}>
        Criando cobrança para os clientes:
      </Typography>
      {customers.map((customer: Customer) => (
        <Grid item key={customer.id}>
          <Chip
            label={customer.name}
            variant="outlined"
            onDelete={() => handleDelete(customer.id)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

//
