import React from "react";
import { Grid } from "@plipag/ceci-ui";
import FeatureCard from "./FeatureCard";
import { MdPeople, MdReceipt, MdInsertChart } from "react-icons/md";

const Features = () => {
  return (
    <Grid container columns={3} spacing={4}>
      <Grid item sm={3} lg={1}>
        <FeatureCard
          to="/clientes"
          Icon={MdPeople}
          title="Todos os Clientes"
          description="Cadastre todas as informações dos seus clientes aqui, criando campos extras, grupos e anotações de acordo com o seu negócio."
        />
      </Grid>
      <Grid item sm={3} lg={1}>
        <FeatureCard
          to="/fatura"
          Icon={MdReceipt}
          title="Fatura Digital"
          description="Personalize a sua fatura com a logomarca e dados da sua empresa para se aproximar ainda mais dos seus clientes."
        />
      </Grid>
      <Grid item sm={3} lg={1}>
        <FeatureCard
          to="/financeiro"
          Icon={MdInsertChart}
          title="Receba Pagamentos"
          description="Com seus clientes cadastrados, agora você pode começar a emitir cobranças. Aproveite para configurar sua régua de cobrança."
        />
      </Grid>
    </Grid>
  );
};

export default Features;
