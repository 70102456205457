import React from "react";
import { Query } from "react-apollo";
import moment from "moment";
import "moment/locale/pt-br";

import { Popup } from "semantic-ui-react";
import { MdBrightness1, MdBrightness2 } from "react-icons/md";

import { businessDayBackward, businessDayForward } from "src/utils/holidays";

import style from "./BoletoStatus.module.css";

import GQL_BOLETO_STATUS from "src/graphql/queries/BoletoStatus.gql";
import GQL_MERCHANT_STATUS from "src/graphql/queries/MerchantStatus.gql";
import GqlError from "src/uikit/GqlError";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";

class BoletoStatus extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cachePolicy: "network-only",
      pollInterval: 1000 * 60 * 10,
      date: null,
      upToDate: false,
    };

    this.updateState = this.updateState.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateState(data) {
    if (data.boletoStatus === businessDayBackward().format("YYYY[-]MM[-]DD"))
      this.setState({
        upToDate: true,
        date: data.boletoStatus,
        pollInterval: 1000 * 60 * 60 * 6,
      });
    else
      this.setState({
        upToDate: false,
        date: data.boletoStatus,
        pollInterval: 1000 * 60 * 15,
      });
  }

  /*
  Oi, Fulano, os pagamentos de ontem serão atualizados até às 12h.
Bom dia/Boa tarde/Boa noite, Fulano, os pagamentos de ontem serão atualizados até às 12h.

Sábado, Domingo e Segunda
Oi, Fulano, os pagamentos de sábado em diante serão atualizados até às 12h de terça-feira.
Bom dia/Boa tarde/Boa noite, Fulano, os pagamentos de sábado em diante serão atualizados até às 12h de terça-feira.

Oi, Fulano, os pagamentos de ontem já estão atualizados.
Bom dia/Boa tarde/Boa noite, Fulano, os pagamentos de ontem já estão atualizados.
   */
  renderUpToDate() {
    const nextBusinessDay = businessDayForward();

    const title = "Tudo atualizado";

    const text = (
      <div className="mt-3">
        <p>
          Pagamentos reconhecidos no banco até o último dia{" "}
          {moment(this.state.date).format("D")}.
        </p>
        <p>
          Pagamentos posteriores serão reconhecidos até o meio dia de{" "}
          {moment(nextBusinessDay).format("dddd").toLowerCase()}.
        </p>
      </div>
    );

    return (
      <Popup
        inverted
        position="bottom right"
        trigger={<MdBrightness1 size={24} className={style.UpToDate} />}
        header={title}
        content={text}
      />
    );
  }

  renderNotUpToDate() {
    const previousBusinessDay = businessDayBackward();
    const nextBusinessDay = businessDayForward();
    const upTo =
      this.state.date || moment().subtract(5, "days").format("YYYY[-]MM[-]DD");

    const title = `Pagamentos atualizados até dia ${moment(upTo).format(
      "DD[/]MM"
    )}`;

    const nextUpdate = (
      <p>
        Próxima atualização bancária até o meio dia de{" "}
        {moment(nextBusinessDay).format("dddd").toLowerCase()}.
      </p>
    );

    const limitTime = moment().isSameOrBefore(
      moment(nextBusinessDay).startOf("day").add(12, "hours")
    );

    let text = (
      <div className="mt-3">
        <p>
          Aguardando retorno do banco para os pagamentos realizados depois do
          dia {moment(previousBusinessDay).format("DD")}.
        </p>
        {limitTime && nextUpdate}
      </div>
    );

    return (
      <Popup
        inverted
        position="bottom right"
        trigger={<MdBrightness2 size={24} className={style.NotUpToDate} />}
        header={title}
        content={text}
      />
    );
  }

  render() {
    return (
      <Query query={GQL_MERCHANT_STATUS}>
        {({ loading, error, data, refetch }) => {
          if (loading) return <OrbitsLoader />;
          if (error) return <GqlError error={error} />;

          const {
            merchant: { status },
          } = data;

          return (
            status === "ENABLED" && (
              <Query
                query={GQL_BOLETO_STATUS}
                pollInterval={this.state.pollInterval}
                fetchPolicy={this.state.cachePolicy}
                onCompleted={this.updateState}
              >
                {({ loading, error, data }) => {
                  if (loading) return <OrbitsLoader />;
                  if (error) return <GqlError error={error} />;

                  return this.state.upToDate
                    ? this.renderUpToDate()
                    : this.renderNotUpToDate();
                }}
              </Query>
            )
          );
        }}
      </Query>
    );
  }
}

export default BoletoStatus;
