import React from "react";
import _ from "lodash";

import Card from "./partials/Card";
import Address from "./partials/Address";
import Notes from "./partials/Notes";
import ArchiveCustomer from "./partials/ArchiveCustomer";
import ReactivateCustomer from "./partials/ReactivateCustomer";
import Receivables from "./partials/Receivables";
import CreateReceivable from "./partials/CreateReceivable";
import CreateReceivement from "./partials/CreateReceivement";
import Receivable from "./partials/Receivable";
import CreatedBilling from "./partials/CreatedBilling";
import Recurrence from "./partials/Recurrence";
import CreatedRecurrence from "./partials/CreatedRecurrence";

export const routes = {
  // Customer.
  CUSTOMER: {
    name: "CUSTOMER",
    route: { module: undefined, id: undefined, page: undefined },
    configs: { header: true, tabs: true, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.CUSTOMER.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <Card customer={customer} closeModal={closeModal} />
    ),
  },
  ARCHIVE_CUSTOMER: {
    name: "ARCHIVE_CUSTOMER",
    route: { module: "arquivar", id: undefined, page: undefined },
    configs: { header: false, tabs: false, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.ARCHIVE_CUSTOMER.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <ArchiveCustomer customer={customer} closeModal={closeModal} />
    ),
  },
  REACTIVATE_CUSTOMER: {
    name: "REACTIVATE_CUSTOMER",
    route: { module: "reativar", id: undefined, page: undefined },
    configs: { header: false, tabs: false, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.REACTIVATE_CUSTOMER.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <ReactivateCustomer customer={customer} closeModal={closeModal} />
    ),
  },
  CUSTOMER_ADDRESS: {
    name: "CUSTOMER_ADDRESS",
    route: { module: "endereco", id: undefined, page: undefined },
    configs: { header: true, tabs: true, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.CUSTOMER_ADDRESS.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <Address customer={customer} closeModal={closeModal} />
    ),
  },
  CUSTOMER_NOTES: {
    name: "CUSTOMER_NOTES",
    route: { module: "anotacoes", id: undefined, page: undefined },
    configs: { header: true, tabs: true, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.CUSTOMER_NOTES.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <Notes customer={customer} closeModal={closeModal} />
    ),
  },
  // Create receivable.
  CREATE_RECEIVABLE_FOR_CUSTOMERS_LIST: {
    name: "CREATE_RECEIVABLE_FOR_CUSTOMERS_LIST",
    route: { module: "cobrar_cliente", id: undefined, page: undefined },
    configs: { header: true, tabs: false, modalBorders: "ssll" },
    getLink: (customerId, id = null) =>
      buildLink(routes.CREATE_RECEIVABLE_FOR_CUSTOMERS_LIST.route, {
        customerId,
        id,
      }),
    getBody: (closeModal, customer, id = null) => (
      <CreateReceivable customer={customer} closeModal={closeModal} />
    ),
  },
  // Create receivable.
  CREATE_RECEIVABLE: {
    name: "CREATE_RECEIVABLE",
    route: { module: "cobrar", id: undefined, page: undefined },
    configs: { header: true, tabs: true, modalBorders: "ssll" },
    getLink: (customerId, id = null) =>
      buildLink(routes.CREATE_RECEIVABLE.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <CreateReceivable customer={customer} closeModal={closeModal} />
    ),
  },
  // Receivables
  RECEIVABLES: {
    name: "RECEIVABLES",
    route: { module: "cobrancas", id: undefined, page: undefined },
    configs: { header: true, tabs: true, modalBorders: "ssss" },
    getLink: (customerId, id = null) =>
      buildLink(routes.RECEIVABLES.route, { customerId, id }),
    getBody: (closeModal, customer, id = null) => (
      <Receivables customer={customer} closeModal={closeModal} />
    ),
  },
  RECEIVABLE: {
    name: "RECEIVABLE",
    route: { module: "cobrancas", id: true, page: undefined },
    configs: { header: false, tabs: false, modalBorders: "ssss" },
    getLink: (customerId, id) =>
      buildLink(routes.RECEIVABLE.route, { customerId, id }),
    getBody: (closeModal, customer, id) => (
      <Receivable customer={customer} id={id} closeModal={closeModal} />
    ),
  },
  CREATED_BILLING: {
    name: "CREATED_BILLING",
    route: { module: "cobrancas", id: true, page: "sucesso" },
    configs: { header: false, tabs: false, modalBorders: "ssss" },
    getLink: (customerId, id) =>
      buildLink(routes.CREATED_BILLING.route, { customerId, id }),
    getBody: (closeModal, customer, id) => (
      <CreatedBilling customer={customer} id={id} closeModal={closeModal} />
    ),
  },
  RECURRENCE: {
    name: "RECURRENCE",
    route: { module: "mensalidades", id: true, page: undefined },
    configs: { header: false, tabs: false, modalBorders: "ssss" },
    getLink: (customerId, id) =>
      buildLink(routes.RECURRENCE.route, { customerId, id }),
    getBody: (closeModal, customer, id) => (
      <Recurrence customer={customer} id={id} closeModal={closeModal} />
    ),
  },
  CREATED_RECURRENCE: {
    name: "CREATED_RECURRENCE",
    route: { module: "mensalidades", id: true, page: "sucesso" },
    configs: { header: false, tabs: false, modalBorders: "ssll" },
    getLink: (customerId, id) =>
      buildLink(routes.CREATED_RECURRENCE.route, { customerId, id }),
    getBody: (closeModal, customer, id) => (
      <CreatedRecurrence customer={customer} id={id} closeModal={closeModal} />
    ),
  },

  CREATE_RECEIVEMENT: {
    name: "CREATE_RECEIVEMENT",

    route: { module: "recebimento", id: undefined, page: undefined },

    configs: { header: true, tabs: true, modalBorders: "ssll" },

    getLink: (customerId, id = null) =>
      buildLink(routes.CREATE_RECEIVEMENT.route, { customerId, id }),

    getBody: (closeModal, customer, id = null) => (
      <CreateReceivement customer={customer} closeModal={closeModal} />
    ),
  },
};

// Format is /clientes/:customerId/:module/:id/:page
export function matchRoute(params) {
  return _.find(routes, (r) => {
    return (
      params.module === r.route.module &&
      ((params.id === undefined && r.route.id === undefined) ||
        (params.id && r.route.id)) &&
      params.page === r.route.page
    );
  });
}

function buildLink(routeParams, { customerId, id = null }) {
  const partRoot = `/clientes/${customerId}`;

  const partModule = routeParams.module ? `/${routeParams.module}` : "";

  const partId = routeParams.id ? `/${id}` : "";

  const partPage = routeParams.page ? `/${routeParams.page}` : "";
  return `${partRoot}${partModule}${partId}${partPage}`;
}
