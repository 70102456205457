import React from "react";
import ReactModal from "react-modal";
import classNames from "classnames";
import {
  clearAllBodyScrollLocks,
  disableBodyScroll,
  enableBodyScroll,
} from "body-scroll-lock";

import { MdClose } from "react-icons/md";

import style from "./Modal.module.css";

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    if (this.props.directRender) this.handleOpenModal();
  }

  componentWillUnmount() {
    this.removeBlur();
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  addBlur() {
    // document.getElementById("root").style.filter = "blur(5px)";
  }

  removeBlur() {
    document.getElementById("root").style.filter = "";
  }

  handleOpenModal(e) {
    e?.stopPropagation();
    this.toggleModal();
    this.addBlur();
    disableBodyScroll(document.querySelector("body"));

    if (typeof this.props.openCallback === "function")
      this.props.openCallback();
  }

  handleCloseModal(e) {
    e?.stopPropagation();
    enableBodyScroll(document.querySelector("body"));
    clearAllBodyScrollLocks();
    this.removeBlur();
    this.toggleModal();

    this.setState({
      showModal: false,
    });

    if (typeof this.props.closeCallback === "function")
      this.props.closeCallback();
  }

  renderModal() {
    const closeBtnStyle = classNames(
      style.NavigateBack,
      this.props.closeBtnDark ? style.DarkGrey : style.LightGrey
    );

    const borderStyle =
      String(this.props.borderStyle).length === 4
        ? {
            borderTopLeftRadius:
              this.props.borderStyle[0] === "s" ? "8px" : "8px",
            borderTopRightRadius:
              this.props.borderStyle[1] === "s" ? "8px" : "8px",
            borderBottomRightRadius:
              this.props.borderStyle[2] === "s" ? "8px" : "8px",
            borderBottomLeftRadius:
              this.props.borderStyle[3] === "s" ? "8px" : "8px",
            backgroundColor: this.props.whiteBackground ? "white" : null,
          }
        : {};

    return (
      <ReactModal
        isOpen={this.state.showModal}
        onRequestClose={this.handleCloseModal}
        contentLabel="ActiveModal"
        overlayClassName={style.ModalOverlay}
        className={style.ModalWindow}
        shouldCloseOnOverlayClick
        shouldCloseOnEsc={true}
        ariaHideApp={false}
      >
        <div
          className={classNames(
            style.ModalFrame,
            style[this.props.size || "small"]
          )}
        >
          <div className={style.ModalContainer} style={borderStyle}>
            <div className={style.ModalClose}>
              <MdClose
                size={32}
                className={closeBtnStyle}
                onClick={this.handleCloseModal}
              />
            </div>
            {this.props.children({ closeModal: this.handleCloseModal })}
          </div>
        </div>
      </ReactModal>
    );
  }

  renderCTA() {
    if (!this.props.trigger) return null;

    return <span onClick={this.handleOpenModal}>{this.props.trigger}</span>;
  }

  render() {
    return (
      <div>
        {this.renderCTA()}
        {this.renderModal()}
      </div>
    );
  }
}

export default Modal;
