import React from "react";
import { Mutation } from "react-apollo";

import { Slider, Rail, Handles, Tracks } from "react-compound-slider";
import { Popup } from "semantic-ui-react";

import GQL_MERCHANT_PREFERENCE_UPDATE from "src/graphql/mutations/MerchantPreferenceUpdate.gql";
import GQL_PREFERENCES from "src/graphql/queries/Preferences.gql";
import GqlError from "src/uikit/GqlError";

const sliderStyle = {
  position: "relative",
  width: "100%",
  height: 80,
};

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 10,
  marginTop: 35,
  borderRadius: 5,
  backgroundColor: "#dfdfdf",
  boxShadow: "inset 0 1px 3px rgba(0,0,0,.3)",
};

function Handle({ handle: { id, value, percent }, getHandleProps }) {
  return (
    <Popup
      inverted
      position="bottom center"
      content="Arraste para selecionar quantos dias"
      trigger={
        <div
          style={{
            left: `${percent}%`,
            position: "absolute",
            marginLeft: -15,
            marginTop: 25,
            zIndex: 2,
            width: 30,
            height: 30,
            border: "2px solid white",
            borderRadius: "50%",
            textAlign: "right",
            cursor: "pointer",
            backgroundColor: "rgb(52,200,100)",
            color: "#333",
            boxShadow: "0 1px 3px rgba(0,0,0,.4), 0 -1px 3px rgba(0,0,0,.4)",
          }}
          {...getHandleProps(id)}
        >
          <div
            style={{
              marginTop: -35,
              fontWeight: "bold",
              fontSize: "large",
              color: "limegreen",
              marginLeft: -25,
            }}
          >
            {value === 30 ? "Recomendado" : value}
          </div>
        </div>
      }
    />
  );
}

function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 35,
        background: "linear-gradient(110deg, #11998e 1%, #38ef7d 70%)",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        boxShadow: "inset 0 1px 3px rgba(0,0,0,.4)",
      }}
      {...getTrackProps()}
    />
  );
}

function CancelSlider({ onUpdate, onChange, initial }) {
  return (
    <Slider
      rootStyle={sliderStyle}
      domain={[5, 45]}
      step={1}
      mode={2}
      values={[initial]}
      onUpdate={onUpdate}
      onChange={onChange}
    >
      <Rail>
        {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
}

class AutomaticCancel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rangeValues: [this.props.days],
      days: this.props.days,
    };

    this.sliderUpdate = this.sliderUpdate.bind(this);
    this.sliderChange = this.sliderChange.bind(this);
  }

  sliderUpdate(values) {
    this.setState(
      {
        rangeValues: values,
      },
      this.setDays
    );
  }

  sliderChange(values) {
    this.setState(
      {
        rangeValues: values,
      },
      this.setDays
    );
  }

  setDays() {
    this.setState({
      days: this.state.rangeValues[0],
    });
  }

  render() {
    return (
      <Mutation
        mutation={GQL_MERCHANT_PREFERENCE_UPDATE}
        variables={{
          automaticCancel: this.state.days,
        }}
        refetchQueries={[{ query: GQL_PREFERENCES }]}
      >
        {(update, { loading, error }) => {
          if (error) return <GqlError error={error} />;

          return (
            <CancelSlider
              onChange={(values) => {
                this.sliderChange(values);
                update();
              }}
              onUpdate={this.sliderUpdate}
              initial={this.props.days}
            />
          );
        }}
      </Mutation>
    );
  }
}

export default AutomaticCancel;
