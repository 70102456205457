import { gql } from "apollo-boost";

import BillingProps from "../fragments/billing";

const GQL_BILLINGS = gql`
  query Billings($startDueDate: Date, $endDueDate: Date, $customerIds: [ID!]) {
    billings(
      startDueDate: $startDueDate
      endDueDate: $endDueDate
      customerId: $customerIds
    ) {
      ...BillingProps
    }
  }
  ${BillingProps}
`;

export default GQL_BILLINGS;
