import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import Routes from "./Routes";

export default function Root() {
  return (
    <BrowserRouter basename="new-onboard">
      <Switch>
        <Routes />
      </Switch>
    </BrowserRouter>
  );
}
