import React from "react";
import { post } from "axios";

import {
  FaPaperclip,
  FaCheckCircle,
  FaHourglassHalf,
  FaExclamationCircle,
} from "react-icons/fa";
import style from "./Uploader.module.css";
import { getToken } from "src/app/token";

const status = {
  AVAILABLE: "available",
  UPLOADING: "uploading",
  UPLOADED: "uploaded",
  ERROR: "error",
};

/* Usage example:
 * <Uploader entity={entities.FILE_CONSTITUTION} onError={console.log} onSuccess={console.log} />
 *
 */
class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status: status.AVAILABLE,
    };

    this.onChange = this.onChange.bind(this);
    this.fileInput = React.createRef();
    this.setState = this.setState.bind(this);
  }

  onChange(e) {
    this.setState({ status: status.UPLOADING });

    let formData = new FormData();
    formData.append(
      this.props.entity.uploadField,
      this.fileInput.current.files[0]
    );
    formData.append("entity_name", this.props.entity.name);

    post(`${process.env.REACT_APP_API_HOST}/upload`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${getToken()}`,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(({ data: { data, errors } }) => {
        if (errors) {
          this.setState({ status: status.AVAILABLE });
          return this.props.onError ? this.props.onError(errors) : null;
        }

        this.setState({
          status: this.props.reset ? status.AVAILABLE : status.UPLOADED,
        });

        return this.props.onSuccess ? this.props.onSuccess(data) : null;
      })
      .catch((err) => {
        this.setState({ status: status.AVAILABLE });

        console.error(err);

        if (this.props.onError)
          return this.props.onError([{ code: 500, message: "Erro de envio" }]);
      });
  }

  render() {
    switch (this.state.status) {
      case status.AVAILABLE:
        return (
          <div className={this.props.className}>
            <div className={style.Available}>
              <button>
                <span className="a-m">
                  {this.props.placeholder || "Escolher arquivo..."}
                </span>
                <FaPaperclip className="a-m ml-2" />
              </button>
              <input
                type="file"
                ref={this.fileInput}
                onChange={this.onChange}
              />
            </div>
          </div>
        );
      case status.UPLOADING:
        return (
          <div className={this.props.className}>
            <div className={style.Uploading}>
              <span className="a-m">Enviando...</span>
              <FaHourglassHalf className="a-m ml-2" />
            </div>
          </div>
        );
      case status.UPLOADED:
        return (
          <div className={this.props.className}>
            <div className={style.Uploaded}>
              <span className="a-m">Arquivo enviado</span>
              <FaCheckCircle className="a-m ml-2" />
            </div>
          </div>
        );
      case status.ERROR:
        return (
          <div className={this.props.className}>
            <div className={style.Error}>
              <FaExclamationCircle className="a-m mr-2" />
              <span className="a-m">Ops, envie para meajuda@plipag.com.br</span>
            </div>
          </div>
        );
      default:
        return null;
    }
  }
}

export default Uploader;
