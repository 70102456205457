import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { Container } from "src/components/Elements";
import { CustomersList } from "./List";
import CustomerComponent from "src/components/Customer";
import { CustomersContext, Cgroup, OrdersKeys, FiltersKeys } from "./context";
import { CustomersGroupsBar } from "./GroupsBar";
import { Customer } from "./context";
import { FirstBillingCTA } from "src/components/FirstBillingCTA";

export default function CustomersModule() {
  const { customerId, cgroupId, ...params }: { [key: string]: string } =
    useParams();
  const location = useLocation();
  const history = useHistory();
  const search = new URLSearchParams(location.search);
  const p = search.get("p");
  const page = p ? ~~p : 1;
  const _limit = ~~(localStorage.getItem("customers.limit") || 0);
  const [limit, _setLimit] = React.useState<number>(_limit > 50 ? _limit : 50);
  const [items, setItems] = React.useState<Array<Customer>>([]);
  const [selecteds, setSelecteds] = React.useState<Array<number>>([]);
  const [cgroups, setCgroups] = React.useState<Array<Cgroup>>([]);
  const [order, setOrder] = React.useState<OrdersKeys>("older");
  const [filter, _setFilter] = React.useState<FiltersKeys>("ACTIVE");

  function toggleItem(id: number) {
    if (selecteds.find((_id) => id === _id)) {
      setSelecteds((ids) => ids.filter((_id) => id !== _id));
    } else {
      setSelecteds((ids) => [...ids, id]);
    }
  }

  function setLimit(limit: number) {
    _setLimit(limit);
    localStorage.setItem("customers.limit", `${limit}`);
    history.push(location.pathname);
  }

  function setFilter(filter: FiltersKeys) {
    _setFilter(filter);
    setSelecteds([]);
  }

  // clear selecteds items on change filter, order, page or group
  React.useEffect(() => {
    setSelecteds([]);
    document.getElementById("page-container")?.scrollTo(0, 0);
  }, [page, filter, cgroupId, order]);

  // reset to page 1 on change filter, order or group
  React.useEffect(() => {
    history.push(location.pathname);
  }, [order, filter, cgroupId, history, location.pathname]);

  const state = {
    limit,
    page,
    selecteds,
    items,
    cgroups,
    cgroupId,
    filter,
    order,
  };

  const context = {
    ...state,
    setLimit,
    setItems,
    setSelecteds,
    toggleItem,
    setCgroups,
    setFilter,
    setOrder,
  };

  return (
    <CustomersContext.Provider value={context}>
      <FirstBillingCTA />
      <CustomersGroupsBar />
      <Container>
        <CustomersList />
        {customerId && (
          <CustomerComponent customerId={customerId} page={params.page} />
        )}
      </Container>
    </CustomersContext.Provider>
  );
}
