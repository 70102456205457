import React from "react";

export function LogoutButton({ user, ...props }) {
  function handleClick() {
    localStorage.removeItem("token");
    window.location.href = process.env.REACT_APP_SIGNIN_URL;
  }

  return (
    <span onClick={handleClick} {...props}>
      {props.children || "Sair"}
    </span>
  );
}
