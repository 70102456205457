import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

import { Grid, Statistic, Rating, Popup } from 'semantic-ui-react'
import { FaCheckCircle, FaBarcode, FaThumbsUp, FaThumbsDown } from 'react-icons/fa'

import {
  billingHasPayment,
  billingIsCancelled,
  billingIsFinalState,
  billingIsOpen,
  billingIsPaid,
} from '../../../BillingFields/scopes'

import PriceTag from '../../../../uikit/PriceTag'

import style from './Summary.module.css'

class ReceivablesSummary extends React.Component {
  statistics() {
    const amountBilled = _.sumBy(
      _.filter(
        this.props.billings,
        billingHasPayment
      ),
      'amountBilled'
    )

    const amountPaid = _.sumBy(
      _.filter(
        this.props.billings,
        billingIsPaid
      ),
      'amountPaid'
    )

    const amountReceivable = _.sumBy(
      _.filter(
        this.props.billings,
        billingIsOpen
      ),
      'amountBilled'
    )

    const amountCancelled = _.sumBy(
      _.filter(
        this.props.billings,
        billingIsCancelled
      ),
      'amountBilled'
    )

    return { amountBilled, amountPaid, amountReceivable, amountCancelled }
  }

  gradeRecency() {
    // E: Older
    // D: 181-730 days
    // C: 91-180 days
    // B: 45-90 days
    // A: 0-45 days
    const m = _.maxBy(this.props.billings, 'dueDate')
    const d = moment().diff(moment(m), 'days')

    let score
    if (d <= 45)
      score = "A"
    else if (d < 90)
      score = "B"
    else if (d < 180)
      score = "C"
    else if (d < 730)
      score = "D"
    else
      score = "E"

    return (
      <Popup inverted trigger={
        <span className={style.ScoreGrade}>{ score.toUpperCase() }</span>
      } content="Tempo desde a última cobrança" />
    )
  }

  gradeFrequency() {
    // E: No billing at all
    // D: 1 per year
    // C: 2-5 per year
    // B: 6-9 per year or 1-4 are recurring
    // A: 10+ per year or 5+ are recurring

    const a = _.filter(
      this.props.billings,
        b => moment().diff(moment(b.dueDate), 'days') < 730
    )

    const recurringCount = _.countBy(a, b => b.recurrenceId !== null)
    const billingCount = _.size(a)

    let score
    if (recurringCount >= 5) {
      score = "A"
    } else if (recurringCount >= 1 && recurringCount <= 4) {
      score = "B"
    } else {
      if (billingCount >= 10)
        score = "A"
      else if (billingCount >= 6)
        score = "B"
      else if (billingCount >= 2)
        score = "C"
      else if (billingCount === 1)
        score = "D"
      else
        score = "E"
    }

    return (
      <Popup inverted trigger={
        <span className={style.ScoreGrade}>{ score.toUpperCase() }</span>
      } content="Frequência com que o cliente é cobrado" />
    )
  }

  gradeValue() {
    // E: 1st quintile, or <100
    // D: 2nd quintile, or 100-250
    // C: 3rd quintile, or 250-500
    // B: 4th quintile, or 500-1000
    // A: 5th quintile, or 1000+

    const a = _.map(this.props.billings, "amountBilled")
    const val = _.sum(a) / _.size(a)

    let score
    if (val < 100)
      score = "E"
    else if (val < 250)
      score = "D"
    else if (val < 500)
      score = "C"
    else if (val < 1000)
      score = "B"
    else
      score = "A"

    return (
      <Popup inverted trigger={
        <span className={style.ScoreGrade}>{ score.toUpperCase() }</span>
      } content="Significância financeira" />
    )
  }

  gradeRFV() {
    return (
      <div>
        { this.gradeRecency() }
        { this.gradeFrequency() }
        { this.gradeValue() }
      </div>
    )
  }

  conversionRate() {
    const typicalDelay = this.typicalPaymentDelay()

    const paidStateCount = _.countBy(
      this.props.billings,
      billingIsPaid
    ).true

    const finalStateCount = _.countBy(
      this.props.billings,
      billingIsFinalState
    ).true

    const rate = paidStateCount / Math.max(1, finalStateCount)
    const perc = Math.round(rate * 100)
    const preStars = Math.round(rate * 5)
    const stars = preStars >= 2 && typicalDelay && typicalDelay < -3 ?
      preStars - 1 : preStars

    return {
      conversionRate: rate,
      conversionRatePerc: perc,
      stars
    }
  }

  typicalPaymentDelay() {
    const list = _.filter(
      this.props.billings,
      x => x.amountPaid > 0 &&
        billingIsPaid(x) &&
        x.dueDate &&
        x.statusDate
    )

    if (_.isEmpty(list))
      return null

    const delays = _.map(
      list,
      x => moment(x.dueDate).diff(moment(x.statusDate), 'days')
    )

    return Math.round(_.sum(delays) / _.size(delays))
  }

  renderDelayMessage() {
    const typicalDelay = this.typicalPaymentDelay()

    if (typicalDelay > 3)
      return (
        <span>
          <FaThumbsUp size={20} className="mr-2" />
          Pagamentos usualmente com { Math.abs(typicalDelay) } dias de antecedência.
        </span>
      )
    else if (typicalDelay < -3)
      return (
        <span>
          <FaThumbsDown size={20} className="mr-2" />
          Pagamentos usualmente atrasam { Math.abs(typicalDelay) } dias.
        </span>
      )
    else
      return (
        <span>
          <FaThumbsUp size={20} className="mr-2" />
          Pagamentos usualmente em dia.
        </span>
      )
  }

  render() {
    if (_.size(this.props.billings) < 3)
      return null

    const stats = this.statistics()
    const { conversionRatePerc, stars } = this.conversionRate()

    return (
      <Grid className={classNames(style.SummaryBody, 'pt-3')}>
        <Grid.Row columns={3} centered verticalAlign="middle">
          <Grid.Column width={9} textAlign="center" verticalAlign="middle">
            <Popup trigger={
              <Rating disabled size="large" maxRating={5}
                      rating={stars}/>
            }
                   position="top center"
                   inverted
                   header="Taxa de pagamentos"
                   content={
                     <div>
                       <p>
                         Cliente pagou { conversionRatePerc }% das faturas
                         encerradas.
                       </p>
                       { this.renderDelayMessage() }
                     </div>
                   }
            />
            <Statistic value={this.gradeRFV()} label={
              <Popup inverted trigger={
                <div className={style.ScoreSubtitle}>
                  Nota do cliente
                </div>
              } content={
                <div>
                  <p>
                    A nota geral é composta por três notas, cada uma variando de
                    A (melhor) até E (pior).
                  </p>
                  <p>
                    Por exemplo, clientes AAA e ABA podem ser vistos como
                    importantes para o negócio,
                    enquanto clientes medianos CDD e BDC têm uma significância
                    muito pequena.
                  </p>
                  <p>
                    Para saber mais, passe o mouse sobre cada nota.
                  </p>
                </div>
              }/>
            } size="large" />
          </Grid.Column>
          <Grid.Column width={7} textAlign="right">
            <Popup position="top right" trigger={
              <div className={style.KPI}>
                {
                  stats.amountReceivable < 0.01 ?
                    "Nenhum" :
                    <PriceTag value={stats.amountReceivable} />
                }
                <FaBarcode size={18} className="ml-2 a-m" />
              </div>
            } inverted content="Valor a receber" />
            <Popup position="top right" trigger={
              <div className={classNames(style.KPI, 'mt-2')}>
                {
                  stats.amountPaid < 0.01 ?
                    "Nenhum" :
                    <PriceTag value={stats.amountPaid} />
                }
                <FaCheckCircle size={18} className="ml-2 a-m" />
              </div>
            } inverted content="Valor recebido até agora" />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

export default ReceivablesSummary
