import React from "react";
import { Drawer, IconButton } from "@plipag/ceci-ui";
import UserCard from "./UserCard";
import AccountMenu from "./AccountMenu";
import { MdClose } from "react-icons/md";
import MerchantCard from "./MerchantCard";

const HeaderAccount = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <MerchantCard onClick={() => setOpen(true)} />
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ zIndex: 1200 }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: 30,
          }}
        >
          <UserCard />
          <IconButton onClick={() => setOpen(false)} sx={{ ml: 3 }}>
            <MdClose />
          </IconButton>
        </div>
        <AccountMenu close={() => setOpen(false)} />
      </Drawer>
    </>
  );
};

export default HeaderAccount;
