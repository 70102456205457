import _ from "lodash";
import client from "src/store/apolloClient";
import { DOC_TYPE, docType } from "src/utils/document";
import { validEmail } from "src/utils/email";
import { validateEmail } from "src/utils/enrichment";
import { gql } from "apollo-boost";
export async function checkDeliverable(state, setState) {
  return await emailDeliverable(state, setState);
}

export async function emailDeliverable(state, setState) {
  if (state.emailEnrichment && state.emailEnrichment.to === state.email)
    return true;

  setState({ enriching: true });

  return await validateEmail(state.email)
    .then((resp) => {
      const enrichment = resp.data.data;

      setState({ emailEnrichment: enrichment, enriching: false });

      let errorStr = "";
      if (enrichment.validMailbox === false) {
        errorStr +=
          "Este email não existe e as faturas não poderão ser entregues. ";

        errorStr +=
          _.size(state.emailSuggestions).length > 0
            ? "Verifique com o cliente ou escolha uma das sugestões. "
            : "Verifique com o cliente o email correto. ";

        if (enrichment.didYouMean)
          errorStr += `Você quis dizer ${enrichment.didYouMean}?`;

        setState({
          error: errorStr,
        });
      }

      return true;
    })
    .catch((err) => {
      setState({ enriching: false });

      return true;
    });
}

export function validateFields(state, setState) {
  if (![DOC_TYPE.CPF, DOC_TYPE.CNPJ].includes(docType(state.document))) {
    setState({
      error: "Informe um documento CPF/CNPJ válido.",
    });
    return false;
  }

  if (String(state.name) <= 3) {
    setState({
      error: "Informe o nome do responsável financeiro, titular do documento.",
    });
    return false;
  }

  if (!validEmail(state.email)) {
    setState({
      error: "Informe um email válido",
    });
    return false;
  }

  if (String(state.nickname) <= 3) {
    setState({
      error: "Informe um nome ou apelido para o cliente.",
    });
    return false;
  }

  return true;
}

export async function validateCustomerBase(state, setState) {
  const query = client.readQuery({
    query: gql`
      query CustomersDocuments {
        customers {
          document
          email
        }
      }
    `,
  });
  const customers = query.customers;

  const customerAlreadyExists = _.filter(customers, {
    document: state.document,
  });
  if (_.size(customerAlreadyExists) > 0) {
    setState({
      error: "Já existe um cliente com este documento",
    });
    return false;
  }

  const exceededEmails = _.filter(customers, { email: state.email });
  if (_.size(exceededEmails) >= 5) {
    setState({
      error: "Este email já foi usado em pelo menos 5 clientes",
    });
    return false;
  }

  return true;
}
