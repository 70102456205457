import React from "react";
import classNames from "classnames";
import SegmentVisit from "src/plg/Segment/SegmentVisit";

import style from "./Context.module.css";

import BackButton from "src/uikit/BackButton";

class Context extends React.Component {
  render() {
    return (
      <SegmentVisit>
        <div>
          {this.props.bg && (
            <React.Fragment>
              <div className={style.Outer} />
              <div className={style.Inner} />
            </React.Fragment>
          )}
          <div className={style.Controls}>
            {this.props.goBack && (
              <BackButton goTo={this.props.goTo} goBack={this.props.goBack} />
            )}
            {this.props.goBack && (
              <div className={style.FloatRight}>
                <BackButton
                  goTo={this.props.goTo}
                  goBack={this.props.goBack}
                  close
                />
              </div>
            )}
          </div>
          <div className={classNames(style.Page, "fadein", "pt-2", "pb-5")}>
            <div
              className={
                this.props.fluid ? style.ContainerFluid : style.Container
              }
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </SegmentVisit>
    );
  }
}

export default Context;
