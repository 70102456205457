import * as cpf from '@fnando/cpf'
import * as cnpj from '@fnando/cnpj'

export const DOC_TYPE = { CPF: 'CPF', CNPJ: 'CNPJ' }

export function docValid(doc) {
  return cpf.isValid(doc) || cnpj.isValid(doc)
}

export function docType(doc) {
  if (cpf.isValid(doc))
    return DOC_TYPE.CPF

  if (cnpj.isValid(doc))
    return DOC_TYPE.CNPJ

  return null
}

export function docFormat(doc) {
  switch (docType(doc)) {
    case DOC_TYPE.CPF:
      return cpf.format(doc)
    case DOC_TYPE.CNPJ:
      return cnpj.format(doc)
    default:
      return doc.replace(/\D/g, '')
  }
}
