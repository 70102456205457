import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@plipag/ceci-ui";
import { FaCheckCircle } from "react-icons/fa";
import { BillingSuccess } from "./BillingSuccess";
import { RecurrenceSuccess } from "./RecurrenceSuccess";

type Props = {
  onClose: () => void;
  close: () => void;
  items: Array<{ [key: string]: any }>;
};

export function SuccessDialog({ onClose, close, items }: Props) {
  const isRecurrence =
    items.length > 0 && Object.keys(items[0]).includes("amount");

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogContent sx={{ textAlign: "center", pt: 4 }}>
        <FaCheckCircle style={{ fontSize: 64, color: "rgb(31, 153, 92)" }} />
        <Typography variant="h5" color="success">
          Feito!
        </Typography>

        {!isRecurrence && <BillingSuccess billings={items} />}
        {isRecurrence && <RecurrenceSuccess recurrences={items} />}

        <DialogActions>
          <Button variant="contained" color="secondary" onClick={close}>
            Fechar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
