import React from "react";
import logo from "src/assets/logo.svg";
import { Link } from "react-router-dom";

function HeaderLogo() {
  return (
    <Link
      to="/"
      style={{ alignSelf: "stretch", alignItems: "center", display: "flex" }}
    >
      <img src={logo} alt="" style={{ marginLeft: 10 }} />
    </Link>
  );
}

export default HeaderLogo;
