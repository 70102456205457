import { gql } from 'apollo-boost'

const SettlementRequestProps = gql`
  fragment SettlementRequestProps on SettlementRequest {
    id
    status
    amount
    scheduled
    manual
    createdAt
  }
`

export default SettlementRequestProps