const obj = {
  // Analytics.
  SEGMENT_TRACK_PAGEVIEWS: false,
  // Billing.
  BILLING_EXPRESS_WINDOW: 11,
  BILLING_MIN_DAYS_AHEAD: 1,
};

// es-lint ignore-next-line
export default obj;
