import { gql } from 'apollo-boost'

import BillingProps from '../fragments/billing'

const GQL_EXPRESS_BILLING = gql`
  mutation ExpressBilling($id: ID!) {
    expressBilling(id: $id) {
      ...BillingProps
    }
  }
  ${BillingProps}
`

export default GQL_EXPRESS_BILLING