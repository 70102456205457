import React from "react";
import {
  Box,
  IconButton,
  ToolbarMenu,
  ToolbarMenuItem,
  useTheme,
} from "@plipag/ceci-ui";
import {
  MdGroup,
  MdMenu,
  MdClose,
  MdInsertChart,
  MdInsertInvitation,
  MdPowerSettingsNew,
  MdSettings,
  MdHelp,
  MdFavorite,
  MdImportExport,
  MdAttachMoney,
} from "react-icons/md";
import { Link } from "react-router-dom";
import MerchantCard from "./MerchantCard";

import BankAccountsModal from "src/modals/BankAccountsModal";
import HelpModal from "src/modals/HelpModal";
import ConfigsModal from "src/modals/ConfigsModal";
import ExportModal from "src/modals/ExportModal";

import { LogoutButton } from "../LogoutButton";

const ToolbarWrapper = ({ background, ...props }) => (
  <Box
    sx={{
      background: background,
      position: "absolute",
      top: 68,
      paddingBottom: "68px",
      width: "100%",
      height: "100vh",
      minHeight: "-webkit-fill-available",
      left: 0,
      display: "flex",
      flexDirection: "column",
    }}
    {...props}
  />
);

const MenuWrapper = (props) => (
  <Box
    sx={{
      overflowY: "auto",
      overflowX: "hidden",
      flexGrow: 1,
    }}
    {...props}
  />
);

const MobileToolbar = () => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <IconButton
        style={{ marginLeft: "auto", fontSize: 30 }}
        onClick={() => setOpen(!open)}
      >
        {open ? <MdClose /> : <MdMenu />}
      </IconButton>

      {open && (
        <ToolbarWrapper background={theme.palette.primary.main}>
          <MenuWrapper onClick={() => setOpen(false)}>
            <ToolbarMenu style={{ flexDirection: "column", padding: 0 }}>
              <ToolbarMenuItem component={Link} to="/" sx={{ width: "100%" }}>
                <MdInsertChart />
                Visão Geral
              </ToolbarMenuItem>
              <ToolbarMenuItem
                component={Link}
                to="/cobrancas"
                sx={{ width: "100%" }}
              >
                <MdInsertInvitation />
                Cobranças
              </ToolbarMenuItem>
              <ToolbarMenuItem
                component={Link}
                to="/clientes"
                sx={{ width: "100%" }}
              >
                <MdGroup />
                Clientes
              </ToolbarMenuItem>
              <ConfigsModal
                trigger={
                  <ToolbarMenuItem fullWidth sx={{ width: "100%" }}>
                    <MdSettings />
                    Configurações
                  </ToolbarMenuItem>
                }
              />
              <BankAccountsModal
                trigger={
                  <ToolbarMenuItem fullWidth sx={{ width: "100%" }}>
                    <MdAttachMoney />
                    Dados bancários
                  </ToolbarMenuItem>
                }
              />
              <ExportModal
                trigger={
                  <ToolbarMenuItem fullWidth sx={{ width: "100%" }}>
                    <MdImportExport />
                    Exportar dados
                  </ToolbarMenuItem>
                }
              />
              <ToolbarMenuItem
                component={Link}
                to="/indicar"
                sx={{ width: "100%" }}
              >
                <MdFavorite />
                Indique e ganhe
              </ToolbarMenuItem>
              <HelpModal
                trigger={
                  <ToolbarMenuItem fullWidth sx={{ width: "100%" }}>
                    <MdHelp />
                    Ajuda
                  </ToolbarMenuItem>
                }
              />
              <LogoutButton>
                <ToolbarMenuItem fullWidth sx={{ width: "100%" }}>
                  <MdPowerSettingsNew />
                  Sair
                </ToolbarMenuItem>
              </LogoutButton>
            </ToolbarMenu>
          </MenuWrapper>
          <div
            style={{
              padding: "20px 40px",
              justifySelf: "end",
              background: theme.palette.primary.main,
              boxShadow:
                "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
            }}
          >
            <MerchantCard />
          </div>
        </ToolbarWrapper>
      )}
    </>
  );
};

export default MobileToolbar;
