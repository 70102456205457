import React from "react";

import { MdSupervisorAccount } from "react-icons/md";
import Modal from "../../uikit/Modal";
import ManagerPicture from "./taislayne-araujo.jpg";
import style from "./HelpModal.module.css";
import { Button } from "semantic-ui-react";

class HelpModal extends React.Component {
  renderContent() {
    return (
      <div>
        <div className={style.ModalHeader}>
          <ul className="horizontal relaxed middle">
            <li>
              <MdSupervisorAccount size={64} />
            </li>
            <li>
              <div className={style.ModalSubtitle}>Gestão da conta</div>
              <div className={style.ModalTitle}>Gerente responsável</div>
            </li>
          </ul>
        </div>
        <div className={style.ModalBody}>
          <ul className="vertical dark-grey text-center">
            <li className="a-m">
              <img src={ManagerPicture} alt="" className={style.Photo} />
            </li>
            <li className="a-m text-center">
              <div className={style.Name}>Taislayne Araújo</div>
              <div className={style.Email}>meajuda@plipag.com.br</div>
            </li>
          </ul>
        </div>
        <div className={style.ModalFooter}>
          <Button
            fluid
            href="https://ajuda.plipag.com.br/pt-BR/"
            target="_blank"
          >
            Base de conhecimento
          </Button>
        </div>
      </div>
    );
  }

  openCallback() {}

  render() {
    return (
      <Modal
        trigger={this.props.trigger}
        size="medium"
        borderStyle="ssss"
        openCallback={this.openCallback}
      >
        {(closeModal) => this.renderContent()}
      </Modal>
    );
  }
}

export default HelpModal;
