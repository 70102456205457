import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import zxcvbn from "zxcvbn";
import classNames from "classnames";

import { minimumStrength } from "src/utils/passwords";
import PasswordStrength from "src/uikit/PasswordStrength";

import {
  Grid,
  Button,
  Form,
  Icon,
  Header,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import { MdAccountCircle } from "react-icons/md";
import Modal from "src/uikit/Modal";

import style from "./UserChangePasswordModal.module.css";

import GQL_CHANGE_PASSWORD from "src/graphql/mutations/ChangePassword.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

class UserChangePasswordModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: "",
      newPassword: "",
      newPasswordCheck: "",
      submit: false,
      strength: 0,
      success: false,
      fail: false,
    };

    this.handleCurrentPassword = this.handleCurrentPassword.bind(this);
    this.handleNewPassword = this.handleNewPassword.bind(this);
    this.handleNewPasswordCheck = this.handleNewPasswordCheck.bind(this);
    this.confirm = this.confirm.bind(this);
  }

  handleCurrentPassword(event) {
    this.setState({
      currentPassword: event.target.value,
      fail: false,
    });
  }

  handleNewPassword(event) {
    this.setState(
      {
        newPassword: event.target.value,
        fail: false,
        strength: UserChangePasswordModal.checkStrength(event.target.value),
      },
      () => {
        this.setState({
          submit: UserChangePasswordModal.checkPasswords(
            this.state.newPassword,
            this.state.newPasswordCheck,
            this.state.currentPassword
          ),
        });
      }
    );
  }

  handleNewPasswordCheck(event) {
    this.setState(
      {
        newPasswordCheck: event.target.value,
        fail: false,
      },
      () => {
        this.setState({
          submit: UserChangePasswordModal.checkPasswords(
            this.state.newPassword,
            this.state.newPasswordCheck,
            this.state.currentPassword
          ),
        });
      }
    );
  }

  confirm(data) {
    if (data.changePassword) {
      this.setState({ success: true, fail: false });
      setTimeout(() => this.setState({ redirectTo: "/logout" }), 3000);
    } else {
      this.setState({ success: false, fail: true });
    }
  }

  static checkPasswords(p1, p2, current) {
    return p1.length >= 6 && p1 === p2 && p1 !== current;
  }

  static checkStrength(password) {
    return zxcvbn(password).score;
  }

  static renderSuccess() {
    return (
      <Dimmer active page>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2" icon inverted>
                <Icon name="check circle" />
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h1" icon inverted>
                Feito!
                <Header.Subheader>Senha alterada.</Header.Subheader>
              </Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Loader active />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Dimmer>
    );
  }

  renderContent() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const { currentPassword, newPassword, newPasswordCheck } = this.state;

    return (
      <Mutation
        mutation={GQL_CHANGE_PASSWORD}
        variables={{ currentPassword, newPassword }}
        onCompleted={(data) => this.confirm(data)}
      >
        {(changePassword, { loading, error }) => {
          if (loading) return <OrbitsLoader />;
          if (error) return <GqlError error={error} />;

          return (
            <div>
              <div className={style.ModalHeader}>
                <ul className="horizontal relaxed middle">
                  <li>
                    <MdAccountCircle size={64} />
                  </li>
                  <li>
                    <div className={style.ModalSubtitle}>Gestão da conta</div>
                    <div className={style.ModalTitle}>Troca de senha</div>
                  </li>
                </ul>
              </div>
              <Form onSubmit={changePassword}>
                {this.state.success && UserChangePasswordModal.renderSuccess()}
                <div className={style.ModalBody}>
                  <div className={classNames(style.FieldSep)}>
                    {this.state.fail && (
                      <Message negative>
                        <Message.Header>Erro</Message.Header>
                        <p>Parece que a senha atual não é esta.</p>
                      </Message>
                    )}
                    {this.state.newPassword.length >= 6 &&
                    this.state.strength < minimumStrength ? (
                      <Message negative>
                        <Message.Header>Senha fraca</Message.Header>
                        <p>
                          Combine letras e números para formar sua nova senha.
                          Evite senhas óbvias como números sequenciais e datas
                          de aniversário.
                        </p>
                      </Message>
                    ) : (
                      <Message positive>
                        <Message.Header>
                          Dispositivos serão desconectados
                        </Message.Header>
                        <p>
                          Como medida de segurança, todos os dispositivos serão
                          desconectados após a troca de senha. Em seguida,
                          iremos para página inicial para acessar a conta com a
                          nova senha.
                        </p>
                      </Message>
                    )}
                  </div>
                  <div className={classNames(style.FieldSep)}>
                    <Form.Input
                      type="password"
                      placeholder="Senha atual"
                      value={currentPassword}
                      icon="key"
                      iconPosition="left"
                      error={this.state.fail}
                      onChange={this.handleCurrentPassword}
                    />
                  </div>
                  <div className={classNames(style.FieldSep)}>
                    <Form.Input
                      type="password"
                      placeholder="Nova senha"
                      value={newPassword}
                      icon="key"
                      iconPosition="left"
                      onChange={this.handleNewPassword}
                    />
                  </div>
                  <div className={classNames(style.FieldSep)}>
                    <Form.Input
                      type="password"
                      placeholder="Confirmar senha"
                      value={newPasswordCheck}
                      icon="key"
                      iconPosition="left"
                      onChange={this.handleNewPasswordCheck}
                    />
                    <PasswordStrength strength={this.state.strength} />
                  </div>
                </div>
                <div className={style.ModalFooter}>
                  <Button
                    primary
                    fluid
                    size="huge"
                    disabled={
                      !this.state.submit ||
                      this.state.strength < minimumStrength
                    }
                  >
                    <span>Trocar senha</span>
                    <Icon name="arrow alternate circle right outline" />
                  </Button>
                </div>
              </Form>
            </div>
          );
        }}
      </Mutation>
    );
  }

  openCallback() {}

  render() {
    return (
      <Modal
        trigger={this.props.trigger}
        size="small"
        borderStyle="ssbb"
        whiteBackground
        openCallback={this.openCallback}
      >
        {(closeModal) => this.renderContent()}
      </Modal>
    );
  }
}

export default UserChangePasswordModal;
