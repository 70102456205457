import React from 'react'
import moment from 'moment'
import 'moment/locale/pt-br'

import { FaChevronCircleLeft, FaChevronCircleRight, FaCalendarAlt } from 'react-icons/fa'

import style from './MonthsNavigator.module.css'

class MonthsNavigator extends React.Component {
  render() {
    return (
      <div className={style.MonthsNavigator}>
        <FaCalendarAlt size={22} className={style.Icon} />
        <FaChevronCircleLeft
          size={22} className={style.Button} onClick={() => {
            this.props.monthBackward()
          }} />
        <span className={style.Name}>
          {
            moment(this.props.currentMonth).year() === moment().year() ?
              moment(this.props.currentMonth).format('MMMM').toUpperCase() :
              moment(this.props.currentMonth).format('MMMM YYYY').toUpperCase()
          }
        </span>
        <FaChevronCircleRight
          size={22} className={style.Button} onClick={() => {
            this.props.monthForward()
          }} />
      </div>
    )
  }
}

export default MonthsNavigator
