import { gql } from 'apollo-boost'
import SettlementProps from '../fragments/settlement'

const GQL_SETTLEMENTS = gql`
  query Settlements($first: Int, $skip: Int) {
    settlements(first: $first, skip: $skip) {
      ...SettlementProps
    }
  }
  ${SettlementProps}
`

export default GQL_SETTLEMENTS