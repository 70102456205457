import { gql } from 'apollo-boost'
import UserProps from '../fragments/user'

const GQL_CURRENT_USER = gql`
  query CurrentUser {
    user {
      ...UserProps
    }
  }
  ${UserProps}
`

export default GQL_CURRENT_USER