import React from "react";
import MonthsNavigator from "src/components/MonthsNavigator";
import { Grid, Dropdown } from "semantic-ui-react";
import moment from "moment";
import "moment/locale/pt-br";
import style from "src/components/BillingsNavigator/BillingsNavigator.module.css";
import { BillingsContext, OrdersKeys } from "../context";
import { FiltersSelect } from "src/components/BillingsNavigator/partials/Filters/FiltersSelect";
import { sortingOptions } from "src/components/BillingsNavigator/sorting";

export function Toolbar() {
  const { filter, setFilter, currentMonth, setCurrentMonth, order, setOrder } =
    React.useContext(BillingsContext);

  function monthForward() {
    setCurrentMonth(moment(currentMonth).add(1, "month").format("YYYY-MM-DD"));
  }
  // a
  function monthBackward() {
    setCurrentMonth(
      moment(currentMonth).subtract(1, "month").format("YYYY-MM-DD")
    );
  }

  return (
    <div className="pb-4">
      <Grid relaxed doubling stackable>
        <Grid.Row columns={2} className="pl-5">
          <Grid.Column computer={6} tablet={16} mobile={16} textAlign="left">
            <MonthsNavigator
              monthForward={monthForward}
              monthBackward={monthBackward}
              currentMonth={currentMonth}
            />
          </Grid.Column>
          <Grid.Column
            computer={10}
            tablet={16}
            mobile={16}
            textAlign="right"
            verticalAlign="top"
          >
            <ul className="horizontal">
              <li>
                <div>
                  <span className={style.FilterTitle}>Organizar</span>
                  <Dropdown
                    basic
                    options={sortingOptions}
                    defaultValue={order}
                    onChange={(_, { value }) => setOrder(value as OrdersKeys)}
                  />
                </div>
              </li>
              <li>
                <div className="ml-5">
                  <span className={style.FilterTitle}>Filtros:</span>
                  <FiltersSelect value={filter} handleFilter={setFilter} />
                </div>
              </li>
            </ul>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
}
