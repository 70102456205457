import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { Grid, useMediaQuery, useTheme } from "@plipag/ceci-ui";
import SettledThisMonth from "./SettledThisMonth";
import Balance from "./Balance";
import OpenPayments from "./OpenPayments";

const GQL_KPIS = gql`
  query Kpis {
    kpi {
      settledThisMonth
      balance

      customerCount
      avgTicket
      defaultRate
    }
  }
`;

const KPIs = () => {
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const { data, loading, error } = useQuery(GQL_KPIS);

  if (loading || error) return null;
  return (
    <Grid
      container
      direction={smallDevice ? "column" : "row"}
      justifyContent="stretch"
      spacing={4}
      sx={{ p: 2, pt: 4 }}
    >
      <Grid item flexGrow={1}>
        <SettledThisMonth value={data?.kpi?.settledThisMonth} />
      </Grid>
      <Grid item flexGrow={1}>
        <Balance value={data?.kpi?.balance} />
      </Grid>
      <Grid item flexGrow={1}>
        <OpenPayments />
      </Grid>
    </Grid>
  );
};

export default KPIs;
