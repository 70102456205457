const a = {
  FILE_CONSTITUTION: {
    name: "file_constitution",
    uploadField: "files",
  },
  FILE_CONTACT_POI: {
    name: "file_contact_poi",
    uploadField: "files",
  },
  FILE_CONTACT_POA: {
    name: "file_contact_poa",
    uploadField: "files",
  },
  LOGO: {
    name: "logo",
    uploadField: "file",
  },
};
export default a;
