import { gql } from "apollo-boost";

const BillingProps = gql`
  fragment BillingProps on Billing {
    id
    customerId
    recurrenceId
    dueDate
    amountBilled
    consumedBalance
    netBilled
    amountPaid
    discountPolicy
    discountDays
    discountAmount
    interestPolicy
    description
    status
    statusDate
    cycle
    seen
    proxy
    anticipate
    url(track: false)
    urlPreview
    createdAt
    updatedAt
  }
`;

export default BillingProps;
