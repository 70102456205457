import React from "react";
import { List } from "@plipag/ceci-ui";
import { Pagination } from "../Pagination";
import { useHistory } from "react-router";
import { LimitSelector } from "./LimitSelector";

type Props = {
  total: number;
  page: number;
  children: React.ReactNode;
  limit: number;
  setLimit: (limit: number) => void;
  limitSelector: boolean;
};

export function PaginatedList({
  children,
  total,
  page,
  limit,
  setLimit,
  limitSelector,
}: Props) {
  const history = useHistory();
  const pages = Math.ceil(total / limit);

  function handleChange(_: any, page: number) {
    history.push(`?p=${page}`);
  }

  return (
    <>
      {limitSelector && (
        <LimitSelector
          sx={{ mt: 2, display: "-webkit-box", justifyContent: "end" }}
          total={total}
          limit={limit}
          setLimit={setLimit}
        />
      )}
      <List sx={{ mt: 1, mb: 1 }}>{children}</List>
      <Pagination page={page} pages={pages} handleChange={handleChange} />
    </>
  );
}
