import { gql } from 'apollo-boost'

import CustomerProps from '../fragments/customer'

const GQL_CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $document: String!,
    $name: String!,
    $nickname: String,
    $email: Email!,
    $mobile: String,
    $street: String,
    $district: String,
    $city: String,
    $province: String,
    $zipcode: String
  ) {
    createCustomer(
      document: $document,
      name: $name,
      nickname: $nickname,
      email: $email,
      mobile: $mobile,
      street: $street,
      district: $district,
      city: $city,
      province: $province,
      zipcode: $zipcode
    ) {
      ...CustomerProps
    }
  }
  ${CustomerProps}
`

export default GQL_CREATE_CUSTOMER