import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardHeader,
  CardActionArea,
  CardContent,
  Typography,
  useTheme,
} from "@plipag/ceci-ui";

const FeatureCard = React.forwardRef(
  ({ Icon, title, description, to, ...props }, ref) => {
    const theme = useTheme();
    return (
      <Card sx={{ height: "100%" }}>
        <CardActionArea
          sx={{ p: 2, height: "100%" }}
          component={Link}
          to={to}
          ref={ref}
          {...props}
        >
          <CardHeader
            avatar={
              <Box
                sx={{
                  border: `4px solid ${theme.palette.secondary.main}`,
                  borderRadius: "50%",
                  padding: 2,
                  color: theme.palette.secondary.main,
                  width: 65,
                  height: 65,
                }}
              >
                <Icon size={25} />
              </Box>
            }
            title={
              <Typography variant="h4" color="primary">
                {title}
              </Typography>
            }
            sx={{
              display: "flex",
              alignItems: "center",
              pb: 0,
              mb: 2,
              minHeight: 100,
            }}
          />

          <CardContent sx={{ pt: 1 }}>
            <Typography>{description}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
);

export default FeatureCard;
