import React from "react";
import { Box, Typography } from "@plipag/ceci-ui";
import logo from "src/assets/logo.svg";
import { AppContext } from "src/app/context";

export function SplashScreen({ error }: { error: any }) {
  const { networkError } = React.useContext(AppContext);
  return (
    <Box
      sx={{
        background: "rgb(38, 66, 124)",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <img
        src={logo}
        alt=""
        style={{
          width: "80%",
          maxWidth: 500,
          margin: "48px 20px",
        }}
      />
      {networkError && (
        <>
          <Typography
            sx={{ color: "white !important", mt: 6, mb: 1 }}
            variant="h4"
          >
            Estamos enfrentando uma instabilidade em nossos servidores.
          </Typography>
          <Typography sx={{ color: "white !important", mb: 3 }} variant="h6">
            Por favor, tente novamente mais tarde.
          </Typography>
          <Typography sx={{ color: "white !important", mb: 3 }} variant="h6">
            Para checar saldo, pagamentos e transferências. Fale com
            meajuda@plipag.com.br ou (41) 98901.5376
          </Typography>
        </>
      )}
    </Box>
  );
}
