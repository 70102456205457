import React from "react";
import axios from "axios";
import { getToken } from "src/app/token";
import { Typography, Skeleton } from "@plipag/ceci-ui";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const BoletosProgress = () => {
  const [paid, setPaid] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get(
        `${
          process.env.REACT_APP_FUNCTIONS_URL
        }/boletos-counter/?token=${getToken()}`
      )
      .then((response) => {
        setPaid(response.data.paid);
        setTotal(response.data.total);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  }, []);

  if (loading)
    return (
      <Skeleton
        variant="circular"
        width={240}
        height={240}
        style={{ margin: "8px auto" }}
      />
    );

  return (
    <div style={{ width: 240, height: 240, margin: "8px auto" }}>
      <CircularProgressbarWithChildren
        value={(paid * 100) / total}
        styles={{
          path: {
            stroke: `#26427C`,
            strokeLinecap: "round",
            transition: "stroke-dashoffset 0.5s ease 0s",
            transformOrigin: "center center",
          },
          trail: {
            stroke: "#CFD6E6",
            strokeLinecap: "round",
            transformOrigin: "center center",
          },
        }}
      >
        <div style={{ textAlign: "center", marginBottom: 20 }}>
          <Typography variant="h5" sx={{ color: "#7A8599 !important" }}>
            {paid} de {total}
          </Typography>
          <Typography variant="p" color="primary">
            boletos pagos este mês
          </Typography>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

export default BoletosProgress;
