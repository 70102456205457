import React from "react";
import { Card, CardContent, Typography } from "@plipag/ceci-ui";
import RevenueChart from "./RevenueChart";
import KPIs from "./KPIs";

const Dashboard = () => {
  return (
    <Card>
      <CardContent sx={{ pt: 3 }}>
        <Typography variant="h5" color="primary" sx={{ p: 2 }}>
          Histórico de cobranças
        </Typography>
        <RevenueChart />
        <KPIs />
      </CardContent>
    </Card>
  );
};

export default Dashboard;
