import React from "react";
import moment from "moment";
import "moment/locale/pt-br";
import { useQuery } from "react-apollo";
import { gql } from "apollo-boost";
import { Link } from "react-router-dom";
import { SummaryCard } from "src/components/SummaryCard";
import Tooltip from "src/components/Tooltip";

const GQL_KPI_OPEN_PAYMENTS = gql`
  query KpiOpenPayments($month: Date) {
    kpi {
      openPayments(month: $month)
    }
  }
`;

const OpenPayments = () => {
  const { data, error, loading } = useQuery(GQL_KPI_OPEN_PAYMENTS, {
    variables: { month: moment().startOf("month").format("YYYY[-]MM[-]DD") },
  });

  if (loading || error) return null;

  const formatedValue = `R$
  ${new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(data?.kpi?.openPayments)}`;

  return (
    <Tooltip
      title="A receber neste mês"
      content="Esse é o valor total das cobranças com vencimento neste mês."
    >
      <SummaryCard
        component={Link}
        to={"/cobrancas"}
        value={formatedValue}
        label="A receber"
      />
    </Tooltip>
  );
};

export default OpenPayments;
