import React from "react";
import _ from "lodash";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import Fuse from "fuse.js";
import classNames from "classnames";

import { Grid } from "semantic-ui-react";
import { MdSearch } from "react-icons/md";
import Input from "src/uikit/Forms/Input";

import style from "./SearchForm.module.css";

import GQL_ALL_CUSTOMERS from "src/graphql/queries/AllCustomers.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

class SearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      term: "",
      results: [],
    };

    this.buildFuse = this.buildFuse.bind(this);
    this.handleTerm = this.handleTerm.bind(this);
  }

  buildFuse(data) {
    if (!this.fuse)
      this.fuse = new Fuse(data, {
        shouldSort: true,
        includeMatches: true,
        threshold: 0.3,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["nickname", "name", "document", "email", "mobile"],
      });
  }

  handleTerm(event) {
    this.setState({
      term: event.target.value,
    });
  }

  render() {
    return (
      <Grid centered>
        <Grid.Row columns={1}>
          <Grid.Column width={8}>
            <Input
              placeholder="Procurar..."
              value={this.state.term}
              onChange={this.handleTerm}
              icon={<MdSearch />}
              zoom={1.5}
              autofocus
            />
            <Query query={GQL_ALL_CUSTOMERS}>
              {({ loading, error, data, refetch }) => {
                if (loading) return <OrbitsLoader />;
                if (error) return <GqlError error={error} />;

                this.buildFuse(data.customers);

                const results = this.fuse.search(this.state.term);

                return (
                  <div className="mt-5">
                    {this.state.term.length > 0 && results.length === 0 && (
                      <span className="font-italic">Nenhum resultado.</span>
                    )}
                    <ul className="vertical">
                      {_.map(results.slice(0, 5), (r) => {
                        return (
                          <li key={r.item.id} className="p-3 fadeInUpBig">
                            <Link
                              to={`/clientes/${r.item.id}`}
                              className={style.result}
                            >
                              <div
                                className={classNames(
                                  "h4",
                                  "font-weight-bold",
                                  style[r.item.status]
                                )}
                              >
                                {r.item.name}
                              </div>
                              <div
                                className={classNames(
                                  "h6",
                                  style[r.item.status]
                                )}
                              >
                                {r.item.nickname}
                              </div>
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              }}
            </Query>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default SearchForm;
