import { gql } from "apollo-boost";

import RecurrenceProps from "../fragments/recurrence";

const GQL_CREATE_RECURRENCE_FOR_CUSTOMERS = gql`
  mutation CreateRecurrenceForCustomer(
    $customersIds: [ID!]!
    $mday: Int!
    $amount: Float!
    $description: String
    $interestPolicy: InterestPolicy!
    $totalCycles: Int!
    $firstDueDate: Date!
    $discountAmount: Float
    $discountDays: Int
  ) {
    createRecurrenceForCustomers(
      customersIds: $customersIds
      mday: $mday
      amount: $amount
      description: $description
      interestPolicy: $interestPolicy
      totalCycles: $totalCycles
      firstDueDate: $firstDueDate
      discountAmount: $discountAmount
      discountDays: $discountDays
    ) {
      recurrences {
        ...RecurrenceProps
      }
    }
  }
  ${RecurrenceProps}
`;

export default GQL_CREATE_RECURRENCE_FOR_CUSTOMERS;
