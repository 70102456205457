import { Select } from "@plipag/ceci-ui";
import React from "react";
import { CustomersContext, OrdersKeys, FiltersKeys } from "../context";

export function Filters() {
  const { filter, setFilter, order, setOrder } =
    React.useContext(CustomersContext);
  const orders = [
    { value: "nickname", label: "por apelido" },
    { value: "name", label: "por nome" },
    { value: "older", label: "mais antigos primeiro" },
    { value: "newer", label: "mais novos primeiro" },
  ];

  const filters = [
    { value: "ACTIVE", label: "ativos" },
    { value: "ARCHIVED", label: "arquivados" },
  ];

  return (
    <>
      <Select
        size="small"
        value={order}
        options={orders}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setOrder(e.target.value as OrdersKeys);
        }}
      />
      <Select
        size="small"
        options={filters}
        value={filter}
        sx={{ ml: 2 }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFilter(e.target.value as FiltersKeys)
        }
      />
    </>
  );
}
