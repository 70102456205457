import _ from "lodash";

import { DOC_TYPE, docType, docValid } from "src/utils/document";
import { validEmail } from "src/utils/email";
import { asPhone, PHONE_FORMATS } from "src/utils/phone";

import {
  ignoreTimeout,
  customerName,
  customerCompanyFull,
  customerPersonEmails,
  customerPersonMobiles,
} from "src/utils/enrichment";

import { lockField } from "./fields";

export function enrichCustomer(doc, setState) {
  if (!docValid(doc)) return null;

  switch (docType(doc)) {
    case DOC_TYPE.CPF:
      customerName(doc)
        .then((data) => {
          const content = data.data.data;
          if (!content) return null;

          if (String(content.name).length > 5)
            setState(
              {
                name: content.name,
                nameLocked: true,
              },
              () => {
                lockField("name", setState);
              }
            );
        })
        .catch(ignoreTimeout);

      customerPersonEmails(doc)
        .then((data) => {
          const content = data.data.data;
          if (!content || !content.emails) return null;

          setState({
            emailSuggestions: content.emails,
          });
        })
        .catch(ignoreTimeout);

      customerPersonMobiles(doc)
        .then((data) => {
          const content = data.data.data;
          if (!content || !content.mobiles) return null;

          setState({
            mobileSuggestions: _.map(
              content.mobiles,
              (m) => asPhone(m, PHONE_FORMATS.E164, true).formatted
            ),
          });
        })
        .catch(ignoreTimeout);
      break;
    case DOC_TYPE.CNPJ:
      customerName(doc)
        .then((data) => {
          const content = data.data.data;
          if (!content) return null;

          if (String(content.name).length > 5)
            setState(
              {
                name: content.name,
                nameLocked: true,
              },
              () => {
                lockField("name", setState);
              }
            );
        })
        .catch(() => {
          customerCompanyFull(doc)
            .then((data) => {
              const content = data.data.data;
              if (!content) return null;

              const email = validEmail(content.email)
                ? content.email.toLowerCase()
                : "";

              const mobile = asPhone(content.phone, PHONE_FORMATS.LOCAL, true)
                .valid
                ? content.phone
                : "";

              const emailSuggestions = email.length > 0 ? [email] : [];

              const mobileSuggestions = mobile.length > 0 ? [mobile] : [];

              setState({
                name: content.name,
                nameLocked: true,

                emailSuggestions: emailSuggestions,
                mobileSuggestions: mobileSuggestions,
              });
            })
            .catch(ignoreTimeout);
        });
      break;
    default:
      return null;
  }
}
