import React from "react";

import { Button } from "semantic-ui-react";
import { FaCheckCircle } from "react-icons/fa";

import { docValid } from "src/utils/document";
import { validEmail } from "src/utils/email";

import style from "../CreateCustomerModal.module.css";

function submittable(state) {
  return (
    String(state.name).length > 0 &&
    validEmail(state.email) &&
    docValid(state.document)
  );
}

export default function a(state, setState, submitForm, loading) {
  const isSubmittable = submittable(state);
  let btnText;
  if (state.enriching) btnText = "Validando...";
  else if (loading) btnText = "Cadastrando...";
  else btnText = "Continuar";

  const clickFun = () => {
    if (!isSubmittable || state.enriching || loading) return () => {};

    return submitForm;
  };

  return (
    <div className={style.ModalFooter}>
      <Button
        primary
        size="large"
        fluid
        onClick={clickFun()}
        disabled={!isSubmittable || state.enriching || loading}
      >
        <FaCheckCircle />
        <span className="a-m ml-2">{btnText}</span>
      </Button>
    </div>
  );
}
