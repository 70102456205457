import React from "react";
import { Route } from "react-router-dom";

import InformationsScreen from "./screens/Informations";
import DocumentsScreen from "./screens/Documents";
import BankInformationsScreen from "./screens/BankInformations";
import SuccessScreen from "./screens/Success";

const Routes = () => {
  return (
    <>
      <Route path="/" exact>
        <InformationsScreen />
      </Route>
      <Route path="/documents">
        <DocumentsScreen />
      </Route>
      <Route path="/bank-informations" exact>
        <BankInformationsScreen />
      </Route>
      <Route path="/success" exact>
        <SuccessScreen />
      </Route>
    </>
  );
};

export default Routes;
