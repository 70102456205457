import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'

import { matchRoute } from './routes'

import Modal from '../../uikit/Modal'

class Receivable extends React.Component {
  openCallback() {}

  render() {
    const route = matchRoute(this.props.match.params)

    if (!route)
      return null

    const { billing, id } = this.props

    return (
      <Modal directRender={true}
        size="small"
        closeBtnDark={true}
        borderStyle={route.configs.modalBorders || "ssss"}
        closeCallback={() => this.props.history.push('/cobrancas')}
        openCallback={this.openCallback}>
        {
          (closeModal) => {

            if (!billing)
              return <Redirect to="/cobrancas" />

            const body = route.getBody(closeModal, billing, id)

            return (
              <div>
                {body}
              </div>
            )
          }
        }
      </Modal>
    )
  }
}

export default withRouter(Receivable)
