import { gql } from 'apollo-boost'

import RecurrenceProps from '../fragments/recurrence'
import BillingProps from '../fragments/billing'

const GQL_PAUSE_RECURRENCE = gql`
  mutation PauseRecurrence($id: ID!) {
    pauseRecurrence(id: $id) {
      ...RecurrenceProps
      billings {
        ...BillingProps
      }
    }
  }
  ${RecurrenceProps}
  ${BillingProps}
`

export default GQL_PAUSE_RECURRENCE