import React from "react";
import { gql } from "apollo-boost";
import { useQuery } from "react-apollo";
import { Button, Box, Typography, useTheme } from "@plipag/ceci-ui";
import BoletosProgress from "./BoletosProgress";
import CreateCustomerModal from "src/modals/CreateCustomerModal";
import Tooltip from "src/components/Tooltip";

const ListItemTitle = ({ children }) => (
  <Typography color="primary-main">{children}</Typography>
);

const NoAvatarMerchant = ({ name }) => {
  const theme = useTheme();
  const [first, second] = name.split(" ");
  const abbr = first[0] + (second ? second[0] : first[1] || "");
  return (
    <Box
      sx={{
        background: theme.palette.secondary.main,
        color: "white",
        width: 150,
        height: 150,
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        fontWeight: "bold",
        fontSize: "1.3em",
        margin: "0 auto",
      }}
    >
      {abbr.toUpperCase()}
    </Box>
  );
};

const ProfileWrapper = ({ children }) => (
  <div style={{ padding: 76, paddingBottom: 0, textAlign: "center" }}>
    {children}
  </div>
);

const List = ({ children }) => (
  <ul
    style={{
      listStyle: "none",
      margin: 0,
      padding: 24,
    }}
  >
    {children}
  </ul>
);

const ListItem = React.forwardRef(({ children, ...props }, ref) => {
  return (
    <li
      style={{
        background: "#F5F7FA",
        marginBottom: 4,
        padding: "7px 12px",
        borderRadius: 4,
      }}
      ref={ref}
      {...props}
    >
      <Typography
        variant="subtitle2"
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {children}
      </Typography>
    </li>
  );
});

const DefaultRate = ({ value }) => {
  return (
    <Tooltip
      title="Taxa de inadimplência"
      content="Percentual das cobranças que não foram pagas. Nós trabalhamos duro para manter este número o mais baixo possível e ajudar o seu negócio a crescer."
      placement="right"
    >
      <ListItem>
        <ListItemTitle>Taxa de inadimplência</ListItemTitle>
        <span>
          {new Intl.NumberFormat("pt-BR", { style: "percent" }).format(
            value / 100
          )}
        </span>
      </ListItem>
    </Tooltip>
  );
};

const AverageTicket = ({ value }) => {
  return (
    <Tooltip
      title="Ticket Médio"
      content="Valor médio das cobranças, representa quanto você fatura, na média, por cada cliente."
      placement="right"
    >
      <ListItem>
        <ListItemTitle>Ticket médio</ListItemTitle>
        <span>
          R${" "}
          {new Intl.NumberFormat("pt-BR", {
            style: "decimal",
            currency: "BRL",
            minimumFractionDigits: 2,
          }).format(value)}
        </span>
      </ListItem>
    </Tooltip>
  );
};

const Balance = ({ value }) => {
  return (
    <Tooltip
      title="Saldo disponível"
      content="Valor ainda não transferido para a sua conta bancária."
      placement="right"
    >
      <ListItem>
        <ListItemTitle>Saldo disponível</ListItemTitle>
        <span>
          R${" "}
          {new Intl.NumberFormat("pt-BR", {
            style: "decimal",
            currency: "BRL",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value)}
        </span>
      </ListItem>
    </Tooltip>
  );
};

const MerchantCard = () => {
  const { data } = useQuery(gql`
    query {
      plg {
        customersCreated
      }
      kpi {
        defaultRate
        avgTicket
        balance
      }
      merchant {
        name
        logo
      }
    }
  `);

  return (
    <>
      <ProfileWrapper>
        {data?.merchant?.logo && (
          <img src={data.merchant.logo} alt="" width="150" />
        )}
        {!data?.merchant?.logo && (
          <NoAvatarMerchant name={data?.merchant?.name || "M"} />
        )}
        <Typography
          variant="h5"
          sx={{ fontWeight: 500, wordBreak: "break-word", mt: 2 }}
        >
          {data?.merchant?.name}&nbsp;
        </Typography>
      </ProfileWrapper>
      <List>
        <DefaultRate value={data?.kpi?.defaultRate || 0} />
        <AverageTicket value={data?.kpi?.avgTicket || 0} />
        <Tooltip
          title="Clientes"
          content="Todos os seus clientes cadastrados e ativos, sem contar aqueles que foram excluídos."
          placement="right"
        >
          <ListItem>
            <ListItemTitle>Clientes</ListItemTitle>
            <span>
              {data?.plg.customersCreated ? (
                data?.plg.customersCreated
              ) : (
                <CreateCustomerModal>
                  <Button sx={{ p: 0, fontWeight: "bold" }} color="secondary">
                    Cadastrar
                  </Button>
                </CreateCustomerModal>
              )}
            </span>
          </ListItem>
        </Tooltip>
        <Balance value={data?.kpi?.balance || 0} />
      </List>
      <BoletosProgress />
    </>
  );
};

export default MerchantCard;
