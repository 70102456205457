import React from "react";
import _ from "lodash";
import classNames from "classnames";
import { useMutation } from "react-apollo";

import { Item, Label } from "semantic-ui-react";

import style from "./CommunicationsSetup.module.css";

import GQL_MERCHANT_PREFERENCE_UPDATE from "src/graphql/mutations/MerchantPreferenceUpdate.gql";
import GQL_PREFERENCES from "src/graphql/queries/Preferences.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

const communicationsSetups = {
  MUTE: {
    name: "MUTE",
    available: true,
    title: "Mudo",
    icon: "https://assets.plipag.com.br/conta/images/icons/open/gradient/747818-ui-interface/png/156-mute.png",
    meta: "Nenhuma notificação é enviada.",
    extra:
      "Ideal quando a fatura é impressa ou a notificação ocorre manualmente.",
  },
  STANDARD: {
    name: "STANDARD",
    available: true,
    title: "Inteligente",
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/123368-color-startups-and-new-business/png/brain.png",
    meta: "Régua inteligente de cobranças no estado da arte e seguindo as melhores práticas para receber no prazo e nutrir o relacionamento.",
    extra: "Ideal para a maioria dos casos.",
  },
  TRANSACTIONAL: {
    name: "TRANSACTIONAL",
    available: true,
    title: "Transacional",
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/123368-color-startups-and-new-business/png/paper-plane.png",
    meta: "Uma única notificação na emissão da cobrança.",
    extra: "Ideal para vendas presenciais.",
  },
  OPTIONAL: {
    name: "OPTIONAL",
    available: true,
    title: "Opcional",
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/123368-color-startups-and-new-business/png/pointer.png",
    meta: "Lembretes de notificação até o vencimento.",
    extra:
      "Ideal quando o pagamento é opcional, como propostas comerciais ou sistemas pré-pagos.",
  },
  AGGRESSIVE: {
    name: "AGGRESSIVE",
    available: false,
    title: "Agressiva",
    icon: "https://assets.plipag.com.br/conta/images/icons/open/flat/2021165-magic/png/013-voodoo doll.png",
    meta: "Régua contínua de cobranças com um forte apelo pós-vencimento.",
    extra:
      "Ideal quando outros meios não foram eficazes e quando se assume o risco de prejudicar o relacionamento com o cliente, pode ser adequado quando a inadimplência é patologicamente acima da média. Consulte disponibilidade com meajuda@plipag.com.br",
  },
};

function wrapDefault(setup, body) {
  return setup.name === communicationsSetups.STANDARD.name ? (
    <React.Fragment>
      {body}
      <div>
        <Label color="orange" ribbon="right">
          Recomendado
        </Label>
      </div>
    </React.Fragment>
  ) : (
    body
  );
}

function CommunicationsSetup({ setup }) {
  const [update, { loading, error }] = useMutation(
    GQL_MERCHANT_PREFERENCE_UPDATE,
    {
      refetchQueries: [{ query: GQL_PREFERENCES }],
    }
  );

  if (loading) return <OrbitsLoader />;
  if (error) return <GqlError error={error} />;

  return _.map(communicationsSetups, (cs) => (
    <div
      className={classNames(
        style.Item,
        cs.name === setup
          ? style.Selected
          : cs.available
          ? style.Available
          : style.Disabled
      )}
      key={_.uniqueId()}
      onClick={() =>
        cs.name !== setup && cs.available
          ? update({ variables: { communicationsSetup: cs.name } })
          : {}
      }
    >
      <Item.Group>
        <Item>
          <Item.Image src={cs.icon} size="mini" />
          <Item.Content verticalAlign="middle">
            <Item.Header content={cs.title} />
            <Item.Meta content={cs.meta} />
            {wrapDefault(cs, <Item.Extra content={cs.extra} />)}
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  ));
}

export default CommunicationsSetup;
