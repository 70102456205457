import React from "react";
import { useMutation, useQuery } from "react-apollo";
import _ from "lodash";
import classNames from "classnames";

import { Header, Popup } from "semantic-ui-react";
import ImgIcon from "src/uikit/ImgIcon";

import style from "./AddToCgroupModal.module.css";

import analytics from "src/plg/Segment";

import GQL_CGROUPS_CUSTOMERS from "src/graphql/queries/CgroupsCustomers.gql";
import GQL_JOIN_CGROUP from "src/graphql/mutations/JoinCgroup.gql";
import GQL_LEAVE_CGROUP from "src/graphql/mutations/LeaveCgroup.gql";
import OrbitsLoader from "src/uikit/Loaders/OrbitsLoader";
import GqlError from "src/uikit/GqlError";

function AddToCgroupModal({ customer, usedCgroups, callback }) {
  const { loading, error, data } = useQuery(GQL_CGROUPS_CUSTOMERS);

  const [joinCgroup] = useMutation(GQL_JOIN_CGROUP);
  const [leaveCgroup] = useMutation(GQL_LEAVE_CGROUP);

  if (loading) return <OrbitsLoader />;
  if (error) return <GqlError error={error} />;

  const availableCgroups = _.differenceBy(data.cgroups, usedCgroups, "id");

  return (
    <div>
      <div className={style.ModalBody}>
        <Header size="medium" className="m-0">
          Colocar
        </Header>
        <Header size="large" className="m-0">
          {customer.nickname || customer.name}
        </Header>
        <Header size="medium" className="m-0">
          no grupo
        </Header>
        <ul className="vertical mt-4 mb-4">
          {_.map(_.filter(availableCgroups), (cgroup) => (
            <li
              key={`cgroup-li:${cgroup.id}`}
              className={style.ListItem}
              onClick={() => {
                joinCgroup({
                  variables: {
                    customerId: customer.id,
                    cgroupId: cgroup.id,
                  },
                });
                analytics.track("Cgroup Customer Joined");
              }}
            >
              <ImgIcon iconKey={cgroup.icon} className={style.Icon} />
              <span className={style.Text}>{cgroup.name}</span>
            </li>
          ))}
          {_.isEmpty(availableCgroups) && (
            <div className="font-italic">Nenhum grupo para adicionar.</div>
          )}
        </ul>
        <div>
          <span className="a-m mr-2">Grupos atuais:</span>
          <ul className={classNames("horizontal", "a-m", style.UsedCgroupList)}>
            {_.map(usedCgroups, (cgroup) => (
              <li
                key={`used-cgroup:${cgroup.id}`}
                className={style.UsedCgroupItem}
              >
                <Popup
                  inverted
                  header={`Remover de ${cgroup.name.toLocaleUpperCase()}`}
                  content="Clique para remover do grupo"
                  trigger={
                    <div>
                      <ImgIcon
                        iconKey={cgroup.icon}
                        className={style.UsedCgroupIcon}
                        onClick={() => {
                          leaveCgroup({
                            variables: {
                              customerId: customer.id,
                              cgroupId: cgroup.id,
                            },
                          });
                          analytics.track("Cgroup Customer Left");
                        }}
                      />
                    </div>
                  }
                />
              </li>
            ))}
            {_.isEmpty(usedCgroups) && (
              <span className="font-italic">Nenhum.</span>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AddToCgroupModal;
