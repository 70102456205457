import { gql } from 'apollo-boost'

import CgroupProps from '../fragments/cgroup'

const GQL_CGROUPS = gql`
  query Cgroups {
    cgroups {
      ...CgroupProps
    }
  }
  ${CgroupProps}
`

export default GQL_CGROUPS