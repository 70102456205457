import React from "react";
import { ToolbarMenu, ToolbarMenuItem } from "@plipag/ceci-ui";
import { MdInsertChart, MdInsertInvitation, MdGroup } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import { useQuery } from "react-apollo";
import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";

function HeaderMenu() {
  const { pathname } = useLocation();
  const { loading, data } = useQuery(GQL_MERCHANT);
  const showActivateAccountButton =
    !loading &&
    !["ENABLED", "SUSPENDED", "BLOCKED"].includes(data?.merchant?.status);

  return (
    <ToolbarMenu sx={{ flexGrow: showActivateAccountButton ? 0 : 1 }}>
      <ToolbarMenuItem component={NavLink} exact to="/">
        <MdInsertChart />
        Visão Geral
      </ToolbarMenuItem>
      <ToolbarMenuItem component={NavLink} exact to="/clientes">
        <MdGroup />
        Clientes
      </ToolbarMenuItem>
      <ToolbarMenuItem
        component={NavLink}
        exact
        to="/cobrancas"
        isActive={() => ["/cobrancas", "/financeiro"].includes(pathname)}
      >
        <MdInsertInvitation />
        Cobranças
      </ToolbarMenuItem>
    </ToolbarMenu>
  );
}

export default HeaderMenu;
