import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import classNames from "classnames";

import { Menu, Popup } from "semantic-ui-react";
import { FaCommentDollar } from "react-icons/fa";
import { MdSwapVerticalCircle } from "react-icons/md";

import style from "./TabBillings.module.css";
import BoletoStatus from "../BoletoStatus";

class TabBillings extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      redirectTo: null,
    };

    this.goto = this.goto.bind(this);
  }

  // UNSAFE_componentWillReceiveProps(nextProps, nextState, nextContext) {
  //   this.setState({ redirectTo: null })
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps === prevState ? { redirectTo: null } : {};
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <Menu stackable tabular style={{ marginBottom: 0, marginRight: 32 }}>
        <Popup
          trigger={
            this.props.match.path === "/cobrancas" ? (
              <Menu.Item active={true}>
                <FaCommentDollar
                  size={22}
                  className={classNames(style.Active)}
                />
                <span className={classNames("ml-2", style.Active)}>
                  Todas as cobranças
                </span>
              </Menu.Item>
            ) : (
              <Menu.Item active={false} onClick={() => this.goto("/cobrancas")}>
                <FaCommentDollar size={22} className={style.InactiveIcon} />
                <span className={classNames("ml-2", style.Inactive)}>
                  Todas as cobranças
                </span>
              </Menu.Item>
            )
          }
          position="bottom right"
          inverted
        >
          <Popup.Header>Últimas cobranças</Popup.Header>
          <Popup.Content>
            Veja as últimas cobranças realizadas de forma organizada.
          </Popup.Content>
        </Popup>

        <Popup
          trigger={
            this.props.match.path === "/financeiro" ? (
              <Menu.Item active={true}>
                <MdSwapVerticalCircle
                  size={22}
                  className={classNames(style.Active)}
                />
                <span className={classNames("ml-2", style.Active)}>
                  Transferências
                </span>
              </Menu.Item>
            ) : (
              <Menu.Item
                active={false}
                onClick={() => this.goto("/financeiro")}
              >
                <MdSwapVerticalCircle
                  size={22}
                  className={style.InactiveIcon}
                />
                <span className={classNames("ml-2", style.Inactive)}>
                  Transferências
                </span>
              </Menu.Item>
            )
          }
          position="bottom right"
          inverted
        >
          <Popup.Header>Transferências</Popup.Header>
          <Popup.Content>Veja o extrato da conta</Popup.Content>
        </Popup>

        <Menu.Menu position="right">
          <Menu.Item>
            <BoletoStatus />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

export default withRouter(TabBillings);
