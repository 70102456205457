import React from "react";
import { Box } from "@plipag/ceci-ui";

export const Container = (props) => (
  <Box
    id="page-container"
    sx={{
      width: "100%",
      height: "calc(100vh - 68px)",
      overflowY: "auto",
      overflowX: "hidden",
      marginTop: "68px",
      background: "#E5E5E5",
    }}
    {...props}
  />
);
