import { gql } from 'apollo-boost'

import PreferencesProps from '../fragments/preferences'

const GQL_MERCHANT_PREFERENCE_UPDATE = gql`
  mutation MerchantUpdateRequest(
    $communicationsSetup: CommunicationsSetup,
    $automaticCancel: Int,
    $webhookUrl: Url,
    $settlementMdays: String
  ) {
    updatePreference(
      communicationsSetup: $communicationsSetup,
      automaticCancel: $automaticCancel,
      webhookUrl: $webhookUrl,
      settlementMdays: $settlementMdays
    ) {
      ...PreferencesProps
    }
  }
  ${PreferencesProps}
`

export default GQL_MERCHANT_PREFERENCE_UPDATE