import {
  Stack,
  Pagination as CeciPagination,
  makeStyles,
} from "@plipag/ceci-ui";

type Props = {
  pages: number;
  page: number;
  handleChange: (_: any, page: number) => void;
};

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiPaginationItem-root": {
      outline: "none",
      backgroundColor: "none !important",
    },
    "& .Mui-selected": {
      backgroundColor: "#E1E9FA !important",
      color: "#17181A",
    },
  },
}));

export function Pagination({ pages, page, handleChange }: Props) {
  const classes = useStyles();

  if (pages < 2) {
    return null;
  }

  return (
    <Stack spacing={2} alignItems="center">
      <CeciPagination
        classes={classes}
        count={pages}
        page={page}
        sx={{ my: 3 }}
        color="secondary"
        onChange={handleChange}
      />
    </Stack>
  );
}
