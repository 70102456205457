import React from "react";
import { Mutation } from "react-apollo";
import _ from "lodash";

import { Button } from "semantic-ui-react";

import analytics from "src/plg/Segment";

import GQL_DESTROY_CGROUP from "src/graphql/mutations/DestroyCgroup.gql";
import GQL_CGROUPS_CUSTOMERS from "src/graphql/queries/CgroupsCustomers.gql";

class DestroyCgroupBtn extends React.Component {
  constructor(props) {
    super(props);

    this.handleMutationOnCompleted = this.handleMutationOnCompleted.bind(this);
  }

  handleMutationUpdate(cache, { data: { destroyCgroup } }) {
    cache.writeQuery({
      query: GQL_CGROUPS_CUSTOMERS,
      data: {
        cgroups: _.filter(
          cache.readQuery({ query: GQL_CGROUPS_CUSTOMERS }).cgroups,
          (g) => g.id !== destroyCgroup
        ),
      },
    });
  }

  handleMutationOnCompleted() {
    this.props.clearCgroupSelection();

    analytics.track("Cgroup Deleted");
  }

  render() {
    const { id } = this.props;

    return (
      <Mutation
        mutation={GQL_DESTROY_CGROUP}
        variables={{ id }}
        update={this.handleMutationUpdate}
        onCompleted={this.handleMutationOnCompleted}
      >
        {(destroyCall, { loading }) => {
          return (
            <Button
              negative
              size="large"
              fluid
              onClick={() => {
                if (window.confirm("Tem certeza?")) {
                  destroyCall();
                  this.props.submitCallback();
                }
              }}
              disabled={loading}
            >
              <span className="a-m ml-2">
                {loading ? "Só um instante..." : "Excluir grupo"}
              </span>
            </Button>
          );
        }}
      </Mutation>
    );
  }
}

export default DestroyCgroupBtn;
