import React from "react";
import { Box } from "@plipag/ceci-ui";

export const Wrapper = (props) => (
  <Box
    sx={{
      width: "100vw",
      height: "100vh",
      overflow: "hidden",
    }}
    {...props}
  />
);
