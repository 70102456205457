export function recurrenceListable(r, showArchived) {
  return [
    'GENERATING',
    'SUSPENDED',
    'GENERATED'
  ].includes(r.status) && (showArchived ? true : !r.archived)
}

export function recurrenceMajorFieldsEditable(r) {
  return [
    'GENERATING',
    'SUSPENDED',
    'GENERATED',
  ].includes(r.status) && !r.archived
}