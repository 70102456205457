import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Modal } from "semantic-ui-react";
import GQL_MERCHANT from "src/graphql/queries/Merchant.gql";
import "./style.css";

const TypeFormModalButton = ({ url, children, title = "" }) => {
  const [open, setOpen] = React.useState(false);
  const { error, loading, data } = useQuery(GQL_MERCHANT);
  if (error || loading) return null;
  const { email } = data?.merchant;

  return (
    <Modal
      basic
      closeIcon
      open={open}
      trigger={children}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <iframe
        src={`${url}#email=${email}`}
        title={title}
        style={{ height: "calc(100vh - 160px)", width: "100%", marginTop: 80 }}
        frameBorder={0}
      />
    </Modal>
  );
};

export default TypeFormModalButton;
