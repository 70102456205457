import React from "react";
import { withRouter } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import _ from "lodash";

import { Message, Button } from "semantic-ui-react";
import { FaTrash } from "react-icons/fa";

import { routes } from "../../routes";

import style from "./DestroyRecurrence.module.css";

import GQL_RECURRENCE_DESTROYABLE from "src/graphql/queries/RecurrenceDestroyable.gql";
import GQL_RECURRENCE_DESTROY from "src/graphql/mutations/RecurrenceDestroy.gql";
import GQL_RECURRENCES_BILLINGS from "src/graphql/queries/RecurrencesBillings.gql";
import GQL_RECURRENCE_ARCHIVE from "src/graphql/mutations/RecurrenceArchive.gql";
import GQL_RECURRENCE_UNARCHIVE from "src/graphql/mutations/RecurrenceUnarchive.gql";

class DestroyArchiveUnarchiveRecurrence extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
    };

    this.getRefetch = this.getRefetch.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.handleMutationDestroyUpdate =
      this.handleMutationDestroyUpdate.bind(this);
    this.handleMutationArchiveUpdate =
      this.handleMutationArchiveUpdate.bind(this);
    this.handleMutationUnarchiveUpdate =
      this.handleMutationUnarchiveUpdate.bind(this);
    this.handleMutationCompleted = this.handleMutationCompleted.bind(this);
  }

  getRefetch() {
    return [
      {
        query: GQL_RECURRENCES_BILLINGS,
        variables: { customerIds: [this.props.recurrence.customerId] },
      },
    ];
  }

  handleMutationDestroyUpdate(cache, { data: { destroyRecurrence } }) {
    // Later on replace refetchQueries with handleMutationUpdate.
  }

  handleMutationArchiveUpdate(cache, { data: { archiveRecurrence } }) {
    // Later on replace refetchQueries with handleMutationUpdate.
  }

  handleMutationUnarchiveUpdate(cache, { data: { unarchiveRecurrence } }) {
    // Later on replace refetchQueries with handleMutationUpdate.
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Não foi possível completar a operação, tente novamente",
    });
  }

  handleMutationCompleted(data) {
    this.props.history.push(
      routes.RECEIVABLES.getLink(this.props.recurrence.customerId)
    );
  }

  render() {
    if (this.state.error) return <Message negative>{this.state.error}</Message>;

    return (
      <Query
        query={GQL_RECURRENCE_DESTROYABLE}
        variables={{ id: this.props.recurrence.id }}
      >
        {({ loading, error, data }) => {
          if (error) return null;
          if (!data) return null;

          const loadingDestroyable = loading;
          const { recurrenceDestroyable } = data;

          const destroyableBtn = (
            <Mutation
              mutation={GQL_RECURRENCE_DESTROY}
              variables={{ id: this.props.recurrence.id }}
              refetchQueries={this.getRefetch()}
              update={this.handleMutationDestroyUpdate}
              onError={this.handleMutationError}
              onCompleted={this.handleMutationCompleted}
            >
              {(destroyRecurrence, { loading, error }) => {
                return error || loadingDestroyable ? null : (
                  <Message info size="big">
                    <Message.Header>Excluir mensalidade?</Message.Header>
                    <Message.Content>
                      <div>
                        Esta mensalidade pode ser excluída junto com suas
                        cobranças. Clique para prosseguir.
                      </div>
                      <div className="pt-2">
                        <Button
                          className={loading ? style.Disabled : style.Button}
                          onClick={
                            loading
                              ? () => {}
                              : () => {
                                  if (window.confirm("Tem certeza?"))
                                    destroyRecurrence();
                                }
                          }
                        >
                          <FaTrash size={18} className="pr-2 a-m" />
                          <span className="a-m">Excluir</span>
                        </Button>
                      </div>
                    </Message.Content>
                  </Message>
                );
              }}
            </Mutation>
          );

          const archiveableBtn = (
            <Mutation
              mutation={GQL_RECURRENCE_ARCHIVE}
              variables={{ id: this.props.recurrence.id }}
              refetchQueries={this.getRefetch()}
              update={this.handleMutationArchiveUpdate}
              onError={this.handleMutationError}
              onCompleted={this.handleMutationCompleted}
            >
              {(archiveRecurrence, { loading, error }) => {
                return error || loadingDestroyable ? null : (
                  <Message info size="big">
                    <Message.Header>Arquivar mensalidade?</Message.Header>
                    <Message.Content>
                      <div>
                        Ao prosseguir com o arquivamento, esta mensalidade não
                        aparecerá na listagem padrão, mas poderá ser recuperada
                        a qualquer momento para ser desarquivada ou reativada.
                      </div>
                      <div className="pt-2">
                        <Button
                          className={loading ? style.Disabled : style.Button}
                          onClick={archiveRecurrence}
                        >
                          <FaTrash size={18} className="pr-2 a-m" />
                          <span className="a-m">Arquivar</span>
                        </Button>
                      </div>
                    </Message.Content>
                  </Message>
                );
              }}
            </Mutation>
          );

          const unarchiveableBtn = (
            <Mutation
              mutation={GQL_RECURRENCE_UNARCHIVE}
              variables={{ id: this.props.recurrence.id }}
              refetchQueries={this.getRefetch()}
              update={this.handleMutationUnarchiveUpdate}
              onError={this.handleMutationError}
              onCompleted={this.handleMutationCompleted}
            >
              {(unarchiveRecurrence, { loading, error }) => {
                return error || loadingDestroyable ? null : (
                  <Message info size="big">
                    <Message.Header>Desarquivar mensalidade?</Message.Header>
                    <Message.Content>
                      <div>
                        Esta mensalidade está arquivada. Após desarquivá-la, ela
                        irá aparecer na lista.
                      </div>
                      <div className="pt-2">
                        <Button
                          className={loading ? style.Disabled : style.Button}
                          onClick={unarchiveRecurrence}
                        >
                          <FaTrash size={18} className="pr-2 a-m" />
                          <span className="a-m">Desarquivar</span>
                        </Button>
                      </div>
                    </Message.Content>
                  </Message>
                );
              }}
            </Mutation>
          );

          return this.props.recurrence.archived
            ? unarchiveableBtn
            : recurrenceDestroyable
            ? destroyableBtn
            : archiveableBtn;
        }}
      </Query>
    );
  }
}

export default withRouter(DestroyArchiveUnarchiveRecurrence);
