import React from "react";
import { Redirect } from "react-router-dom";
import { Mutation } from "react-apollo";
import moment from "moment";
import _ from "lodash";

import { routes } from "../../../routes";
import settings from "src/app/settings";
import toDecimal from "src/utils/decimal";
import { businessDayForward } from "src/utils/holidays";
import { getUiPref } from "src/utils/uiPreferences";

import PlgSwitchCond from "src/plg/PlgSwitchCond";

import { Message, Grid, Popup, Checkbox, Button } from "semantic-ui-react";
import { MdInfo } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

import analytics from "src/plg/Segment";

import Input from "src/uikit/Forms/Input";
import Calendar from "src/uikit/Forms/Calendar";

import GQL_CREATE_BILLING from "src/graphql/mutations/CreateBilling.gql";
import BillingProps from "src/graphql/fragments/billing";
import GQL_BILLINGS from "src/graphql/queries/Billings.gql";
import GQL_BILLINGS_CUSTOMERS from "src/graphql/queries/BillingsCustomers.gql";

class CreateBilling extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,

      customerId: this.props.customer.id,
      amountBilled: 0,
      amountBilledFace: "",
      daypickerDay: businessDayForward(moment().add(2, "days")).toDate(),
      dueDate: moment(
        businessDayForward(moment().add(2, "days")).toDate()
      ).format("YYYY[-]MM[-]DD"),
      description: "",
      interest: getUiPref("interest", false),
      interestPolicy: getUiPref("interest", false) ? "MARKET" : "NO_INTEREST",
      discountAmount: 0,
      discountAmountFace: "",
      discountDays: 0,
      discountPolicy: "NO_DISCOUNT",
      anticipateFlag: false,
      anticipate: null,
    };

    this.goto = this.goto.bind(this);
    this.handleAmountBilled = this.handleAmountBilled.bind(this);
    this.daypickerClick = this.daypickerClick.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleInterest = this.handleInterest.bind(this);
    this.handleDiscount = this.handleDiscount.bind(this);
    this.handleAnticipate = this.handleAnticipate.bind(this);
    this.submittable = this.submittable.bind(this);
    this.getVars = this.getVars.bind(this);
    this.handleMutationUpdate = this.handleMutationUpdate.bind(this);
    this.handleMutationComplete = this.handleMutationComplete.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
  }

  handleAmountBilled(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      amountBilled: floatValue,
      amountBilledFace: decimalValue,
    });
  }

  daypickerClick(day, { selected, disabled }) {
    this.setState(
      {
        daypickerDay: selected || disabled ? undefined : day,
        dueDate:
          selected || disabled
            ? undefined
            : moment(day).format("YYYY[-]MM[-]DD"),
      },
      () => {
        if (
          this.state.dueDate &&
          moment(this.state.dueDate).diff(moment(), "days") <=
            settings.BILLING_EXPRESS_WINDOW
        )
          this.setState({
            anticipate: null,
          });
      }
    );
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
    });
  }

  handleInterest(value) {
    const val = !this.state.interest;

    this.setState({
      interest: val,
      interestPolicy: val ? "MARKET" : "NO_INTEREST",
    });
  }

  handleDiscount(e) {
    const { floatValue, decimalValue } = toDecimal(e.target.value);

    this.setState({
      discountAmount: floatValue,
      discountAmountFace: decimalValue,
      discountPolicy: floatValue > 0 ? "FIXED" : "NO_DISCOUNT",
    });
  }

  handleAnticipate(e) {
    const val = !this.state.anticipate;

    this.setState({
      anticipateFlag: val,
      anticipate: val ? true : null,
    });
  }

  submittable() {
    if (
      this.state.discountAmount > 0 &&
      this.state.amountBilled > 0 &&
      this.state.discountAmount >= this.state.amountBilled
    )
      return false;

    if (this.props.customer.status !== "ACTIVE") return false;

    return this.state.dueDate && this.state.amountBilled > 0;
  }

  getVars() {
    return {
      customerId: this.state.customerId,
      amountBilled: this.state.amountBilled,
      dueDate: this.state.dueDate,
      description: this.state.description,
      interestPolicy: this.state.interestPolicy,
      discountAmount: this.state.discountAmount,
      discountDays: this.state.discountDays,
      discountPolicy: this.state.discountPolicy,
      anticipate: this.state.anticipate,
    };
  }

  handleMutationUpdate(cache, { data: { createBilling } }) {
    cache.writeFragment({
      id: `Billing:${createBilling.id}`,
      fragment: BillingProps,
      data: Object.assign(createBilling, {
        __typename: "Billing",
      }),
    });

    cache.writeQuery({
      query: GQL_BILLINGS,
      variables: { customersIds: [createBilling.customerId] },
      data: {
        billings: _.concat(
          cache.readQuery({
            query: GQL_BILLINGS,
            variables: { customerIds: [createBilling.customerId] },
          }).billings,
          createBilling
        ),
      },
    });
  }

  handleMutationComplete(resp) {
    const {
      createBilling: { id },
    } = resp;
    analytics.track("Billing Created", {
      from: "Billing Modal",
      amount: this.state.amountBilled,
    });
    this.goto(routes.CREATED_BILLING.getLink(this.props.customer.id, id));
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  renderError(error) {
    if (!error) return null;
    return (
      <Message
        header="Dados de cobrança inválidos."
        negative
        list={error.graphQLErrors.map((e) => e.message)}
      />
    );
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <Mutation
        mutation={GQL_CREATE_BILLING}
        variables={this.getVars()}
        onCompleted={this.handleMutationComplete}
        onError={this.handleMutationError}
        refetchQueries={[
          {
            query: GQL_BILLINGS,
            variables: { customerIds: [this.props.customer.id] },
          },
          {
            query: GQL_BILLINGS_CUSTOMERS,
            variables: {
              startDueDate: moment(this.state.dueDate)
                .startOf("month")
                .format("YYYY-MM-DD"),
              endDueDate: moment(this.state.dueDate)
                .endOf("month")
                .format("YYYY-MM-DD"),
            },
          },
        ]}
      >
        {(createBilling, { loading, error, data }) => {
          return (
            <Grid centered>
              {error && (
                <Grid.Row columns={1} centered>
                  <Grid.Column width={16}>
                    {this.renderError(error)}
                  </Grid.Column>
                </Grid.Row>
              )}
              {this.props.customer.status !== "ACTIVE" && (
                <Grid.Row columns={1} centered>
                  <Grid.Column width={14}>
                    <Message warning>
                      <Message.Header>
                        Cliente{" "}
                        <span className="font-weight-extra-bold">
                          arquivado
                        </span>
                      </Message.Header>
                      <Message.Content>
                        Um cliente arquivado não pode emitir novas cobranças.
                      </Message.Content>
                    </Message>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row columns={1} centered>
                <Grid.Column width={9}>
                  <Input
                    type="decimal"
                    placeholder="Valor"
                    onChange={this.handleAmountBilled}
                    value={this.state.amountBilledFace}
                    textAlign="center"
                    fontSize="large"
                    autofocus
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Calendar
                    onChange={this.daypickerClick}
                    value={this.state.daypickerDay}
                    placeholder="Vencimento"
                    minDaysBillingAhead={settings.BILLING_MIN_DAYS_AHEAD}
                    maxMonthsAhead={12}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={1} centered>
                <Grid.Column width={14}>
                  <Input
                    value={this.state.description}
                    onChange={this.handleDescription}
                    placeholder="Descrição"
                    showClearBtn
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2} className="pb-0">
                <Grid.Column width={10}>
                  Juros&nbsp;
                  <Popup
                    trigger={<MdInfo size={16} />}
                    header="Juros de mercado"
                    content="2% de multa no atraso acrescido de 1% de mora ao mês"
                    inverted
                  />
                </Grid.Column>
                <Grid.Column width={4} textAlign="right">
                  <Checkbox
                    toggle
                    fitted
                    onChange={this.handleInterest}
                    checked={this.state.interest}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row centered columns={2} className="pt-0">
                <Grid.Column width={7}>
                  <Popup
                    trigger={<span>Desconto</span>}
                    content="Desconto até o vencimento"
                    inverted
                  />
                </Grid.Column>
                <Grid.Column width={7} textAlign="right">
                  <Input
                    onChange={this.handleDiscount}
                    value={this.state.discountAmountFace}
                    textAlign="right"
                  />
                </Grid.Column>
              </Grid.Row>
              {this.state.dueDate &&
                false &&
                moment(this.state.dueDate).diff(moment(), "days") >
                  settings.BILLING_EXPRESS_WINDOW && (
                  <Grid.Row centered columns={2} className="pb-0">
                    <Grid.Column width={7}>
                      <Popup
                        trigger={<span>Cobrar agora</span>}
                        header="Cobrança expressa"
                        content="Uma cobrança expressa é emitida
                              imediatamente, ignorando a régua de cobrança
                              inteligente para o cliente receber a fatura tão
                              logo quanto possível."
                        inverted
                      />
                    </Grid.Column>
                    <Grid.Column width={7} textAlign="right">
                      <Checkbox
                        toggle
                        fitted
                        onChange={this.handleAnticipate}
                        checked={this.state.anticipateFlag}
                      />
                    </Grid.Column>
                  </Grid.Row>
                )}
              <Grid.Row centered>
                <Grid.Column width={16}>
                  <PlgSwitchCond
                    condition={(s) => s.status}
                    positive={
                      <Button
                        primary
                        size="large"
                        fluid
                        onClick={createBilling}
                        disabled={loading || !this.submittable()}
                      >
                        {loading ? (
                          "Carregando..."
                        ) : (
                          <div>
                            <FaCheckCircle />
                            <span className="a-m ml-2">
                              Gerar cobrança{" "}
                              {this.state.anticipate ? "agora" : ""}
                            </span>
                          </div>
                        )}
                      </Button>
                    }
                    negative={<div>A conta não está ativa</div>}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Mutation>
    );
  }
}

export default CreateBilling;
