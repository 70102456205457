import React from "react";
import { Button } from "semantic-ui-react";
import { MdEdit } from "react-icons/md";
import TypeFormModal from "src/components/TypeFormModal";

const EditBankInformationsButton = () => {
  return (
    <TypeFormModal url="https://contapli.typeform.com/to/tPVy9DTs">
      <Button fluid style={{ marginTop: 10 }}>
        <MdEdit /> Editar dados bancários
      </Button>
    </TypeFormModal>
  );
};

export default EditBankInformationsButton;
