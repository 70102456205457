import React from 'react'
import { Redirect } from 'react-router-dom'

import tabs from '../CustomerTabs/tabs'
import CustomerTabs from '../CustomerTabs/CustomerTabs'
import ShowAddress from './ShowAddress'
import EditAddress from './EditAddress'

import style from './Address.module.css'

const pages = { SHOW: 'SHOW', EDIT: 'EDIT' }

export default class Address extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      redirectTo: null,
      page: String(this.props.zipcode).length >= 8 ? pages.SHOW : pages.EDIT,
    }

    this.goto = this.goto.bind(this)
    this.changePage = this.changePage.bind(this)
  }

  goto(urn) {
    this.setState({ redirectTo: urn })
  }

  changePage(page) {
    this.setState({ page })
  }

  render() {
    if (this.state.redirectTo)
      return <Redirect to={this.state.redirectTo} />

    return (
      <div>
        <CustomerTabs
          activeTab={tabs.ADDRESS}
          customer={this.props.customer}
          goto={this.goto}
        />
        <div className={style.ModalBody}>
          <div className="pt-4 pb-3">
            {
              this.state.page === pages.SHOW &&
                <ShowAddress
                  customer={this.props.customer}
                  changePage={this.changePage} />
            }
            {
              this.state.page === pages.EDIT &&
                <EditAddress
                  customer={this.props.customer}
                  changePage={this.changePage} />
            }
          </div>
        </div>
      </div>
    )
  }
}