import React from "react";

import renderHeader from "./Header";
import renderFormError from "./FormError";
import renderFormBody from "./FormBody";
import renderFormFooter from "./FormFooter";

export default function a(state, setState, submitForm, loading) {
  return (
    <React.Fragment>
      {renderHeader()}
      {renderFormError(state)}
      {renderFormBody(state, setState)}
      {renderFormFooter(state, setState, submitForm, loading)}
    </React.Fragment>
  );
}
