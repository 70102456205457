import React from "react";

import { SvgIcon } from "@plipag/ceci-ui";

const AddFileIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M11.875 0H2.375C1.06875 0 0.0118753 1.08 0.0118753 2.4L0 21.6C0 22.92 1.05687 24 2.36312 24H16.625C17.9313 24 19 22.92 19 21.6V7.2L11.875 0ZM14.25 16.8H10.6875V20.4H8.3125V16.8H4.75V14.4H8.3125V10.8H10.6875V14.4H14.25V16.8ZM10.6875 8.4V1.8L17.2188 8.4H10.6875Z" />
  </SvgIcon>
);

export default AddFileIcon;
