import React from "react";

import CreateBilling from "./partials/CreateBilling";

import style from "./CreateReceivement.module.css";

class CreateReceivement extends React.Component {
  render() {
    return (
      <div>
        <div className={style.ModalBody}>
          <CreateBilling customer={this.props.customer} />
        </div>
      </div>
    );
  }
}

export default CreateReceivement;
