import { useMutation } from "@apollo/react-hooks";
import { MenuItem } from "@plipag/ceci-ui";
import GQL_ARCHIVE_CUSTOMER from "src/graphql/mutations/ArchiveCustomer.gql";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

type Props = {
  id: number;
  onClick: () => void;
};

export function ArchiveButton({ id, onClick }: Props) {
  const [dispatch, { loading }] = useMutation(GQL_ARCHIVE_CUSTOMER, {
    variables: { id },
    refetchQueries: ["CustomersConnection"],
  });

  async function handleClick() {
    await dispatch();
    toast("Cliente arquivado com sucesso", { hideProgressBar: true });
    onClick();
  }

  return (
    <MenuItem onClick={handleClick} disabled={loading}>
      Arquivar cliente
    </MenuItem>
  );
}
