import React from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { CTACard } from "./CTACard";

export function ActivateAccountCTA() {
  const { data, loading, error } = useQuery(gql`
    query Merchant {
      merchant {
        status
      }
    }
  `);

  if (error || loading) return null;

  if (["ENABLED", "SUSPENDED", "BLOCKED"].includes(data.merchant.status))
    return null;

  return (
    <CTACard
      link="/ativacao"
      title="Ative sua Conta a qualquer momento. É grátis."
      description="Apenas com a conta ativada é possível emitir cobranças e receber pagamentos de seus clientes."
      buttonText="ATIVAR MINHA CONTA"
    />
  );
}
