import React from "react";
import { Redirect } from "react-router-dom";
import { Query, Mutation } from "react-apollo";
import _ from "lodash";
import moment from "moment";

import { Button } from "semantic-ui-react";
import { MdDelete } from "react-icons/md";

import analytics from "src/plg/Segment";

import tabs from "../CustomerTabs/tabs";

import Input from "src/uikit/Forms/Input";

import CustomerTabs from "../CustomerTabs/CustomerTabs";

import style from "./Notes.module.css";

import GQL_NOTES from "src/graphql/queries/Notes.gql";
import GQL_DESTROY_NOTE from "src/graphql/mutations/DestroyNote.gql";
import GQL_CREATE_NOTE from "src/graphql/mutations/CreateNote.gql";
import GqlLoadingIcon from "src/uikit/GqlLoadingIcon";
import GqlError from "src/uikit/GqlError";

export default class Notes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectTo: null,

      text: "",
    };

    this.goto = this.goto.bind(this);
    this.handleText = this.handleText.bind(this);
    this.handleMutationError = this.handleMutationError.bind(this);
    this.removeNoteFromCache = this.removeNoteFromCache.bind(this);
    this.addNoteToCache = this.addNoteToCache.bind(this);
    this.clearText = this.clearText.bind(this);
    this.groupMonthItems = this.groupMonthItems.bind(this);
  }

  handleText(e) {
    this.setState({
      text: e.target.value,
    });
  }

  handleMutationError({ graphQLErrors }) {
    this.setState({
      error:
        _.size(graphQLErrors) > 0
          ? graphQLErrors[0].message
          : "Verifique os campos e tente novamente",
    });
  }

  clearText() {
    this.setState({
      text: "",
    });
  }

  addNoteToCache(cache, { data: { createNote } }) {
    const cachedData = cache.readQuery({
      query: GQL_NOTES,
      variables: {
        customerId: this.props.customer.id,
      },
    }).notes;

    cache.writeQuery({
      query: GQL_NOTES,
      variables: {
        customerId: this.props.customer.id,
      },
      data: {
        notes: _.concat(cachedData, createNote),
      },
    });
  }

  removeNoteFromCache(cache, { data: { destroyNote } }) {
    const cachedData = cache.readQuery({
      query: GQL_NOTES,
      variables: {
        customerId: this.props.customer.id,
      },
    }).notes;

    cache.writeQuery({
      query: GQL_NOTES,
      variables: {
        customerId: this.props.customer.id,
      },
      data: {
        notes: _.filter(cachedData, (note) => note.id !== destroyNote),
      },
    });
  }

  goto(urn) {
    this.setState({ redirectTo: urn });
  }

  groupMonthItems(notes) {
    const groupedByMonth = _.groupBy(
      _.map(notes, (note) =>
        Object.assign(note, {
          month: moment(note.createdAt).format("YYYY-MM-01"),
        })
      ),
      (note) => note.month
    );

    const orderedMonths = _.orderBy(
      _.toPairs(groupedByMonth),
      (x) => x[0],
      "desc"
    );

    return _.fromPairs(orderedMonths);
  }

  renderNote(note) {
    if (!note) return null;

    return (
      <div className={style.NoteContainer}>
        <div className={style.NoteText}>
          {_.map(note.text.split("\n"), (line) => (
            <div key={_.uniqueId()}>{line === "" ? <br /> : line}</div>
          ))}
        </div>
        <div className="text-right">
          <span className={style.NoteTimestamps}>
            criado dia {moment(note.createdAt).format("DD [às] HH:MM")}
            {note.createdAt !== note.updatedAt && (
              <span>
                , editado dia {moment(note.updatedAt).format("DD [às] HH:MM")}
              </span>
            )}
          </span>
          <Mutation
            mutation={GQL_DESTROY_NOTE}
            variables={{ id: note.id }}
            onError={this.handleMutationError}
            update={this.removeNoteFromCache}
          >
            {(destroyNote, { loading, error, data }) => {
              if (loading) return <GqlLoadingIcon />;
              if (error) return <GqlError error={error} />;

              const f = () => {
                if (window.confirm("Excluir?")) {
                  destroyNote();
                  analytics.track("Note Deleted");
                }
              };

              return <MdDelete className={style.Delete} onClick={f} />;
            }}
          </Mutation>
        </div>
      </div>
    );
  }

  renderList() {
    return (
      <Query
        query={GQL_NOTES}
        variables={{ customerId: this.props.customer.id }}
      >
        {({ loading, error, data, refetch }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          return (
            <ul className="vertical">
              {_.map(this.groupMonthItems(data.notes), (notes, month) => (
                <div key={`Notes:Month:${month}`} className="mb-4">
                  <div className={style.MonthGroup}>
                    {moment(month).format("MMMM [de] YYYY").toLocaleLowerCase()}
                  </div>
                  <div>
                    {_.map(
                      _.orderBy(notes, ["createdAt"], ["desc"]),
                      (note) => (
                        <li key={note.id}>{this.renderNote(note)}</li>
                      )
                    )}
                  </div>
                </div>
              ))}
            </ul>
          );
        }}
      </Query>
    );
  }

  renderSaveButton() {
    return (
      <Mutation
        mutation={GQL_CREATE_NOTE}
        variables={{
          customerId: this.props.customer.id,
          text: this.state.text,
        }}
        onError={this.handleMutationError}
        onCompleted={this.clearText}
        update={this.addNoteToCache}
      >
        {(createNote, { loading, error, data }) => {
          if (loading) return <GqlLoadingIcon />;
          if (error) return <GqlError error={error} />;

          const action = () => {
            createNote();
            analytics.track("Note Created");
          };

          return (
            <Button
              primary
              className="mt-3"
              onClick={action}
              disabled={String(this.state.text).length < 3}
            >
              Guardar nota
            </Button>
          );
        }}
      </Mutation>
    );
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    return (
      <div>
        <CustomerTabs
          activeTab={tabs.NOTES}
          customer={this.props.customer}
          goto={this.goto}
        />
        <div className={style.ModalBody}>
          <div className="pt-4 pb-3">
            <Input
              multiline={true}
              onChange={this.handleText}
              value={this.state.text}
              placeholder="Deixe uma nota..."
              autofocus
            />
            <div className="text-right">{this.renderSaveButton()}</div>
          </div>
          <div className="pt-3 pb-2">{this.renderList()}</div>
        </div>
      </div>
    );
  }
}
