export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function validEmail(email) {
  const parts = String(email).split('@')
  if (parts.length !== 2)
    return false

  const ps = parts[1].split('.')
  if (ps.length === 0)
    return false

  const tld = ps[ps.length - 1]
  if (tld.length < 2 || tld === 'co')
    return false

  return EMAIL_REGEX.test(String(email).toLowerCase())
}
