import React from 'react'

import style from './OrbitsLoader.module.css'

function OrbitsLoader() {
//  const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
//
//  if(!isChrome) {
//    document.getElementsByClassName('infinityChrome')[0].style.display = "none";
//    document.getElementsByClassName('infinity')[0].style.display = "block";
//  }

  return (
    <div className={style.Main}>
      {/* Chrome. */}
      <div className={style.infinityChrome}>
        <div />
        <div />
        <div />
      </div>

      {/* Safari and others. */}
      <div className={style.infinity}>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
        <div>
          <span />
        </div>
      </div>
    </div>
  )
}

export default OrbitsLoader